import React, { useState } from 'react'
import { Select } from '@mantine/core'

import { AttendeeFieldCard } from '@/components/Event/NewScript/components/Review/AttendeeFieldCard/AttendeeFieldCard'
import {
  TURNAROUND_TIME_LABEL,
  TURNAROUND_TIME_OPTIONS,
  TurnaroundHours,
} from '@/constants'
import { ScriptAttendee } from '@/types'

type AttendeeWithTranscriptFieldCardProps = {
  attendee: ScriptAttendee
  onSubmitClick: (value: string | null) => void
}

export const AttendeeWithTranscriptFieldCard: React.FC<
  AttendeeWithTranscriptFieldCardProps
> = ({ attendee, onSubmitClick }) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    attendee.transcript_order,
  )

  return (
    <AttendeeFieldCard
      key={attendee.attendee_id}
      attendee={attendee}
      fieldValue={
        TURNAROUND_TIME_LABEL[selectedValue ?? TurnaroundHours.ROUGH_DRAFT]
      }
      onCancelClick={() => setSelectedValue(attendee.transcript_order)}
      onSubmitClick={() => onSubmitClick(selectedValue)}
      fieldInput={
        <Select
          data={TURNAROUND_TIME_OPTIONS}
          value={selectedValue ?? TurnaroundHours.ROUGH_DRAFT.toString()}
          onChange={(value: string) => {
            setSelectedValue(
              value === TurnaroundHours.ROUGH_DRAFT.toString() ? null : value,
            )
          }}
          placeholder="Certified not ordered"
        />
      }
    />
  )
}
