import { Text, Title } from '@mantine/core'

export const DetailRow = ({
  label,
  value,
}: {
  label: string
  value?: string | number | null
}) => (
  <div>
    <Title size={14} color="gray.9" fw={600}>
      {label}
    </Title>
    <Text color="gray.8" size="sm">
      {value ?? 'N/A'}
    </Text>
  </div>
)
