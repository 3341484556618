import React from 'react'
import { LoadingOverlay } from '@mantine/core'

import { FilesModal } from './FilesModal'

import { MediaType } from '@/constants'
import { useMediaResourcesQuery } from '@/queries/events'
import { Event } from '@/types'

export const DepositionNoticesModal: React.FC<{
  event: Event
  onClose: () => void
}> = ({ event, onClose }) => {
  const { data: mediaResources, isLoading } = useMediaResourcesQuery(event)

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <FilesModal
        files={(mediaResources ?? [])
          .filter(
            resource => resource.media_type === MediaType.DEPOSITION_NOTICE,
          )
          .map(resource => ({
            key: resource.id,
            name: resource.original_name ?? 'Deposition notice',
            url: resource.url,
          }))}
        empty="There are no deposition notices"
        onClose={onClose}
      />
    </>
  )
}
