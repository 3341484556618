import React from 'react'
import { Select, SelectProps } from '@mantine/core'

import { TurnaroundHours } from '@/constants'

export const TURNAROUND_TIME_LABEL = {
  [TurnaroundHours.ROUGH_DRAFT]: 'Certified transcript not ordered',
  [TurnaroundHours.SEVEN_DAYS]: 'Standard 7–10 Day Turnaround',
  [TurnaroundHours.THREE_DAYS]: 'Faster 3 Day Turnaround',
  [TurnaroundHours.ONE_DAY]: 'Surge 1 Day Turnaround',
}

const ROUGH_DRAFT_TURNAROUND_TIME_OPTION = {
  label: TURNAROUND_TIME_LABEL[TurnaroundHours.ROUGH_DRAFT],
  value: TurnaroundHours.ROUGH_DRAFT.toString(),
}

const CERTIFIED_TRANSCRIPT_TURNAROUND_TIME_OPTIONS = [
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.SEVEN_DAYS],
    value: TurnaroundHours.SEVEN_DAYS.toString(),
  },
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.THREE_DAYS],
    value: TurnaroundHours.THREE_DAYS.toString(),
  },
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.ONE_DAY],
    value: TurnaroundHours.ONE_DAY.toString(),
  },
]

type Props = {
  value: TurnaroundHours | null
  onChange: (value: TurnaroundHours | null) => void
  withRoughDraftOption: boolean
} & Omit<SelectProps, 'label' | 'placeholder' | 'data' | 'onChange' | 'value'>

export const TurnaroundHoursSelect: React.FC<Props> = ({
  value,
  onChange,
  withRoughDraftOption = true,
  ...rest
}) => {
  const data = [
    ...(withRoughDraftOption ? [ROUGH_DRAFT_TURNAROUND_TIME_OPTION] : []),
    ...CERTIFIED_TRANSCRIPT_TURNAROUND_TIME_OPTIONS,
  ]

  return (
    <Select
      label="Transcription turnaround hours"
      placeholder="Pick one"
      data={data}
      {...rest}
      onChange={val => onChange(val ? parseInt(val) : null)}
      value={value ? value.toString() : null}
    />
  )
}
