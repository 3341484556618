import React, { useState } from 'react'
import { Stack } from '@mantine/core'

import { AttendeeCardEdit } from './AttendeeCardEdit'
import { AttendeeCardView } from './AttendeeCardView'

import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useEventZoomParticipants } from '@/components/Event/NewScript/queries/scriptData'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  attendee: ScriptAttendee
  onSubmitClick?: (attendee: ScriptAttendee) => void
  emailEmptySlot?: React.ReactNode
  event: Event
  isPresenceDisabled: boolean
  isWithZoomParticipants?: boolean
  namePrefix: string
}

export const AttendeeCard: React.FC<Props> = ({
  attendee,
  onSubmitClick,
  emailEmptySlot,
  children,
  event,
  isPresenceDisabled,
  isWithZoomParticipants = true,
  namePrefix,
}) => {
  const {
    actions: { updateAttendee },
  } = useScriptState(event)
  const { data: eventParticipants } = useEventZoomParticipants(event, {
    enabled: isWithZoomParticipants,
  })
  const [edit, setEdit] = useState<boolean>(false)

  const onEditClickHandler = () => {
    setEdit(true)
  }

  const onSubmitClickHandler = (attendee: ScriptAttendee) => {
    if (onSubmitClick) {
      onSubmitClick(attendee)
    } else {
      updateAttendee(attendee)
    }
    setEdit(false)
  }

  const onCancelClick = () => {
    setEdit(false)
  }

  return (
    <Stack>
      <ActionContainer>
        <Stack>
          {edit ? (
            <AttendeeCardEdit
              isPresenceDisabled={isPresenceDisabled}
              timezone={event.team.timezone}
              eventType={event.type}
              attendee={attendee}
              zoomParticipants={
                isWithZoomParticipants && eventParticipants
                  ? eventParticipants
                  : []
              }
              namePrefix={namePrefix}
              onSubmitClick={onSubmitClickHandler}
              onCancelClick={onCancelClick}
            />
          ) : (
            <AttendeeCardView
              attendee={attendee}
              onEditClick={onEditClickHandler}
              emailEmptySlot={emailEmptySlot}
            />
          )}
          {children}
        </Stack>
      </ActionContainer>
    </Stack>
  )
}
