import React from 'react'
import {
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { ActionIcon, Button, Stack } from '@mantine/core'
import { Cross2Icon } from '@radix-ui/react-icons'
import { v4 as uuid } from 'uuid'

import { AttendeeForm } from '@/components/Event/NewScript/components/common/forms/AttendeeForm'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { EventType } from '@/constants'
import { useModals } from '@/hooks/useModals'
import { Attendee, ScriptAttendee, ZoomParticipant } from '@/types'

export const AttendeesForm: React.FC<{
  zoomParticipants: ZoomParticipant[]
  eventType: EventType
  timezone: string
  eventAttendees: Attendee[]
  isPresenceDisabled: boolean
}> = ({
  zoomParticipants,
  eventType,
  timezone,
  eventAttendees,
  isPresenceDisabled,
}) => {
  const methods = useFormContext<{ attendees: ScriptAttendee[] }>()

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'attendees',
  })

  const { openConfirmModal } = useModals()

  const attendees = useWatch({
    control: methods.control,
    name: 'attendees',
  })

  const addNewAttendee = () => {
    const attendee = {
      attendee_id: uuid(),
      name: '',
      email: '',
      role: null,
      present: null,
      transcript_order: null,
      identity_document: null,
      zoom_participants: [],
    }
    // TODO: figure out how to address initial null value
    append(attendee as unknown as ScriptAttendee)
    methods.trigger('attendees')
  }

  const removeAttendeeHandler = (fieldId: string, attendee: ScriptAttendee) => {
    const removeAttendee = () => {
      remove(fields.findIndex(f => f.id === fieldId))
      methods.trigger('attendees')
    }

    const attendeeFromState = attendees.find(
      (a: ScriptAttendee) => a.attendee_id === attendee.attendee_id,
    )

    if (attendeeFromState !== undefined) {
      if (attendeeFromState.name !== '' || attendeeFromState.email !== '') {
        openConfirmModal({
          title: 'Are you sure?',
          onConfirm: removeAttendee,
          children: (
            <div>
              Are you sure you want to remove this attendee? This action is not
              reversible.
            </div>
          ),
          labels: {
            cancel: 'Cancel',
            confirm: 'Remove',
          },
        })
      } else {
        removeAttendee()
      }
    }
  }

  return (
    <Stack align="flex-start">
      <FormProvider {...methods}>
        {fields.map((field, number) => (
          <ActionContainer key={field.id}>
            {!eventAttendees.some(e => e.id === field.attendee_id) && (
              <ActionIcon
                title="Remove attendee"
                color="primary"
                variant="light"
                sx={theme => ({
                  position: 'absolute',
                  top: theme.spacing.xs,
                  right: theme.spacing.xs,
                  ':hover': {
                    backgroundColor: theme.colors.indigo[0],
                  },
                })}
                onClick={() => removeAttendeeHandler(field.id, field)}
              >
                <Cross2Icon />
              </ActionIcon>
            )}
            <Stack>
              <AttendeeForm
                isPresenceDisabled={isPresenceDisabled}
                eventType={eventType}
                timezone={timezone}
                namePrefix={`attendees.${number}`}
                zoomParticipants={zoomParticipants}
              />
            </Stack>
          </ActionContainer>
        ))}
      </FormProvider>
      <Button
        variant="subtle"
        compact
        styles={{ root: { paddingLeft: 0, paddingRight: 0 } }}
        onClick={addNewAttendee}
      >
        Add new attendee
      </Button>
    </Stack>
  )
}
