import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Anchor, Group, Stack } from '@mantine/core'
import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons'

import { EditableInput } from './EditableInput'
import { FormErrors } from './FormErrors'

import { SelectInput } from '@/components/common/SelectInput'
import {
  ActionButton,
  ActionContainer,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import {
  ExhibitDesignation,
  ExhibitDesignationLabels,
  ReorderExhibitDirection,
} from '@/constants'
import { ShownExhibit } from '@/types'

export type FormFieldValues = Record<string, ShownExhibit[]>

type Props = {
  name: string
}

type ExhibitOrderingButtonsProps = {
  onReorderExhibit: (direction: ReorderExhibitDirection) => void
  disableUpButton: boolean
  disableDownButton: boolean
}

const ExhibitOrderingButtons: React.FC<ExhibitOrderingButtonsProps> = ({
  onReorderExhibit,
  disableUpButton,
  disableDownButton,
}) => {
  return (
    <Stack spacing={0}>
      <ActionButton
        p="xs"
        style={{
          zIndex: 1,
          width: 'fit-content',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onClick={() => onReorderExhibit(ReorderExhibitDirection.UP)}
        disabled={disableUpButton}
      >
        <ArrowUpIcon width={16} height={16} />
      </ActionButton>
      <ActionButton
        p="xs"
        mt={-1}
        style={{
          width: 'fit-content',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        onClick={() => onReorderExhibit(ReorderExhibitDirection.DOWN)}
        disabled={disableDownButton}
      >
        <ArrowDownIcon width={16} height={16} />
      </ActionButton>
    </Stack>
  )
}

export const ShownExhibitsFieldArray: React.FC<Props> = ({ name }) => {
  const {
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<FormFieldValues>()
  const { fields, swap, update } = useFieldArray<FormFieldValues>({
    control,
    name,
  })

  const handleReorderExhibit = (
    index: number,
    direction: ReorderExhibitDirection,
  ) => {
    if (
      (direction === ReorderExhibitDirection.UP && index === 0) ||
      (direction === ReorderExhibitDirection.DOWN &&
        index === fields.length - 1)
    ) {
      return
    }

    const newIndex = index + (direction === ReorderExhibitDirection.UP ? -1 : 1)
    setValue(`${name}.${index}.index`, newIndex)
    setValue(`${name}.${newIndex}.index`, index)
    swap(index, newIndex)
  }

  return (
    <Stack>
      {fields.map((field, index) => (
        <ActionContainer key={field.id}>
          <Group position="apart">
            <Stack>
              <EditableInput
                field={field}
                onChange={value => {
                  update(field.index, { ...field, name: value })
                  // need to trigger validation
                  trigger(name)
                }}
              />
              <Group>
                <SelectInput
                  name={`${name}[${index}].designation`}
                  control={control}
                  data={Object.values(ExhibitDesignation).map(type => ({
                    label: ExhibitDesignationLabels[type],
                    value: type,
                  }))}
                />
                <Anchor
                  href={field.download_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </Anchor>
              </Group>
              {errors[name]?.[index] && (
                <FormErrors<ShownExhibit> errors={errors[name]?.[index]} />
              )}
            </Stack>
            <ExhibitOrderingButtons
              onReorderExhibit={(direction: ReorderExhibitDirection) =>
                handleReorderExhibit(field.index, direction)
              }
              disableUpButton={field.index === 0}
              disableDownButton={field.index === fields.length - 1}
            />
          </Group>
        </ActionContainer>
      ))}
    </Stack>
  )
}
