import React from 'react'
import { Controller } from 'react-hook-form'
import { Select, SelectProps } from '@mantine/core'

type Props = {
  name: string
  control: any // TODO: Fix type
} & SelectProps

export const SelectInput: React.FC<Props> = ({ name, control, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select value={value} onChange={onChange} {...props} />
      )}
    />
  )
}
