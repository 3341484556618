import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Stack } from '@mantine/core'

import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { LowConfidenceWordInput } from '@/components/Event/NewScript/components/Review/LowConfidenceWordInput'
import { Event, LowConfidenceWord } from '@/types'

type FormFieldValues = Record<string, LowConfidenceWord[]>

export const LowConfidenceWordsFieldArray: React.FC<{
  name: keyof FormFieldValues
  event: Event
}> = ({ name, event }) => {
  const { control, formState } = useFormContext<FormFieldValues>()
  const { fields } = useFieldArray<FormFieldValues>({
    control,
    name,
  })

  return (
    <Stack spacing="xs">
      {fields.map((field, number) => (
        <Controller
          key={field.id}
          control={control}
          name={`${name}.${number}`}
          render={({ field }) => (
            <LowConfidenceWordInput
              {...field}
              event={event}
              error={
                formState.errors[name]?.[number] && (
                  <FormErrors<LowConfidenceWord>
                    errors={formState.errors[name]?.[number]}
                  />
                )
              }
            />
          )}
        />
      ))}
    </Stack>
  )
}
