import { useMutation, useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import axios from 'axios'

import { UploadedFile } from '@/components/common/Uploader'
import { AttendeeRole, SAVE_REPORTER_FEEDBACK_MUTATION_KEY } from '@/constants'
import {
  AsrWord,
  Event,
  EventResult,
  Exhibit,
  JoinInfo,
  MediaResource,
  Reporter,
  ShownExhibit,
  TranscriptOrders,
} from '@/types'

export type CreateBookingPayload = {
  reporter_id: Reporter['id']
}

export const useBookMutation = (
  event: Event,
  options: {
    onSuccess: () => void
    onReporterSettingsMissing?: () => void
    onReporterCommissionExpired?: () => void
    onErrorMessage: (error: string) => void
  },
  params?: { book_related_events: boolean },
) =>
  useMutation((payload: CreateBookingPayload) => {
    return axios
      .post(event.booking_url, payload, { params })
      .then(options.onSuccess, error => {
        if (error.response?.status === 400) {
          if (error.response?.data?.detail === 'Missing reporter settings') {
            options.onReporterSettingsMissing?.()
            return
          } else if (
            error.response?.data?.detail ===
            'Reporter commission will be expired'
          ) {
            options.onReporterCommissionExpired?.()
            return
          } else {
            options.onErrorMessage(error.response?.data?.detail)
            return
          }
        }

        throw error
      })
  })

export const useUnbookMutation = (
  event: Event,
  options: {
    onSuccess: () => void
    onErrorMessage: (error: string) => void
  },
  params?: { unbook_related_events: boolean },
) =>
  useMutation(() => {
    return axios
      .delete(event.booking_url, { params })
      .then(options.onSuccess, error => {
        if (error.response?.status === 400) {
          options.onErrorMessage(error.response?.data?.detail)
          return
        }

        throw error
      })
  })

export type AttendeeData = {
  name: string
  role: AttendeeRole
  email: string | null
  present: boolean
}

export type ReporterFeedbackPayload = {
  reporter_state: string
  reporter_county: string
  event_status: EventResult
  witness_requested_transcript: boolean
  transcript_ordered: boolean
  turnaround_hours: number | null
  cna_declared_at: string | null
  cna_type: string | null
  cna_type_other_text: string | null
  id_type: string | null
  id_number: string | null
  id_screenshots: UploadedFile[]
  timestamp_log: string | null
  additional_notes: string | null
  attendees: AttendeeData[]
  transcript_orders: TranscriptOrders | null
}

export const useReporterFeedbackQuery = (event: Event) =>
  useQuery<ReporterFeedbackPayload>(['reporter-feedback', event.id], () => {
    return axios
      .get(event.feedback_url)
      .then(r => r.data)
      .catch(e => {
        if (e.response.status === 404) {
          return
        }

        throw e
      })
  })

export const useSaveReporterFeedbackMutation = (
  event: Event,
  options: {
    onErrorMessage: (error: string) => void
  },
) =>
  useMutation(
    (payload: ReporterFeedbackPayload) => {
      return axios.put(event.feedback_url, payload).catch(error => {
        if (error.response?.status === 400) {
          options.onErrorMessage(error.response?.data?.detail)
          return
        }

        throw error
      })
    },
    { mutationKey: SAVE_REPORTER_FEEDBACK_MUTATION_KEY },
  )

export const useSubmitReporterFeedbackMutation = (
  event: Event,
  options: {
    onSuccess?: () => void
    onErrorMessage: (error: any) => void
  },
) =>
  useMutation((payload: ReporterFeedbackPayload) => {
    return axios
      .post(event.submit_feedback_url, payload)
      .then(options?.onSuccess, error => {
        if (error.response?.status === 400) {
          options.onErrorMessage(error.response?.data?.detail)
          return
        }

        throw error
      })
  })

export const useEndEventMutation = (
  event: Event,
  options: {
    onErrorMessage: (error: any) => void
    onSuccess?: () => void
    onError?: () => void
  },
  params?: { end_related_events: boolean },
) =>
  useMutation(
    () => {
      return axios
        .post(event.end_url, undefined, {
          params,
        })
        .then(options?.onSuccess, error => {
          if (error.response?.status === 400) {
            options.onErrorMessage(error.response?.data?.detail)
            return
          }

          throw error
        })
    },
    { onError: options.onError },
  )

export const useJoinInfoQuery = (
  event: Event,
  options?: { enabled: boolean },
) =>
  useQuery<JoinInfo>(
    ['join-info', event.id],
    () => axios.get(event.join_info_url).then(r => r.data),
    options,
  )

export const useExhibitsQuery = (event: Event) =>
  useQuery<Exhibit[]>(['exhibits', event.id], () =>
    axios.get(event.exhibits_url).then(r => r.data),
  )

export const useMediaResourcesQuery = (event: Event) =>
  useQuery<MediaResource[]>(['media-resources', event.id], () =>
    axios.get(event.media_resources_url).then(r => r.data),
  )

export const useLowConfidenceWordsQuery = (
  event: Event,
  options?: Omit<UseQueryOptions<AsrWord[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<AsrWord[]>(
    ['low-confidence-words', event.id],
    () => axios.get(event.low_confidence_words_url).then(r => r.data),
    options,
  )

export const useShownExhibitsQuery = (
  event: Event,
  options?: Omit<UseQueryOptions<ShownExhibit[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<ShownExhibit[]>(
    ['shown-exhibits', event.id],
    () =>
      axios
        .get(event.shown_exhibits_url)
        .then(r => r.data)
        // null value indicates that no exhibits were returned by the zoom app api
        .catch(() => null),
    options,
  )
