import React from 'react'
import { Stack } from '@mantine/core'

import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { IdentityDocumentInput } from '@/components/Event/NewScript/components/common/IdentityDocumentCard/IdentityDocumentInput'
import {
  ActionContainer,
  ActionText,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { getEventTypeShortLabel } from '@/components/Event/NewScript/utils/event'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  guardian: ScriptAttendee
  namePrefix: string
  attendeeChanged: (attendee: ScriptAttendee) => void
  event: Event
  isPresenceDisabled: boolean
  witnessName: string
}

export const GuardianInput: React.FC<Props> = ({
  guardian,
  namePrefix,
  attendeeChanged,
  event,
  isPresenceDisabled,
  witnessName,
}) => {
  const { identity_document: identityDocument } = guardian

  return (
    <>
      <ActionContainer outer>
        <Stack>
          <ActionText mono>
            Would the guardian for the witness identify yourself, confirm the
            nature of your relationship with the minor, and spell your last name
            for the record?
          </ActionText>
          <AttendeeCard
            attendee={guardian}
            onSubmitClick={attendeeChanged}
            event={event}
            isPresenceDisabled={isPresenceDisabled}
            namePrefix={namePrefix}
          />
        </Stack>
      </ActionContainer>
      <ActionContainer outer>
        <Stack>
          <ActionText mono>
            Mr/Ms. {guardian.name}, can you please hold a valid government photo
            identification up to the screen to verify your identity?
          </ActionText>
          <ActionContainer>
            <IdentityDocumentInput
              namePrefix={`${namePrefix}.identity_document`}
              attendee={guardian}
              hideButton
              showWarnings
              onSubmitClick={attendeeChanged}
            />
          </ActionContainer>
        </Stack>
      </ActionContainer>
      {identityDocument?.status === 'expired' && (
        <ActionContainer outer>
          <ActionText mono>
            For the record, the guardian has presented an expired ID for
            verification. Accordingly, do both sides stipulate to my
            administering the oath to this witness.
          </ActionText>
        </ActionContainer>
      )}
      {identityDocument?.status === 'not_present' && (
        <ActionContainer outer>
          <ActionText mono>
            For the record, the guardian has not presented an ID for
            verification. Accordingly, do both sides stipulate to my
            administering the oath to this witness.
          </ActionText>
        </ActionContainer>
      )}
      <ActionContainer outer>
        <ActionText mono>
          Do you consent to this {getEventTypeShortLabel(event)} being conducted
          for {witnessName}?
        </ActionText>
      </ActionContainer>
    </>
  )
}
