import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Stack } from '@mantine/core'

import { Event } from '@/types'

export const EventCompletedCard: React.FC<{ event: Event }> = ({ event }) => {
  return (
    <Alert
      style={{ width: '100%' }}
      title="Form submission successful"
      color="green"
    >
      <Stack align="flex-start">
        This is an admin view. If you wish to continue to “Past event” page
        (which is what DRs would see), click on the button below. You will be
        able to return to this new Script page any time later by adding
        “/new-script” to the URL.
        <Button component={Link} to={`/admin/event/${event.id}`} color="green">
          Visit "Past event" page
        </Button>
      </Stack>
    </Alert>
  )
}
