import React from 'react'
import { Alert } from '@mantine/core'

export const Note: React.FC = ({ children }) => {
  return (
    <Alert styles={{ root: { backgroundColor: 'transparent !important' } }}>
      {children}
    </Alert>
  )
}
