import React, { useCallback } from 'react'
import { Select, Text } from '@mantine/core'
import { useModals } from '@mantine/modals'

import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { TURNAROUND_TIME_OPTIONS, TurnaroundHours } from '@/constants'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  onChange: (attendee: ScriptAttendee) => void
  attendee: ScriptAttendee
  error: string | undefined
  event: Event
  isPresenceDisabled: boolean
  namePrefix: string
}

export const AttendeeTranscriptOrderCard: React.FC<Props> = ({
  attendee,
  onChange,
  error,
  event,
  isPresenceDisabled,
  namePrefix,
}) => {
  const { openConfirmModal } = useModals()

  const onTurnaroundHoursChange = useCallback(
    (value: string) => {
      if (value === TurnaroundHours.ONE_DAY.toString()) {
        openConfirmModal({
          title: (
            <Text size="xl" fw={600}>
              Confirm Surge 1 day turnaround
            </Text>
          ),
          onConfirm: () =>
            onChange({
              ...attendee,
              transcript_order: value,
            }),
          children: (
            <div>
              Surge turnaround is a non-standard more expensive option.{' '}
              <Text component="span" fw={600}>
                Please confirm that it was requested by the attendee.
              </Text>
            </div>
          ),
          labels: {
            cancel: 'Cancel',
            confirm: 'Confirm order',
          },
          centered: true,
          size: 'xl',
        })
      } else {
        onChange({
          ...attendee,
          transcript_order:
            value === TurnaroundHours.ROUGH_DRAFT.toString() ? null : value,
        })
      }
    },
    [attendee, onChange, openConfirmModal],
  )

  const onAttendeeChange = useCallback(
    (attendee: ScriptAttendee) => {
      onChange(attendee)
    },
    [onChange],
  )

  return (
    <AttendeeCard
      isPresenceDisabled={isPresenceDisabled}
      key={attendee.attendee_id}
      event={event}
      attendee={attendee}
      onSubmitClick={onAttendeeChange}
      emailEmptySlot={
        <Text color="red" italic>
          missing email address
        </Text>
      }
      namePrefix={namePrefix}
    >
      <Select
        value={
          attendee.transcript_order ?? TurnaroundHours.ROUGH_DRAFT.toString()
        }
        placeholder="Certified not ordered"
        data={TURNAROUND_TIME_OPTIONS}
        onChange={onTurnaroundHoursChange}
      />
      {!!error && (
        <FormErrors
          title="We have detected a missing email address."
          errors={error}
        />
      )}
    </AttendeeCard>
  )
}
