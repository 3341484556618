import React from 'react'
import { Menu, NavLink, Text } from '@mantine/core'
import { ChevronDownIcon } from '@radix-ui/react-icons'

import { StepNavLink } from '@/components/Event/NewScript/components/common/Stepper/StepNavLink'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepsNavigation } from '@/components/Event/NewScript/hooks/useStepsNavigation'
import { ScriptStep } from '@/constants'
import { Event } from '@/types'

type MenuStepsProps = {
  event: Event
}

export const MenuSteps: React.FC<MenuStepsProps> = ({ event }) => {
  const { setCurrentStep, isIncomplete, getNavColor, getStatusLabel } =
    useStepsNavigation(event)

  const {
    state: { current_step },
  } = useScriptState(event)

  const StepItem: React.FC<{ label: string; step: ScriptStep }> = ({
    label,
    step,
  }) => {
    return (
      <Menu.Item
        disabled={isIncomplete(step)}
        color={current_step === step ? 'blue' : undefined}
        data-hovered={true}
        onClick={() => setCurrentStep(step)}
      >
        <StepNavLink
          label={label}
          description={getStatusLabel(step)}
          color={getNavColor(step)}
        />
      </Menu.Item>
    )
  }
  return (
    <Menu shadow="md" width={280} position="bottom-start">
      <Menu.Target>
        <NavLink
          w="auto"
          pl={0}
          label={
            <Text c="black" fz="lg" fw={700}>
              Proceeding
            </Text>
          }
          color="black"
          rightSection={<ChevronDownIcon />}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <StepItem label="1. Preparation" step={ScriptStep.PREPARATION} />
        <StepItem label="2. Attendance" step={ScriptStep.ATTENDANCE} />
        <StepItem label="3. Opening" step={ScriptStep.OPENING} />
        <StepItem label="4. Questioning" step={ScriptStep.QUESTIONING} />
        <StepItem label="5. Closing" step={ScriptStep.CLOSING} />
        <StepItem label="6. Review" step={ScriptStep.REVIEW} />
      </Menu.Dropdown>
    </Menu>
  )
}
