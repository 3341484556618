import React from 'react'
import {
  Alert,
  Badge,
  Box,
  Button,
  Group,
  NavLink,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useModals } from '@mantine/modals'
import { ChevronRightIcon } from '@radix-ui/react-icons'

import { DetailRow } from './DetailRow'

import { useConnectionInfoModal } from '@/components/Event/ConnectionInformationButton'
import { useFilesModal } from '@/components/Event/hooks/useFilesModal'
import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { DepositionNoticesModal } from '@/components/Event/Modals/DepositionNoticesModal'
import { ExhibitsModal } from '@/components/Event/Modals/ExhibitsModal'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import {
  ATTENDEE_ROLE_LABEL,
  AttendeeRole,
  IN_EVENT_SUPPORT_URL,
} from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { useJoinZoomCall } from '@/hooks/useJoinZoomCall'
import { AdminEvent, Event, ScriptAttendee } from '@/types'
import { isAdminEvent } from '@/utils/parrot'
import {
  formatDate,
  formatDuration,
  formatTimeRange,
  formatTimezone,
} from '@/utils/time'

type DetailsProps = {
  event: Event | AdminEvent
}

export const Details: React.FC<DetailsProps> = ({ event }) => {
  const {
    state: { attendees },
  } = useScriptState(event)
  const { user } = useAuth()
  const modals = useModals()
  const canResetScript = user?.role === 'admin'

  const { hasTrainingAccess, resetScript, isResetting } =
    useNewScriptTraining(event)

  const openExhibitsModal = useFilesModal(event, 'Exhibits', ExhibitsModal)

  const openDepositionNoticesModal = useFilesModal(
    event,
    'Deposition notice',
    DepositionNoticesModal,
  )

  const openConfirmResetModal = () => {
    modals.openConfirmModal({
      id: 'confirm-reset-script',
      title: <Text fw={600}>Are you sure you want to reset script?</Text>,
      labels: { confirm: 'Reset script', cancel: 'Cancel' },
      children: (
        <Text>
          This will remove all the progress within the script. All forms and
          data added will be removed. This will put the script back to
          Preparation step. This action cannot be undone.
        </Text>
      ),
      onConfirm: resetScript,
    })
  }

  const { isFetching: isJoinZoomCallFetching, joinZoomCall } = useJoinZoomCall(
    event,
    !event.uses_external_zoom_meeting,
  )

  const { isFetching: isConnectionInfoModalFetching, fetchAndOpenModal } =
    useConnectionInfoModal(event)

  if (!event) {
    return null
  }
  const timeRange = formatTimeRange(event.datetime, event.duration)
  const durationStr = formatDuration(event.duration)
  const eventDuration = `${timeRange} (${durationStr})`
  const eventDate = formatDate(event.datetime, { long: true, separator: ', ' })
  const eventTimezone = formatTimezone(event.team.timezone)

  return (
    <Box w="100%">
      <Stack>
        <Title size="h4">Details</Title>
        <Stack spacing="sm">
          <NavLink
            fw={600}
            label="Join Zoom Meeting"
            active
            rightSection={<ChevronRightIcon />}
            disabled={isJoinZoomCallFetching}
            onClick={joinZoomCall}
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          />
          <NavLink
            fw={600}
            label="Dial-in Info"
            active
            rightSection={<ChevronRightIcon />}
            disabled={isConnectionInfoModalFetching}
            onClick={fetchAndOpenModal}
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          />
          {event.livestream_url && (
            <NavLink
              component={'a'}
              fw={600}
              label="Live transcript"
              active
              href={event.livestream_url}
              target="_blank"
              rightSection={<ChevronRightIcon />}
              sx={theme => ({
                borderRadius: theme.radius.sm,
              })}
            />
          )}

          <NavLink
            fw={600}
            label="Exhibits"
            active
            rightSection={<ChevronRightIcon />}
            onClick={openExhibitsModal}
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          />
          <NavLink
            fw={600}
            label="Notice"
            active
            rightSection={<ChevronRightIcon />}
            onClick={openDepositionNoticesModal}
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          />

          <NavLink
            component="a"
            fw={600}
            label="In-Event Support"
            active
            href={IN_EVENT_SUPPORT_URL}
            target="_blank"
            rightSection={<ChevronRightIcon />}
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          />

          {(hasTrainingAccess || canResetScript) && (
            <Alert color="red" title="Reset script">
              <Stack>
                <Text>
                  This will remove all progress and data added. The script will
                  be reverted to the initial state (back to Preparation step)
                </Text>
                <Button
                  color="red"
                  loading={isResetting}
                  onClick={openConfirmResetModal}
                >
                  Reset script
                </Button>
              </Stack>
            </Alert>
          )}
        </Stack>
        <Group position="left" my="xs">
          <Badge color="gray" size="md">
            Deposition
          </Badge>
          <Badge size="md">{event.status}</Badge>
        </Group>
        <Stack spacing="sm">
          <DetailRow label="Date" value={eventDate} />
          <DetailRow label="Time" value={eventDuration} />
          <DetailRow label="Event timezone" value={eventTimezone} />
          <DetailRow label="Proceeding" value={event.case.name} />
          <DetailRow label="Case number" value={event.case.number!} />
          <DetailRow
            label="Organization"
            value={event.team.organization.name}
          />
          <DetailRow
            label="Client location"
            value={`${event.team.state}: ${event.team.city} `}
          />
          {isAdminEvent(event) && (
            <DetailRow
              label="Assigned reporter"
              value={event?.reporter?.name}
            />
          )}
        </Stack>
        <Title size="h4" py="sm">
          Attendees
        </Title>
        <Stack spacing="sm">
          {attendees.map((attendee: ScriptAttendee) => (
            <DetailRow
              label={
                ATTENDEE_ROLE_LABEL[attendee.role as AttendeeRole] || 'Unknown'
              }
              value={attendee.name}
              key={attendee.attendee_id}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}
