import { useCallback, useState } from 'react'

export type Warnings<T> = Record<keyof T, Set<string>>

export const useFieldWarnings = <T>() => {
  const [warnings, setWarnings] = useState<Warnings<T>>({} as Warnings<T>)
  const setFieldWarning = (fieldName: keyof T, message: string) => {
    setWarnings(prev => {
      if (!prev[fieldName]) {
        prev[fieldName] = new Set([])
      }
      prev[fieldName].add(message)
      return { ...prev }
    })
  }

  const resetWarnings = () => setWarnings({} as Warnings<T>)

  const getWarningsMessages = useCallback(
    () => Object.values(warnings).flatMap(v => Array.from(v as Set<string>)),
    [warnings],
  )

  return {
    warnings,
    resetWarnings,
    setFieldWarning,
    getWarningsMessages,
  }
}
