import React, { useEffect, useState } from 'react'
import {
  Container,
  Group,
  Paper,
  Text,
  TypographyStylesProvider,
} from '@mantine/core'
import enUS from 'date-fns/locale/en-US'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

import { DepositionScript } from './Event/Script/DepositionScript'
import { EuoScript } from './Event/Script/EuoScript'
import { SwearInScript } from './Event/Script/SwearInScript'
import { Shell } from './Shell'

import { HearingScript } from '@/components/Event/Script/HearingScript'
import { AttendeeRole } from '@/constants'
import { Event, Reporter } from '@/types'

export type Helpers = {
  day_of_week: string
  date: string
  time: string

  start_time: string

  plaintiff: string
  defendant: string
  witness: string
}

export const ScriptScreen: React.FC<{ event: Event; reporter: Reporter }> = ({
  event,
  reporter,
}) => {
  const ScriptText = {
    deposition: DepositionScript,
    examination_under_oath: EuoScript,
    meet_and_confer: SwearInScript,
    hearing: HearingScript,
  }[event.type]

  const helpers = useHelpers(() => ({
    day_of_week: format(new Date(), 'EEEE'),
    date: format(new Date(), 'LLLL d, y'),
    time: format(
      utcToZonedTime(new Date(), event.team.timezone),
      'hh:mmaaa (zzz)',
      { timeZone: event.team.timezone, locale: enUS },
    ),

    start_time: format(
      utcToZonedTime(parseISO(event.datetime), event.team.timezone),
      'hh:mmaaa (zzz)',
      { timeZone: event.team.timezone, locale: enUS },
    ),

    plaintiff:
      event.attendees.find(a => a.role === AttendeeRole.PLAINTIFF_ATTORNEY)
        ?.name ?? 'PLAINTIFF NAME',
    defendant:
      event.attendees.find(a => a.role === AttendeeRole.DEFENDANT_ATTORNEY)
        ?.name ?? 'DEFENDANT NAME',
    witness:
      event.attendees.find(a => a.role === AttendeeRole.WITNESS)?.name ??
      'WITNESS NAME',
  }))

  return (
    <Shell
      header={{
        middle: (
          <Group spacing={5}>
            <Text size="sm">{event.name}</Text>
            <Text size="sm"> / </Text>
            <Text size="sm" weight={600}>
              Script
            </Text>
          </Group>
        ),
      }}
    >
      <Container>
        <Paper p="xl">
          <TypographyStylesProvider>
            <ScriptText event={event} reporter={reporter} helpers={helpers} />
          </TypographyStylesProvider>
        </Paper>
      </Container>
    </Shell>
  )
}

const useHelpers = (factory: () => Helpers): Helpers => {
  const [state, setState] = useState(factory())

  // we update the helpers every 30 seconds,
  // so `new Date()` shows the correct time
  // when reporter gets to the specific part
  useEffect(() => {
    const id = setInterval(() => {
      setState(factory())
    }, 30 * 1000)

    return () => clearInterval(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return state
}
