import React from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Container,
  LoadingOverlay,
  Paper,
  Stack,
} from '@mantine/core'

import { EventTitle } from '@/components/common/EventTitle'
import { AvailableEventDetail } from '@/components/Event/Detail/AvailableEventDetail'
import { BookedEventDetail } from '@/components/Event/Detail/BookedEventDetail'
import { CancelledEventDetail } from '@/components/Event/Detail/CancelledEventDetail'
import { PastEventDetail } from '@/components/Event/Detail/PastEventDetail'
import { UnavailableEventDetail } from '@/components/Event/Detail/UnavailableEventDetail'
import { WaitingEventDetail } from '@/components/Event/Detail/WaitingEventDetail'
import { canUseDRScriptForEvent } from '@/components/Event/NewScript/utils/event'
import { Shell } from '@/components/Shell'
import { useAdminEventQuery } from '@/queries/adminEvents'
import { ReporterFeedbackSaveStatus } from '@/routes/Event/components/ReporterFeedbackSaveStatus'
import { NotFound } from '@/routes/NotFound'

export const Event = () => {
  const { id } = useParams<{ id: string }>()

  const {
    data: event,
    isLoading,
    refetch,
  } = useAdminEventQuery(id, {
    refetchInterval: 20000,
    refetchOnWindowFocus: true,
  })

  if (isLoading) {
    return (
      <Shell>
        <LoadingOverlay visible={true} />
      </Shell>
    )
  }

  if (event === undefined) {
    return <NotFound text="Event not found" />
  }

  const Detail = {
    available: AvailableEventDetail,
    booked: BookedEventDetail,
    past: PastEventDetail,
    waiting: WaitingEventDetail,
    cancelled: CancelledEventDetail,
    unavailable: UnavailableEventDetail,
  }[event.general_status]

  return (
    <Shell
      header={{
        middle: <EventTitle>{event.name}</EventTitle>,
        right: <ReporterFeedbackSaveStatus />,
      }}
    >
      <Container size="lg">
        <Paper p="xl">
          {event && canUseDRScriptForEvent(event) && (
            <Alert
              title="New Script should be used for this event!"
              color="red"
              mb="xl"
            >
              <Stack align="flex-start">
                If you wish to check the new DR script data or submit it, please
                click on the button below.
                <Button
                  component={Link}
                  to={`/admin/event/${event.id}/new-script`}
                  color="red"
                >
                  Visit "New script" page
                </Button>
              </Stack>
            </Alert>
          )}
          <Detail event={event} onChange={refetch} />
        </Paper>
      </Container>
    </Shell>
  )
}
