import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react'

const NAMESPACE = import.meta.env.VITE_AUTH0_NAMESPACE

export type Role = 'admin' | 'reporter'

export type AuthUser = User & { id: string; role: Role; featureFlags: string[] }

export const useAuth = (): Auth0ContextInterface<AuthUser> => {
  const auth0 = useAuth0()
  const id = auth0.user?.sub?.split('|')[1] as string
  const groups = auth0.user?.[`${NAMESPACE}/groups`]
  const isInternal = auth0.user?.[`${NAMESPACE}/is-internal`]
  const isReportersAdmin = groups?.includes('reporters-admins')
  // if user is internal (have Parrot Google account) but doesn't have sufficient
  // permissions to access Reporters app (are not in correct user group) then we
  // will treat them as reporters
  const role = isInternal && isReportersAdmin ? 'admin' : 'reporter'

  const featureFlags = auth0.user?.[`${NAMESPACE}/feature-flags`] || []

  return {
    ...auth0,
    user: auth0.user ? { ...auth0.user, id, role, featureFlags } : undefined,
  }
}
