import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm as useMantineForm } from '@mantine/form'
import { FormValidateInput, UseFormReturnType } from '@mantine/form/lib/types'
import { showNotification } from '@mantine/notifications'
import isEqual from 'lodash/isEqual'

export const useForm = <T extends Record<string, any>>({
  onChange,
  ...args
}: {
  validate?: FormValidateInput<T>
  onChange?: (values: T) => void
  initialValues: T
  validateInputOnChange?: boolean
  validateInputOnBlur?: boolean
}) => {
  const form = useMantineForm(args)

  useOnFormChanged(form, onChange)

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(
    (cb, cbWithErrors?) => {
      return form.onSubmit(values => {
        setSubmitting(true)

        cb(values)
          .then(() => {
            setSubmitting(false)
            form.clearErrors()
          })
          .catch((e: { response: { status: number } }) => {
            setSubmitting(false)

            if (!e?.response) {
              return
            }

            if (e.response?.status === 422) {
              setFormErrorsFromFastApiErrorResponse(e, form)
            } else {
              showNotification({
                message:
                  'There was an error while submitting the form. Please try again',
                color: 'red',
              })
            }
          })
      }, cbWithErrors)
    },
    [form],
  )

  return {
    ...form,

    // we override `onSubmit` such that it requires the consumer to pass in a callback
    // that returns a promise. this allows us to add features like `submitting`.
    onSubmit,

    // we provide the `submitting` flag for the consumer to disable/enable the submit button
    submitting,
  }
}

export const setFormErrorsFromFastApiErrorResponse = <
  T extends Record<string, any>,
>(
  error: any,
  form: UseFormReturnType<T>,
) => {
  form.clearErrors()

  const errors = parseFastApiErrorResponse(error.response.data)

  for (const [field, message] of Object.entries<string>(errors)) {
    form.setFieldError(field, message)
  }
}

// Convert fastapi-formatted error response
// to a mapping of field name to error message.
export const parseFastApiErrorResponse = <T>(response: {
  detail: { loc: string[]; msg: string; type: string }[]
}): Partial<Record<keyof T, string>> => {
  const errors: Partial<Record<keyof T, string>> = {}

  /*
   validation error response format:
   {
       "detail": [
           {
               "loc": ["body", <field_name>],
               "msg": <error_message>,
               "type": <error_type>,
           }
       ]
   },
   */
  for (const error of response.detail) {
    // create a string representing the path where the field is located from the response
    // we have to skip the body tag
    // example: test_form.array_field.0.target_field
    const key = error.loc.slice(1).join('.')
    errors[key as keyof T] = error.msg
  }

  return errors
}

const useOnFormChanged = <T extends Record<string, any>>(
  form: UseFormReturnType<T>,
  onChange?: (values: T) => void,
) => {
  const prevProps = useRef<T>(form.values)

  useEffect(() => {
    if (onChange && !isEqual(prevProps.current, form.values)) {
      prevProps.current = form.values
      onChange(form.values)
    }
  }, [onChange, prevProps, form.values])
}
