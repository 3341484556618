import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { ScriptStep, ScriptStepStatus } from '@/constants'
import { Event } from '@/types'

const stepStatusLabels = {
  [ScriptStepStatus.IN_PROGRESS]: 'In progress',
  [ScriptStepStatus.COMPLETED]: 'Completed',
  [ScriptStepStatus.INCOMPLETE]: 'Incomplete',
  [ScriptStepStatus.SKIPPED]: 'Skipped',
}

export const useStepsNavigation = (event: Event) => {
  const { state, actions } = useScriptState(event)

  const getStatus = (step: ScriptStep): ScriptStepStatus => {
    return state.steps[step].status
  }

  const getStatusLabel = (step: ScriptStep): string => {
    return stepStatusLabels[getStatus(step)]
  }

  const isInProgress = (step: ScriptStep): boolean => {
    return getStatus(step) === ScriptStepStatus.IN_PROGRESS
  }

  const isIncomplete = (step: ScriptStep): boolean => {
    return getStatus(step) === ScriptStepStatus.INCOMPLETE
  }

  const isCompleted = (step: ScriptStep) => {
    return getStatus(step) === ScriptStepStatus.COMPLETED
  }

  const isSkipped = (step: ScriptStep) => {
    return getStatus(step) === ScriptStepStatus.SKIPPED
  }

  const setCurrentStep = (step: ScriptStep) => {
    actions.updateCurrentStep(step)
  }

  const getNavColor = (step: ScriptStep): string | undefined => {
    if (isInProgress(step)) {
      return 'blue'
    } else if (isCompleted(step)) {
      return 'green'
    } else if (isSkipped(step)) {
      return 'gray'
    } else {
      return undefined
    }
  }

  return {
    getStatus,
    isInProgress,
    isIncomplete,
    isCompleted,
    setCurrentStep,
    getNavColor,
    getStatusLabel,
  }
}
