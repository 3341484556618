import React, { useState } from 'react'

import { EventCancelledCardForm } from './EventCancelledCardForm'
import { EventCancelledCardView } from './EventCancelledCardView'

import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { Event } from '@/types'

type EventCancelledCardProps = {
  event: Event
}

export const EventCancelledCard: React.FC<EventCancelledCardProps> = ({
  event,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const { state } = useScriptState(event)

  return isEdit ? (
    <EventCancelledCardForm
      event={event}
      onSubmitClick={() => setIsEdit(false)}
    />
  ) : (
    <EventCancelledCardView
      event={event}
      onEditClick={() => setIsEdit(true)}
      depositionEnded={state.result}
      notes={state.notes ?? ''}
    />
  )
}
