import React from 'react'
import { Select } from '@mantine/core'

import { ReporterBase } from '@/types'
import { STATES } from '@/utils/states'

export const ReporterSelect: React.FC<
  {
    reporters: ReporterBase[]
    value?: ReporterBase['id']
    onChange: (value?: ReporterBase['id']) => void
    showLevel?: boolean
    showState?: boolean
  } & Omit<React.ComponentProps<typeof Select>, 'value' | 'data' | 'onChange'>
> = ({
  reporters,
  value,
  onChange,
  showLevel = true,
  showState = false,
  ...props
}) => {
  const reporterLabel = ({ name, state, experience_level }: ReporterBase) => {
    const info: string[] = []

    if (showLevel) {
      info.push(`L${experience_level}`)
    }
    if (showState) {
      info.push(state !== undefined ? STATES[state] : '--')
    }

    return `${name}` + (info.length > 0 ? ` (${info.join(' ')})` : '')
  }

  return (
    <Select
      {...props}
      data={reporters.map(reporter => ({
        value: reporter.id,
        label: reporterLabel(reporter),
      }))}
      disabled={reporters.length === 0}
      value={value ?? null}
      onChange={value => onChange((value as ReporterBase['id']) ?? undefined)}
      searchable
    />
  )
}
