import { Global } from '@mantine/core'

import mediumWOFF from './assets/fonts/aeonik-mono-medium/AeonikMono-Medium.woff'
import mediumWOFF2 from './assets/fonts/aeonik-mono-medium/AeonikMono-Medium.woff2'

export const CustomFonts = () => (
  <Global
    styles={{
      '@font-face': {
        fontFamily: 'Aeonik Mono Medium',
        src: `url('${mediumWOFF2}') format("woff2"), url('${mediumWOFF}') format("woff")`,
        fontWeight: 500,
        fontStyle: 'normal',
        fontDisplay: 'swap',
      },
    }}
  />
)
