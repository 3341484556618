import React from 'react'

import parrotZoomImg from '@/assets/images/script/parrot-zoom.png'
import { ActionItem } from '@/components/Event/Script/components/ActionItem'
import { Note } from '@/components/Event/Script/components/Note'

export const HearingScript: React.FC = () => {
  return (
    <div>
      <h1>Hearings</h1>
      <p>
        The main difference between hearings and EOUs/Depositions is that the
        digital reporter is not the moderator/facilitator of the proceeding, but
        instead is a participant responsible for ensuring the record is captured
        accurately.
      </p>
      <p>
        The digital reporter <strong>DOES NEED TO</strong>
      </p>
      <ol className="list-decimal">
        <li>Ensure the Backup Device is able to join the Zoom</li>
        <li>Confirm the RealTime Transcript is transcribing properly</li>
        <li>
          Ask for clarification or repetition of testimony if in your judgment
          it was clearly unintelligible due to
          <ol className="list-[lower-alpha]">
            <li>Bad internet connection</li>
            <li>Microphone not picking up the testimony</li>
            <li>People talking over each other.</li>
          </ol>
        </li>
        <li>
          Only interrupt if deemed absolutely necessary. It is imperative to not
          disrupt or interrupt the event
        </li>
        <li>Take note of important occurrences in the event form</li>
      </ol>
      <p>
        The digital reporter <strong>DOES NOT</strong>
      </p>
      <ol className="list-decimal">
        <li>
          Announce the beginning or end of the proceedings or on and off record
          time
        </li>
        <li>Ask the parties to introduce themselves</li>
        <li>Swear the witness in</li>
        <li>Ask about read/waive</li>
        <li>Ask if the parties will be ordering a transcript</li>
      </ol>

      <h3>Hearing Checklist</h3>

      <ActionItem>
        Join the call 10 mins before start time (you may have to wait for the
        host to let you in). Per usual, you should be named “First Name Last
        Name - Parrot Court Reporting”.
        <Note>
          <strong>Note:</strong> If you are unable to join the call, please
          notify event support.
        </Note>
      </ActionItem>
      <ActionItem>
        Introduce yourself to the Judicial Assistant or Judge, include your
        name, your role as the digital reporter, the case name, and the party
        you are there on behalf of.
        <Note>
          <strong>Note:</strong> Address the judge properly. If it's necessary
          to speak with the judge, they should be addressed as “Your Honor” or
          “Judge” and the judicial assistant as Mr. or Ms.
        </Note>
      </ActionItem>
      <ActionItem>
        Make sure that the zoom bot/back up device has been admitted into the
        call. It is named “Parrot CR - Backup Device”. <br />
        <img
          height="50%"
          width="50%"
          src={parrotZoomImg}
          alt="Parrot CR - Backup Device"
        />
        <ActionItem>
          Please check to see that our Zoom bot is in the call visually or by
          checking the participants list
        </ActionItem>
        <ActionItem>
          <strong>IF YOU DO NOT SEE THE BACKUP DEVICE PRESENT</strong>, Either
          message the Judicial assistant or verbally request them to let the
          Back up Device in. Refer to it as your backup device.
          <Note>
            <strong>Note:</strong> This is imperative for transcription and must
            be addressed urgently. If it is not let in, we will not be able to
            transcribe the event. Please notify our event support team if it is
            not admitted into the room.
          </Note>
        </ActionItem>
      </ActionItem>
      <ActionItem>
        Check to make sure that the transcription software is working properly
        by monitoring the RealTime Transcript.
        <Note>
          <strong>Note:</strong> If it is not working properly, please urgently
          notify our event support team.
        </Note>
      </ActionItem>
      <ActionItem>
        Take notes and time stamp the key events of the proceeding/hearing in
        the event form. See the note below for an example.
        <ActionItem>Start/end time of the hearing</ActionItem>
        <ActionItem>Names/parties of the participants</ActionItem>
        <ActionItem>
          Start/end time of each participant’s presentation, otherwise called an
          “argument”
          <Note>
            <strong>Note:</strong> Often, the Judge has a hearing for multiple
            cases set for the same time.The Judge will decide when to address or
            “call” each case. Cases can be called based on different criteria
            (alphabetically, chronologically, etc). It is imperative to wait and
            pay close attention for the case to be called.
          </Note>
          <Note>
            <strong>
              Do not interrupt to ask for spellings, please address at the end
              of the hearing.
            </strong>
          </Note>
        </ActionItem>
      </ActionItem>
      <ActionItem>
        If the audio is poor and the transcript isn’t picking up the speech, it
        is your responsibility to politely interject to remedy the situation.
        <ActionItem>
          Use your best judgment to determine if you need to ask a participant
          to repeat something they said. Keep in mind that interrupting the
          middle of an argument or testimony may be extremely disruptive and it
          is best to wait for a break to ask for clarification.
        </ActionItem>
        <ActionItem>
          Take cues from the participants, they won’t move to the next question
          if they could not hear what was just said. If they did move on, the
          likely explanation is that for some reason the DR could not hear it
          but the participants did.
        </ActionItem>
        <ActionItem>
          “Forgive my interruption, I was unable to capture what Mr./Mrs. Just
          said” or Can I kindly ask that Mr./Mrs. (name) speak louder please,
          I’m having a hard time hearing him/her. Or Can I kindly ask that
          Mr./Mrs. please speak up? The audio isn’t coming through clearly.”
          <Note>
            <strong>Note:</strong> Remember that what was said during the
            hearing will need to be transcribed later. So ask yourself if you
            were able to clearly hear and understand the testimony.{' '}
            <strong>
              If you cannot make out was said clearly, the transcription
              software definitely can’t pick up the audio!
            </strong>
          </Note>
        </ActionItem>
      </ActionItem>
      <ActionItem>
        Avoid interruptions for minor clarifications. The Judge alone should
        intervene regarding crosstalk, combativeness, etc. However,
        clarification/repetition requests for the integrity of the record are
        left up to the discretion of the Digital Reporter.
      </ActionItem>

      <h3>Example Notes</h3>

      <ol className="list-disc">
        <li>9:15am Judge Smith calls case/On Record</li>
        <li>
          9:18am Appearances, John Smith for Plaintiff, Jane Smith for Defendant
        </li>
        <li>
          9:20am John Smith attorney for Plaintiff calls witness Mike Smith,
          direct exam
          <ol className="list-[circle]">
            <li>
              1/1/2022, driving, kids in car, saw red toyota hit white Honda
            </li>
            <li>Red Toyota ran red light</li>
            <li>Got out saw driver of white Honda bleeding from head</li>
            <li>Driver Red Toyota said he was texting and driving</li>
          </ol>
        </li>
        <li>
          9:35am Jane Smith cross exam
          <ol className="list-[circle]">
            <li>You told police kids were screaming at time of crash</li>
            <li>Couldn't be paying full attention to crash</li>
          </ol>
        </li>
        <li>
          9:45 redirect
          <ol className="list-[circle]">
            <li>Confirms was paying full attention not distracted</li>
          </ol>
        </li>
        <li>9:50a John Smith for P argument</li>
        <li>
          9:55a Jane Smith for D Argument
          <ol className="list-[circle]">
            <li>Argues cases John Smith said are wrong for these facts</li>
            <li>Other cases should apply</li>
            <li>
              Facts show witness was distracted, must discredit his testimony
            </li>
          </ol>
        </li>
        <li>
          10:00a Judge followup, Judge grants Plaintiff Motion, testimony will
          be admitted at trial (or Judge grants requests 1, 3, 5, denies 1, 4,
          7.)
        </li>
        <li>10:12a Off Record</li>
      </ol>
    </div>
  )
}
