import React from 'react'
import { Button, Group, Stack, Text } from '@mantine/core'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ScriptAttendee } from '@/types'

type AttendeeFieldFormProps = {
  attendee: ScriptAttendee
  onSave: () => void
  onCancel: () => void
}

export const AttendeeFieldInputWrapper: React.FC<AttendeeFieldFormProps> = ({
  attendee,
  onSave,
  onCancel,
  children,
}) => {
  return (
    <Stack>
      <Stack>
        <Text fw={600}>{attendee.name}</Text>
        {children}
      </Stack>
      <Group align="left">
        <Button variant="filled" color="primary.2" onClick={onSave}>
          Save changes
        </Button>
        <SubtleButton onClick={onCancel}>Cancel</SubtleButton>
      </Group>
    </Stack>
  )
}
