import React from 'react'
import { Stack, Text } from '@mantine/core'

import { Card } from './Card'

import { ATTENDEE_ROLE_LABEL } from '@/constants'
import { Event } from '@/types'

export const AttendeesCard: React.FC<{
  event: Event
}> = ({ event }) => {
  return (
    <Card heading="Attendees">
      {event.attendees.map(attendee => (
        <Stack key={attendee.id} align="flex-start" spacing={0} pb={10}>
          <Text c="black" size="sm" fw="bold">
            {attendee.name}
          </Text>
          <Text size="sm">{attendee.email}</Text>
          <Text size="sm" color="gray">
            {ATTENDEE_ROLE_LABEL[attendee.role]}
          </Text>
        </Stack>
      ))}
    </Card>
  )
}
