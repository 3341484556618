import React from 'react'
import {
  Alert,
  Badge,
  Button,
  ColorSwatch,
  Group,
  useMantineTheme,
} from '@mantine/core'

import { Card } from '@/components/Event/Detail/components/Card'
import { useMutateZoombotStatus, useZoombotStatus } from '@/queries/zoombot'
import { Event } from '@/types'

type Props = {
  event: Event

  onChange(): void
}

const TOP_OFFSET_CLASS = 'top-[79px]'

export const ZoombotControlsCard: React.FC<Props> = ({ event }) => {
  const { data: zoombotStatus, refetch, error } = useZoombotStatus(event.id)
  const theme = useMantineTheme()

  const { mutateAsync: setZoombotStatus } = useMutateZoombotStatus({
    eventId: event.id,
    options: {
      onSuccess: refetch,
    },
  })

  const toggleStatus = () => {
    if (zoombotStatus?.recording_paused.state) {
      setZoombotStatus('resume_recording')
    } else {
      setZoombotStatus('pause_recording')
    }
  }

  if (!zoombotStatus && !error) {
    return null
  }

  const isPaused = !!zoombotStatus?.recording_paused.state
  const isSuspended = !!zoombotStatus?.event_suspended.state
  const color = isPaused ? 'orange' : 'red'

  return (
    <Card
      heading="Recording"
      className={`sticky z-10 ${TOP_OFFSET_CLASS}`}
      highlighted
      highlightColor={theme.colors.red[8]}
      shadow="sm"
    >
      {error && (
        <Alert color={color} title="Problem">
          There is an issue communicating with the zoombot.
        </Alert>
      )}
      {!error && (
        <Group position="apart">
          {isSuspended && (
            <>
              <Badge color="red" size="xs" className="py-3 mr-2">
                Recording suspended
              </Badge>
              <span>
                {zoombotStatus?.event_suspended.detail || 'Unrecognized error'}
              </span>
            </>
          )}
          {!isSuspended && (
            <>
              <Badge color={color} size="xs" className="py-3">
                <Group spacing={5}>
                  <ColorSwatch color={color} size={5} />
                  {isPaused ? 'Recording paused' : 'Recording in progress'}
                </Group>
              </Badge>
              <Button
                size="xs"
                color="gray"
                variant="light"
                onClick={toggleStatus}
              >
                {isPaused ? 'Resume recording' : 'Pause recording'}
              </Button>
            </>
          )}
        </Group>
      )}
    </Card>
  )
}
