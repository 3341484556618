import React, { useState } from 'react'
import {
  Group,
  PopoverProps,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import {
  BackpackIcon,
  CalendarIcon,
  ChatBubbleIcon,
  ClockIcon,
  FrameIcon,
  GlobeIcon,
  HomeIcon,
  PersonIcon,
  QuestionMarkCircledIcon,
  ReaderIcon,
} from '@radix-ui/react-icons'
import classNames from 'classnames'
import addMinutes from 'date-fns/addMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isFuture from 'date-fns/isFuture'
import isSameDay from 'date-fns/isSameDay'
import parseISO from 'date-fns/parseISO'

import { View } from './Calendar'
import { EventPopover } from './EventPopover'

import { AttorneyList } from '@/components/Calendar/components/AttorneyList'
import { DataEntry } from '@/components/DataEntry'
import { BookEventButton } from '@/components/Event/BookEventButton'
import { CancelEventBookingButton } from '@/components/Event/CancelEventBookingButton'
import {
  EventTypeBadge,
  EventUnifiedStatusBadge,
} from '@/components/Event/Detail/components/DetailsCard'
import { EventDetailButton } from '@/components/Event/EventDetailButton'
import { Event } from '@/types'
import {
  getAttorneys,
  getUnifiedEventStatus,
  isAdminEvent,
  isReporterEvent,
} from '@/utils/parrot'
import { STATES } from '@/utils/states'
import {
  formatDate,
  formatDuration,
  formatTime,
  formatTimeRange,
} from '@/utils/time'

export const CalendarEvent: React.FC<{
  event: Event
  view: View
  onChange?: () => void
}> = ({ event, view, onChange }) => {
  const [forceOpened, setForceOpened] = useState(false)
  const popoverStyles = usePopoverStyles(view)

  const Preview = {
    month: SmallPreview,
    week: LargePreview,
    day: LargePreview,
    list: InlinePreview,
    listCustom: InlinePreview,
  }[view]
  return (
    <EventPopover
      handle={<Preview event={event} />}
      header={
        <Stack spacing="xs">
          <Text>{event.name}</Text>
          <Group spacing="sm">
            <EventTypeBadge event={event} />
            <EventUnifiedStatusBadge event={event} />
          </Group>
        </Stack>
      }
      forceOpened={forceOpened}
      position={popoverStyles.position}
    >
      <>
        <Group spacing="md">
          <Stack ml="xs" spacing="xs">
            <DataEntry icon={CalendarIcon} tooltip="Date">
              {formatDate(event.datetime, { long: true, separator: ' ' })}
            </DataEntry>

            <DataEntry icon={ClockIcon} tooltip="Time">
              {formatTimeRange(event.datetime, event.duration)} (
              {formatDuration(event.duration)})
            </DataEntry>

            {isAdminEvent(event) && (
              <DataEntry icon={PersonIcon} tooltip="Assigned reporter">
                {event.reporter?.name ?? 'Not assigned'}
              </DataEntry>
            )}

            <DataEntry icon={HomeIcon} tooltip="Organization">
              {event.team.organization.name}: {event.team.name}
            </DataEntry>

            <DataEntry icon={BackpackIcon} tooltip="Attorneys">
              <AttorneyList attorneys={getAttorneys(event)} />
            </DataEntry>

            <DataEntry
              icon={ReaderIcon}
              tooltip={event.case.is_claim ? 'Claim' : 'Case'}
            >
              {event.case.name}
            </DataEntry>

            {(event.case.number || event.claim_number) && (
              <DataEntry
                icon={FrameIcon}
                tooltip={event.case.number ? 'Case number' : 'Claim number'}
              >
                {event.case.number || event.claim_number}
              </DataEntry>
            )}

            {event.case.state && (
              <DataEntry icon={GlobeIcon} tooltip="Location">
                {
                  /*
                  it shouldn't happen that in reporters app there would be
                  event in case with blank state but our model allows it due to
                  quick schedules
                  */
                  event.case.state in STATES
                    ? STATES[event.case.state]
                    : event.case.state
                }
                {event.case.county ? `: ${event.case.county}` : ''}
              </DataEntry>
            )}

            {event.recording.is_translator_present && (
              <DataEntry icon={ChatBubbleIcon} tooltip="Interpreter">
                Interpreter present
              </DataEntry>
            )}
          </Stack>

          <Group mb="xl" spacing="xs" className="w-full">
            {isReporterEvent(event) &&
              event.reporter_status === 'overlapping' && (
                <NoticeBox>This overlaps with a booked event.</NoticeBox>
              )}

            {isReporterEvent(event) &&
              event.reporter_status === 'overlapping_related' && (
                <NoticeBox>
                  An event related to this overlaps with a booked event.
                </NoticeBox>
              )}

            {getUnifiedEventStatus(event) === 'available' &&
              event.has_related_events && (
                <NoticeBox>
                  This has related {event.related_events_type} events; you need
                  to book all of them.
                </NoticeBox>
              )}

            {getUnifiedEventStatus(event) === 'waiting' && (
              <NoticeBox>
                {event.is_waiting_for_reporter_feedback
                  ? 'This event is missing the post-event form.'
                  : 'There are missing post-event documents.'}
              </NoticeBox>
            )}

            {getUnifiedEventStatus(event) === 'cancelled' && (
              <NoticeBox>This event was canceled.</NoticeBox>
            )}

            {event.status === 'upcoming' &&
              isFuture(parseISO(event.datetime)) && (
                <NoticeBox>
                  Event is starting in{' '}
                  {formatDistanceToNow(parseISO(event.datetime))}
                </NoticeBox>
              )}
          </Group>
        </Group>

        <Group position="right">
          {getUnifiedEventStatus(event) === 'booked' && (
            <CancelEventBookingButton
              event={event}
              onCancel={() => onChange?.()}
            />
          )}

          <EventDetailButton event={event} />

          {getUnifiedEventStatus(event) === 'available' && (
            <div onClick={() => setForceOpened(true)}>
              <BookEventButton
                event={event}
                onBook={() => {
                  setForceOpened(false)
                  onChange?.()
                }}
                onCancel={() => setForceOpened(false)}
              />
            </div>
          )}
        </Group>
      </>
    </EventPopover>
  )
}

const NoticeBox: React.FC = ({ children }) => {
  return (
    <div className="bg-gray-50 pl-3 pt-2 pb-2 w-full">
      <DataEntry icon={QuestionMarkCircledIcon}>{children}</DataEntry>
    </div>
  )
}

export const SmallPreview: React.FC<{ event: Event }> = ({ event }) => {
  const styles = useEventStyles()
  const status = getUnifiedEventStatus(event)

  return (
    <div className="px-1">
      <div
        className={classNames(
          'inline-flex items-center w-full overflow-hidden px-1 space-x-1 rounded hover:bg-gray-100 text-sm cursor-pointer',
          {
            'border border-gray-300': !isSameDay(
              parseISO(event.datetime),
              addMinutes(parseISO(event.datetime), event.duration),
            ),
          },
        )}
      >
        <div className="mr-1">
          <Dot style={styles[status]} />
        </div>

        <Text color="gray" size="xs">
          {formatTime(event.datetime)}
        </Text>

        <Text size="xs" weight={500} className="truncate">
          {event.name}
        </Text>
      </div>
    </div>
  )
}

export const LargePreview: React.FC<{ event: Event }> = ({ event }) => {
  const styles = useEventStyles()
  const attorneys = event.attendees.filter(attendee => attendee.is_attorney)

  return (
    <div className="border border-white bg-white rounded h-full">
      <div
        className="w-full h-full p-1 rounded cursor-pointer border text-xs text-ellipsis overflow-hidden"
        style={{ ...styles[getUnifiedEventStatus(event)], minHeight: 25 }}
      >
        {event.duration <= 60 && (
          <div>
            <span className="font-semibold truncate">{event.name}</span>,{' '}
            {formatTime(event.datetime)}
          </div>
        )}

        {event.duration > 60 && (
          <div>
            <div className="font-semibold truncate">{event.name}</div>
            <div>{formatTime(event.datetime)}</div>
            {attorneys.length > 0 && (
              <div className="truncate">
                {attorneys.map(attorney => attorney.name).join(', ')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const InlinePreview: React.FC<{ event: Event }> = ({ event }) => {
  const styles = useEventStyles()
  const attorneys = event.attendees.filter(attendee => attendee.is_attorney)

  return (
    <div className="mx-16 flex items-center max-w-full px-1 space-x-5 hover:bg-gray-100 cursor-pointer">
      <div className="mr-1">
        <Dot style={styles[getUnifiedEventStatus(event)]} />
      </div>

      <div className="w-40">
        <Text
          color="gray"
          size="sm"
          sx={theme => ({ color: theme.colors.gray[6] })}
        >
          {formatTimeRange(event.datetime, event.duration)}
        </Text>
      </div>

      <div className="w-24">
        <Text
          color="gray"
          size="xs"
          sx={theme => ({ color: theme.colors.gray[6] })}
        >
          {attorneys.map(attorney => attorney.name).join(', ') || 'No Attorney'}
        </Text>
      </div>

      {isAdminEvent(event) && (
        <div className="w-44">
          <Text size="sm">{event.reporter?.name ?? 'Not assigned'}</Text>
        </div>
      )}

      <Text size="sm">{event.name}</Text>
    </div>
  )
}

const Dot: React.FC<Pick<React.HTMLProps<HTMLDivElement>, 'style'>> = ({
  style,
}) => {
  return <div className="w-2.5 h-2.5 rounded-full border-2" style={style} />
}

const useEventStyles = () => {
  const theme = useMantineTheme()

  return {
    available: {
      backgroundColor: 'white',
      borderColor: theme.colors.green[5],
      color: theme.colors.green[5],
    },
    unavailable: {
      backgroundColor: 'white',
      borderColor: theme.colors.dark[5],
      color: theme.colors.dark[5],
      borderStyle: 'dotted',
    },
    booked: {
      backgroundColor: theme.colors.indigo[5],
      borderColor: theme.colors.indigo[5],
      color: 'white',
    },
    overlapping: {
      backgroundColor: 'white',
      borderColor: theme.colors.orange[5],
      color: theme.colors.orange[5],
      borderStyle: 'dotted',
    },
    overlapping_related: {
      backgroundColor: 'white',
      borderColor: theme.colors.orange[5],
      color: theme.colors.orange[5],
      borderStyle: 'dotted',
    },
    past: {
      backgroundColor: theme.colors.gray[5],
      borderColor: theme.colors.gray[5],
      color: 'white',
    },
    waiting: {
      backgroundColor: theme.colors.red[5],
      borderColor: theme.colors.red[5],
      color: 'white',
    },
    cancelled: {
      backgroundColor: 'white',
      borderColor: theme.colors.gray[5],
      color: theme.colors.gray[5],
      textDecoration: 'line-through',
      borderStyle: 'dotted',
    },
  }
}

const usePopoverStyles = (view: View) => {
  const positions: Record<View, PopoverProps['position']> = {
    month: 'right-start',
    week: 'right-start',
    day: 'bottom',
    list: 'bottom',
    listCustom: 'bottom',
  }

  return {
    position: positions[view],
  }
}
