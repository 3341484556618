import React, { useState } from 'react'
import { ActionIcon, Popover, Stack } from '@mantine/core'
import { Cross2Icon } from '@radix-ui/react-icons'

export type PopoverProps = React.ComponentProps<typeof Popover>

export const EventPopover: React.FC<{
  header: React.ReactNode
  handle: React.ReactNode
  forceOpened?: boolean
  position?: PopoverProps['position']
}> = ({ header, handle, forceOpened = false, position, children }) => {
  const [opened, setOpened] = useState(false)

  return (
    <Popover
      withinPortal
      opened={opened || forceOpened}
      position={position}
      onClose={() => setOpened(false)}
      zIndex={150} // show popover under modals
      width={400}
    >
      <Popover.Target>
        <div className="h-full w-full" onClick={() => setOpened(o => !o)}>
          {handle}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <div className="relative">
            <div className="flex items-center mb-4">
              <div className="flex-1">{header}</div>

              <div>
                <ActionIcon onClick={() => setOpened(false)}>
                  <Cross2Icon />
                </ActionIcon>
              </div>
            </div>
          </div>
          <div className="items-center mb-4">{children}</div>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
