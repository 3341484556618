import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Group, Radio, Stack, Text, Textarea } from '@mantine/core'
import * as yup from 'yup'

import { CNAVerbatim } from '@/components/Event/NewScript/components/Closing/CNAVerbatim'
import { EventResultOptions } from '@/components/Event/NewScript/components/common/EventResultOptions'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { ActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { useEndZoomEvent } from '@/components/Event/NewScript/hooks/useEndZoomEvent'
import { canceledEventSchema } from '@/components/Event/NewScript/schemas/canceledEventSchema'
import {
  AttendeeRole,
  DepositionResult,
  REASON_FOR_CNA_LABEL,
  ReasonForCna,
  ScriptStep,
  TimestampType,
} from '@/constants'
import { Event } from '@/types'
import { getCurrentTimeInUtc } from '@/utils/time'

type EventCancelledInterface = yup.InferType<typeof canceledEventSchema>

type EventCancelledClosingFormProps = {
  event: Event
}

export const EventCancelledClosingForm: React.FC<
  EventCancelledClosingFormProps
> = ({ event }) => {
  const { state, actions } = useScriptState(event)

  const {
    completeAction,
    isActionCompleted,
    canContinue,
    completeStepAndContinue,
  } = useStepActions(ScriptStep.CLOSING, event)

  const witness = state.attendees.find(
    attendee => attendee.role === AttendeeRole.WITNESS,
  )

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(canceledEventSchema),
    defaultValues: {
      result: state.result,
      notes: state.notes,
      reason_for_cna: state.reason_for_cna,
      cna_declared_at: state.cna_declared_at,
      other_reason: state.other_reason,
    },
  })

  const { register, control } = methods

  const { confirmAndEndEvent, isLoading } = useEndZoomEvent(event)

  const onContinueClick = () => {
    confirmAndEndEvent(() => completeStepAndContinue(ScriptStep.REVIEW))
  }

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (
        name &&
        ['reason_for_cna', 'other_reason', 'notes', 'result'].includes(name)
      ) {
        if (value.result === DepositionResult.CNA) {
          actions.unskipStepAction({
            result: value.result,
            notes: value.notes,
            reasonForCNA: value.reason_for_cna,
            otherReason: value.other_reason,
            stepAndActionKeys: [
              {
                step: ScriptStep.CLOSING,
                key: 'state_the_cna',
              },
              {
                step: ScriptStep.CLOSING,
                key: 'select_reason_for_cna',
              },
            ],
          })
        } else {
          actions.skipStepAction({
            result: value.result,
            notes: value.notes,
            reasonForCNA: value.reason_for_cna,
            otherReason: value.other_reason,
            stepAndActionKeys: [
              {
                step: ScriptStep.CLOSING,
                key: 'state_the_cna',
              },
              {
                step: ScriptStep.CLOSING,
                key: 'select_reason_for_cna',
              },
            ],
          })
        }
      }
    })

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const validateAndCompleteAction = async (
    validation_keys: Array<keyof EventCancelledInterface>,
    action_key: string,
  ) => {
    const results: Promise<boolean>[] = []

    validation_keys.forEach(key => {
      results.push(methods.trigger(key))
    })

    Promise.all(results).then(res => {
      res.every(isValid => isValid) && completeAction(action_key)
    })
  }
  // TODO: Pass step number from props
  return (
    <Stack>
      <ScriptTimeline title="5. Closing">
        {/* Declare how deposition ended */}
        <TimelineAction
          actionKey="declare_how_deposition_ended"
          title="Declare how deposition ended"
          onComplete={() =>
            validateAndCompleteAction(
              ['result'],
              'declare_how_deposition_ended',
            )
          }
          completed={isActionCompleted('declare_how_deposition_ended')}
        >
          <ActionContainer outer>
            <Stack>
              <ActionContainer>
                <EventResultOptions
                  control={control}
                  canceledFromStep={state.first_skipped_step}
                  event={event!}
                />
              </ActionContainer>
              {methods.formState.errors.result !== undefined && (
                <FormErrors errors={methods.formState.errors.result.message} />
              )}
              {state.first_skipped_step !== ScriptStep.ATTENDANCE && (
                <ActionContainer>
                  <Textarea
                    {...register('notes')}
                    label="Notes"
                    placeholder="How was the event? (Anything worth mentioning?)"
                    minRows={3}
                  />
                </ActionContainer>
              )}
            </Stack>
          </ActionContainer>
        </TimelineAction>
        {!state.steps[ScriptStep.CLOSING].actions.state_the_cna.skip && (
          <TimelineAction
            actionKey="state_the_cna"
            title="State the CNA on the record"
            onComplete={() =>
              validateAndCompleteAction(['cna_declared_at'], 'state_the_cna')
            }
            completed={isActionCompleted('state_the_cna')}
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <Group>
                    <ActionButton
                      onClick={() =>
                        actions.addTimestamp({
                          isOnRecord: true,
                          timestampType: TimestampType.RECORDING_ON,
                          timezone: event.team.timezone as string,
                        })
                      }
                      disabled={state.is_on_record}
                    >
                      Go on record
                    </ActionButton>
                    {state.is_on_record && (
                      <Text weight={700} size="xs">
                        You are now on the record.
                      </Text>
                    )}
                  </Group>
                </ActionContainer>
                <ActionText mono>
                  <CNAVerbatim
                    eventDatetime={event.datetime}
                    timezone={event.team.timezone}
                    eventName={event.name}
                    witnessName={witness?.name ?? "[State Witness' name]]"}
                  />
                </ActionText>
                <ActionContainer>
                  <Group>
                    <ActionButton
                      onClick={() => {
                        actions.addTimestamp({
                          isOnRecord: false,
                          timestampType: TimestampType.RECORDING_OFF,
                          timezone: event.team.timezone as string,
                          isCnaDeclaration: true,
                        })
                        methods.setValue(
                          'cna_declared_at',
                          getCurrentTimeInUtc(),
                          {
                            shouldValidate: true,
                          },
                        )
                      }}
                      disabled={!state.is_on_record}
                    >
                      Go off record
                    </ActionButton>
                    {!state.is_on_record && (
                      <Text weight={700} size="xs">
                        You are off the record.
                      </Text>
                    )}
                  </Group>
                </ActionContainer>
                <FormErrors
                  errors={methods.formState.errors?.cna_declared_at?.message}
                />
              </Stack>
            </ActionContainer>
          </TimelineAction>
        )}
        {!state.steps[ScriptStep.CLOSING].actions.select_reason_for_cna
          .skip && (
          <TimelineAction
            actionKey="select_reason_for_cna"
            title="Select reason for CNA"
            completed={isActionCompleted('select_reason_for_cna')}
            onComplete={() =>
              validateAndCompleteAction(
                ['reason_for_cna', 'other_reason'],
                'select_reason_for_cna',
              )
            }
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <Controller
                    name="reason_for_cna"
                    control={control}
                    render={({ field }) => (
                      <Radio.Group
                        {...field}
                        onChange={value => {
                          field.onChange(value)
                        }}
                      >
                        <Stack>
                          {Object.values(ReasonForCna).map(value => (
                            <Radio
                              styles={{ radio: { cursor: 'pointer' } }}
                              key={value}
                              value={value}
                              label={REASON_FOR_CNA_LABEL[value]}
                            />
                          ))}
                        </Stack>
                      </Radio.Group>
                    )}
                  />
                </ActionContainer>
                {methods.formState.errors.reason_for_cna !== undefined && (
                  <FormErrors
                    errors={methods.formState.errors.reason_for_cna.message}
                  />
                )}
                <ActionContainer>
                  <Textarea
                    {...register('other_reason')}
                    label="Specify the other reason (will be visible on the certificate)"
                    placeholder="How was the event? (Anything worth mentioning?)"
                    minRows={3}
                  />
                </ActionContainer>
                {methods.formState.errors.other_reason !== undefined && (
                  <FormErrors
                    errors={methods.formState.errors.other_reason.message}
                  />
                )}
              </Stack>
            </ActionContainer>
          </TimelineAction>
        )}
        <ContinueButton
          event={event}
          label="Continue to review"
          canContinue={canContinue()}
          step={ScriptStep.CLOSING}
          loading={isLoading}
          handleClick={() => onContinueClick()}
        />
      </ScriptTimeline>
    </Stack>
  )
}
