import React from 'react'
import { Title } from '@mantine/core'

export const EventTitle: React.FC = ({ children }) => {
  return (
    <Title
      className="text-center text-ellipsis whitespace-nowrap overflow-hidden xs:overflow-visible"
      order={6}
    >
      {children}
    </Title>
  )
}
