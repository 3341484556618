import { useQuery } from 'react-query'
import axios from 'axios'

import { AdminEvent, Event } from '@/types'
import { isReporterEvent } from '@/utils/parrot'

export const useRelatedEvents = (
  event: Event,
  type?: 'available' | 'booked',
  options?: { enabled: boolean },
) =>
  useQuery(
    [`related-events`, type, event.id],
    () =>
      axios.get<Event[]>(getRelatedEventsUrl(event, type)).then(r => r.data),
    options,
  )

const getRelatedEventsUrl = (event: Event, type?: 'available' | 'booked') => {
  if (isReporterEvent(event) && type === 'available') {
    return event.available_related_events_url
  }

  if (isReporterEvent(event) && type === 'booked') {
    return event.booked_related_events_url
  }

  return (event as AdminEvent).related_events_url
}
