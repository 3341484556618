import { Link } from 'react-router-dom'
import { Button, Center, Paper, Space, Text } from '@mantine/core'

import { Shell } from '@/components/Shell'

export const NotFound = ({ text = 'Page Not Found' }: { text?: string }) => (
  <Shell>
    <Paper p="xl">
      <Center>
        <Text>{text}</Text>
      </Center>
      <Space />
      <Center>
        <Link to="/">
          <Button>Go home</Button>
        </Link>
      </Center>
    </Paper>
  </Shell>
)
