import React, { useMemo, useState } from 'react'
import { Column, FilterType } from 'react-table'
import {
  ActionIcon,
  Button,
  Container,
  Group,
  Menu,
  Paper,
  Title,
} from '@mantine/core'
import {
  DotsHorizontalIcon,
  EyeClosedIcon,
  EyeOpenIcon,
  FileTextIcon,
  MinusIcon,
  PaperPlaneIcon,
  Pencil1Icon,
  PersonIcon,
  RulerHorizontalIcon,
} from '@radix-ui/react-icons'

import { DataTable } from '@/components/DataTable'
import { Shell } from '@/components/Shell'
import { useReportersQuery } from '@/queries/reporters'
import {
  ChangeReporterExperienceLevelModal,
  ChangeReporterRoleModal,
  EditReporterModal,
  InviteReporterModal,
  SetReporterStripeAccountIdModal,
} from '@/routes/Admin/Reporters/Modals'
import {
  ReporterFilterControls,
  ReporterFilters,
} from '@/routes/Admin/Reporters/ReporterFilterControls'
import { useDeactivate } from '@/routes/Admin/Reporters/useDeactivate'
import { useReactivate } from '@/routes/Admin/Reporters/useReactivate'
import { useResendWelcomeEmail } from '@/routes/Admin/Reporters/useResendWelcomeEmail'
import { ReporterBase, ReporterRole } from '@/types'
import { REPORTER_ROLES } from '@/utils/parrot'

// Because of bug in react-table (https://github.com/TanStack/react-table/issues/3930),
// boolean filter does not work correctly so we need to
// do this manually, should be fixed in react-table v8
const createBooleanFilter: (
  atr: keyof ReporterBase,
) => FilterType<ReporterBase> =
  (attribute: keyof ReporterBase) => (rows, _, filterValue: boolean | null) => {
    if (filterValue === null) {
      return rows
    }
    return rows.filter(r => r.values[attribute] === filterValue)
  }

export const Reporters = () => {
  const [inviting, setInviting] = useState(false)
  const { data: reporters, refetch } = useReportersQuery()

  const [filters, setFilters] = useState<ReporterFilters>({
    search: null,
    state: null,
    experience_level: null,
    is_active: true,
    has_documents: null,
    role: null,
  })

  const columns = useMemo<Column<ReporterBase>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone_number',
        Cell: ({ value }: { value: string }) =>
          value ? value : <MinusIcon color="gray" />,
      },
      {
        Header: 'Level',
        accessor: 'experience_level',
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: ({ value }: { value: string }) =>
          value ? value : <MinusIcon color="gray" />,
      },
      {
        Header: 'Documents',
        accessor: 'has_documents',
        Cell: ({ value }: { value: boolean }) => (value ? 'Yes' : 'No'),
        filter: createBooleanFilter('has_documents'),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }: { value: ReporterRole }) =>
          REPORTER_ROLES.find(r => r.value === value)?.label,
      },
      {
        id: 'is_active',
        accessor: 'is_active',
        isVisible: false,
        filter: createBooleanFilter('is_active'),
      },
      {
        id: 'actions',
        Cell: ({ row }: { row: { original: ReporterBase } }) => (
          <ReporterRowActions
            reporter={row.original}
            onReporterUpdate={refetch}
          />
        ),
      },
    ],
    [refetch],
  )

  const data = useMemo(() => reporters ?? [], [reporters])

  const { search, ...restFilters } = filters

  return (
    <>
      <Shell header={{ middle: <Title order={6}>Reporters</Title> }}>
        <Container size="xl">
          <Paper p="xl">
            <div className="flex items-center justify-between mb-12">
              <Group className="basis-4/5 flex-nowrap">
                <ReporterFilterControls
                  reporters={data}
                  onChange={filters => setFilters(filters)}
                  initialValue={filters}
                />
              </Group>
              <div>
                <Button
                  variant="light"
                  color="gray"
                  onClick={() => setInviting(true)}
                >
                  Invite reporter
                </Button>

                <InviteReporterModal
                  opened={inviting}
                  onClose={() => setInviting(false)}
                  onSave={refetch}
                />
              </div>
            </div>

            <DataTable
              data={data}
              columns={columns}
              searchQuery={search}
              filters={restFilters}
            />
          </Paper>
        </Container>
      </Shell>
    </>
  )
}

const ReporterRowActions: React.FC<{
  reporter: ReporterBase
  onReporterUpdate: () => void
}> = ({ reporter, onReporterUpdate }) => {
  const deactivate = useDeactivate(reporter, onReporterUpdate)
  const reactivate = useReactivate(reporter, onReporterUpdate)
  const resendEmail = useResendWelcomeEmail(reporter)

  const [editing, setEditing] = useState(false)
  const [changingExperience, setChangingExperience] = useState(false)
  const [settingStripeAccount, setSettingStripeAccount] = useState(false)
  const [settingRole, setSettingRole] = useState(false)

  return (
    <div className="text-right">
      <Menu position="bottom-start">
        <Menu.Target>
          <ActionIcon>
            <DotsHorizontalIcon />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<Pencil1Icon />} onClick={() => setEditing(true)}>
            Edit reporter
          </Menu.Item>

          {reporter.role === 'digital_reporter' && (
            <Menu.Item
              icon={<RulerHorizontalIcon />}
              onClick={() => setChangingExperience(true)}
            >
              Set experience level
            </Menu.Item>
          )}

          <Menu.Item onClick={() => resendEmail()} icon={<PaperPlaneIcon />}>
            Resend welcome mail
          </Menu.Item>

          {reporter.role === 'digital_reporter' && (
            <Menu.Item
              icon={<FileTextIcon />}
              onClick={() => setSettingStripeAccount(true)}
            >
              Set stripe account ID
            </Menu.Item>
          )}

          <Menu.Item icon={<PersonIcon />} onClick={() => setSettingRole(true)}>
            Set role
          </Menu.Item>

          <Menu.Divider />

          {reporter.is_active ? (
            <Menu.Item onClick={() => deactivate()} icon={<EyeClosedIcon />}>
              Deactivate
            </Menu.Item>
          ) : (
            <Menu.Item onClick={() => reactivate()} icon={<EyeOpenIcon />}>
              Reactivate
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <EditReporterModal
        opened={editing}
        reporter={reporter}
        onClose={() => setEditing(false)}
        onSave={onReporterUpdate}
      />
      <ChangeReporterExperienceLevelModal
        opened={changingExperience}
        reporter={reporter}
        onClose={() => setChangingExperience(false)}
        onSave={onReporterUpdate}
      />
      <SetReporterStripeAccountIdModal
        opened={settingStripeAccount}
        reporter={reporter}
        onClose={() => setSettingStripeAccount(false)}
        onSave={onReporterUpdate}
      />
      <ChangeReporterRoleModal
        opened={settingRole}
        reporter={reporter}
        onClose={() => setSettingRole(false)}
        onSave={onReporterUpdate}
      />
    </div>
  )
}
