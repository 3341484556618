import React from 'react'

import { ActionButton } from './ActionButton'

import { useJoinZoomCall } from '@/hooks/useJoinZoomCall'
import { Event } from '@/types'

export const JoinCallActionButton: React.FC<{
  event: Event
}> = ({ event }) => {
  const asHost = !event.uses_external_zoom_meeting
  const { isFetching, joinZoomCall } = useJoinZoomCall(event, asHost)

  return (
    <ActionButton onClick={joinZoomCall} loading={isFetching}>
      {asHost ? 'Join the call as host' : 'Join the call'}
    </ActionButton>
  )
}
