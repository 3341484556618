import React from 'react'
import { Prompt } from 'react-router-dom'
import { Alert, Center, LoadingOverlay, Text } from '@mantine/core'

import { useScriptState } from './Event/NewScript/hooks/state/useScriptState'

import { EmailLink } from '@/components/common/EmailLink'
import { EventTitle } from '@/components/common/EventTitle'
import { ScriptLayout } from '@/components/Event/NewScript/components/common/Layout/ScriptLayout'
import { Script } from '@/components/Event/NewScript/Script'
import { Shell } from '@/components/Shell'
import { Event } from '@/types'

export const NewScriptScreen = ({
  event,
  refetchEvent,
}: {
  event: Event
  refetchEvent: () => void
}) => {
  const { state, isLoading, isError } = useScriptState(event)

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Shell
      header={{
        middle: <EventTitle>{event.name}</EventTitle>,
      }}
    >
      <Prompt
        when={event.is_ongoing}
        message="Event is still ongoing. Are you sure you want to leave?"
      />
      {isError || !state ? (
        <Center>
          <Alert color="red">
            <Text align="center" size="lg" fw={600}>
              Sorry, we couldn't load the script for this event.
            </Text>
            <Text size="md">
              Please try to reload the page, if the error persist contact{' '}
              <EmailLink
                size="sm"
                email={import.meta.env.VITE_APP_SUPPORT_EMAIL}
              />
            </Text>
          </Alert>
        </Center>
      ) : (
        <>
          <ScriptLayout event={event}>
            <Script event={event} refetchEvent={refetchEvent} />
          </ScriptLayout>
        </>
      )}
    </Shell>
  )
}
