import { Stack, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { useModals } from '@/hooks/useModals'
import { useDeactivateReporterMutation } from '@/queries/reporters'
import { ReporterBase } from '@/types'

export const useDeactivate = (
  reporter: ReporterBase,
  onDeactivated?: () => void,
) => {
  const modals = useModals()

  const { mutateAsync, isLoading } = useDeactivateReporterMutation(reporter, {
    onSuccess: () => {
      showNotification({
        message: 'Reporter has been deactivated',
      })

      onDeactivated?.()
    },
  })

  return () =>
    modals.openConfirmModal({
      title: 'Deactivate reporter?',
      children: (
        <Stack>
          <Text size="sm">
            Do you want to deactivate {reporter.name}? The reporter will no
            longer be able to log in to the platform.
          </Text>
          <Text size="sm">
            All booked upcoming events will be unassigned from this reporter.
          </Text>
        </Stack>
      ),
      labels: { confirm: 'Deactivate reporter', cancel: 'Cancel' },
      confirmProps: { loading: isLoading },
      onConfirm: mutateAsync,
    })
}
