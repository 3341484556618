import { Button, Stack, Text } from '@mantine/core'

import { useModals } from '@/hooks/useModals'
import { useJoinInfoQuery } from '@/queries/events'
import { Event, JoinInfo } from '@/types'

type HookResult = {
  isFetching: boolean
  joinZoomCall: () => void
}

export const useJoinZoomCall = (event: Event, asHost: boolean): HookResult => {
  const modals = useModals()
  const {
    data: joinInfoData,
    refetch,
    isFetching,
  } = useJoinInfoQuery(event, {
    enabled: false,
  })

  const getJoinUrl = (joinInfo?: JoinInfo) =>
    asHost ? joinInfo?.host_url : joinInfo?.join_url

  const joinZoomCall = () => {
    const joinUrl = getJoinUrl(joinInfoData)

    if (joinUrl) {
      window.open(joinUrl, '_blank')
      return
    }

    refetch()
      .then(r => r.data)
      .then(joinInfo => {
        const joinUrl = getJoinUrl(joinInfo)

        if (joinUrl) {
          window.open(joinUrl, '_blank')
        } else {
          const id = modals.openModal({
            title: 'Call cannot be joined',
            children: (
              <Stack>
                <Text size="sm">Please try again in a few moments.</Text>

                <Button fullWidth onClick={() => modals.closeModal(id)}>
                  Okay
                </Button>
              </Stack>
            ),
            centered: true,
          })
        }
      })
  }

  return {
    isFetching,
    joinZoomCall,
  }
}
