import * as yup from 'yup'

import { attendeesFieldSchema } from '@/components/Event/NewScript/schemas/attendeeSchema'
import { ScriptAttendee } from '@/types'

export const closingStepSchema = yup.object().shape({
  witness_requested_transcript: yup
    .boolean()
    .nullable()
    .when(
      ['$canWitnessReadOrWaive', '$isEUO'],
      ([canWitnessReadOrWaive, isEUO], schema) => {
        if (canWitnessReadOrWaive && !isEUO) {
          return schema.required('One of the options must be selected')
        }

        return schema
      },
    )
    .default(null),
  order_transcript: yup
    .boolean()
    .nullable()
    .default(null)
    .when('$isEUO', ([isEUO], schema) => {
      if (isEUO) {
        return schema
      }

      return schema.test(
        'transcript-order-validation',
        'If this field is set to "Yes", at least one attendee needs to order certified transcript',
        function (value) {
          const attendees: ScriptAttendee[] = this.parent.attendees

          if (value) {
            return attendees.some(
              attendee => attendee.transcript_order !== null,
            )
          }

          return true
        },
      )
    }),
  attendees: attendeesFieldSchema,
  is_on_record: yup
    .boolean()
    .oneOf(
      [false],
      'You must go off the record. Do that by clicking the ‘Go off record’ button.',
    )
    .required(
      'YYou must go off the record. Do that by clicking the ‘Go off record’ button.',
    ),
  spellings: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  timestamps: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
})
