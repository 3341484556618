import React from 'react'
import { Stack } from '@mantine/core'
import { formatDistanceToNow, parseISO } from 'date-fns'

import {
  ActionContainer,
  ActionText,
  ActionTitle,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { Event } from '@/types'

type Props = {
  event: Event
}

export const UpcomingEventAlert: React.FC<Props> = ({ event }) => {
  const startsIn = formatDistanceToNow(parseISO(event.datetime))
  return (
    <Stack align="flex-start" spacing="xl" p="xl">
      <ActionContainer outer>
        <ActionTitle>Proceeding is starting in {startsIn}</ActionTitle>
        <ActionText>
          This is upcoming proceeding, you must join the Zoom meeting to start
          the proceeding and unlock the next step in the script. If you joined
          the Zoom meeting and the script is still locked, please try to refresh
          the page or contact support.
        </ActionText>
      </ActionContainer>
    </Stack>
  )
}
