import React from 'react'

import { Helpers } from '@/components/ScriptScreen'
import { ParrotContact } from '@/constants'
import { Event, Reporter } from '@/types'

export const SwearInScript: React.FC<{
  event: Event
  reporter: Reporter
  helpers: Helpers
}> = ({ event, reporter, helpers }) => {
  return (
    <div>
      <h1>SWEAR-IN: Script and Manual for Digital Reporter</h1>
      <ol>
        <li>
          Good morning/afternoon. Today is{' '}
          <strong>{helpers.day_of_week}</strong>. It is{' '}
          <strong>{helpers.date}</strong>, the time is{' '}
          <strong>{helpers.time}</strong>. We are present to record a Discovery
          deposition in the case of{' '}
          <strong>
            {event.case.name}, {helpers.witness} v. {helpers.defendant}
          </strong>
          , court case number <strong>{event.case.number}</strong>.
        </li>
        <li>
          My name is <strong>{reporter.name}</strong>, I will be the Deposition
          Officer for today’s deposition.
        </li>
        <li>
          Please identify yourself for the record, spell your last name defense
          counselor?
          <ol>
            <li>
              <strong>
                (let the attorney for the defense say their last name and spell
                it)
              </strong>
            </li>
          </ol>
        </li>
        <li>
          Thank you. Representing the Plaintiff, please identify yourself and
          spell your last name?
          <ol>
            <li>
              <strong>
                (let the attorney for the plaintiff say their name and spell
                their last name)
              </strong>
            </li>
          </ol>
        </li>
        <li>
          For the record, both sides stipulate to this deposition being
          conducted remotely?
          <ol>
            <li>
              <strong>Need both attorneys to say yes, or I stipulate</strong>
            </li>
          </ol>
        </li>
        <li>
          And who will be our deponent today? Please identify yourself and spell
          your last name for the record.
          <ol>
            <li>
              <strong>Let them say their name</strong>
            </li>
          </ol>
        </li>
        <li>
          <strong>{helpers.witness}</strong>, can you please hold a valid
          government photo identification up to the screen to verify your
          identity?
          <ol>
            <li>
              <strong>Read the deponent’s ID number.</strong>
            </li>
            <li>
              <strong>Take a screenshot of the ID while on the screen.</strong>
            </li>
          </ol>
        </li>
        <li>
          Thank you. <strong>{helpers.witness}</strong>, Please raise your right
          hand,
          <ol>
            <li>
              <strong>
                Do you swear to tell the truth, the whole truth and nothing but
                the truth in this deposition?
              </strong>
            </li>
          </ol>
        </li>
        <li>
          Thank you. At the time, I will be leaving the deposition and need to
          know who to transfer the Host role to Zoom. To whom should I transfer?
          <ol>
            <li>
              Counselor will respond. Click the button at the bottom that says
              “participants” and add the new host.
            </li>
          </ol>
        </li>
        <li>
          I will be leaving the deposition now. Counselor, whenever you are
          ready you may proceed with questioning. Thank you and have a good day.
        </li>
      </ol>
      <h2>Things to be prepared for during the deposition</h2>
      <ol>
        <li>
          <strong>
            In the event that an attendee’s internet fails, or someone’s
            question or answer is jumbled, inaudible
          </strong>{' '}
          - instruct them to please repeat the answer/question.
        </li>
        <li>
          <strong>Parties talking over each other</strong> - ask the parties to
          please talk one at a time and repeat the statement they made.
        </li>
        <li>
          <strong>Helping parties with presentation and annotation</strong> - be
          prepared to teach the attorney how to present an exhibit, annotate an
          exhibit, clear annotation on an exhibit.
        </li>
        <li>
          <strong>Internet connectivity/strength issues</strong> - ask them to
          leave then reconnect, if the internet is really bad, ask them to call
          in via telephone.
        </li>
        <li>
          <strong>Going off the record</strong> - if both parties agree to go
          off the record, be prepared to pause the recording, and to go back on
          the record when the parties are ready. Operators need to know how to
          use the <strong>break out room</strong> functionality if necessary
        </li>
        <li>
          <strong>Read back</strong> - the attorneys may ask you as if you were
          the "court reporter“ to "read back“ a question, this implies that you
          will be asked literally to scroll through the livestream transcript
          and read the last question back to them.
        </li>
        <li>
          Add your name in Zoom First Name Last Name - Parrot Court Reporting
          (e.i. {reporter.name} - Parrot Court Reporting)
        </li>
      </ol>
      <h2>End of depo</h2>
      <ol>
        <li>
          Ask the witness whether they want to Read or Waive reading of the
          deposition transcript - if they say read, then ask for a good email
          address for the deponent.
          <ol>
            <li>
              This is not something you need to be concerned with, but what
              read/waive means, is asking whether the deponent wants to read the
              transcript of the deposition before it is finalized or do they
              waive the right to read it.
            </li>
            <li>
              Instruct party who introduced exhibits to email them to
              {ParrotContact.EXHIBITS_EMAIL}
            </li>
          </ol>
        </li>
      </ol>
    </div>
  )
}
