import React from 'react'

import { MenuSteps } from '@/components/Event/NewScript/components/common/Stepper/MenuSteps'
import { NavSteps } from '@/components/Event/NewScript/components/common/Stepper/NavSteps'
import { Event } from '@/types'

export type StepsNavigationProps = {
  collapsed?: boolean
  event: Event
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({
  event,
  collapsed = false,
}) => {
  if (collapsed) {
    return <MenuSteps event={event} />
  } else {
    return <NavSteps event={event} />
  }
}
