import { forwardRef, PropsWithChildren } from 'react'
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'

// polymorphic wrapper of polymorphic component, see
// https://v6.mantine.dev/guides/polymorphic/#create-your-own-polymorphic-components

type SubtleButtonProps = PropsWithChildren<
  Omit<ButtonProps, 'variant' | 'color' | 'sx'>
>

const _SubtleButton = forwardRef<HTMLButtonElement, SubtleButtonProps>(
  ({ children, ...rest }, ref) => (
    <Button
      ref={ref}
      variant="subtle"
      color="primary.2"
      sx={{
        ':hover': {
          backgroundColor: 'transparent',
        },
      }}
      {...rest}
    >
      {children}
    </Button>
  ),
)
_SubtleButton.displayName = 'SubtleButton'

export const SubtleButton = createPolymorphicComponent<
  'button',
  SubtleButtonProps
>(_SubtleButton)
