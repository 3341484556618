import { useMutation, UseMutationOptions, useQuery } from 'react-query'
import axios from 'axios'

import { eventZoombotStatus } from '@/queries/urls'

type ZoombotStatusItem = {
  state: boolean
  detail?: string
}

type ZoombotStatus = {
  recording_paused: ZoombotStatusItem
  event_finished: ZoombotStatusItem
  event_suspended: ZoombotStatusItem
}

export const useZoombotStatus = (eventId: string) => {
  return useQuery(
    ['recording_status', eventId],
    () =>
      axios.get<ZoombotStatus>(eventZoombotStatus(eventId)).then(r => r.data),
    {
      enabled: eventId !== undefined,
      // sync with server every 5 secs
      refetchInterval: 5000,
    },
  )
}

type ZoombotStatusAction = 'resume_recording' | 'pause_recording'

export const useMutateZoombotStatus = ({
  eventId,
  options,
}: {
  eventId: string
  options: UseMutationOptions<undefined, any, ZoombotStatusAction>
}) => {
  return useMutation(
    (action: ZoombotStatusAction) =>
      axios
        .post(eventZoombotStatus(eventId), { status: action })
        .then(r => r.data),
    options,
  )
}
