import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mantine/core'

import { useAuth } from '@/hooks/useAuth'
import { Event } from '@/types'

export const EventDetailButton: React.FC<{
  event: Event
}> = ({ event }) => {
  const { user } = useAuth()

  return (
    <Button
      component={Link}
      to={
        user?.role === 'admin'
          ? `/admin/event/${event.id}`
          : `/event/${event.id}`
      }
      variant="light"
      color="gray"
    >
      Go to detail
    </Button>
  )
}
