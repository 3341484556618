import { useMemo, useState } from 'react'
import uniq from 'lodash/uniq'

import { Filters } from './FilterControls'

import { toCalendarEvent } from '@/components/Calendar/Calendar'
import { useCalendarEvents } from '@/queries/calendar'
import * as urls from '@/queries/urls'
import { CalendarParams } from '@/queries/useCalendarParams'
import { AdminEvent, Event, ReporterEvent } from '@/types'
import { isReporterEvent } from '@/utils/parrot'
import { normalize } from '@/utils/string'

export type UseFilteredCalendarEventsProps = {
  calendarParams: CalendarParams
  initialFilters: Filters
}

export const useFilteredCalendarEvents = ({
  calendarParams,
  initialFilters,
}: UseFilteredCalendarEventsProps) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)

  const {
    events: allEvents,
    isFetching,
    refetch,
  } = useCalendarEvents<AdminEvent[] | ReporterEvent[]>({
    url: filters.reporter_id
      ? urls.reporterEvents(filters.reporter_id)
      : urls.adminEvents(),
    calendarParams,
  })

  const filterByState = (event: Event) => {
    if (!filters.state) {
      return true
    }

    return filters.state === event.case.state
  }

  const filterByStatus = (event: Event) => {
    if (filters.reporter_id && isReporterEvent(event)) {
      return filters.reporter_status_in.includes(event.reporter_status)
    }

    return filters.general_status_in.includes(event.general_status)
  }

  const filterByEvent = (event: Event) => {
    const normalizedSearch = normalize(filters.event_search ?? '')
    const includesEventName = normalize(event.name).includes(normalizedSearch)

    return includesEventName
  }

  const filterByTeam = (event: Event) => {
    const normalizedSearch = normalize(filters.team_search ?? '')
    const includesTeamName = normalize(event.team.name).includes(
      normalizedSearch,
    )

    return includesTeamName
  }

  const filterByOrganization = (event: Event) => {
    const normalizedSearch = normalize(filters.organization_search ?? '')

    const includesOrganizationName = normalize(
      event.team.organization.name,
    ).includes(normalizedSearch)

    return includesOrganizationName
  }

  const filterByRequiredReporterExperienceLevel = (event: Event) => {
    if (filters.required_reporter_experience_level === undefined) {
      return true
    }

    return (
      event.computed_required_reporter_experience_level ===
      filters.required_reporter_experience_level
    )
  }

  const events = useMemo(
    () =>
      allEvents
        .filter(filterByStatus)
        .filter(filterByEvent)
        .filter(filterByTeam)
        .filter(filterByOrganization)
        .filter(filterByRequiredReporterExperienceLevel)
        .filter(filterByState)
        .map(toCalendarEvent),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allEvents, filters],
  )

  // filter used to remove falsy values - undefined/null/empty string from states
  // it shouldn't happen that in reporters app there would be event in case with
  // blank state but our model allows it due to quick schedules
  const states = uniq(allEvents.map(event => event.case.state)).filter(
    state => state,
  )

  return {
    events,

    filters,
    setFilters,

    isFetching,
    refetch,

    states,
  }
}
