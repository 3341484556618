import React from 'react'
import { LoadingOverlay } from '@mantine/core'

import { FilesModal } from './FilesModal'

import { useExhibitsQuery } from '@/queries/events'
import { Event } from '@/types'

export const ExhibitsModal: React.FC<{ event: Event; onClose: () => void }> = ({
  event,
  onClose,
}) => {
  const { data: exhibits, isLoading } = useExhibitsQuery(event)

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <FilesModal
        files={(exhibits ?? []).map(exhibit => ({
          key: exhibit.id,
          name: exhibit.name,
          url: exhibit.url,
        }))}
        empty="There are no exhibits"
        onClose={onClose}
      />
    </>
  )
}
