import React from 'react'

import { ActionButton } from './ActionButton'

import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { Event } from '@/types'

export const OpenLiveTranscriptActionButton: React.FC<{ event: Event }> = ({
  event,
}) => {
  const trackUserAction = useTrackUserAction()

  const onButtonClick = () => {
    trackUserAction('Live transcript opened', { event_id: event.id })
    return true
  }

  return (
    <ActionButton
      component="a"
      href={event.livestream_url}
      target="_blank"
      variant="outline"
      onClick={onButtonClick}
    >
      Open live transcript
    </ActionButton>
  )
}
