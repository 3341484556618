import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { isExaminationUnderOath } from '@/components/Event/NewScript/utils/event'
import { ScriptStep, ScriptStepStatus } from '@/constants'
import { Event } from '@/types'

export const useStepActions = (step: ScriptStep, event: Event) => {
  const { actions, state } = useScriptState(event)

  const isActionCompleted = (key: string): boolean => {
    return state.steps[step].actions[key]?.completed ?? false
  }

  const canContinue = () => {
    if (
      [ScriptStepStatus.COMPLETED, ScriptStepStatus.SKIPPED].includes(
        state.steps[step].status,
      )
    ) {
      return false
    }

    return Object.values(state.steps[step].actions).every(
      a => a.optional || a.skip || a.completed,
    )
  }

  const completeAction = (key: string) => {
    return actions.completeStepAction({ step: step, key })
  }

  const undoAction = (key: string) => {
    return actions.undoStepAction({ step: step, key })
  }

  const completeStepAndContinue = (nextStep: ScriptStep) => {
    return actions.completeStepAndContinue({ step, nextStep })
  }

  const cancelProceeding = () => {
    const notSkippable = [ScriptStep.CLOSING, ScriptStep.REVIEW]

    let first_skipped_step: ScriptStep | null = null
    const skipSteps: ScriptStep[] = []
    const skipStepActions = []
    const unskipStepActions = []

    Object.values(state.steps).forEach(step => {
      if (
        !notSkippable.includes(step.key) &&
        step.status !== ScriptStepStatus.COMPLETED
      ) {
        if (first_skipped_step === null) {
          first_skipped_step = step.key
        }
        //actions.skipStep(step.key)
        skipSteps.push(step.key)
      }
    })

    // Changing optional and non-optional steps
    skipStepActions.push({
      step: ScriptStep.CLOSING,
      key: 'collect_information',
    })
    skipStepActions.push({
      step: ScriptStep.CLOSING,
      key: 'go_off_the_record',
    })
    unskipStepActions.push({
      step: ScriptStep.CLOSING,
      key: 'declare_how_deposition_ended',
    })

    // Review
    skipStepActions.push({
      step: ScriptStep.REVIEW,
      key: 'deposition_ended',
    })

    skipStepActions.push({
      step: ScriptStep.REVIEW,
      key: 'identity_documents',
    })

    skipStepActions.push({
      step: ScriptStep.REVIEW,
      key: 'transcript_information',
    })

    skipStepActions.push({
      step: ScriptStep.REVIEW,
      key: 'low_confidence_words',
    })

    skipStepActions.push({
      step: ScriptStep.REVIEW,
      key: 'examinations',
    })

    skipStepActions.push({
      step: ScriptStep.CLOSING,
      key: 'clarify_exhibit_and_spelling',
    })

    actions.cancelProceeding({
      skipSteps,
      skipStepActions,
      unskipStepActions,
      first_skipped_step,
    })
  }

  const revertCanceledProceeding = () => {
    const isEUO = isExaminationUnderOath(event)
    actions.revertCanceledProceeding(isEUO)
  }

  return {
    isActionCompleted,
    canContinue,
    completeAction,
    completeStepAndContinue,
    undoAction,
    cancelProceeding,
    revertCanceledProceeding,
  }
}
