import { ZoomParticipant } from '@/types'

export const getFieldName = (fieldName: string, prefix?: string) => {
  if (prefix !== undefined) {
    return `${prefix}.${fieldName}`
  }

  return fieldName
}

export const transformToZoomParticipants = (
  values: string[],
  zoomParticipants: ZoomParticipant[],
): ZoomParticipant[] => {
  return values.map(zpId => zoomParticipants.find(p => zpId === p.id)!)
}

export const getCombinedId = (zoomParticipant: ZoomParticipant): string => {
  return `${zoomParticipant.meeting_instance_id}:${zoomParticipant.user_id}`
}
