import { useMutation, useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import axios from 'axios'
import sortBy from 'lodash/sortBy'

import * as urls from './urls'

import { Reporter, ReporterBase, ReporterRole } from '@/types'
import { formatPhoneNumberToInternationalFormat } from '@/utils/phoneNumber'

export const useReportersQuery = () =>
  useQuery('reporters', () =>
    axios
      .get<ReporterBase[]>(urls.reporters())
      .then(r => r.data)
      .then(reporters => sortBy(reporters, r => r.name.toLowerCase())),
  )

export const useReporterQuery = (
  id: string | undefined,
  options?: Omit<UseQueryOptions<Reporter>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<Reporter>(
    ['reporter', id],
    () => axios.get(urls.reporter(id!)).then(r => r.data),
    { enabled: id !== undefined, ...options },
  )

export const useCreateReporterMutation = (
  onCreated: (_: { password_set_url: string }) => void,
) =>
  useMutation((payload: Pick<Reporter, 'name' | 'email'>) => {
    return axios
      .post<{ password_set_url: string }>(urls.reporters(), payload)
      .then(r => r.data)
      .then(onCreated)
  })

export type UpdateReporterPayload = Pick<
  Reporter,
  | 'name'
  | 'email'
  | 'state'
  | 'county'
  | 'phone_number'
  | 'commission_number'
  | 'commission_expiry'
  | 'stripe_account_id'
  | 'commission_licenses'
  | 'certificates'
>

export const useUpdateReporterMutation = (
  reporter: Reporter,
  onUpdated: (reporter: ReporterBase) => void,
) =>
  useMutation((payload: UpdateReporterPayload) => {
    const phoneNumber = payload.phone_number
      ? formatPhoneNumberToInternationalFormat(payload.phone_number)
      : null

    return axios
      .put<Reporter>(reporter.url, {
        ...payload,
        phone_number: phoneNumber,
      })
      .then(r => onUpdated(r.data))
  })

export const useUpdateReporterExperienceLevelMutation = (
  reporter: ReporterBase,
  onUpdated: () => void,
) =>
  useMutation((payload: { experience_level: string }) => {
    return axios
      .put(reporter.experience_url, {
        experience_level: parseInt(payload.experience_level),
      })
      .then(onUpdated)
  })

export const useSetReporterStripeAccountIdMutation = (
  reporter: ReporterBase,
  onUpdated: () => void,
) =>
  useMutation((payload: { stripe_account_id: string }) => {
    return axios
      .put(reporter.stripe_account_url, {
        stripe_account_id: payload.stripe_account_id,
      })
      .then(onUpdated)
  })

export const useSetReporterRoleMutation = (
  reporter: ReporterBase,
  onUpdated: () => void,
) =>
  useMutation((payload: { role: ReporterRole }) => {
    return axios.put(reporter.role_url, { role: payload.role }).then(onUpdated)
  })

export const useDeactivateReporterMutation = (
  reporter: ReporterBase,
  options: {
    onSuccess?: () => void
  },
) =>
  useMutation(() => {
    return axios.put(reporter.deactivate_url).then(options?.onSuccess)
  })

export const useReactivateReporterMutation = (
  reporter: ReporterBase,
  options: {
    onSuccess?: () => void
  },
) =>
  useMutation(() => {
    return axios.put(reporter.reactivate_url).then(options?.onSuccess)
  })

export const useResendWelcomeEmailMutation = (
  reporter: ReporterBase,
  options: {
    onSuccess?: () => void
    onError?: () => void
  },
) =>
  useMutation(() => {
    return axios
      .post(reporter.resend_welcome_email_url)
      .then(options?.onSuccess)
      .catch(options?.onError)
  })
