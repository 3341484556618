import React from 'react'
import { Group, Text, Tooltip } from '@mantine/core'
import { IconProps } from '@radix-ui/react-icons/dist/types'

export const DataEntry: React.FC<{
  icon: React.ForwardRefExoticComponent<IconProps>
  tooltip?: string
}> = ({ icon: Icon, children, tooltip }) => {
  return (
    <Tooltip label={tooltip} disabled={!tooltip} withinPortal>
      <Group m={1.5} noWrap align="center" spacing="sm">
        <div>
          <Icon width={18} height={18} />
        </div>

        <Text size="sm">{children}</Text>
      </Group>
    </Tooltip>
  )
}
