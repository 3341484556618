export const reporters = (): string => '/api/reporters'

export const reporter = (id: string): string => `/api/reporters/${id}`

export const adminEvents = (): string => `/api/admins/events`

export const adminEvent = (id: string): string => `/api/admins/events/${id}`

export const reporterEvents = (reporter_id: string): string =>
  `/api/reporters/${reporter_id}/events`

export const reporterWaitingEvents = (reporter_id: string): string =>
  `/api/reporters/${reporter_id}/events/waiting`

export const reporterAvailableEvents = (reporter_id: string): string =>
  `/api/reporters/${reporter_id}/events/available`

export const reporterEvent = (reporter_id: string, id: string): string =>
  `/api/reporters/${reporter_id}/events/${id}`

export const eventZoombotStatus = (event_id: string) =>
  `/api/events/${event_id}/zoombot-status`

export const eventNewZoomParticipants = (event_id: string) =>
  `/api/events/${event_id}/participants`
