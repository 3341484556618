import React from 'react'
import { SegmentedControl } from '@mantine/core'

import { View } from './Calendar'

export const STANDARD_VIEWS: { label: string; value: View }[] = [
  { label: 'Month', value: 'month' },
  { label: 'Week', value: 'week' },
  { label: 'Day', value: 'day' },
  { label: 'List', value: 'list' },
]

export const ViewControl: React.FC<{
  value: View | undefined
  onChange: (view: View) => void
}> = ({ value, onChange }) => {
  return (
    <SegmentedControl value={value} onChange={onChange} data={STANDARD_VIEWS} />
  )
}
