import { ATTENDEE_NAME_IGNORED_WORDS, AttendeeRole } from '@/constants'

export const isValidEmail = (value: string): boolean => {
  return /^\S+@\S+$/.test(value)
}

export const isAttorney = (role: AttendeeRole): boolean => {
  return [
    AttendeeRole.DEFENDANT_ATTORNEY,
    AttendeeRole.PLAINTIFF_ATTORNEY,
    AttendeeRole.WITNESS_ATTORNEY,
  ].includes(role)
}

export const isDR = (role: AttendeeRole): boolean => {
  return AttendeeRole.DIGITAL_REPORTER === role
}

export const isWitness = (role: AttendeeRole): boolean => {
  return AttendeeRole.WITNESS === role
}

export const isIgnoredWord = (word: string) => {
  return ATTENDEE_NAME_IGNORED_WORDS.includes(
    // remove punctuation and convert to lowercase
    word.replace(/[^\w\s']/, '').toLowerCase(),
  )
}
