import React from 'react'
import { Alert, LoadingOverlay } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { DetailsCard } from './components/DetailsCard'
import { DuringEventInfoCard } from './components/DuringEventInfoCard'
import { EndCard } from './components/EndCard'
import { Layout } from './components/Layout'
import { LinksCard } from './components/LinksCard'

import {
  hasHearingDocket,
  HearingDocket,
} from '@/components/Event/Detail/components/HearingDocket'
import { ZoombotControlsCard } from '@/components/Event/Detail/components/ZoombotControlsCard'
import { FeedbackForm } from '@/components/Event/Feedback/FeedbackForm'
import { canUseScriptForEvent } from '@/components/Event/Script/utils/event'
import { useReporterFeedbackQuery } from '@/queries/events'
import { useReporterQuery } from '@/queries/reporters'
import { Event } from '@/types'

export const OngoingBookedEventDetail: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  const { data: feedback, isLoading: isFeedbackLoading } =
    useReporterFeedbackQuery(event)
  const { data: reporter, isLoading: isReporterLoading } = useReporterQuery(
    event.booking?.reporter_id,
  )

  if (isFeedbackLoading || isReporterLoading) {
    return <LoadingOverlay visible={true} />
  }

  return (
    <Layout
      left={
        <>
          <Alert title="Event is in progress">
            Fill in this form as information is received.
          </Alert>

          {hasHearingDocket(event) && <HearingDocket event={event} />}

          <FeedbackForm
            event={event}
            reporter={reporter!}
            feedback={feedback}
            onSubmit={onChange}
          />
        </>
      }
      right={
        <>
          {event.type === 'hearing' && (
            <ZoombotControlsCard event={event} onChange={onChange} />
          )}
          <DetailsCard event={event} />
          <DuringEventInfoCard />
          <LinksCard
            event={event}
            options={{
              join_button: true,
              open_script: canUseScriptForEvent(event),
              open_live_transcript: true,
            }}
          />
          <EndCard event={event} onEnd={onChange} />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
