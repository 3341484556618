import React from 'react'
import { List, Text } from '@mantine/core'

import { Card } from './Card'

export const DuringEventInfoCard: React.FC = () => {
  return (
    <Card heading="During event">
      <List type="unordered" withPadding style={{ listStyle: 'disc' }}>
        <List.Item>
          <Text component="span" size="sm">
            Monitor crosstalk
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Monitor livestream
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Assist on and off record
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Confirm spelling
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Read back assisted
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Make note of timestamps
          </Text>
        </List.Item>
        <List.Item>
          <Text component="span" size="sm">
            Exhibits timestamps
          </Text>
        </List.Item>
      </List>
    </Card>
  )
}
