import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingOverlay } from '@mantine/core'

import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { canUseDRScriptForEvent } from '@/components/Event/NewScript/utils/event'
import { NewScriptScreen } from '@/components/NewScriptScreen'
import { STALE_TIME_EVENT_QUERY } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { useReporterEventQuery } from '@/queries/reporterEvents'
import { NotFound } from '@/routes/NotFound'

export const NewScript: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { user } = useAuth()

  const {
    isLoading,
    error,
    data: event,
    refetch,
  } = useReporterEventQuery(id, user?.id, {
    staleTime: STALE_TIME_EVENT_QUERY,
  })
  const hasAccess = event && canUseDRScriptForEvent(event)
  const { hasTrainingAccess } = useNewScriptTraining(event)

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  if (error || !event || (!hasAccess && !hasTrainingAccess)) {
    return <NotFound />
  }

  return <NewScriptScreen event={event} refetchEvent={refetch} />
}
