import React from 'react'
import { Stack, Timeline, TimelineProps, Title } from '@mantine/core'

export type ScriptTimelineProps = {
  title: React.ReactNode
} & Omit<TimelineProps, 'title' | 'bulletSize' | 'lineWidth' | 'active'>

export const ScriptTimeline: React.FC<ScriptTimelineProps> = ({
  title,
  children,
}) => {
  return (
    <Stack w={'100%'}>
      <Title order={2}>{title}</Title>
      <Timeline bulletSize={24} lineWidth={2} active={Number.MAX_VALUE}>
        {children}
      </Timeline>
    </Stack>
  )
}
