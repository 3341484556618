import React from 'react'
import { Burger, Button, Group, MediaQuery } from '@mantine/core'

import logo from '@/logo.svg'

type Props = {
  isBurgerMenuOpen: boolean
  onBurgerMenuClick: () => void
  onTodayClick?: () => void
}

export const Left: React.FC<Props> = ({
  onBurgerMenuClick,
  onTodayClick,
  isBurgerMenuOpen,
}) => (
  <Group className="gap-2" noWrap>
    <MediaQuery largerThan="md" styles={{ display: 'none' }}>
      <Burger
        color="gray"
        size="xs"
        opened={isBurgerMenuOpen}
        onClick={() => onBurgerMenuClick()}
      />
    </MediaQuery>
    <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
      <img src={logo} alt="logo" className="w-7 h-7" />
    </MediaQuery>
    <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
      <Button variant="light" color="gray" onClick={onTodayClick}>
        Today
      </Button>
    </MediaQuery>
  </Group>
)
