import { modals } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'

import { useEndEventMutation } from '@/queries/events'
import { Event } from '@/types'

const showErrorNotification = () => {
  showNotification({
    color: 'red',
    message:
      'Error occurred while automatically ending the meeting. Please end it manually.',
  })
}

const showSuccessNotification = () => {
  showNotification({
    color: 'green',
    message: 'Event was ended successfully',
  })
}

export const useEndZoomEvent = (event: Event) => {
  const { mutateAsync, isError, isLoading } = useEndEventMutation(event, {
    onSuccess: () => {
      showSuccessNotification()
    },
    onErrorMessage: () => {
      showErrorNotification()
    },
    onError: () => {
      showErrorNotification()
    },
  })

  const confirmAndEndEvent = (onEnd: () => void) => {
    modals.openConfirmModal({
      title: 'End meeting for all?',
      children:
        'All attendees will be disconnected from the call, and it will not be possible to continue with the event',
      labels: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'red', loading: isLoading },
      onConfirm: () => {
        mutateAsync().finally(() => {
          onEnd()
        })
      },
    })
  }

  return { endZoomEvent: mutateAsync, confirmAndEndEvent, isError, isLoading }
}
