import React from 'react'
import { Alert, LoadingOverlay, Stack, Text } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'
import {
  DownloadAudioRecordingButton,
  DownloadVideoRecordingButton,
  LinksCard,
  OpenTranscriptButton,
} from './components/LinksCard'

import {
  hasHearingDocket,
  HearingDocket,
} from '@/components/Event/Detail/components/HearingDocket'
import { FeedbackForm } from '@/components/Event/Feedback/FeedbackForm'
import { canUseScriptForEvent } from '@/components/Event/Script/utils/event'
import { AvailableEventsTeaser } from '@/components/Event/Teaser/AvailableEventsTeaser'
import {
  useMediaResourcesQuery,
  useReporterFeedbackQuery,
} from '@/queries/events'
import { useReporterQuery } from '@/queries/reporters'
import { Event } from '@/types'

export const PastEventDetail: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  const { data: feedback, isLoading: isFeedbackLoading } =
    useReporterFeedbackQuery(event)
  const { data: reporter, isLoading: isReporterLoading } = useReporterQuery(
    event.booking?.reporter_id,
  )
  const { data: media, isLoading: isMediaLoading } =
    useMediaResourcesQuery(event)

  if (isFeedbackLoading || isReporterLoading || isMediaLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Layout
      left={
        <>
          <Alert title="Past event" color="gray">
            All good. No action needed.
          </Alert>

          {hasHearingDocket(event) && <HearingDocket event={event} />}

          {event.is_waiting_for_reporter_feedback ? (
            <FeedbackForm
              event={event}
              reporter={reporter!}
              feedback={feedback}
              onSubmit={onChange}
            />
          ) : (
            <Card>
              <Stack align="flex-start">
                <Text size="sm">
                  {event.is_court_reporter_event ? (
                    <>
                      Thank you very much for conducting the event. If you need
                      to access audio or video files, you can follow these
                      links.
                    </>
                  ) : (
                    <>
                      Thank you very much for being the digital reporter. If you
                      need to access transcript or recording, you can follow
                      these links.
                    </>
                  )}
                </Text>

                {!event.is_court_reporter_event && (
                  <OpenTranscriptButton event={event} media={media ?? []} />
                )}

                <DownloadAudioRecordingButton media={media ?? []} />

                <DownloadVideoRecordingButton media={media ?? []} />
              </Stack>
            </Card>
          )}

          <AvailableEventsTeaser />
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <LinksCard
            event={event}
            options={{
              join_button: false,
              open_script: canUseScriptForEvent(event),
              open_live_transcript: true,
            }}
          />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
