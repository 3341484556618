import { ReactNode } from 'react'
import { Col, Grid } from '@mantine/core'

export const Layout = ({
  left,
  right,
}: {
  left: ReactNode
  right: ReactNode
}) => {
  return (
    <Grid>
      <Col span={8} className="space-y-5">
        {left}
      </Col>
      <Col span={4} className="space-y-5">
        {right}
      </Col>
    </Grid>
  )
}
