import React from 'react'
import { Title, TitleProps } from '@mantine/core'

export const ActionTitle: React.FC<Omit<TitleProps, 'order' | 'color'>> = ({
  children,
  ...props
}) => {
  return (
    <Title {...props} color="gray.9" order={6}>
      {children}
    </Title>
  )
}
