import React, { useMemo } from 'react'
import { Flex, Grid, Stack, Text } from '@mantine/core'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import {
  DEPOSITION_ENDED_OPTIONS_LABEL,
  DepositionResult,
  REASON_FOR_CNA_LABEL,
  ReasonForCna,
} from '@/constants'
import { Event } from '@/types'
import { formatTimeForTimezone } from '@/utils/time'

type Props = {
  onEditClick: () => void
  depositionEnded: string
  notes: string
  event: Event
}

export const EventCancelledCardView: React.FC<Props> = ({
  onEditClick,
  event,
}) => {
  const { state } = useScriptState(event)

  const { timezone } = useFormattedCurrentDateParts(event.team.timezone)

  const { firstLine, secondLine } = useMemo(() => {
    const firstLine = DEPOSITION_ENDED_OPTIONS_LABEL[state.result]
    const last_timestamp = state.last_timestamp

    if (state.result !== DepositionResult.CNA) {
      return {
        firstLine,
        secondLine: state.notes,
      }
    }

    return {
      firstLine,
      secondLine: `${formatTimeForTimezone(
        last_timestamp,
        event.team.timezone,
      )} ${timezone} - ${
        REASON_FOR_CNA_LABEL[state.reason_for_cna as ReasonForCna]
      } - ${state.other_reason}`,
    }
  }, [
    state.result,
    state.notes,
    state.reason_for_cna,
    state.other_reason,
    state.last_timestamp,
    event.team.timezone,
    timezone,
  ])

  return (
    <ActionContainer outer>
      <ActionContainer>
        <Grid grow>
          <Grid.Col span={10}>
            <Stack>
              <Text>{firstLine}</Text>
              <Text>{secondLine}</Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex align="center" justify="end" h={'100%'} w="100%">
              <SubtleButton p={0} onClick={onEditClick}>
                Edit
              </SubtleButton>
            </Flex>
          </Grid.Col>
        </Grid>
      </ActionContainer>
    </ActionContainer>
  )
}
