import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Center, MediaQuery, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { BellIcon } from '@radix-ui/react-icons'

import { UserMenu } from '@/components/Header/UserMenu'
import { FAQ_URL } from '@/constants'
import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'

type Props = {
  waitingEventsCount?: number
  showHelpCenter?: boolean
}

export const Right: React.FC<Props> = ({
  waitingEventsCount,
  showHelpCenter,
}) => {
  const { breakpoints } = useMantineTheme()
  const trackUserAction = useTrackUserAction()
  const isTabletResolution = useMediaQuery(`(max-width: ${breakpoints.md})`)

  const onOpenFAQ = () => {
    trackUserAction('FAQ clicked', {})
    return true
  }

  const waitingEventsButtonLabel = isTabletResolution
    ? waitingEventsCount
    : `Waiting for me (${waitingEventsCount})`

  return (
    <Center className="sm:w-full h-full flex items-center justify-end overflow-hidden gap-4">
      {!!waitingEventsCount && (
        <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
          <Button
            variant="light"
            color="red"
            leftIcon={<BellIcon />}
            component={Link}
            to="/waiting"
          >
            {waitingEventsButtonLabel}
          </Button>
        </MediaQuery>
      )}
      {showHelpCenter && (
        <Button
          variant="subtle"
          color="gray"
          component="a"
          href={FAQ_URL}
          target="_blank"
          onClick={onOpenFAQ}
        >
          FAQ
        </Button>
      )}
      <UserMenu />
    </Center>
  )
}
