import React from 'react'
import { Text } from '@mantine/core'

type ActionTextProps = {
  mono?: boolean
}

export const ActionText: React.FC<ActionTextProps> = ({ children, mono }) => {
  return (
    <Text
      size="sm"
      color="gray.9"
      sx={{
        fontFamily: mono ? 'Aeonik Mono Medium, monospace' : undefined,
      }}
    >
      {children}
    </Text>
  )
}
