import React from 'react'
import { Box } from '@mantine/core'

export type ActionContainerProps = {
  outer?: boolean
}

export const ActionContainer: React.FC<ActionContainerProps> = ({
  outer = false,
  children,
}) => {
  return (
    <Box
      p="xl"
      w="100%"
      sx={theme => ({
        backgroundColor: theme.colors.indigo[outer ? 0 : 1],
        // TODO: use xss custom size here
        borderRadius: theme.radius[outer ? 'md' : 'sm'],
        position: 'relative',
      })}
    >
      {children}
    </Box>
  )
}
