import { Alert, List, Text, Title } from '@mantine/core'
import isEmpty from 'lodash/isEmpty'

export type FormWarningsProps = {
  warnings: string[]
}

export const FormWarnings = ({ warnings }: FormWarningsProps) => {
  if (isEmpty(warnings)) {
    return null
  }

  return (
    <Alert
      title={
        <Title order={6} color="gray.9">
          We may have detected an error. Please double check the information you
          provided:
        </Title>
      }
      color="orange.3"
      variant="filled"
    >
      <List withPadding style={{ listStyle: 'disc' }}>
        {warnings.map(warning => (
          <List.Item key={warning}>
            <Text color="gray.9">{warning}</Text>
          </List.Item>
        ))}
      </List>
    </Alert>
  )
}
