import React from 'react'

import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import { formatTimeForTimezone } from '@/utils/time'

type CNAVerbatimProps = {
  timezone: string
  eventName: string
  eventDatetime: string
  witnessName: string
}

export const CNAVerbatim: React.FC<CNAVerbatimProps> = ({
  timezone,
  eventName,
  eventDatetime,
  witnessName,
}) => {
  const dateTime = useFormattedCurrentDateParts(timezone)
  const eventScheduledStartTime = formatTimeForTimezone(eventDatetime, timezone)

  return (
    <>
      Today is {dateTime.day}. It is {dateTime.date} {dateTime.month}{' '}
      {dateTime.year} the time is {dateTime.time} {dateTime.timezone}. We are
      present to record the {eventName} which was scheduled for{' '}
      {eventScheduledStartTime}. {witnessName} has failed to appear and
      accordingly at this time I will be issuing a certificate of
      non-appearance.
    </>
  )
}
