import React, { useMemo, useState } from 'react'
import { Select, TextInput } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import uniq from 'lodash/uniq'

import { BooleanSelect } from '@/components/common/BooleanSelect'
import { StateSelect } from '@/components/StateSelect'
import { ReporterBase, ReporterRole } from '@/types'
import { EXPERIENCE_LEVELS, REPORTER_ROLES } from '@/utils/parrot'

export type ReporterFilters = {
  search: string | null
  state: string | null
  experience_level: string | null
  is_active: boolean | null
  has_documents: boolean | null
  role: ReporterRole | null
}

export const ReporterFilterControls: React.FC<{
  reporters: ReporterBase[]
  onChange: (filters: ReporterFilters) => void
  initialValue: ReporterFilters
}> = ({ reporters, onChange, initialValue }) => {
  const [filters, setFilters] = useState<ReporterFilters>(initialValue)

  const states = useMemo<string[]>(
    () =>
      uniq(reporters.map(r => r.state)).filter(
        (state: string | null | undefined): state is string => Boolean(state),
      ),
    [reporters],
  )

  const levels = EXPERIENCE_LEVELS.map(level => level.value)

  const handleFilterChange = (newFilters: Partial<ReporterFilters>) => {
    setFilters({ ...filters, ...newFilters })
  }

  useDidUpdate(() => {
    onChange(filters)
  }, [filters])

  return (
    <>
      <TextInput
        rightSection={<MagnifyingGlassIcon color="gray" />}
        placeholder="Search"
        value={filters.search ?? ''}
        onChange={e => handleFilterChange({ search: e.target.value })}
      />
      <Select
        data={levels}
        value={filters.experience_level}
        onChange={experience_level => handleFilterChange({ experience_level })}
        placeholder="All levels"
        clearable
      />
      <Select
        data={REPORTER_ROLES}
        value={filters.role}
        onChange={(role: ReporterRole | null) => handleFilterChange({ role })}
        placeholder="All roles"
        clearable
      />
      <StateSelect
        value={filters.state}
        states={states}
        onChange={state => handleFilterChange({ state })}
        placeholder="All states"
        clearable
        searchable
      />
      <BooleanSelect
        trueLabel="Active"
        falseLabel="Inactive"
        value={filters.is_active}
        onChange={is_active =>
          handleFilterChange({
            is_active,
          })
        }
        placeholder="Any status"
        clearable
      />
      <BooleanSelect
        value={filters.has_documents}
        onChange={has_documents =>
          handleFilterChange({
            has_documents,
          })
        }
        placeholder="Documents"
        clearable
      />
    </>
  )
}
