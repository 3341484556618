import { useParams } from 'react-router'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'

import { View } from '@/components/Calendar/Calendar'
import { STANDARD_VIEWS } from '@/components/Calendar/ViewControl'

export const CALENDAR_PARAMS_PATH =
  ':view(month|week|day|list)/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})'

export type CalendarParams = {
  view: Exclude<View, 'listCustom'>
  date: Date
}

export const useCalendarParams = (): CalendarParams | undefined => {
  const { view, year, month, day } = useParams<{
    view?: string
    year?: string
    month?: string
    day?: string
  }>()

  const viewIsValid = (view: string): view is CalendarParams['view'] =>
    STANDARD_VIEWS.map<string>(view => view.value).includes(view)

  const date = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date())

  if (view === undefined || !viewIsValid(view) || !isValid(date)) {
    return undefined
  }

  return { view, date }
}

export const toCalendarParamsPath = (
  view: CalendarParams['view'],
  date: Date,
): string => {
  const year = format(date, 'yyyy')
  const month = format(date, 'MM')
  const day = format(date, 'dd')

  return `${view}/${year}/${month}/${day}`
}
