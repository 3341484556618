import React from 'react'
import { Center, Paper, Space } from '@mantine/core'

import { Shell } from './Shell'

export const Wall: React.FC<{
  heading: React.ReactNode
}> = ({ heading, children }) => {
  return (
    <Shell header={{ hasHistory: false }}>
      <Paper p="xl">
        <Center>{heading}</Center>
        <Space />
        <Center>{children}</Center>
      </Paper>
    </Shell>
  )
}
