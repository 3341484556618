import { Event } from '@/types'

type StatePolicies = {
  canHaveCna: boolean
  canWitnessReadOrWaive: boolean
  isNY: boolean
  isCT: boolean
  isPA: boolean
}

export const useStatePolicies = (event?: Event): StatePolicies => {
  if (!event?.case.state) {
    return {
      canHaveCna: false,
      canWitnessReadOrWaive: false,
      isNY: false,
      isCT: false,
      isPA: false,
    }
  }

  return {
    canHaveCna: !['IL', 'GA'].includes(event.case.state),
    canWitnessReadOrWaive: !['MI', 'WI', 'NY'].includes(event.case.state),
    isNY: ['NY'].includes(event.case.state),
    isCT: ['CT'].includes(event.case.state),
    isPA: ['PA'].includes(event.case.state),
  }
}
