import React, { forwardRef } from 'react'
import { Select } from '@mantine/core'

type Props = Omit<
  React.ComponentProps<typeof Select>,
  'value' | 'onChange' | 'data'
> & {
  value: boolean | null
  onChange(newValue: boolean | null): void
  trueLabel?: string
  falseLabel?: string
}

export const BooleanSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, trueLabel = 'Yes', falseLabel = 'No', ...rest }, ref) => {
    const stringValue = value === null ? null : String(value)
    const handleChange = (newValue: string | null) => {
      if (newValue === null) {
        onChange(null)
        return
      }

      onChange(newValue === 'true')
    }
    const data = [
      {
        value: String(true),
        label: trueLabel,
      },
      {
        value: String(false),
        label: falseLabel,
      },
    ]
    return (
      <Select
        ref={ref}
        value={stringValue}
        onChange={handleChange}
        data={data}
        {...rest}
      />
    )
  },
)

BooleanSelect.displayName = 'BooleanSelect'
