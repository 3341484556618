import {
  AttendeeRole,
  DepositionResult,
  ScriptStep,
  ScriptStepStatus,
} from '@/constants'
import { DRScriptData } from '@/types'

export const SCRIPT_INITIAL_STATE: DRScriptData = {
  // General
  current_step: ScriptStep.PREPARATION,
  first_skipped_step: null,
  steps: {
    [ScriptStep.PREPARATION]: {
      key: ScriptStep.PREPARATION,
      status: ScriptStepStatus.IN_PROGRESS,
      actions: {
        join_zoom_using_meeting_id: {
          key: 'join_zoom_using_meeting_id',
          completed: false,
        },
        livestream_zoom_bot: {
          key: 'livestream_zoom_bot',
          completed: false,
        },
        create_breakout_rooms: {
          key: 'create_breakout_rooms',
          completed: false,
        },
        share_only_computer_audio: {
          key: 'share_only_computer_audio',
          completed: false,
        },
        open_live_transcript: {
          key: 'open_live_transcript',
          completed: false,
        },
        open_notice: {
          key: 'open_notice',
          completed: false,
        },
      },
    },
    [ScriptStep.ATTENDANCE]: {
      key: ScriptStep.ATTENDANCE,
      status: ScriptStepStatus.INCOMPLETE,
      actions: {
        attendees: {
          key: 'attendees',
          completed: false,
        },
        exhibits_app: {
          key: 'exhibits_app',
          completed: false,
          skip: true,
        },
        cna: {
          key: 'cna',
          completed: false,
          optional: false,
        },
      },
    },
    [ScriptStep.OPENING]: {
      key: ScriptStep.OPENING,
      status: ScriptStepStatus.INCOMPLETE,
      actions: {
        spotlight_the_wittness: {
          key: 'spotlight_the_wittness',
          completed: false,
        },
        go_on_the_record: {
          key: 'go_on_the_record',
          completed: false,
        },
        identify_counsels: {
          key: 'identify_counsels',
          completed: false,
        },
        let_counsels_stipulate: {
          key: 'let_counsels_stipulate',
          completed: false,
        },
        identify_wittness: {
          key: 'identify_wittness',
          completed: false,
        },
        swear_in_witness: {
          key: 'swear_in_witness',
          completed: false,
        },
        identify_interpreter: {
          key: 'identify_interpreter',
          completed: false,
          optional: true,
        },
        identify_guardian: {
          key: 'identify_guardian',
          completed: false,
          optional: true,
        },
      },
    },
    [ScriptStep.QUESTIONING]: {
      key: ScriptStep.QUESTIONING,
      status: ScriptStepStatus.INCOMPLETE,
      actions: {
        start_questioning: {
          key: 'start_questioning',
          completed: false,
        },
        oversight_the_progress: {
          key: 'oversight_the_progress',
          completed: false,
        },
      },
    },
    [ScriptStep.CLOSING]: {
      key: ScriptStep.CLOSING,
      status: ScriptStepStatus.INCOMPLETE,
      actions: {
        clarify_exhibit_and_spelling: {
          key: 'clarify_exhibit_and_spelling',
          completed: false,
        },
        collect_information: {
          key: 'collect_information',
          completed: false,
        },
        go_off_the_record: {
          key: 'go_off_the_record',
          completed: false,
        },
        declare_how_deposition_ended: {
          key: 'declare_how_deposition_ended',
          completed: false,
          skip: true,
        },
        state_the_cna: {
          key: 'state_the_cna',
          completed: false,
          skip: true,
        },
        select_reason_for_cna: {
          key: 'select_reason_for_cna',
          completed: false,
          skip: true,
        },
      },
    },
    [ScriptStep.REVIEW]: {
      key: ScriptStep.REVIEW,
      status: ScriptStepStatus.INCOMPLETE,
      actions: {
        attendees: {
          key: 'attendees',
          completed: false,
        },
        identity_documents: {
          key: 'identity_documents',
          completed: false,
        },
        transcript_information: {
          key: 'transcript_information',
          completed: false,
        },
        shown_exhibits: {
          key: 'shown_exhibits',
          completed: false,
          skip: true,
        },
        examinations: {
          key: 'examinations',
          completed: false,
        },
        location: {
          key: 'location',
          completed: false,
        },
        deposition_ended: {
          key: 'deposition_ended',
          completed: false,
          skip: true,
        },
        low_confidence_words: {
          key: 'low_confidence_words',
          completed: false,
          skip: false,
        },
      },
    },
  },

  // Attendance step
  attendees: [],
  zoom_participants: [],
  low_confidence_words: null,

  // Opening
  isRecordingStarted: undefined,

  // Questioning step
  last_timestamp: null,
  is_on_record: false,

  timestamps: null,
  spellings: null,
  notes: null,

  // Closing default
  witness_requested_transcript: null,
  order_transcript: false,
  isRecordingEnded: undefined,
  cna_declared_at: null,

  // Closing event canceled
  reason_for_cna: '',
  other_reason: '',

  // Review
  state: '',
  county: '',
  shown_exhibits: null,

  // Event result
  result: null as never as DepositionResult, // FIXME: This workaround is used to avoid Yup typing issues
  submitted: false,
}

export const ROLES_WITH_ID = [AttendeeRole.WITNESS, AttendeeRole.GUARDIAN]
