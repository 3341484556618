import { PropsWithChildren, useEffect } from 'react'
import { Column, useFilters, useGlobalFilter, useTable } from 'react-table'
import { Table } from '@mantine/core'

// type all values as nullable
type NullableObject<T> = { [K in keyof T]: T[keyof T] | null }

type Props<T extends object> = {
  data: T[]
  columns: Column<T>[]
  filters?: Partial<NullableObject<T>>
  searchQuery?: string | null
}

export const DataTable = <T extends object>({
  data,
  columns,
  filters,
  searchQuery,
}: PropsWithChildren<Props<T>>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = useTable<T>(
    { columns, data, autoResetGlobalFilter: false },
    useFilters,
    useGlobalFilter,
  )

  useEffect(() => {
    if (!filters) {
      return
    }

    Object.keys(filters).forEach(key => setFilter(key, filters[key as keyof T]))
  }, [filters, setFilter, setGlobalFilter])

  useEffect(() => {
    // this searches across the whole object T
    // if the search becomes too slow, we should debounce the value
    // and filter across a subset of fields only
    setGlobalFilter(searchQuery)
  }, [searchQuery, setGlobalFilter])

  return (
    <Table highlightOnHover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <th {...column.getHeaderProps()} key={i}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell, i) => (
                <td {...cell.getCellProps()} key={i}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
