import React from 'react'

import { useModals } from '@/hooks/useModals'
import { Event } from '@/types'

export const useFilesModal = (
  event: Event,
  title: string,
  Component: React.FC<{ event: Event; onClose: () => void }>,
) => {
  const modals = useModals()

  return () => {
    const id = modals.openModal({
      title,
      centered: true,
      children: (
        <Component event={event} onClose={() => modals.closeModal(id)} />
      ),
    })
  }
}
