import React, { useEffect, useMemo, useState } from 'react'
import { Box, Stack, Text } from '@mantine/core'

import {
  GeneralStatusFilter,
  ReporterFilter,
  ReporterStatusFilter,
  RequiredExperienceLevelFilter,
  SearchFilter,
  StateFilter,
} from '@/components/Calendar/FilterControls'
import { useReportersQuery } from '@/queries/reporters'
import { Event, ExperienceLevel, Reporter, ReporterEvent } from '@/types'

export type Filters = {
  general_status_in: Event['general_status'][]
  reporter_status_in: ReporterEvent['reporter_status'][]
  reporter_id?: Reporter['id']
  required_reporter_experience_level?: ExperienceLevel
  event_search?: string
  team_search?: string
  organization_search?: string
  state?: string | null
}

export const FilterControls: React.FC<{
  values: Filters
  onChange: (filters: Filters) => void
  states: Array<string>
}> = ({ values: initialFilters, onChange, states }) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const { data: allReporters = [] } = useReportersQuery()

  const reporters = useMemo(
    () => allReporters.filter(r => r.is_active),
    [allReporters],
  )

  useEffect(() => {
    onChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <Box ml="xl" mr="xl" mt="xs">
      <Stack>
        <Text weight="bold">Visible events</Text>
        <Stack spacing="sm">
          {filters.reporter_id ? (
            <ReporterStatusFilter
              values={filters.reporter_status_in}
              onChange={statuses =>
                setFilters(filters => ({
                  ...filters,
                  reporter_status_in: statuses,
                }))
              }
            />
          ) : (
            <GeneralStatusFilter
              values={filters.general_status_in}
              onChange={statuses =>
                setFilters(filters => ({
                  ...filters,
                  general_status_in: statuses,
                }))
              }
            />
          )}
        </Stack>
        <Text weight="bold" mt="sm">
          Filters
        </Text>
        <Stack spacing="md">
          <SearchFilter
            value={filters.event_search ?? ''}
            placeholder="Search name"
            onChange={event_search =>
              setFilters(filters => ({ ...filters, event_search }))
            }
          />
          <SearchFilter
            value={filters.team_search ?? ''}
            placeholder="Search team"
            onChange={team_search =>
              setFilters(filters => ({ ...filters, team_search }))
            }
          />
          <SearchFilter
            placeholder="Search organization"
            value={filters.organization_search ?? ''}
            onChange={organization_search =>
              setFilters(filters => ({ ...filters, organization_search }))
            }
          />
          <ReporterFilter
            reporters={reporters}
            value={filters.reporter_id}
            onChange={reporter =>
              setFilters(filters => ({
                ...filters,
                reporter_id: reporter,
                required_reporter_experience_level: undefined,
              }))
            }
          />
          <RequiredExperienceLevelFilter
            value={filters.required_reporter_experience_level}
            maxLevel={
              reporters.find(reporter => reporter.id === filters.reporter_id)
                ?.experience_level
            }
            onChange={required_reporter_experience_level =>
              setFilters(filters => ({
                ...filters,
                required_reporter_experience_level,
              }))
            }
          />
          <StateFilter
            value={filters?.state}
            states={states}
            onChange={state =>
              setFilters({
                ...filters,
                state,
              })
            }
          />
        </Stack>
      </Stack>
    </Box>
  )
}
