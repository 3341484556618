import axios from 'axios'

export type PresignData = {
  presigned_post: {
    url: string
    fields: { key: string } & Map<string, any>
  }
  preview_url: string
}

type PresignOptions = {
  file: File
  presignData: PresignData
  onProgress?: (percent: number) => void
}

const uploadPresignedS3Post = ({
  file,
  presignData,
  onProgress,
}: PresignOptions): Promise<{ file: File; xhr: XMLHttpRequest }> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    Object.entries(presignData.presigned_post.fields).forEach(
      ([key, value]) => {
        formData.append(key, value)
      },
    )
    formData.append('file', file)
    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener('progress', ({ loaded, total }) =>
      onProgress?.(loaded / total),
    )
    xhr.upload.addEventListener('load', () => {
      resolve({ file, xhr })
    })
    xhr.upload.addEventListener('error', reject)
    xhr.open('POST', presignData.presigned_post.url)
    xhr.send(formData)
  })
}

export const presignAndUploadFile = async (
  presignUrl: string,
  file: File,
): Promise<{ key: string; previewUrl: string }> => {
  const { data: presignData } = await axios.get<PresignData>(presignUrl, {
    params: { content_type: file.type },
  })

  await uploadPresignedS3Post({
    file,
    presignData,
  })

  return {
    key: presignData.presigned_post.fields.key,
    previewUrl: presignData.preview_url,
  }
}
