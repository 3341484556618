import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import axios from 'axios'

import { eventNewZoomParticipants } from '@/queries/urls'
import { DRScriptData, Event, ZoomParticipant } from '@/types'

export const useSaveScriptDataMutation = (
  event: Event,
  options?: {
    onSuccess?: () => void
    onError?: (err: unknown) => void
  },
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: DRScriptData) => {
      return axios.post<DRScriptData>(event.script_data_url, payload)
    },
    onMutate: async (payload: DRScriptData) => {
      await queryClient.cancelQueries(['script_data', event.id])

      const previousState = queryClient.getQueryData([
        'script_data',
        event.id,
      ]) as DRScriptData
      queryClient.setQueryData(['script_data', event.id], payload)

      return { previousState }
    },
    onError: (err, payload, context: any) => {
      queryClient.setQueryData(['script_data', event.id], context.previousState)
      options?.onError?.(err)
    },
    onSuccess: () => {
      options?.onSuccess?.()
    },
  })
}

export const useSubmitScriptDataMutation = (event: Event, options = {}) =>
  useMutation({
    mutationFn: (payload: DRScriptData) => {
      return axios.post<DRScriptData>(event.submit_script_data_url, payload)
    },
    ...options,
  })

export const useGetScriptDataQuery = (event?: Event, options = {}) => {
  const queryKey = ['script_data', event!.id]
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey,
    cacheTime: Infinity,
    queryFn: () =>
      axios.get<DRScriptData>(event!.script_data_url).then(res => res.data),
    ...options,
  })

  return { data, isLoading, isError, error, refetch }
}

export const useEventZoomParticipants = (
  event?: Event,
  options: UseQueryOptions<ZoomParticipant[]> = {},
) => {
  const queryKey = ['zoom_participants', event!.id]
  const url = eventNewZoomParticipants(event!.id)

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey,
    cacheTime: Infinity,
    queryFn: () => axios.get<ZoomParticipant[]>(url).then(res => res.data),
    placeholderData: [],
    ...options,
  })

  return { data, isLoading, isError, error, refetch }
}
