import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { useModals } from '@/hooks/useModals'
import { useReactivateReporterMutation } from '@/queries/reporters'
import { ReporterBase } from '@/types'

export const useReactivate = (
  reporter: ReporterBase,
  onReactivated?: () => void,
) => {
  const modals = useModals()

  const { mutateAsync, isLoading } = useReactivateReporterMutation(reporter, {
    onSuccess: () => {
      showNotification({
        message: 'Reporter has been reactivated',
      })

      onReactivated?.()
    },
  })

  return () =>
    modals.openConfirmModal({
      title: 'Reactivate reporter?',
      children: (
        <Text size="sm">
          Do you want to reactivate{' '}
          <Text component="span" weight={500}>
            {reporter.name}
          </Text>
          ? The reporter will be able to log in to the platform again.
        </Text>
      ),
      labels: { confirm: 'Reactivate reporter', cancel: 'Cancel' },
      confirmProps: { loading: isLoading },
      onConfirm: mutateAsync,
    })
}
