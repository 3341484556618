import React from 'react'

import { DefaultReviewForm } from '@/components/Event/NewScript/components/Review/DefaultReviewForm'
import { EventCancelledReviewForm } from '@/components/Event/NewScript/components/Review/EventCancelledReviewForm'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { ScriptStepProps } from '@/types'

export const ReviewStep: React.FC<ScriptStepProps> = ({
  event,
  refetchEvent,
}) => {
  const {
    state: { first_skipped_step },
  } = useScriptState(event)

  return first_skipped_step ? (
    <EventCancelledReviewForm event={event} refetchEvent={refetchEvent} />
  ) : (
    <DefaultReviewForm event={event} refetchEvent={refetchEvent} />
  )
}
