import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { CalendarRef } from '@/components/Calendar/Calendar'
import { CalendarProps } from '@/components/CalendarScreen'
import { DefaultHeader } from '@/components/Header/CalendarHeader/DefaultHeader'
import { MobileHeader } from '@/components/Header/CalendarHeader/MobileHeader'

type Props = {
  onBurgerMenuClick: () => void
  title: string | undefined
  calendarProps: CalendarProps
  calendarRef: React.RefObject<CalendarRef>
  isBurgerMenuOpen: boolean
  waitingEventsCount: number | undefined
}

export const CalendarHeader: React.FC<Props> = props => {
  const { breakpoints } = useMantineTheme()
  const isSmOrHigherResolution = useMediaQuery(`(min-width: ${breakpoints.sm})`)

  if (isSmOrHigherResolution) {
    return <DefaultHeader {...props} />
  }

  return <MobileHeader {...props} />
}
