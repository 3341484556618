import { useEffect } from 'react'

import { useFieldWarnings } from '@/components/Event/NewScript/hooks/form/useFieldWarnings'
import { ScriptIdentityDocument } from '@/types'

export const useIdentityDocumentWarnings = (
  identityDocument: ScriptIdentityDocument | null,
) => {
  const { setFieldWarning, warnings, getWarningsMessages, resetWarnings } =
    useFieldWarnings<ScriptIdentityDocument>()

  useEffect(() => {
    resetWarnings()

    if (!identityDocument) {
      return
    }

    if (!identityDocument?.number?.length) {
      setFieldWarning(
        'number',
        'Document number is optional for now but will be required later.',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityDocument])

  return {
    warnings,
    getWarningsMessages,
  }
}
