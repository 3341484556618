import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Group, Stack, Text, Textarea, Tooltip } from '@mantine/core'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'

import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'

export const TimestampsForm: React.FC = () => {
  const methods = useFormContext()

  return (
    <Stack spacing={4}>
      <Group spacing={4}>
        <Text size="sm" fw={500}>
          Timestamps
        </Text>
        <Tooltip
          label="You can add new timestamps or edit existing manually, just keep the same formatting. One timestamp per row and (Time{space}Event). Be careful not to delete already existing timestamps, there is no way to undo this action now."
          multiline
          width={400}
        >
          <QuestionMarkCircledIcon />
        </Tooltip>
      </Group>
      <Textarea
        {...methods.register('timestamps')}
        error={!!methods.formState.errors.timestamps?.message}
        minRows={4}
      />
      <FormErrors errors={methods.formState.errors.timestamps?.message} />
    </Stack>
  )
}
