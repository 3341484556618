import countBy from 'lodash/countBy'
import * as yup from 'yup'

import { ROLES_WITH_ID } from '@/components/Event/NewScript/constants'
import { identityDocumentSchema } from '@/components/Event/NewScript/schemas/identityDocumentSchema'
import { zoomParticipantSchema } from '@/components/Event/NewScript/schemas/zoomParticipantSchema'
import { AttendeeRole, TurnaroundHours } from '@/constants'

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const attendeeSchema = yup.object({
  attendee_id: yup.string().required(),
  name: yup
    .string()
    .trim()
    .required('Name is required')
    .max(255, 'Name must have at most 255 characters')
    .test(
      'capitalized',
      'Please enter correctly capitalized name',
      (value: string) => {
        const words = value.split(/\s+/)
        return words.every(word => /(^&$)|\p{Uppercase_Letter}/u.test(word))
      },
    ),
  transcript_order: yup
    .string()
    .oneOf(Object.values(TurnaroundHours).map(t => t.toString()))
    .nullable()
    .default(null),
  email: yup
    .string()
    .max(255, 'Email must have at most 255 characters')
    .test('email', 'Email is required', (value, context) => {
      if (!value) {
        return true
      }

      if (!EMAIL_REGEXP.test(value)) {
        return context.createError({ message: 'Please enter valid email' })
      }

      return true
    })
    .when('transcript_order', {
      is: (value: number | null) => value !== null,
      then: schema =>
        schema.required(
          'Email is required if attendee wants to order transcript',
        ),
      otherwise: schema => schema.nullable(),
    }),
  role: yup
    .mixed<AttendeeRole>()
    .oneOf(Object.values(AttendeeRole))
    .required('Role is required'),
  present: yup.boolean().nullable().notOneOf([null], 'Joined Zoom is required'),
  zoom_participants: yup
    .array()
    .of(zoomParticipantSchema)
    .when(
      ['present', '$isZoomParticipantsMappingRequired'],
      ([present, isZoomParticipantsMappingRequired], schema) => {
        if (present && isZoomParticipantsMappingRequired) {
          return schema
            .min(
              1,
              'Please select at least one Zoom user name for this attendee',
            )
            .required('Please select Zoom user name for this attendee')
        }
        return schema.default([])
      },
    ),
  identity_document: identityDocumentSchema
    .nullable()
    .when(
      ['$isDocumentNumberRequired', 'role'],
      ([isDocumentNumberRequired, role], schema) => {
        if (isDocumentNumberRequired && ROLES_WITH_ID.includes(role)) {
          return schema.required('Identity document is required')
        }

        return schema
      },
    ),
})

export const attendeesFieldSchema = yup
  .array(attendeeSchema)
  .required()
  .min(1, 'At least one attendee is required')
  .test('witness', (value, context) => {
    const witnessCount = value.filter(
      attendee => attendee.role === AttendeeRole.WITNESS,
    ).length
    if (witnessCount !== 1) {
      return context.createError({
        path: 'attendees.global.witness',
        type: 'custom',
        message: 'There must be exactly one witness',
      })
    }
    return true
  })
  .test('unique-attendee-email', (value, ctx) => {
    const emailCounts = countBy(value, 'email')

    const errors = value
      .map((attendee, idx) => {
        if (!attendee.email || emailCounts[attendee.email] <= 1) {
          return null
        }

        return ctx.createError({
          path: `attendees.${idx}.email`,
          message: `E-mail ${attendee.email} is duplicated, all attendees must have unique (or empty) e-mail`,
        })
      })
      .filter((v): v is NonNullable<yup.ValidationError> => !!v)

    return errors.length > 0 ? new yup.ValidationError(errors) : true
  })
