import React from 'react'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'

import { useFilesModal } from '@/components/Event/hooks/useFilesModal'
import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { DepositionNoticesModal } from '@/components/Event/Modals/DepositionNoticesModal'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { ActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionTitle'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { JoinCallActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/JoinCallActionButton'
import { OpenLiveTranscriptActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/OpenLiveTranscriptActionButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { UpcomingEventAlert } from '@/components/Event/NewScript/components/Preperation/UpcomingEventAlert'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { preparationStepSchema } from '@/components/Event/NewScript/schemas/preparationStepSchema'
import { ScriptStep, ZOOM_LIVESTREAM_BOT_NAME } from '@/constants'
import { ScriptStepProps } from '@/types'

export const PreparationStep: React.FC<ScriptStepProps> = ({ event }) => {
  const {
    canContinue,
    completeStepAndContinue,
    completeAction,
    isActionCompleted,
  } = useStepActions(ScriptStep.PREPARATION, event)

  const openDepositionNoticesModal = useFilesModal(
    event,
    'Deposition notice',
    DepositionNoticesModal,
  )

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(preparationStepSchema),
    defaultValues: {
      // In development we can't start a zoom meeting, we just mark it ongoing to bypass the validation
      isEventOngoing: import.meta.env.DEV ? true : event.is_ongoing,
    },
  })

  useDidUpdate(() => {
    methods.setValue('isEventOngoing', event.is_ongoing)
  }, [event.is_ongoing])

  const { hasTrainingAccess } = useNewScriptTraining(event)

  const completeJoinZoom = () => {
    methods.trigger('isEventOngoing').then(isValid => {
      if (isValid) {
        completeAction('join_zoom_using_meeting_id')
      }
    })
  }

  const onContinueButtonPressed = () => {
    methods.trigger().then(isValid => {
      if (isValid) {
        completeStepAndContinue(ScriptStep.ATTENDANCE)
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Form>
        {!event.is_ongoing && !hasTrainingAccess && (
          <UpcomingEventAlert event={event} />
        )}
        <ScriptTimeline title="1. Preparation">
          {/* join_zoom_using_meeting_id */}
          <TimelineAction
            actionKey="join_zoom_using_meeting_id"
            title="Join Zoom using your meeting link or meeting ID"
            onComplete={completeJoinZoom}
            completed={isActionCompleted('join_zoom_using_meeting_id')}
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <JoinCallActionButton event={event} />
                </ActionContainer>
                {!!methods.formState.errors.isEventOngoing?.message && (
                  <FormErrors
                    errors={methods.formState.errors.isEventOngoing.message}
                  />
                )}
                <ActionContainer>
                  <ActionTitle>Reminder</ActionTitle>
                  <ActionText>
                    Make sure your audio works with Zoom's built-in speaker and
                    microphone test.
                  </ActionText>
                </ActionContainer>
              </Stack>
            </ActionContainer>
          </TimelineAction>

          {/* Livestream Zoom Bot */}
          {event.is_zoombot_recording_enabled === true && (
            <TimelineAction
              actionKey="livestream_zoom_bot"
              title="Allow Livestream Zoom Bot Recording"
              onComplete={completeAction}
              completed={isActionCompleted('livestream_zoom_bot')}
            >
              <ActionContainer outer>
                <ActionContainer>
                  <ActionTitle>Reminder</ActionTitle>
                  <ActionText>
                    Check that "{ZOOM_LIVESTREAM_BOT_NAME}" is in the meeting,
                    and make sure you Allow Recording when you join the event.
                  </ActionText>
                </ActionContainer>
              </ActionContainer>
            </TimelineAction>
          )}

          {/* create_breakout_rooms */}
          <TimelineAction
            actionKey="create_breakout_rooms"
            title="Create at least two Zoom breakout rooms"
            onComplete={completeAction}
            completed={isActionCompleted('create_breakout_rooms')}
          >
            <ActionContainer outer>
              <ActionContainer>
                <ActionTitle>Reminder</ActionTitle>
                <ActionText>
                  Create at least two Zoom breakout rooms, one for defendant's
                  side and one for plaintiff's side.
                </ActionText>
              </ActionContainer>
            </ActionContainer>
          </TimelineAction>

          {/* share_only_computer_audio */}
          <TimelineAction
            actionKey="share_only_computer_audio"
            title="Share only computer audio in Zoom"
            onComplete={completeAction}
            completed={isActionCompleted('share_only_computer_audio')}
          >
            <ActionContainer outer>
              <ActionContainer>
                <ActionTitle>Reminder</ActionTitle>
                <ActionText>
                  Prepare for playback requests by sharing your computer audio
                  in Zoom.
                </ActionText>
              </ActionContainer>
            </ActionContainer>
          </TimelineAction>

          {/* open_live_transcript */}
          <TimelineAction
            actionKey="open_live_transcript"
            completed={isActionCompleted('open_live_transcript')}
            onComplete={completeAction}
            title="Open live transcript to test working playback"
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <OpenLiveTranscriptActionButton event={event} />
                </ActionContainer>
                <ActionContainer>
                  <ActionTitle>Reminder</ActionTitle>
                  <ActionText>
                    Make sure live transcript works by saying “One, two, three”
                    to see if it transcribes.
                  </ActionText>
                </ActionContainer>
              </Stack>
            </ActionContainer>
          </TimelineAction>

          {/* open_notice */}
          <TimelineAction
            actionKey="open_notice"
            completed={isActionCompleted('open_notice')}
            onComplete={completeAction}
            title="Open notice to get familiar with the details"
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <ActionButton onClick={openDepositionNoticesModal}>
                    Open notice
                  </ActionButton>
                </ActionContainer>
                <ActionContainer>
                  <ActionTitle>Reminder</ActionTitle>
                  <ActionText>
                    Review the notice to familiarize yourself with the expected
                    participants and case details of the event.
                  </ActionText>
                </ActionContainer>
              </Stack>
            </ActionContainer>
          </TimelineAction>
          <ContinueButton
            event={event}
            label="Continue to Attendance"
            canContinue={canContinue() || hasTrainingAccess}
            step={ScriptStep.PREPARATION}
            handleClick={onContinueButtonPressed}
          />
        </ScriptTimeline>
      </Form>
    </FormProvider>
  )
}
