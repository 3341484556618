import { useModals as useMantineModals } from '@mantine/modals'
import defaultsDeep from 'lodash/defaultsDeep'

export const useModals: typeof useMantineModals = () => {
  const modals = useMantineModals()

  return {
    ...modals,
    openConfirmModal: props =>
      modals.openConfirmModal(
        defaultsDeep(props, {
          centered: true,
          cancelProps: { variant: 'light', color: 'gray' },
        }),
      ),
  }
}
