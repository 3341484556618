import React from 'react'
import { MantineShadow, Paper, Title } from '@mantine/core'

export const Card: React.FC<{
  heading?: string
  highlighted?: boolean
  highlightColor?: string
  className?: string
  shadow?: MantineShadow
}> = ({
  heading,
  className,
  children,
  highlightColor,
  shadow,
  highlighted = false,
}) => {
  return (
    <Paper
      p="lg"
      withBorder
      className={className}
      shadow={shadow}
      sx={theme => ({
        borderColor: highlighted
          ? highlightColor || theme.colors.indigo[6]
          : undefined,
      })}
    >
      <div className="space-y-4">
        {heading && <Title order={6}>{heading}</Title>}

        <div>{children}</div>
      </div>
    </Paper>
  )
}
