import React, { useEffect } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { Button, Text } from '@mantine/core'
import * as Sentry from '@sentry/react'

import { Wall } from '@/components/Wall'
import { useAuth } from '@/hooks/useAuth'

export const AuthProvider: React.FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      audience={import.meta.env.VITE_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={appState => {
        // see `loginWithRedirect` below
        if (appState?.redirectUri) {
          window.location.href = appState?.redirectUri
        }
      }}
      cacheLocation="localstorage"
    >
      <InnerProvider>{children}</InnerProvider>
    </Auth0Provider>
  )
}

const InnerProvider: React.FC = ({ children }) => {
  const { user, isLoading, isAuthenticated, loginWithRedirect, error, logout } =
    useAuth()

  useEffect(() => {
    if (isLoading || isAuthenticated || error) {
      return
    }

    loginWithRedirect({
      appState: {
        redirectUri: window.location.href,
      },
    })
  }, [isLoading, isAuthenticated, error, loginWithRedirect])

  useEffect(() => {
    if (!user) {
      return
    }

    Sentry.setUser({ email: user.email })
  }, [user])

  if (error) {
    const displayedMessage = handleAuth0Error(error)
    return (
      <Wall heading={<Text align="center">{displayedMessage}</Text>}>
        <Button onClick={() => logout({ returnTo: window.location.origin })}>
          Try again
        </Button>
      </Wall>
    )
  }

  if (user && !user?.email_verified) {
    return (
      <Wall
        heading={
          <Text>Please verify your email address to be able to sign in.</Text>
        }
      >
        <Button onClick={() => logout({ returnTo: window.location.origin })}>
          Sign in
        </Button>
      </Wall>
    )
  }

  return <>{children}</>
}

const handleAuth0Error = (error: Error): string => {
  switch (error.message) {
    case 'user is blocked':
      return 'Your account has been deactivated.'
    default:
      Sentry.captureException(error)
      // remove all params like `code` and `state` so the refresh of the page
      // leads to home page and cause the user to login again
      // see https://github.com/auth0/auth0-react/issues/143#issuecomment-717860720
      window.history.replaceState({}, document.title, window.location.pathname)
      return `An unexpected error has occurred: ${error.message}. Please try to log in again by clicking on the button below.`
  }
}
