import { useQueries, UseQueryResult } from 'react-query'
import axios from 'axios'
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'
import format from 'date-fns/format'
import uniqBy from 'lodash/uniqBy'

import {
  getMonthViewVisibleInterval,
  getWeekViewInterval,
} from '@/components/Calendar/Calendar'
import { CalendarParams } from '@/queries/useCalendarParams'

export type UseCalendarEventsProps = {
  url: string
  calendarParams: CalendarParams
}

export const useCalendarEvents = <T>({
  url,
  calendarParams: { date, view },
}: UseCalendarEventsProps) => {
  // for month, we are doing 6 parallel queries, for other views we are loading
  // only one week
  const weeks =
    view === 'month'
      ? eachWeekOfInterval(getMonthViewVisibleInterval(date))
          // `getMonthViewVisibleInterval` uses next day at 00:00:00 as end date,
          // but it's treated as another week by `eachWeekOfInterval` function,
          // so we don't need the last week generated by `eachWeekOfInterval`
          .slice(0, -1)
          .map(startOfWeek => getWeekViewInterval(startOfWeek))
      : [getWeekViewInterval(date)]

  const results = useQueries(
    weeks.map(week => ({
      queryKey: [url, week],
      queryFn: () =>
        axios
          .get<T>(url, {
            params: {
              since: format(week.start, 'yyyy-MM-dd'),
              until: format(week.end, 'yyyy-MM-dd'),
            },
          })
          .then(r => r.data),
    })),
  ) as UseQueryResult<T>[]

  const events =
    // filter events which spans midnight and are therefore included in two
    // queries
    uniqBy(
      results
        // get data from all queries
        .map(({ data }) => data)
        // filter out unfinished queries data
        .filter((data): data is T => data !== undefined)
        // merge all queries data into one array
        .flat(),
      'id',
    )
  const isFetching = results.some(({ isFetching }) => isFetching)
  const refetch = async () =>
    await Promise.all(results.map(({ refetch }) => refetch()))

  return {
    events,
    isFetching,
    refetch,
  }
}
