import React from 'react'

import { AttendanceStep } from '@/components/Event/NewScript/components/Attendance/AttendanceStep'
import { ClosingStep } from '@/components/Event/NewScript/components/Closing/ClosingStep'
import { OpeningStep } from '@/components/Event/NewScript/components/Opening/OpeningStep'
import { PreparationStep } from '@/components/Event/NewScript/components/Preperation/PreparationStep'
import { QuestioningStep } from '@/components/Event/NewScript/components/Questioning/QuestioningStep'
import { ReviewStep } from '@/components/Event/NewScript/components/Review/ReviewStep'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useParticipantsMapping } from '@/components/Event/NewScript/hooks/useParticipantsMapping'
import { ScriptStep } from '@/constants'
import { ScriptStepProps } from '@/types'

const stepMap: { [K in ScriptStep]: React.FC<ScriptStepProps> } = {
  [ScriptStep.PREPARATION]: PreparationStep,
  [ScriptStep.ATTENDANCE]: AttendanceStep,
  [ScriptStep.OPENING]: OpeningStep,
  [ScriptStep.QUESTIONING]: QuestioningStep,
  [ScriptStep.CLOSING]: ClosingStep,
  [ScriptStep.REVIEW]: ReviewStep,
}

export const Script: React.FC<ScriptStepProps> = ({ event, refetchEvent }) => {
  const { state } = useScriptState(event)
  const isMappingEnabled =
    state.steps[ScriptStep.ATTENDANCE].actions['attendees'].completed &&
    // if the event is canceled/CNA/Rescheduled we can disable the mapping
    state.first_skipped_step === null

  useParticipantsMapping({
    event: event,
    enabled: isMappingEnabled,
  })

  const StepComponent = stepMap[state.current_step ?? ScriptStep.PREPARATION]
  return <StepComponent event={event} refetchEvent={refetchEvent} />
}
