import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormProvider, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { debounce, get } from 'lodash'

import { EmailLink } from '@/components/common/EmailLink'
import { AttendeesForm } from '@/components/Event/NewScript/components/Attendance/AttendeesForm'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { FormWarnings } from '@/components/Event/NewScript/components/common/forms/FormWarnings'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionTitle'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { useGlobalAttendeesWarnings } from '@/components/Event/NewScript/hooks/form/useGlobalAttendeesWarnings'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import { useEventZoomParticipants } from '@/components/Event/NewScript/queries/scriptData'
import { presenceStepSchema } from '@/components/Event/NewScript/schemas/presenceStepSchema'
import { AttendeeRole, ParrotContact, ScriptStep } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { ScriptStepProps } from '@/types'

export const AttendanceStep: React.FC<ScriptStepProps> = ({ event }) => {
  const { actions, state } = useScriptState(event)
  const { canHaveCna } = useStatePolicies(event)
  const [cancellationFlowStarted, setCancellationFlowStarted] = useState(false)
  const reporter = useAuth().user

  const {
    canContinue,
    completeStepAndContinue,
    completeAction,
    isActionCompleted,
  } = useStepActions(ScriptStep.ATTENDANCE, event)

  const { data: eventParticipants } = useEventZoomParticipants(event, {
    enabled: !isActionCompleted('attendees'),
    refetchInterval: 10_000,
  })

  // if the event is canceled/CNA/Rescheduled or no participants we can disable the mapping
  const isZoomParticipantsMappingRequired =
    eventParticipants &&
    eventParticipants.length > 0 &&
    !cancellationFlowStarted

  const methods = useForm({
    mode: 'onSubmit', // We already validate on every change using watch subscription, so no need to use "all" mode
    resolver: yupResolver(presenceStepSchema),
    shouldFocusError: false,
    context: {
      isZoomParticipantsMappingRequired,
      isDocumentNumberRequired: false,
    },
    defaultValues: {
      attendees: state.attendees,
      zoomParticipants: eventParticipants,
    },
  })

  const watchAttendees = useWatch({
    control: methods.control,
    name: 'attendees',
  })

  // TODO: improve performance, triggering validation on every change is not optimal
  //  added debounce to reduce amount of validations triggered when typing
  const validateForm = debounce(value => {
    methods.trigger().then(isValid => {
      if (isValid) {
        actions.updateAttendees(value.attendees)
      }
    })
  }, 300)

  useEffect(() => {
    const sub = methods.watch((value, { name }) => {
      if (!name?.includes('attendees')) {
        return
      }
      validateForm(value)
    })

    return () => sub.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  useEffect(() => {
    if (!eventParticipants) {
      return
    }

    const mappedParticipants = [
      ...new Set([...state.attendees.flatMap(a => a.zoom_participants)]),
    ]
    const newParticipants = eventParticipants.filter(
      p => !mappedParticipants.some(mp => mp?.id === p.id),
    )

    if (newParticipants.length > 0 && !isActionCompleted('attendees')) {
      methods.setValue('zoomParticipants', eventParticipants)
      showNotification({
        title: 'New Zoom participant detected',
        message: 'Please assign it to the correct attendee before continuing.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventParticipants])

  const witnessCnaText = useMemo(() => {
    return (
      state.attendees.find(a => a.role === AttendeeRole.WITNESS)?.name ??
      'witness'
    )
  }, [state.attendees])

  const onContinueButtonPressed = () => {
    methods.trigger().then(isValid => {
      if (isValid) {
        const containsInterpreter = state.attendees.some(
          a => a.role === AttendeeRole.INTERPRETER,
        )

        const containsGuardian = state.attendees.some(
          a => a.role === AttendeeRole.GUARDIAN,
        )
        const actionsToSkip = []
        if (!containsInterpreter) {
          actionsToSkip.push({
            step: ScriptStep.OPENING,
            key: 'identify_interpreter',
          })
        }

        if (!containsGuardian) {
          actionsToSkip.push({
            step: ScriptStep.OPENING,
            key: 'identify_guardian',
          })
        }

        if (actionsToSkip.length > 0) {
          actions.skipStepAction({
            stepAndActionKeys: actionsToSkip,
          })
        }

        completeStepAndContinue(ScriptStep.OPENING)
      }
    })
  }

  const validateAndCompleteAction = (key: string) => {
    methods.trigger().then(isValid => {
      if (isValid) {
        completeAction(key)
      }
    })
  }

  const { warnings } = useGlobalAttendeesWarnings(watchAttendees as any)

  const globalErrors = useMemo(() => {
    const errors = methods.formState.errors
    const result: Record<string, any> = {}

    const attendeeErrors = get(errors, 'attendees.global')
    const zoomParticipantErrors = get(errors, 'zoomParticipants.message')
    const combinedErrors = { ...attendeeErrors, ...zoomParticipantErrors }

    if (combinedErrors) {
      Object.keys(combinedErrors).forEach(key => {
        result[key] = combinedErrors[key]
      })
    }

    return result
  }, [methods.formState.errors])

  const isPresenceDisabled =
    state.steps[ScriptStep.ATTENDANCE].actions['attendees'].completed

  return (
    <FormProvider {...methods}>
      <Form>
        <Stack align="flex-start" spacing="xl">
          <ScriptTimeline title="2. Attendance">
            <TimelineAction
              actionKey="attendees"
              title="Collect information for each attendee"
              onComplete={validateAndCompleteAction}
              completed={isActionCompleted('attendees')}
            >
              {/* Introduction */}
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    Hi {'{participant name}'}, I’m {reporter?.name} and I’m the
                    Parrot digital reporter for today. Just to let you know,
                    everything in the main room is being recorded for
                    transcription purposes. However, breakout rooms are not
                    recorded, so I’d be happy to place you in a breakout room at
                    any time when we are off the record.
                  </ActionText>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      While doing presence, check the attendees’ Zoom
                      video/audio quality and try to resolve issues that arise.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>

              {/* Form */}
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    While we’re waiting, can you state your full name and share
                    your role? And to send you post-event documents we need your
                    email address. Can you put your email in the Zoom chat?
                  </ActionText>
                  <Stack>
                    <AttendeesForm
                      isPresenceDisabled={isPresenceDisabled}
                      eventAttendees={event.attendees}
                      zoomParticipants={eventParticipants ?? []}
                      timezone={event.team.timezone}
                      eventType={event.type}
                    />
                    <FormWarnings warnings={warnings} />
                    <FormErrors errors={globalErrors} />
                  </Stack>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      Pre-filled names, roles, and emails may contain mistakes.
                      Review and correct everything. Remove words that aren’t
                      actual names (esq, office, interpreter etc.). Add all
                      missing attendees and confirm their presence.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>

              {/* Waiting for the rest */}
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    Thank you, please let me know if I can assist with anything
                    while we wait for everyone else to arrive.
                  </ActionText>
                </Stack>
              </ActionContainer>

              <Text fw={600} color="indigo.7" mt="md">
                If requested, create Zoom breakout rooms and assign attendee to
                correct one
              </Text>

              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    As you decided for breakout room, there should be a button
                    asking you to join the room now. I’ll be here if you need
                    anything, you can leave the breakout room any time and
                    you’ll be placed back here.
                  </ActionText>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      Create at least two Zoom breakout rooms, one for
                      defendant’s side and one for plaintiff’s side.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>
            </TimelineAction>

            <TimelineAction
              title="Prepare Exhibits App"
              actionKey="exhibits_app"
              onComplete={completeAction}
              completed={isActionCompleted('exhibits_app')}
            >
              <Stack>
                <Text fw={600} color="indigo.7" mt="md">
                  Sign out of personal Zoom
                </Text>

                <ActionContainer outer>
                  <ActionContainer>
                    <ActionText>
                      If you are logged into your personal Zoom account, be sure
                      you first sign out of Zoom before opening the Exhibits
                      app. See In-Event Support for help.
                    </ActionText>
                  </ActionContainer>
                </ActionContainer>

                <Text fw={600} color="indigo.7" mt="md">
                  Invite counsel to the Exhibits app (depositions only)
                </Text>
                <ActionContainer outer>
                  <Stack>
                    <ActionContainer>
                      <ActionText>
                        In Zoom, invite counsel to the Exhibits app. If the
                        attendees are using their web browser to join, send them
                        the link to the app in the chat.
                      </ActionText>
                    </ActionContainer>
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        For EUOs or other attendees that may need to share their
                        screen, you can invite them to the app during the event,
                        when requested.
                      </ActionText>
                    </ActionContainer>
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        If you have technical difficulties with the app, and
                        attendees share their screen, instead of using the
                        application, please have them email exhibits to{' '}
                        <EmailLink
                          size="sm"
                          email={ParrotContact.EXHIBITS_EMAIL}
                        />
                        .
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                </ActionContainer>
                <Text fw={600} color="indigo.7" mt="md">
                  Optional Script Explanation (When attendees ask what the app
                  is)
                </Text>
                <ActionContainer outer>
                  <Stack>
                    <ActionContainer>
                      <ActionText>
                        <i>Note:</i> You are welcome to use your own words to
                        describe the app. Use the script below to help you.
                      </ActionText>
                    </ActionContainer>
                    <ActionContainer>
                      <ActionText mono>
                        I have just invited you to our Exhibits App where you
                        could upload your exhibits easily and introduce them
                        into the event as you choose. The exhibits will then be
                        uploaded automatically to your Parrot account.
                        <br /> <br />
                        If you prefer to simply share your screen for exhibits,
                        please remember to email {
                          ParrotContact.EXHIBITS_EMAIL
                        }{' '}
                        at the end of the event so the exhibits can be included
                        in the transcript.
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                </ActionContainer>
              </Stack>
            </TimelineAction>
            {canHaveCna && (
              <TimelineAction
                actionKey="cna"
                title="If witness has not appeared yet, ask about CNA wait time."
                onComplete={completeAction}
                completed={isActionCompleted('cna')}
              >
                <ActionContainer outer>
                  <Stack>
                    <ActionText mono>
                      How long would you like to wait for {witnessCnaText} to
                      appear?
                    </ActionText>
                  </Stack>
                </ActionContainer>
              </TimelineAction>
            )}
            <ContinueButton
              event={event}
              label="Continue to Opening"
              canContinue={canContinue()}
              canCancel={methods.formState.isValid}
              onCancelSwitchClick={(cancellationFlowStarted: boolean) => {
                setCancellationFlowStarted(cancellationFlowStarted)
                // trigger validation in next render cycle when schema context is already updated
                setTimeout(methods.trigger, 0)
              }}
              step={ScriptStep.ATTENDANCE}
              handleClick={onContinueButtonPressed}
            />
          </ScriptTimeline>
        </Stack>
      </Form>
    </FormProvider>
  )
}
