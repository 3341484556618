import React, { useState } from 'react'
import { Box, Button, Checkbox, Group, Stack, Text } from '@mantine/core'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ATTENDEE_ROLE_LABEL } from '@/constants'
import { ScriptAttendee, ZoomParticipant } from '@/types'

type ParticipantAttendeesMultiselectProps = {
  onAddAttendeeClick: () => void
  participant: ZoomParticipant
  attendees: ScriptAttendee[]
  zoomParticipants: ZoomParticipant[]
  selectedAttendeeIds?: string[]
  onSave: (data: ScriptAttendee[]) => void
}

export const ParticipantAttendeesMultiselect: React.FC<
  ParticipantAttendeesMultiselectProps
> = ({ onAddAttendeeClick, participant, attendees, onSave }) => {
  const [selectedAttendeeIds, setSelectedAttendeeIds] = useState<string[]>([])

  const onConfirmClick = () => {
    const updatedAttendees = attendees.map((attendee: ScriptAttendee) => {
      if (selectedAttendeeIds.includes(attendee.attendee_id)) {
        return {
          ...attendee,
          zoom_participants: [
            ...(attendee.zoom_participants ?? []),
            participant,
          ],
        }
      }

      return attendee
    })

    onSave(updatedAttendees)
    setSelectedAttendeeIds([])
  }
  return (
    <>
      <Text fw={600} mb="md" size="sm">
        Select all people joining under this "{participant.user_name}" Zoom
        account.
      </Text>
      <Stack spacing="xs" align="flex-start">
        <Checkbox.Group
          value={selectedAttendeeIds}
          onChange={values => setSelectedAttendeeIds(values)}
          w="100%"
        >
          <Stack spacing="xs">
            {attendees
              .filter(a => a.present)
              .map((attendee: ScriptAttendee) => (
                <Box
                  key={attendee.attendee_id}
                  bg="indigo.1"
                  p="md"
                  sx={theme => ({
                    borderRadius: theme.radius.sm,
                    width: '100%',
                  })}
                >
                  <Group>
                    <Checkbox
                      styles={{ input: { cursor: 'pointer' } }}
                      value={attendee.attendee_id}
                    />
                    <Stack spacing={0}>
                      <Text fw={600}>{attendee.name}</Text>
                      <Text>
                        {ATTENDEE_ROLE_LABEL[attendee.role] || 'Unknown role'}
                      </Text>
                    </Stack>
                  </Group>
                </Box>
              ))}
          </Stack>
        </Checkbox.Group>
        <SubtleButton p={0} onClick={onAddAttendeeClick}>
          Add new attendee
        </SubtleButton>
      </Stack>
      <Stack align="flex-end">
        <Button disabled={!selectedAttendeeIds.length} onClick={onConfirmClick}>
          Confirm
        </Button>
      </Stack>
    </>
  )
}
