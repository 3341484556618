import { useEffect } from 'react'

import {
  useFieldWarnings,
  Warnings,
} from '@/components/Event/NewScript/hooks/form/useFieldWarnings'
import { MAX_ATTENDEE_NAME_LENGTH } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { ScriptAttendee } from '@/types'
import { isAttorney, isDR, isIgnoredWord, isWitness } from '@/utils/validations'

// TODO: extract the checks to validation functions to share with the old form
export const useAttendeeWarnings = (
  currentAttendee: ScriptAttendee,
  allAttendees?: ScriptAttendee[],
): {
  warnings: Warnings<ScriptAttendee>
  getWarningsMessages: () => string[]
} => {
  // TODO: Consider removing this from the hook
  const { user } = useAuth()

  const { setFieldWarning, warnings, resetWarnings, getWarningsMessages } =
    useFieldWarnings<ScriptAttendee>()

  useEffect(() => {
    resetWarnings()
    if (currentAttendee.name.length > MAX_ATTENDEE_NAME_LENGTH) {
      setFieldWarning(
        'name',
        `This attendee name looks quite long: “${currentAttendee.name}”`,
      )
    }

    if (
      currentAttendee.name.split(' ').filter((word: string) => word.length > 0)
        .length === 1
    ) {
      setFieldWarning(
        'name',
        `This name looks suspicious as it contains only one word: “${currentAttendee.name}”`,
      )
    }

    // name shouldn't contain ignored word for speaker name shortening
    const ignoredWordsInName = currentAttendee.name
      .split(' ')
      .filter((word: string) => isIgnoredWord(word))

    if (ignoredWordsInName.length > 0) {
      setFieldWarning(
        'name',
        `This attendee name contains words: ${ignoredWordsInName
          .map((word: string) => `“${word}”`)
          .join(', ')} which don’t look like a valid name: “${
          currentAttendee.name
        }”`,
      )
    }

    if (isDR(currentAttendee.role) && currentAttendee.email !== user?.email) {
      setFieldWarning('email', `Please enter your email: “${user?.email}”`)
    }

    if (
      isAttorney(currentAttendee.role) &&
      currentAttendee.present &&
      !currentAttendee.email
    ) {
      setFieldWarning('email', 'Please provide email address for counsel')
    }

    if (allAttendees !== undefined) {
      const digitalReporters = allAttendees.filter(attendee =>
        isDR(attendee.role),
      )
      const witnesses = allAttendees.filter(attendee =>
        isWitness(attendee.role),
      )

      if (isDR(currentAttendee.role) && digitalReporters.length > 1) {
        setFieldWarning(
          'role',
          `There should always be only one Digital Reporter attendee: ${digitalReporters
            .map(({ name }) => `“${name}”`)
            .join(' vs. ')}`,
        )
      }

      if (currentAttendee.role === 'witness' && witnesses.length > 1) {
        setFieldWarning(
          'role',
          `There should always be only one Witness attendee: ${witnesses
            .map(({ name }) => `“${name}”`)
            .join(' vs. ')}`,
        )
      }

      const attendeesNames = allAttendees.map(attendee => attendee.name)
      const duplicateNames = attendeesNames.filter(
        (name, idx) => attendeesNames.indexOf(name) !== idx,
      )

      if (
        duplicateNames.length > 0 &&
        duplicateNames.includes(currentAttendee.name)
      ) {
        setFieldWarning(
          'name',
          `This attendee is duplicated: “${currentAttendee.name}”`,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttendee])

  return {
    warnings,
    getWarningsMessages,
  }
}
