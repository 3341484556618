import React from 'react'
import { Alert, List, LoadingOverlay, Stack, Text } from '@mantine/core'
import { EnvelopeClosedIcon } from '@radix-ui/react-icons'

import { AttendeesCard } from './components/AttendeesCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'
import {
  DownloadAudioRecordingButton,
  DownloadVideoRecordingButton,
  LinksCard,
  OpenTranscriptButton,
} from './components/LinksCard'

import {
  hasHearingDocket,
  HearingDocket,
} from '@/components/Event/Detail/components/HearingDocket'
import { FeedbackForm } from '@/components/Event/Feedback/FeedbackForm'
import { canUseScriptForEvent } from '@/components/Event/Script/utils/event'
import {
  useMediaResourcesQuery,
  useReporterFeedbackQuery,
} from '@/queries/events'
import { useReporterQuery } from '@/queries/reporters'
import { Event } from '@/types'

export const WaitingEventDetail: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  const { data: feedback, isLoading: isFeedbackLoading } =
    useReporterFeedbackQuery(event)
  const { data: reporter, isLoading: isReporterLoading } = useReporterQuery(
    event.booking?.reporter_id,
  )
  const { data: media, isLoading: isMediaLoading } =
    useMediaResourcesQuery(event)

  if (isFeedbackLoading || isReporterLoading || isMediaLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Layout
      left={
        <>
          <Alert title="Event requires your action" color="red">
            Please follow the steps to complete the event.
          </Alert>

          {hasHearingDocket(event) && <HearingDocket event={event} />}

          {event.is_waiting_for_reporter_feedback ? (
            <FeedbackForm
              event={event}
              reporter={reporter!}
              feedback={feedback}
              onSubmit={onChange}
            />
          ) : (
            <>
              {event.booking?.is_waiting_for_cna_signature && (
                <Card
                  heading="The CNA is waiting for your signature"
                  highlighted
                >
                  <Stack>
                    <Text size="sm">
                      Client requested Certificate of Non Appearance. We're
                      kindly reaching out to you to sign a Certificate of Non
                      Appearance for this event.
                    </Text>

                    <SignCNACertificateNotice />
                  </Stack>
                </Card>
              )}

              {event.booking?.is_waiting_for_cott_signature && (
                <Card
                  heading="The transcript is waiting for your signature"
                  highlighted
                >
                  <Stack spacing="lg">
                    <Text size="sm">
                      We're kindly reaching out to you to provide a Certificate
                      of True Testimony for the transcript of your event.
                    </Text>
                    <Text size="sm">
                      To help ensure excellent transcription quality, our
                      professional transcriptionist already transcribed the
                      event. The transcript was then scoped by a legal scopist
                      to ensure the highest quality possible.
                    </Text>
                    <Text size="sm">
                      Thanks to our robust transcription process we don't expect
                      any significant errors to be found in the transcript.
                    </Text>
                    <Text size="sm">
                      We suggest you to do a quick scan of the transcript before
                      certifying it. Here's a list of useful tips of what to
                      focus on:
                    </Text>
                    <List
                      type="unordered"
                      withPadding
                      style={{ listStyle: 'disc' }}
                      className="my-2"
                      size="sm"
                    >
                      <List.Item>
                        Check speaker names for correct spelling and consistency
                        throughout the transcript
                      </List.Item>
                      <List.Item>
                        Check place names, such as roads, schools, or towns
                      </List.Item>
                      <List.Item>
                        Check technical terms, such as legal or medical jargon
                      </List.Item>
                      <List.Item>
                        If anything has been marked as &lt;unintelligible&gt;,
                        please listen to it and let us know if you catch what
                        was said
                      </List.Item>
                      <List.Item>
                        Go through your case introduction and welcome to verify
                        information such as case number, names, the date, etc.
                      </List.Item>
                    </List>
                    <Text size="sm">
                      If the client still finds an error in the transcript,
                      there is an official process whereby we can fix the
                      reported issues using an errata sheet, without any
                      consequences on your end.
                    </Text>

                    <SignCOTTCertificateNotice />
                  </Stack>
                </Card>
              )}

              <Card heading="Useful links">
                <Stack>
                  <Text size="sm">
                    If you need to access transcript or recording, you can
                    follow these links.
                  </Text>

                  <OpenTranscriptButton event={event} media={media ?? []} />

                  <DownloadAudioRecordingButton media={media ?? []} />

                  <DownloadVideoRecordingButton media={media ?? []} />
                </Stack>
              </Card>
            </>
          )}
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <LinksCard
            event={event}
            options={{
              join_button: false,
              open_script: canUseScriptForEvent(event),
              open_live_transcript: true,
            }}
          />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}

export const SignCNACertificateNotice: React.FC = () => (
  <Alert icon={<EnvelopeClosedIcon />}>
    Please check your email inbox. You will receive an email from{' '}
    <Text size="sm" component="span" weight={500}>
      Acrobat Sign
    </Text>{' '}
    with instructions on how sign the CNA certificate for this event.
  </Alert>
)

export const SignCOTTCertificateNotice: React.FC = () => (
  <Alert icon={<EnvelopeClosedIcon />}>
    Please check your email inbox. You will receive an email from{' '}
    <Text size="sm" component="span" weight={500}>
      Acrobat Sign
    </Text>{' '}
    with instructions on how sign and certify the transcript for this event.
  </Alert>
)
