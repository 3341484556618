import orderBy from 'lodash/orderBy'

import { AttendeeRole, EventType } from '@/constants'
import {
  AdminEvent,
  Attendee,
  Event,
  ReporterEvent,
  ReporterRole,
} from '@/types'

// duplicated in parrot backend parrot/external/models/reporter.py
const STATES_WITHOUT_COMMISSION_NUMBER = ['MA', 'MI']

export const isReporterEvent = (event: Event): event is ReporterEvent =>
  'reporter_status' in event

export const isAdminEvent = (event: Event): event is AdminEvent =>
  'reporter' in event

export const isCommissionNumberRequired = (
  reporter_state: string | undefined,
): boolean =>
  !reporter_state || !STATES_WITHOUT_COMMISSION_NUMBER.includes(reporter_state)

export const getUnifiedEventStatus = (
  event: ReporterEvent | Event,
): ReporterEvent['reporter_status'] | Event['general_status'] => {
  if (isReporterEvent(event)) {
    return event.reporter_status
  } else {
    return event.general_status
  }
}

export const getAttorneys = (event: Event): Attendee[] =>
  orderBy(
    event.attendees.filter(attendee => attendee.is_attorney),
    ['is_attorney_who_is_our_customer'],
    ['desc'],
  )

export const getAttendeeRolesForEvent = (
  event: Pick<Event, 'is_court_reporter_event' | 'type'>,
): AttendeeRole[] => {
  const getRolesByType = (eventType: EventType): AttendeeRole[] => {
    if (eventType === EventType.DEPOSITION) {
      return [
        AttendeeRole.DEFENDANT_ATTORNEY,
        AttendeeRole.PLAINTIFF_ATTORNEY,
        AttendeeRole.WITNESS_ATTORNEY,
        AttendeeRole.WITNESS,
        AttendeeRole.INTERPRETER,
        AttendeeRole.GUARDIAN,
        AttendeeRole.OTHER,
      ]
    } else if (eventType === EventType.EXAMINATION_UNDER_OATH) {
      return [
        AttendeeRole.INTERPRETER,
        AttendeeRole.INVESTIGATOR,
        AttendeeRole.OPPOSING_COUNSEL,
        AttendeeRole.STAFF_COUNSEL,
        AttendeeRole.WITNESS_ATTORNEY,
        AttendeeRole.WITNESS,
        AttendeeRole.OTHER,
      ]
    } else if (eventType === EventType.HEARING) {
      return [
        AttendeeRole.DEFENDANT_ATTORNEY,
        AttendeeRole.PLAINTIFF_ATTORNEY,
        AttendeeRole.CLERK_OF_COURT,
        AttendeeRole.INTERPRETER,
        AttendeeRole.JUDGE,
        AttendeeRole.WITNESS_ATTORNEY,
        AttendeeRole.WITNESS,
        AttendeeRole.OTHER,
      ]
    } else if (eventType === EventType.MEET_AND_CONFER) {
      return [AttendeeRole.DEFENDANT_ATTORNEY, AttendeeRole.PLAINTIFF_ATTORNEY]
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _exhaustiveCheck: never = eventType

    return []
  }

  const reporterRole = event.is_court_reporter_event
    ? AttendeeRole.COURT_REPORTER
    : AttendeeRole.DIGITAL_REPORTER
  return [...getRolesByType(event.type), reporterRole]
}

export const EXPERIENCE_LEVELS = [
  { label: 'Level 0 - Onboarding', value: '0' },
  { label: 'Level 1 - EUO', value: '1' },
  {
    label: 'Level 2 - Multiple EUOs',
    value: '2',
  },
  { label: 'Level 3 - Depositions', value: '3' },
  { label: 'Level 4 - First-time clients', value: '4' },
  { label: 'Level 5 - Priority investigator', value: '5' },
  {
    label: 'Level 6 - Priority enterprise or Hearings or Depositions in Aid',
    value: '6',
  },
]

export const REPORTER_ROLES: { label: string; value: ReporterRole }[] = [
  { label: 'Court reporter', value: 'court_reporter' },
  { label: 'Digital reporter', value: 'digital_reporter' },
]
