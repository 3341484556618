import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import axios from 'axios'

import * as urls from './urls'

import { AdminEvent, Event, Reporter } from '@/types'

export const useAdminEventQuery = (
  id: string,
  options?: Omit<
    UseQueryOptions<AdminEvent, unknown, AdminEvent>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<AdminEvent>(
    ['admin_event', id],
    () => axios.get(urls.adminEvent(id)).then(r => r.data),
    { ...options },
  )

export const useAvailableReportersQuery = (event: Event) =>
  useQuery<{ reporter: Reporter; available: boolean }[]>(
    ['available_reporters', event.id],
    () => axios.get(event.available_reporters_url).then(r => r.data),
  )
