import React from 'react'
import { useHistory } from 'react-router'

import { FilterControls, useInitialFilters } from './FilterControls'
import { useFilteredCalendarEvents } from './useFilteredCalendarEvents'

import { CalendarEvent } from '@/components/Calendar/CalendarEvent'
import { CalendarScreen } from '@/components/CalendarScreen'
import { useReporter } from '@/providers/UserProvider'
import { useWaitingReporterEventsQuery } from '@/queries/reporterEvents'
import {
  CalendarParams,
  toCalendarParamsPath,
  useCalendarParams,
} from '@/queries/useCalendarParams'
import { NotFound } from '@/routes/NotFound'
import { ReporterEvent } from '@/types'

export const Dashboard = () => {
  const calendarParams = useCalendarParams()

  if (!calendarParams) {
    return <NotFound />
  }

  return <DashboardScreen calendarParams={calendarParams} />
}

const DashboardScreen: React.FC<{
  calendarParams: CalendarParams
}> = ({ calendarParams }) => {
  const history = useHistory()
  const reporter = useReporter()
  const initialFilters = useInitialFilters({
    reporter_status_in: ['available', 'booked', 'past', 'waiting'],
  })
  const {
    events,
    filters,
    setFilters,
    isFetching: isFetchingCalendarEvents,
    refetch,
  } = useFilteredCalendarEvents({
    reporterId: reporter.id,
    calendarParams,
    initialFilters,
  })
  const { data: waitingEvents } = useWaitingReporterEventsQuery(reporter.id)

  return (
    <CalendarScreen
      events={events ?? []}
      initialView={calendarParams.view}
      initialDate={calendarParams.date}
      waitingEventsCount={waitingEvents?.length}
      renderEvent={(event, { view }) => (
        <CalendarEvent
          event={event as ReporterEvent}
          view={view}
          onChange={refetch}
        />
      )}
      onStateChange={({ date, view }) => {
        if (view === 'listCustom') {
          return
        }

        history.push(`/calendar/${toCalendarParamsPath(view, date)}`)
      }}
      isLoading={isFetchingCalendarEvents}
      hotkeysEnabled={true}
      sidebar={<FilterControls values={filters} onChange={setFilters} />}
    />
  )
}
