import { useCallback, useRef } from 'react'
import isEqual from 'lodash/isEqual'

import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { ReporterFeedback } from '@/types'

const getChangedKeys = (
  prev: ReporterFeedback,
  current: ReporterFeedback,
): (keyof ReporterFeedback)[] => {
  const keys = Object.keys(current) as (keyof ReporterFeedback)[]
  return keys.filter(k => !isEqual(prev[k], current[k]))
}

export const useFeedbackFormTracking = (event_id: string) => {
  const prevValuesRef = useRef<ReporterFeedback | undefined>()
  const trackUserAction = useTrackUserAction()

  const trackOnSave = useCallback(
    (values: ReporterFeedback) => {
      if (prevValuesRef.current === undefined) {
        prevValuesRef.current = values
        return
      }

      getChangedKeys(prevValuesRef.current, values).forEach(key => {
        trackUserAction('Event form changed', { event_id, field: key })
      })

      prevValuesRef.current = values
    },
    [trackUserAction, event_id],
  )

  return { trackOnSave }
}
