import React from 'react'
import { Button, Stack, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { Card } from './Card'

import { useModals } from '@/hooks/useModals'
import { useEndEventMutation } from '@/queries/events'
import { Event } from '@/types'

export const EndCard: React.FC<{
  event: Event
  onEnd: () => void
}> = ({ event, onEnd }) => {
  const end = useEnd(event, onEnd)

  const isHearing = event.type === 'hearing'

  return (
    <Card heading="Danger zone">
      <Stack align="flex-start">
        <Text size="sm">
          Don't forget to end {isHearing ? 'recording' : 'meeting'} once it's
          done.
        </Text>

        <Button color="red" variant="light" onClick={end}>
          End {isHearing ? 'recording' : 'meeting'}
        </Button>
      </Stack>
    </Card>
  )
}

const useEnd = (event: Event, onEnd: () => void) => {
  const modals = useModals()

  const isHearing = event.type === 'hearing'

  const { mutateAsync, isLoading } = useEndEventMutation(
    event,
    {
      onSuccess: () => {
        showNotification({
          message: `${isHearing ? 'Recording' : 'Event'} has been ended`,
        })

        onEnd()
      },
      onErrorMessage: message => {
        showNotification({ color: 'red', message })

        onEnd()
      },
    },
    { end_related_events: isHearing },
  )

  return () =>
    modals.openConfirmModal({
      title: isHearing
        ? 'Do you want to end the recording?'
        : 'End meeting for all?',
      children: (
        <>
          {isHearing && (
            <Text size="sm">
              Zoom bot will disconnect from the meeting and the recording will
              be finished. Make sure all hearings have ended before ending the
              recording, you cannot start recording again after this.
            </Text>
          )}
          {!isHearing && (
            <Text size="sm">
              All attendees will be disconnected from the call, and it will not
              be possible to continue with the event.
            </Text>
          )}
        </>
      ),
      labels: {
        confirm: isHearing ? 'End recording' : 'End meeting for all',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'red', loading: isLoading },
      onConfirm: mutateAsync,
    })
}
