import {
  CountryCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'

// country acts as a default when the number does not contain an international
// code, i.e. if the number contains +421, it gets parsed as Slovak number and
// is not forced to be a US number - this is true for all the methods in this file
export const isValidPhoneNumber = (
  potentialNumber: string,
  country: CountryCode = 'US',
): boolean =>
  isPossiblePhoneNumber(potentialNumber, country) &&
  parsePhoneNumber(potentialNumber, country)?.isValid()

export const formatPhoneNumberToInternationalFormat = (
  phoneNumber: string,
  country: CountryCode = 'US',
): string => parsePhoneNumber(phoneNumber, country).formatInternational()
