import * as yup from 'yup'

import { attendeesFieldSchema } from '@/components/Event/NewScript/schemas/attendeeSchema'
import { zoomParticipantSchema } from '@/components/Event/NewScript/schemas/zoomParticipantSchema'
import { ScriptAttendee, ZoomParticipant } from '@/types'

export const presenceStepSchema = yup.object().shape({
  attendees: attendeesFieldSchema,
  zoomParticipants: yup
    .array(zoomParticipantSchema)
    .test('zoom-participants', (value, context) => {
      if (
        context.options?.context?.isZoomParticipantsMappingRequired === false
      ) {
        return true
      }

      const mappedArray: { mapped: boolean; name: string }[] | undefined =
        value?.map((participant: ZoomParticipant) => {
          let isParticipantMapped = false

          context.parent.attendees.forEach((attendee: ScriptAttendee) => {
            if (!isParticipantMapped) {
              isParticipantMapped = !!attendee.zoom_participants?.find(
                (mappedParticipant: ZoomParticipant) =>
                  mappedParticipant.id === participant.id,
              )
            }
          })

          return {
            mapped: isParticipantMapped,
            name: participant.user_name,
          }
        })

      // errors
      const errors = mappedArray
        ?.filter(e => !e.mapped)
        .reduce((acc, e, index) => {
          return {
            ...acc,
            [`zoom_participants.${index}`]: {
              type: 'custom',
              message: `Zoom username ${e.name} was not assigned to any attendee`,
            },
          }
        }, {})

      if (Object.keys(errors ?? {}).length > 0) {
        return context.createError({
          message: errors,
        })
      }

      return true
    }),
})
