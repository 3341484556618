import { useHistory, useLocation } from 'react-router-dom'

export const useQueryParams = () => {
  const { pathname, search } = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(search)

  const navigate = () => {
    history.push({ pathname, search: `?${queryParams.toString()}` })
  }

  return {
    has: (param: string) => queryParams.has(param),
    get: (param: string) => queryParams.get(param),
    getAll: (param: string) => queryParams.getAll(param),
    set: (param: string, value: string) => {
      queryParams.set(param, value)
      navigate()
    },
    append: (param: string, value: string) => {
      queryParams.append(param, value)
      navigate()
    },
    delete: (param: string) => {
      queryParams.delete(param)
      navigate()
    },
  }
}
