import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Group, LoadingOverlay, Stack, Text } from '@mantine/core'

import { Card } from './Card'

import { EventRow } from '@/components/Event/EventRow'
import { useAuth } from '@/hooks/useAuth'
import { useRelatedEvents } from '@/queries/relatedEvents'
import { Event } from '@/types'
import { getAttorneys } from '@/utils/parrot'

export const HearingDocket: React.FC<{
  event: Event
}> = ({ event }) => {
  const { user } = useAuth()
  const { data: relatedEvents, isLoading } = useRelatedEvents(event)

  if (isLoading || relatedEvents === undefined) {
    return (
      <Card heading="Hearing Docket">
        <LoadingOverlay visible />
      </Card>
    )
  }

  return (
    <Card heading="Hearing Docket">
      <Stack spacing="xs" className="w-full">
        <EventRow
          active
          event={event}
          subtitle={<HearingEventRowSubtitle event={event} />}
          right={
            <Group>
              {event.general_status === 'past' && (
                <Text color="gray" size="sm">
                  Finished
                </Text>
              )}
              <Text color="indigo" size="sm" weight={500}>
                Selected
              </Text>
            </Group>
          }
        />
        {relatedEvents.map(relatedEvent => (
          <EventRow
            key={relatedEvent.id}
            event={relatedEvent}
            subtitle={<HearingEventRowSubtitle event={relatedEvent} />}
            right={
              <Group>
                {relatedEvent.general_status === 'past' && (
                  <Text color="gray" size="sm">
                    Finished
                  </Text>
                )}
                <Button
                  compact
                  variant="outline"
                  color="gray"
                  component={Link}
                  to={
                    user?.role === 'admin'
                      ? `/admin/event/${relatedEvent.id}`
                      : `/event/${relatedEvent.id}`
                  }
                  replace
                >
                  Switch to hearing
                </Button>
              </Group>
            }
          />
        ))}
      </Stack>
    </Card>
  )
}

const HearingEventRowSubtitle: React.FC<{
  event: Event
}> = ({ event }) => {
  const attorneys = getAttorneys(event)

  if (attorneys.length === 0) {
    return null
  }

  return (
    <>
      {`Attorney ${getAttorneys(event)
        .map(({ name }) => name)
        .join(', ')}`}
    </>
  )
}

export const hasHearingDocket = (event: Event) =>
  event.has_related_events && event.type === 'hearing'
