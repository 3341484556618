import React from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { Button, Flex, Group, Stack } from '@mantine/core'

import { AttendeeForm } from '@/components/Event/NewScript/components/common/forms/AttendeeForm'
import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { EventType } from '@/constants'
import { ScriptAttendee, ZoomParticipant } from '@/types'

type Props = {
  attendee: ScriptAttendee
  onCancelClick: () => void
  onSubmitClick: (attendee: ScriptAttendee) => void
  zoomParticipants: ZoomParticipant[]
  timezone: string
  eventType: EventType
  isPresenceDisabled: boolean
  namePrefix: string
}

export const AttendeeCardEdit: React.FC<Props> = ({
  attendee,
  onSubmitClick,
  onCancelClick,
  zoomParticipants,
  timezone,
  eventType,
  isPresenceDisabled,
  namePrefix,
}) => {
  const methods = useFormContext()

  const onSubmitClickHandler = async () => {
    const isValid = await methods.trigger(namePrefix)
    if (isValid) {
      onSubmitClick({
        ...attendee,
        ...methods.getValues(namePrefix),
      })
    }
  }

  return (
    <Stack align="stretch">
      <FormProvider {...methods}>
        <AttendeeForm
          isPresenceDisabled={isPresenceDisabled}
          timezone={timezone}
          zoomParticipants={zoomParticipants}
          eventType={eventType}
          namePrefix={namePrefix}
        />
        <Flex align="flex-start">
          <Group>
            <Button
              variant="filled"
              color="primary.2"
              onClick={onSubmitClickHandler}
            >
              Save changes
            </Button>
            <SubtleButton onClick={onCancelClick}>Cancel</SubtleButton>
          </Group>
        </Flex>
      </FormProvider>
    </Stack>
  )
}
