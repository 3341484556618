import React, { useEffect, useState } from 'react'
import { Box, Stack, Text } from '@mantine/core'

import {
  ReporterStatusFilter,
  SearchFilter,
} from '@/components/Calendar/FilterControls'
import { useQueryParams } from '@/hooks/useQueryParams'
import { ReporterEvent } from '@/types'

export type Filters = {
  reporter_status_in: ReporterEvent['reporter_status'][]
  search?: string
}

// Filters in query params have priority over default initial params
export const useInitialFilters = (initialFilters: Filters) => {
  const queryParams = useQueryParams()
  const queryFilters: Partial<Filters> = {}
  if (queryParams.getAll('reporter_status_in').length > 0) {
    queryFilters['reporter_status_in'] = queryParams.getAll(
      'reporter_status_in',
    ) as Filters['reporter_status_in']
  }
  if (queryParams.get('search')) {
    queryFilters['search'] = queryParams.get('search') || undefined
  }

  return {
    ...initialFilters,
    ...queryFilters,
  }
}

export const FilterControls: React.FC<{
  values: Filters
  onChange: (filters: Filters) => void
}> = ({ values: initialFilters, onChange }) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)

  useEffect(() => {
    onChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <Box ml="xl" mr="xl" mt="xs">
      <Stack>
        <Text weight="bold">Visible events</Text>
        <Stack spacing="sm">
          <ReporterStatusFilter
            values={filters.reporter_status_in}
            onChange={statuses =>
              setFilters(filters => ({
                ...filters,
                reporter_status_in: statuses,
              }))
            }
          />
        </Stack>
        <Text weight="bold" mt="sm">
          Filters
        </Text>
        <SearchFilter
          value={filters.search ?? ''}
          onChange={search => setFilters(filters => ({ ...filters, search }))}
        />
      </Stack>
    </Box>
  )
}
