import { Stack, Text } from '@mantine/core'

import { useModals } from '@/hooks/useModals'
import { useRelatedEvents } from '@/queries/relatedEvents'
import { Event } from '@/types'

export const useConfirmSubmitModal = (event: Event) => {
  const modals = useModals()

  const isHearing = event.type === 'hearing'
  const isOngoing = event.is_ongoing
  const hasRelatedEvents = event.has_related_events

  const { refetch: refetchRelatedEvents } = useRelatedEvents(event, 'booked', {
    enabled: false,
  })

  const showConfirmModal = (relatedEvents: Event[] = []) => {
    const hasOngoingRelatedEvents =
      relatedEvents.filter(e => e.is_ongoing).length > 0

    const willEndMeeting = !isHearing && isOngoing
    const willEndRecording = isHearing && isOngoing && !hasOngoingRelatedEvents

    return new Promise<void>((resolve, reject) => {
      if (willEndMeeting) {
        modals.openConfirmModal({
          title: 'Submit form and End meeting for all?',
          children: (
            <Stack mb="xl">
              <Text size="sm">
                It will not be possible to make changes after submitting form.
              </Text>

              <Text size="sm">
                All attendees will be disconnected from the call, and it will
                not be possible to continue with the event.
              </Text>
            </Stack>
          ),
          labels: {
            confirm: 'Submit form and End meeting for all',
            cancel: 'Cancel',
          },
          confirmProps: {
            color: 'red',
          },
          onConfirm: resolve,
          onCancel: reject,
        })
        return
      }

      if (willEndRecording) {
        modals.openConfirmModal({
          title: 'Submit form and End recording?',
          children: (
            <Stack mb="xl">
              <Text size="sm">
                It will not be possible to make changes after submitting form.
              </Text>

              <Text size="sm">
                Zoom bot will disconnect from the meeting and the recording will
                be finished. Make sure all hearings have ended before ending the
                recording, you cannot start recording again after this.
              </Text>
            </Stack>
          ),
          labels: {
            confirm: 'Submit form and End recording',
            cancel: 'Cancel',
          },
          confirmProps: {
            color: 'red',
          },
          onConfirm: resolve,
          onCancel: reject,
        })
        return
      }

      modals.openConfirmModal({
        title: 'Submit form?',
        children: (
          <Stack mb="xl">
            <Text size="sm">
              It will not be possible to make changes after submitting form.
            </Text>
          </Stack>
        ),
        labels: {
          confirm: 'Submit',
          cancel: 'Cancel',
        },
        onConfirm: resolve,
        onCancel: reject,
      })
    })
  }

  // related events are in confirmation dialog relevant only for hearings, so we
  // don't have to load them if event is not hearing or does not have related events
  if (!isHearing || !hasRelatedEvents) {
    return () => showConfirmModal()
  }

  return () => refetchRelatedEvents().then(r => showConfirmModal(r.data))
}
