import { useMemo } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  GlobalError,
} from 'react-hook-form'
import { Alert, List, Text, Title } from '@mantine/core'
import isEmpty from 'lodash/isEmpty'

export type FormErrorsProps<T extends FieldValues> = {
  errors:
    | FieldErrorsImpl<T>
    | (Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & {
        root?: Record<string, GlobalError> & GlobalError
      })
    | string
    | undefined
    | any // TODO: remove any once figure out how to handle this type
  title?: string
}

export const FormErrors = <T extends FieldValues>({
  errors,
  title,
}: FormErrorsProps<T>) => {
  const alertTitle = useMemo(
    () => title || 'We have detected an error in the data you provided:',
    [title],
  )

  if (!errors) {
    return null
  }

  if (isEmpty(errors)) {
    return null
  }

  return (
    <Alert
      title={
        <Title order={6} color="gray.9">
          {alertTitle}
        </Title>
      }
      color="red.3"
      variant="filled"
    >
      <List withPadding style={{ listStyle: 'disc' }}>
        {
          // Simple validation if errors is instance of FieldErrorsImpl
          typeof errors === 'object' ? (
            Object.keys(errors).map(key =>
              typeof errors[key] === 'object' && !('message' in errors[key]) ? (
                Object.keys(errors[key]).map(nestedKey => (
                  <List.Item key={nestedKey}>
                    <Text color="gray.9">
                      {errors[key][nestedKey]?.message}
                    </Text>
                  </List.Item>
                ))
              ) : (
                <List.Item key={key}>
                  <Text color="gray.9">{errors[key]?.message}</Text>
                </List.Item>
              ),
            )
          ) : (
            <List.Item>
              <Text color="gray.9">{errors}</Text>
            </List.Item>
          )
        }
      </List>
    </Alert>
  )
}
