import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Group, Select, Stack } from '@mantine/core'

import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { COUNTIES, STATES } from '@/utils/states'

export const LocationForm: React.FC = () => {
  const methods = useFormContext()
  const state = useWatch({ control: methods.control, name: 'state' })

  return (
    <Stack>
      <Group grow>
        <Controller
          control={methods.control}
          name="state"
          render={({ field }) => (
            <Select
              {...field}
              error={!!methods.formState.errors.state?.message}
              label="State"
              placeholder="Select option"
              onChange={value => {
                methods.setValue('county', '')
                value && field.onChange(value)
              }}
              searchable
              data={Object.keys(STATES).map(key => ({
                value: key,
                label: STATES[key],
              }))}
            />
          )}
        />
        <Controller
          control={methods.control}
          name="county"
          render={({ field }) => (
            <Select
              {...field}
              onChange={value => {
                value && field.onChange(value)
              }}
              placeholder="Select option"
              error={!!methods.formState.errors.county?.message}
              label="County"
              searchable
              data={state ? COUNTIES[state] : []}
            />
          )}
        />
      </Group>
      {(methods.formState.errors.state !== undefined ||
        methods.formState.errors.county !== undefined) && (
        <FormErrors
          errors={{
            ...(methods.formState.errors.state !== undefined && {
              state: methods.formState.errors.state,
            }),
            ...(methods.formState.errors.county !== undefined && {
              county: methods.formState.errors.county,
            }),
          }}
        />
      )}
    </Stack>
  )
}
