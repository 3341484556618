import { ReactNode } from 'react'
import { createStyles } from '@mantine/core'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

import { Warnings } from '@/components/Event/NewScript/hooks/form/useFieldWarnings'

const useWarningStyle = createStyles(theme => ({
  input: {
    color: theme.black,
    borderColor: theme.colors.orange[6],
  },
  icon: {
    color: theme.colors.orange[6],
  },
}))

type HookResult<T> = {
  getInputWarningProps: (field: keyof T) => {
    classNames: Record<string, string | undefined>
    rightSection?: ReactNode
  }
}

export const useInputWarnings = <T,>(warnings: Warnings<T>): HookResult<T> => {
  const { classes: warningStyleClasses } = useWarningStyle()
  return {
    getInputWarningProps: (field: keyof T) => ({
      classNames: {
        input: warnings?.[field] ? warningStyleClasses.input : undefined,
      },
      rightSection: warnings?.[field] ? (
        <ExclamationTriangleIcon className={warningStyleClasses.icon} />
      ) : undefined,
    }),
  }
}
