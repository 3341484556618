import React from 'react'
import { Flex, Grid, Stack, Text } from '@mantine/core'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ATTENDEE_ROLE_LABEL } from '@/constants'
import { ScriptAttendee } from '@/types'

type AttendeeFieldViewProps = {
  attendee: ScriptAttendee
  fieldValue: string
  onEditClick: () => void
}
export const AttendeeFieldView: React.FC<AttendeeFieldViewProps> = ({
  attendee,
  fieldValue,
  onEditClick,
}) => {
  return (
    <Grid grow>
      <Grid.Col span={5}>
        <Stack spacing={0}>
          <Text>{attendee.name}</Text>
          <Text>{ATTENDEE_ROLE_LABEL[attendee.role]}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={5}>
        <Flex h="100%" align="center">
          <Text>{fieldValue}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <Flex h="100%" align="center" justify="end">
          <SubtleButton onClick={onEditClick} p={0}>
            Edit
          </SubtleButton>
        </Flex>
      </Grid.Col>
    </Grid>
  )
}
