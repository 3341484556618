import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Group,
  Menu,
  Text,
} from '@mantine/core'
import { BellIcon, CaretSortIcon } from '@radix-ui/react-icons'

import { CalendarRef } from '@/components/Calendar/Calendar'
import { CalendarProps } from '@/components/CalendarScreen'
import { Controls } from '@/components/Header/CalendarHeader/components/Controls'
import { Left } from '@/components/Header/CalendarHeader/components/Left'
import { Right } from '@/components/Header/CalendarHeader/components/Right'

type Props = {
  onBurgerMenuClick: () => void
  title: string | undefined
  calendarProps: CalendarProps
  calendarRef: React.RefObject<CalendarRef>
  isBurgerMenuOpen: boolean
  waitingEventsCount?: number
}

export const MobileHeader: React.FC<Props> = ({
  calendarProps,
  calendarRef,
  onBurgerMenuClick,
  title,
  isBurgerMenuOpen,
  waitingEventsCount,
}) => {
  const history = useHistory()

  return (
    <Group className="h-full p-2 gap-0" noWrap>
      <Left
        onBurgerMenuClick={onBurgerMenuClick}
        isBurgerMenuOpen={isBurgerMenuOpen}
      />
      <Box className="flex grow item-center justify-evenly">
        <Group className="gap-1" noWrap>
          <Controls
            title={title}
            onPrevClick={() => calendarRef.current?.prev()}
            onNextClick={() => calendarRef.current?.next()}
            onTodayClick={() => calendarRef.current?.today()}
          />
        </Group>

        {waitingEventsCount && (
          <Center>
            <ActionIcon
              className="flex items-center"
              variant="light"
              color="red"
              onClick={() => history.push('/waiting')}
            >
              <BellIcon color="#E03131" />
            </ActionIcon>
          </Center>
        )}
        <Menu width={200} position="bottom-end" shadow="md">
          <Menu.Target>
            <Button
              variant="subtle"
              color="gray"
              className="p-0 pl-1"
              rightIcon={<CaretSortIcon color="black" className="-ml-2.5" />}
              size="xs"
            >
              <Text size="sm" weight={400} color="dark">
                {calendarProps.initialView?.charAt(0)?.toUpperCase()}
              </Text>
            </Button>
          </Menu.Target>
          <Menu.Dropdown classNames="flex items-center justify-evenly">
            <Menu.Item onClick={() => calendarRef.current?.changeView('month')}>
              Month
            </Menu.Item>
            <Menu.Item onClick={() => calendarRef.current?.changeView('week')}>
              Week
            </Menu.Item>
            <Menu.Item onClick={() => calendarRef.current?.changeView('day')}>
              Day
            </Menu.Item>
            <Menu.Item onClick={() => calendarRef.current?.changeView('list')}>
              List
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Right />
    </Group>
  )
}
