import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Group,
  Input,
  Radio,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { ClockIcon } from '@radix-ui/react-icons'

import { useFeedbackForm } from './useFeedbackForm'

import { TimeField } from '@/components/common/TimeField'
import { MIME_TYPES, Uploader } from '@/components/common/Uploader'
import { AttendeesInput } from '@/components/Event/AttendeesInput'
import { Card } from '@/components/Event/Detail/components/Card'
import { TranscriptOrderInput } from '@/components/Event/Feedback/TranscriptOrderInput'
import { StateSelect } from '@/components/StateSelect'
import { CNA_TYPE_OTHER_TEXT_CHAR_LIMIT, TurnaroundHours } from '@/constants'
import { Event, Reporter, ReporterFeedback } from '@/types'
import { getAttendeeRolesForEvent } from '@/utils/parrot'
import { COUNTIES, STATES_CODES } from '@/utils/states'

export const FeedbackForm: React.FC<{
  event: Event
  reporter: Reporter
  feedback: ReporterFeedback | undefined
  onSubmit: () => void
}> = ({ event, reporter, feedback, onSubmit }) => {
  const isHearing = event.type === 'hearing'
  const shouldProvideWitnessID = !isHearing && !event.is_court_reporter_event
  const eventResults = [
    {
      value: 'completed',
      label: 'Completed',
      description: 'The questioning was completed',
    },
    {
      value: 'canceled',
      label: 'Canceled or Rescheduled',
      description:
        'Informed that the event is not needed for the scheduled start time',
    },
    // hearings don't have these event results
    ...(!isHearing
      ? [
          {
            value: 'canceled_cna',
            label: 'CNA',
            description:
              'The Parrot client requested for a copy of the certificate of non-appearance',
          },
          {
            value: 'reset',
            label: 'Reset',
            description:
              'The event was able to begin, but could not be completed',
          },
        ]
      : []),
  ]

  const { form, save, saving, submit, onSubmitWithErrors } = useFeedbackForm({
    event,
    reporter,
    feedback,
    onSubmit,
  })

  const attendeeRoles = useMemo(() => getAttendeeRolesForEvent(event), [event])

  return (
    <form
      onSubmit={form.onSubmit(submit, onSubmitWithErrors)}
      noValidate // disable html5 validation so JS validation runs immediately
    >
      <Stack>
        <Card heading="Attendees present">
          <AttendeesInput form={form} roles={attendeeRoles} />
        </Card>

        {shouldProvideWitnessID && (
          <Card heading="Witness ID presented">
            <Stack>
              <TextInput
                label="ID number"
                error={form.errors.id_number}
                value={form.values.id_number ?? ''}
                onChange={event =>
                  form.setFieldValue('id_number', event.currentTarget.value)
                }
              />

              <Select
                label="ID type"
                withinPortal
                placeholder="Pick one"
                error={form.errors.id_type}
                value={form.values.id_type}
                onChange={value => form.setFieldValue('id_type', value)}
                data={[
                  {
                    value: 'drivers_license',
                    label: 'Drivers license',
                  },
                  {
                    value: 'passport',
                    label: 'Passport',
                  },
                  {
                    value: 'police_badge',
                    label: 'Police badge',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                ]}
                searchable
              />

              <Input.Wrapper
                id="input-screenshot"
                label="Screenshot"
                description="Supported are PNG, PDF, JPEG. Max size 10MB"
                error={form.errors.id_screenshots}
                className="mb-6"
              >
                <Uploader
                  inputId="input-screenshot"
                  accept={[MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.PDF]}
                  maxSize={10 * 10 ** 6} // 10MB
                  presignUrl={event.presign_id_screenshot_upload_url}
                  files={form.values.id_screenshots}
                  onChange={files =>
                    form.setFieldValue('id_screenshots', files)
                  }
                />
              </Input.Wrapper>
            </Stack>
          </Card>
        )}

        {!event.is_court_reporter_event && (
          <Card>
            <Stack>
              <Textarea
                label="Timestamp log of key events"
                error={form.errors.timestamp_log}
                value={form.values.timestamp_log ?? ''}
                onChange={event =>
                  form.setFieldValue('timestamp_log', event.currentTarget.value)
                }
                minRows={3}
                autosize
              />
            </Stack>
          </Card>
        )}

        {event.type !== 'examination_under_oath' && !isHearing && (
          <Card>
            <Stack>
              <Input.Wrapper error={form.errors.witness_requested_transcript}>
                <Checkbox
                  label="Witness choose to read the transcript"
                  checked={form.values.witness_requested_transcript}
                  onChange={event =>
                    form.setFieldValue(
                      'witness_requested_transcript',
                      event.currentTarget.checked,
                    )
                  }
                />
              </Input.Wrapper>

              <Checkbox
                label={
                  event.can_order_for_oc
                    ? 'Are there any certified transcript orders at this time?'
                    : 'Parrot client ordered transcript'
                }
                checked={form.values.transcript_ordered}
                onChange={event => {
                  form.setFieldValue('transcript_orders', null)

                  form.setFieldValue(
                    'transcript_ordered',
                    event.currentTarget.checked,
                  )
                }}
                error={form.errors.transcript_ordered}
              />

              {form.values.transcript_ordered && (
                <Box mt="md">
                  <TranscriptOrderInput
                    form={form}
                    withOcOrdering={!!event.can_order_for_oc}
                    attendees={form.values.attendees}
                    defaultTurnaroundHours={
                      event.is_court_reporter_event
                        ? TurnaroundHours.SEVEN_DAYS
                        : TurnaroundHours.ROUGH_DRAFT
                    }
                    withRoughDraftOption={!event.is_court_reporter_event}
                    // for court reporter events all attorneys must order transcript
                    // for other events only some attorneys can order transcript
                    clearable={
                      !!event.can_order_for_oc && !event.is_court_reporter_event
                    }
                  />
                </Box>
              )}
            </Stack>
          </Card>
        )}

        <Card>
          <Stack>
            <Radio.Group
              label="How did the event end?"
              required
              error={form.errors.event_status}
              value={form.values.event_status}
              onChange={value => {
                if (!value) {
                  return
                }
                form.setFieldValue(
                  'event_status',
                  value as typeof form.values.event_status,
                )
              }}
            >
              {eventResults.map(option => (
                <Radio
                  key={option.value}
                  my="sm"
                  value={option.value}
                  label={
                    <>
                      <Text weight={500} size="sm">
                        {option.label}
                      </Text>
                      <Text color="gray" size="xs">
                        {option.description}
                      </Text>
                    </>
                  }
                />
              ))}
            </Radio.Group>

            {form.values.event_status === 'canceled_cna' && (
              <>
                <Radio.Group
                  required
                  label="Certificate of Non-Appearance"
                  placeholder="Pick one"
                  error={form.errors.cna_type}
                  value={form.values.cna_type ?? undefined}
                  onChange={value => {
                    form.setFieldValue('cna_type', value)
                    form.setFieldValue(
                      'cna_type_other_text',
                      value === 'other'
                        ? form.values.cna_type_other_text
                        : null,
                    )
                  }}
                >
                  {[
                    {
                      value: 'witness_no_show',
                      label: 'Witness No-Show',
                    },
                    {
                      value: 'witness_and_client_no_show',
                      label: 'Witness and Client No-Show',
                    },
                    {
                      value: 'opposing_counsel_no_show',
                      label: 'Opposing Counsel No-Show',
                    },
                    {
                      value: 'other',
                      label: 'Other (All attendees are present)',
                    },
                  ].map(option => (
                    <Radio
                      my="sm"
                      key={option.value}
                      value={option.value}
                      label={<Text size="sm">{option.label}</Text>}
                    />
                  ))}
                </Radio.Group>

                {form.values.cna_type === 'other' && (
                  <>
                    <Textarea
                      description={`Used ${
                        (form.values.cna_type_other_text || '').length
                      } out of ${CNA_TYPE_OTHER_TEXT_CHAR_LIMIT} available characters`}
                      label={
                        <>
                          <Text component="span" size="sm">
                            Specify the reason{' '}
                          </Text>
                          <Text
                            component="span"
                            size="sm"
                            sx={theme => ({ color: theme.colors.gray[6] })}
                          >
                            (will be visible on certificate)
                          </Text>
                        </>
                      }
                      error={form.errors.cna_type_other_text}
                      value={form.values.cna_type_other_text ?? ''}
                      onChange={event =>
                        form.setFieldValue(
                          'cna_type_other_text',
                          event.currentTarget.value.slice(
                            0,
                            CNA_TYPE_OTHER_TEXT_CHAR_LIMIT,
                          ),
                        )
                      }
                      minRows={3}
                      autosize
                    />
                  </>
                )}

                <TimeField
                  required
                  label="Time of declaration of CNA"
                  description="In your local time"
                  error={form.errors.cna_declared_at}
                  value={
                    form.values.cna_declared_at
                      ? form.values.cna_declared_at
                      : undefined
                  }
                  onChange={dateTime => {
                    form.setFieldValue('cna_declared_at', dateTime ?? null)
                  }}
                  rightSection={
                    <Tooltip label="Set to current time">
                      <div>
                        <ClockIcon
                          color="gray"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            form.setFieldValue(
                              'cna_declared_at',
                              new Date().toISOString(),
                            )
                          }
                        />
                        <Space w="xl" />
                      </div>
                    </Tooltip>
                  }
                  className="w-1/2"
                />
              </>
            )}

            <Textarea
              label="How was the event? Any notes?"
              error={form.errors.additional_notes}
              value={form.values.additional_notes ?? ''}
              onChange={event =>
                form.setFieldValue(
                  'additional_notes',
                  event.currentTarget.value,
                )
              }
              minRows={3}
              autosize
            />
          </Stack>
        </Card>

        {!isHearing && (
          <Card heading="Where were you located during event">
            <Stack>
              <StateSelect
                label="State"
                placeholder="Pick one"
                error={form.errors.reporter_state}
                value={form.values.reporter_state}
                onChange={value => {
                  form.setFieldValue('reporter_state', value)
                  form.setFieldValue('reporter_county', '')
                }}
                states={STATES_CODES}
                required
              />

              <Select
                required
                label="County"
                placeholder="Pick one"
                error={form.errors.reporter_county}
                value={form.values.reporter_county}
                onChange={value => form.setFieldValue('reporter_county', value)}
                data={
                  form.values.reporter_state
                    ? COUNTIES[form.values.reporter_state].map(county => ({
                        value: county,
                        label: county,
                      }))
                    : []
                }
                disabled={!form.values.reporter_state}
                searchable
              />
            </Stack>
          </Card>
        )}

        <Group position="right">
          <Button
            loading={saving}
            color="gray"
            variant="light"
            className="w-48"
            onClick={save}
          >
            Save changes
          </Button>

          <Button type="submit" loading={form.submitting} className="min-w-48">
            Submit form
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
