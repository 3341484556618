import React, { useRef } from 'react'
import {
  BusinessHoursInput,
  DateSelectArg,
  EventChangeArg,
  formatDate,
  FormatDateOptions,
  formatRange,
} from '@fullcalendar/core'
import { BASE_OPTION_DEFAULTS } from '@fullcalendar/core/internal'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import Interval from 'date-fns/esm'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'

import './calendar.css'

import { Event } from '@/types'

export const TITLE_FORMAT: FormatDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const MOBILE_TITLE_FORMAT: FormatDateOptions = {
  year: '2-digit',
  month: 'short',
  day: 'numeric',
}

export type View = 'month' | 'week' | 'day' | 'list' | 'listCustom'

export type CalendarRef = {
  next(): void
  prev(): void
  today(): void
  changeView(view: View): void
}

export type CalendarEvent = {
  start: string
  end: string
  extendedProps: Record<string, any>
}

export type CalendarState = {
  title?: string
  view: View
  date: Date
  interval?: Interval
}

export const Calendar: React.FC<{
  events: CalendarEvent[]
  controlRef: React.MutableRefObject<CalendarRef | null>
  renderEvent: (
    event: CalendarEvent['extendedProps'],
    extra: { view: View },
  ) => React.ReactNode
  noEventsContent?: React.ReactNode

  initialView: View
  initialDate: Date
  visibleInterval?: Interval | ((currentDate: Date) => Interval)

  businessHours?: BusinessHoursInput

  selectable?: boolean
  select?: (arg: DateSelectArg) => void

  editable?: boolean
  onEventChange?: (arg: EventChangeArg) => void

  onStateChange?: (state: CalendarState) => void
  titleFormat: FormatDateOptions
}> = ({
  events,
  controlRef,
  renderEvent,
  noEventsContent = 'No events to display',

  initialView,
  initialDate,
  visibleInterval,

  businessHours,

  selectable = false,
  select,

  editable = false,
  onEventChange,
  titleFormat,
  onStateChange = () => {},
}) => {
  const innerRef = useRef<FullCalendar | null>(null)

  // we shift dates by days/hours to avoid any potential timezone conversion issues
  // TODO: do this properly
  controlRef.current = {
    prev: () => {
      const currentDate = innerRef.current?.getApi().getDate() as Date
      const currentViewType = innerRef.current?.getApi().view.type
      let newDate = currentDate

      switch (currentViewType) {
        case 'day':
          newDate = addHours(startOfDay(subDays(currentDate, 1)), 12)
          break
        case 'list':
        case 'week':
          newDate = addDays(startOfWeek(subWeeks(currentDate, 1)), 1)
          break
        case 'month':
          newDate = addDays(startOfMonth(subMonths(currentDate, 1)), 1)
          break
      }

      // the `currentDate` is in user's timezone but `goToDate` accepts date in UTC
      innerRef.current?.getApi().gotoDate(zonedToUtc(newDate))
      onStateChange(getCurrentState())
    },
    next: () => {
      const currentDate = innerRef.current?.getApi().getDate() as Date
      const currentViewType = innerRef.current?.getApi().view.type
      let newDate = currentDate

      switch (currentViewType) {
        case 'day':
          newDate = addHours(startOfDay(addDays(currentDate, 1)), 12)
          break
        case 'list':
        case 'week':
          newDate = addDays(startOfWeek(addWeeks(currentDate, 1)), 1)
          break
        case 'month':
          newDate = addDays(startOfMonth(addMonths(currentDate, 1)), 1)
          break
      }

      // the `currentDate` is in user's timezone but `goToDate` accepts date in UTC
      innerRef.current?.getApi().gotoDate(zonedToUtc(newDate))
      onStateChange(getCurrentState())
    },
    today: () => {
      innerRef.current?.getApi().today()
      onStateChange(getCurrentState())
    },
    changeView: (view: View) => {
      innerRef.current?.getApi().changeView(view)
      onStateChange(getCurrentState())
    },
  }

  const getCurrentState = () => {
    if (!innerRef.current) {
      return {} as CalendarState
    }

    const date = innerRef.current.getApi().getDate()
    const view = innerRef.current.getApi().view

    return {
      title: view.title,
      view: view.type as View,
      date: date,
      interval: {
        start: view.activeStart,
        end: view?.activeEnd,
      },
    }
  }

  return (
    <FullCalendar
      ref={innerRef}
      events={events}
      editable={editable}
      eventChange={e => onEventChange?.(e)}
      selectable={selectable}
      select={select}
      businessHours={businessHours}
      plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
      scrollTime={{ hours: 8 }}
      initialView={initialView}
      titleFormat={titleFormat}
      // FullCalendar assumes that the `initialDate` is in UTC, but it's actually
      // in user's timezone, so we have to convert it to UTC
      initialDate={zonedToUtc(initialDate)}
      height="100%"
      headerToolbar={false}
      firstDay={0} // sunday
      allDaySlot={false}
      listDayFormat={{
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        omitCommas: true,
      }}
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: 'lowercase',
      }}
      views={{
        month: {
          type: 'dayGrid',
          visibleRange: currentDate =>
            getMonthViewVisibleInterval(utcToZoned(currentDate)),
        },
        week: {
          // there is some weird bug in `timeGridWeek` view which causes wrong
          // calculation of its `visibleRange` so have to use `timeGrid` view
          // and set `visibleRange` manually
          // how to reproduce it:
          //  1. set your timezone to UTC+12:00
          //  2. change type of this view to `timeGridWeek` and remove `visibleRange` attribute
          //  3. load calendar with some Monday date
          // then the calendar will show week before the Monday date
          type: 'timeGrid',
          dayHeaderFormat: {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          },
          visibleRange: currentDate =>
            getWeekViewInterval(utcToZoned(currentDate)),
        },
        day: {
          type: 'timeGrid',
          dayHeaderFormat: {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          },
          visibleRange: currentDate =>
            getDayViewInterval(utcToZoned(currentDate)),
        },
        list: {
          type: 'listWeek',
          visibleRange: currentDate =>
            getWeekViewInterval(utcToZoned(currentDate)),
        },
        listCustom: {
          type: 'list',
          visibleRange: visibleInterval,
        },
      }}
      visibleRange={visibleInterval}
      listDaySideFormat={undefined}
      eventContent={state =>
        renderEvent(state.event.extendedProps as any, {
          view: state.view.type as View,
        })
      }
      noEventsContent={noEventsContent}
      nowIndicator={true}
    />
  )
}

export const toCalendarEvent = (event: Event): CalendarEvent => ({
  start: event.datetime,
  end: event.end_datetime,
  extendedProps: event,
})

export const viewDateToTitle = (
  view: View,
  date: Date,
  titleFormat: FormatDateOptions,
): string | undefined => {
  if (view === 'day') {
    return formatDate(date, titleFormat)
  }

  const interval = viewDateToInterval(view, date)

  if (interval === undefined) {
    return undefined
  }

  return formatRange(interval.start, interval.end, {
    ...titleFormat,
    separator: BASE_OPTION_DEFAULTS.titleRangeSeparator,
    isEndExclusive: true,
  })
}

export const viewDateToInterval = (
  view: View,
  date: Date,
): Interval | undefined =>
  ({
    month: getMonthViewInterval(date),
    week: getWeekViewInterval(date),
    day: getDayViewInterval(date),
    list: getWeekViewInterval(date),
    listCustom: undefined,
  })[view]

export const getDayViewInterval = (date: Date): Interval => {
  const start = date
  const end = getEndDate(date)

  return { start, end }
}

export const getWeekViewInterval = (date: Date): Interval => {
  const start = startOfWeek(date)
  const end = getEndDate(endOfWeek(date))

  return { start, end }
}

export const getMonthViewInterval = (date: Date): Interval => {
  const start = startOfMonth(date)
  const end = getEndDate(endOfMonth(date))

  return { start, end }
}

export const getMonthViewVisibleInterval = (date: Date): Interval => {
  /*
  start of week   start of month
      v                  v
      26 27 28 29 30 31  1
       2  3  4  5  6  7  8
       9 10 11 12 13 14 15
      16 17 18 19 20 21 22
      23 24 25 26 27 28 29
      30 31  1  2  3  4  5
         ^               ^
   end of month     end of week
  */
  const start = startOfWeek(startOfMonth(date))
  let end = getEndDate(endOfWeek(endOfMonth(date)))

  // we always show 6 rows in month view, that's why we can't use for example
  // `getMonthDays` from `@mantine/dates`
  const numberOfWeeks = differenceInCalendarWeeks(end, start)
  if (numberOfWeeks < 6) {
    end = addWeeks(end, 6 - numberOfWeeks)
  }

  return { start, end }
}

export const getEndDate = (date: Date) => startOfDay(addDays(date, 1))

export const zonedToUtc = (date: Date) =>
  addMinutes(date, new Date().getTimezoneOffset())

export const utcToZoned = (date: Date) =>
  addMinutes(date, -new Date().getTimezoneOffset())
