import React from 'react'
import { Badge, Button, Group, Stack } from '@mantine/core'
import {
  CalendarIcon,
  ChatBubbleIcon,
  ClockIcon,
  FrameIcon,
  GlobeIcon,
  HomeIcon,
  PersonIcon,
  ReaderIcon,
} from '@radix-ui/react-icons'

import { Card } from './Card'

import { DataEntry } from '@/components/DataEntry'
import { ConnectionInformationButton } from '@/components/Event/ConnectionInformationButton'
import { useFilesModal } from '@/components/Event/hooks/useFilesModal'
import { DepositionNoticesModal } from '@/components/Event/Modals/DepositionNoticesModal'
import { ExhibitsModal } from '@/components/Event/Modals/ExhibitsModal'
import { Event } from '@/types'
import { getUnifiedEventStatus, isAdminEvent } from '@/utils/parrot'
import { formatDate, formatDuration, formatTimeRange } from '@/utils/time'

export const DetailsCard: React.FC<{
  event: Event
}> = ({ event }) => {
  const openExhibitsModal = useFilesModal(event, 'Exhibits', ExhibitsModal)
  const openDepositionNoticesModal = useFilesModal(
    event,
    'Deposition notice',
    DepositionNoticesModal,
  )

  return (
    <Card heading={event.name}>
      <Stack spacing="md">
        <Group spacing="sm">
          <EventTypeBadge event={event} />

          <EventUnifiedStatusBadge event={event} />
        </Group>

        <Stack ml="xs">
          <Stack spacing="xs">
            <DataEntry icon={CalendarIcon} tooltip="Date">
              {formatDate(event.datetime, { long: true, separator: ' ' })}
            </DataEntry>

            <DataEntry icon={ClockIcon} tooltip="Time">
              {formatTimeRange(event.datetime, event.duration)} (
              {formatDuration(event.duration)})
            </DataEntry>

            {isAdminEvent(event) && (
              <DataEntry icon={PersonIcon} tooltip="Assigned reporter">
                {event.reporter?.name ?? 'Not assigned'}
              </DataEntry>
            )}

            <DataEntry icon={HomeIcon} tooltip="Organization">
              {event.team.organization.name}: {event.team.name}
            </DataEntry>
          </Stack>

          <Stack spacing="xs">
            <DataEntry
              icon={ReaderIcon}
              tooltip={event.case.is_claim ? 'Claim' : 'Case'}
            >
              {event.case.name}
            </DataEntry>

            {(event.case.number || event.claim_number) && (
              <DataEntry
                icon={FrameIcon}
                tooltip={event.case.number ? 'Case number' : 'Claim number'}
              >
                {event.case.number || event.claim_number}
              </DataEntry>
            )}

            {event.team.state && event.team.city && (
              <DataEntry icon={GlobeIcon} tooltip="Client location">
                {event.team.state}: {event.team.city}
              </DataEntry>
            )}
          </Stack>

          {event.recording.is_translator_present && (
            <Stack spacing="xs">
              <DataEntry icon={ChatBubbleIcon} tooltip="Interpreter">
                Interpreter present
              </DataEntry>
            </Stack>
          )}
        </Stack>
        <Group spacing={4}>
          <Button variant="light" color="gray" onClick={openExhibitsModal}>
            Exhibits
          </Button>
          <Button
            variant="light"
            color="gray"
            onClick={openDepositionNoticesModal}
          >
            Notice
          </Button>
          {event.general_status === 'booked' && (
            <ConnectionInformationButton event={event} />
          )}
        </Group>
      </Stack>
    </Card>
  )
}

export const EventTypeBadge: React.FC<{ event: Event }> = ({ event }) => {
  return (
    <Badge color="gray">
      {/* showing EUO because Examination Under Oath is too long */}
      {event.type.replace('examination_under_oath', 'euo').replaceAll('_', ' ')}
    </Badge>
  )
}
export const EventUnifiedStatusBadge: React.FC<{
  event: Event
}> = ({ event }) => {
  const unifiedStatus = getUnifiedEventStatus(event)
  const color = {
    available: 'green',
    booked: 'indigo',
    overlapping: 'orange',
    overlapping_related: 'orange',
    past: 'gray',
    waiting: 'red',
    cancelled: 'gray',
    unavailable: 'gray',
  }[unifiedStatus]

  const label = {
    available: 'Available to book',
    booked: 'Booked event',
    overlapping: 'Overlapping',
    overlapping_related: 'Overlapping related',
    past: 'Past event',
    waiting: 'Waiting for action',
    cancelled: 'Canceled',
    unavailable: 'Unavailable',
  }[unifiedStatus]

  return <Badge color={color}>{label}</Badge>
}
