import React, { useEffect } from 'react'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Group, Stack } from '@mantine/core'
import * as yup from 'yup'

import { QuestioningVerbatim } from './QuestioningVerbatim'

import { NotesForm } from '@/components/Event/NewScript/components/common/forms/NotesForm'
import { SpellingsForm } from '@/components/Event/NewScript/components/common/forms/SpellingsForm'
import { TimestampsForm } from '@/components/Event/NewScript/components/common/forms/TimestampsForm'
import { ActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionTitle'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import { ScriptStep, TimestampType } from '@/constants'
import { ScriptStepProps } from '@/types'
import { formatTimeForTimezone } from '@/utils/time'

const ConductingStepSchema = yup.object().shape({
  timestamps: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  spellings: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  notes: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
})

export const QuestioningStep: React.FC<ScriptStepProps> = ({ event }) => {
  const {
    completeAction,
    canContinue,
    isActionCompleted,
    completeStepAndContinue,
  } = useStepActions(ScriptStep.QUESTIONING, event)

  const { actions, state } = useScriptState(event)

  const dateTime = useFormattedCurrentDateParts(event.team.timezone)

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ConductingStepSchema),
    defaultValues: {
      timestamps: state.timestamps,
      spellings: state.spellings,
      notes: state.notes,
    },
  })

  useEffect(() => {
    const subscription = methods.watch((value, { type }) => {
      // We store here only values that are changed by typing
      if (type !== 'change') {
        return
      }

      actions.updateConductingData({
        notes: value.notes,
        timestamps: value.timestamps,
        spellings: value.spellings,
      })
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const addNewTimestamp = (timestampType: TimestampType) => {
    const res = actions.addTimestamp({
      isOnRecord:
        timestampType === TimestampType.EXHIBIT
          ? undefined
          : timestampType === TimestampType.RECORDING_ON,
      timestampType: timestampType,
      timezone: event.team.timezone as string,
    })
    methods.setValue('timestamps', res.timestamps)
  }

  const validateAndCompleteAction = (key: string) => {
    methods.trigger().then(isValid => {
      if (isValid) {
        completeAction(key)
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <Stack align="flex-start" spacing="xl">
          <ScriptTimeline title="4. Questioning">
            <TimelineAction
              actionKey="start_questioning"
              title="Let counsels know they can start questioning"
              onComplete={completeAction}
              completed={isActionCompleted('start_questioning')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    Okay, whenever you are ready, counsel, you may proceed with
                    questioning.
                  </ActionText>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      Mute your microphone and stay on camera while on the
                      record. <br />
                      Remain professional and attentive throughout the entire
                      proceeding.
                      <br />
                      Monitor audio integrity and request repetition or
                      clarification, as necessary.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>
            </TimelineAction>
            <TimelineAction
              actionKey="oversight_the_progress"
              title="Monitor the event"
              onComplete={validateAndCompleteAction}
              completed={isActionCompleted('oversight_the_progress')}
            >
              <Stack>
                {/* Go off record etc. */}
                <ActionContainer outer>
                  <Stack>
                    <ActionContainer>
                      <Stack>
                        <Group>
                          <ActionButton
                            onClick={() =>
                              addNewTimestamp(TimestampType.RECORDING_OFF)
                            }
                            disabled={!state.is_on_record}
                          >
                            Go off record
                          </ActionButton>
                          <ActionButton
                            onClick={() =>
                              addNewTimestamp(TimestampType.EXHIBIT)
                            }
                            disabled={!state.is_on_record}
                          >
                            Exhibit shown
                          </ActionButton>
                        </Group>
                        {!state.is_on_record && (
                          <Stack>
                            <Stack py="md">
                              <ActionText mono>
                                We are now OFF the record and the time is{' '}
                                {state.last_timestamp &&
                                  formatTimeForTimezone(
                                    state.last_timestamp,
                                    event.team.timezone,
                                  )}{' '}
                                {dateTime.timezone}
                              </ActionText>
                              <ActionText mono>{`<After break>`}</ActionText>
                              <ActionText mono>
                                <QuestioningVerbatim event={event} />
                              </ActionText>
                            </Stack>
                            <ActionButton
                              onClick={() =>
                                addNewTimestamp(TimestampType.RECORDING_ON)
                              }
                            >
                              Go on record
                            </ActionButton>
                          </Stack>
                        )}
                      </Stack>
                    </ActionContainer>
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        Breaks must be unanimous. If there is an objection from
                        either side, objection must be resolved before reading
                        off the record. Note the exhibit and exhibit # for the
                        record. If the witness or attorney shows any document,
                        make sure to clarify if it’s being used as an exhibit or
                        just as a reference.{' '}
                      </ActionText>
                    </ActionContainer>
                    <ActionContainer>
                      <TimestampsForm />
                    </ActionContainer>
                  </Stack>
                </ActionContainer>

                <ActionContainer outer>
                  <Stack>
                    <ActionContainer>
                      <SpellingsForm />
                    </ActionContainer>
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        If the witness or attorney uses a name, a location, or a
                        term that are not common words, take note of it and at
                        the end of all the questioning or at a natural break,
                        circle back and ask for the proper spelling.
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                </ActionContainer>

                <ActionContainer outer>
                  <ActionContainer>
                    <NotesForm />
                  </ActionContainer>
                </ActionContainer>
              </Stack>
            </TimelineAction>
            <ContinueButton
              event={event}
              label="Continue to Closing"
              canContinue={canContinue()}
              step={ScriptStep.QUESTIONING}
              handleClick={() => completeStepAndContinue(ScriptStep.CLOSING)}
            />
          </ScriptTimeline>
        </Stack>
      </Form>
    </FormProvider>
  )
}
