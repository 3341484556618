import { useCreateInitialScriptState } from '@/components/Event/NewScript/hooks/state/useCreateInitialScriptState'
import { useReporter } from '@/providers/UserProvider'
import { Event } from '@/types'

const TRAINING_EVENT_ID = 'training-event'

export const useNewScriptTraining = (event?: Event) => {
  const reporter = useReporter()
  const { initScriptData, isLoading } = useCreateInitialScriptState({
    event: event!,
    reporter,
  })
  const hasTrainingAccess = event?.id === TRAINING_EVENT_ID

  const resetScript = () => {
    if (!event) {
      return
    }

    return initScriptData()
  }

  return { hasTrainingAccess, resetScript, isResetting: isLoading }
}
