import React, { useEffect } from 'react'
import { LoadingOverlay } from '@mantine/core'
import axios from 'axios'
import qs from 'qs'

import { useAuth } from '@/hooks/useAuth'

axios.defaults.baseURL = import.meta.env.VITE_APP_URL
// change `?param[]=1&param[]=2&param[]=3` to `?param=1&param=2&param=3`
// because FastAPI doesn't understand to `param[]` format
// see https://github.com/axios/axios/issues/1443
axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { indices: false })

export const AxiosProvider: React.FC = ({ children }) => {
  const { isLoading, getAccessTokenSilently } = useAuth()

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(config =>
      getAccessTokenSilently().then(token => {
        config.headers!['Authorization'] = `Bearer ${token}`

        return config
      }),
    )

    return () => axios.interceptors.request.eject(interceptor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <LoadingOverlay visible={true} />
  }

  return <>{children}</>
}
