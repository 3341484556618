import React from 'react'
import { Alert } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'

import { AvailableEventsTeaser } from '@/components/Event/Teaser/AvailableEventsTeaser'
import { Event } from '@/types'

export const CancelledEventDetail: React.FC<{
  event: Event
}> = ({ event }) => {
  return (
    <Layout
      left={
        <>
          <Alert title="Canceled event" color="gray">
            This event was cancelled.
          </Alert>
          <AvailableEventsTeaser />
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
