import React, { useEffect, useRef, useState } from 'react'
import {
  AppShell,
  Box,
  Drawer,
  Header as MantineHeader,
  LoadingOverlay,
  Navbar,
  useMantineTheme,
} from '@mantine/core'
import { useHotkeys, useMediaQuery } from '@mantine/hooks'

import { viewDateToTitle } from './Calendar/Calendar'

import {
  Calendar,
  CalendarRef,
  MOBILE_TITLE_FORMAT,
  TITLE_FORMAT,
} from '@/components/Calendar/Calendar'
import { Header, HeaderProps } from '@/components/Header'
import { CalendarHeader } from '@/components/Header/CalendarHeader'
import { HEADER_HEIGHT, NAVBAR_WIDTH } from '@/constants'

export type CalendarProps = Pick<
  React.ComponentProps<typeof Calendar>,
  | 'events'
  | 'selectable'
  | 'select'
  | 'editable'
  | 'onEventChange'
  | 'initialDate'
  | 'initialView'
  | 'renderEvent'
  | 'onStateChange'
  | 'businessHours'
  | 'noEventsContent'
  | 'visibleInterval'
>

export const CalendarScreen: React.FC<
  {
    header?: HeaderProps
    sidebar?: React.ReactNode
    isLoading?: boolean
    hotkeysEnabled?: boolean
    waitingEventsCount?: number
  } & CalendarProps
> = ({
  header,
  sidebar,
  waitingEventsCount,
  isLoading = false,
  hotkeysEnabled = false,
  ...calendarProps
}) => {
  const { breakpoints } = useMantineTheme()
  const isSmOrHigherResolution = useMediaQuery(`(min-width: ${breakpoints.sm})`)
  const calendarRef = useRef<CalendarRef | null>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const calendarTitleFormat = isSmOrHigherResolution
    ? TITLE_FORMAT
    : MOBILE_TITLE_FORMAT

  const [title, setTitle] = useState<string | undefined>()

  useEffect(() => {
    setTitle(
      viewDateToTitle(
        calendarProps.initialView,
        calendarProps.initialDate,
        calendarTitleFormat,
      ),
    )
  }, [
    calendarTitleFormat,
    calendarProps.initialView,
    calendarProps.initialDate,
  ])

  useHotkeys([
    ['m', () => hotkeysEnabled && calendarRef.current?.changeView('month')],
    ['w', () => hotkeysEnabled && calendarRef.current?.changeView('week')],
    ['d', () => hotkeysEnabled && calendarRef.current?.changeView('day')],
    ['l', () => hotkeysEnabled && calendarRef.current?.changeView('list')],
  ])

  const isSmOrLarger = useMediaQuery(`(min-width: ${breakpoints.sm})`)

  useEffect(() => {
    if (isSmOrLarger) {
      setDrawerOpen(false)
    }
  }, [isSmOrLarger])

  const renderHeader = () => {
    if (header) {
      return <Header {...header} />
    }

    return (
      <CalendarHeader
        calendarProps={calendarProps}
        calendarRef={calendarRef}
        onBurgerMenuClick={() => setDrawerOpen(!drawerOpen)}
        title={title}
        isBurgerMenuOpen={drawerOpen}
        waitingEventsCount={waitingEventsCount}
      />
    )
  }

  return (
    <AppShell
      padding={0}
      fixed
      navbarOffsetBreakpoint="md"
      header={
        <MantineHeader height={HEADER_HEIGHT}>{renderHeader()}</MantineHeader>
      }
      navbar={
        sidebar ? (
          <Navbar
            fixed
            hidden
            hiddenBreakpoint="md"
            width={{
              md: NAVBAR_WIDTH,
            }}
          >
            <div>{sidebar}</div>

            <Drawer
              zIndex={10}
              opened={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <Box mt={HEADER_HEIGHT}>{sidebar}</Box>
            </Drawer>
          </Navbar>
        ) : undefined
      }
    >
      <LoadingOverlay visible={isLoading} />
      <div className="h-full">
        <Calendar
          titleFormat={calendarTitleFormat}
          controlRef={calendarRef}
          {...{
            ...calendarProps,
            onStateChange: state => {
              setTitle(state.title)
              calendarProps.onStateChange?.(state)
            },
          }}
        />
      </div>
    </AppShell>
  )
}
