import React, { useEffect } from 'react'
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import { ActionIcon, Anchor, Box, Loader, Text } from '@mantine/core'
import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import classNames from 'classnames'

import { presignAndUploadFile } from '@/utils/s3'

export type UploadedFile = {
  key: string
  original_file_name: string
  url?: string | null
}

// Just used MIME_TYPES, don't want to import library just for the list of those
export enum MIME_TYPES {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
}

type UIFileBase = {
  isUploading: boolean
  errors: string[]
  key?: string
  previewUrl?: string | null
}

type UIFile = {
  file: File
} & UIFileBase

type FakeUIFile = {
  file: { name: string }
} & UIFileBase

type Props = {
  presignUrl: string
  inputId?: string
  files: UploadedFile[]
  onChange?: (files: Omit<UploadedFile, 'url'>[]) => void
} & Omit<DropzoneOptions, 'onDrop'>

export const Uploader: React.FC<Props> = ({
  presignUrl,
  onChange,
  inputId,
  files: initialFiles,
  ...options
}) => {
  const [files, setFiles] = React.useState<(UIFile | FakeUIFile)[]>(
    initialFiles.map(file => ({
      key: file.key,
      errors: [],
      isUploading: false,
      previewUrl: file.url,
      file: { name: file.original_file_name },
    })),
  )

  // we need to sync via useEffect otherwise there might be
  // race condition when onChange callback changes during upload
  useEffect(() => {
    onChange?.(
      files
        .map(f => ({
          original_file_name: f.file.name,
          key: f.key,
        }))
        .filter(isUploadedFile),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const handleFileUploadError = (file: UIFile) => {
    setFiles(files =>
      files.map(f =>
        f.file === file.file
          ? { ...f, errors: ['Upload failed.'], isUploading: false }
          : f,
      ),
    )
  }

  const handleFileUploaded = async (
    file: UIFile,
    key: string,
    previewUrl: string,
  ) => {
    setFiles(files =>
      files.map(f =>
        f.file === file.file
          ? { ...f, key, previewUrl, isUploading: false }
          : f,
      ),
    )
  }

  const handleRemove = (file: UIFile | FakeUIFile) => {
    setFiles(files => files.filter(f => f.file !== file.file))
  }

  const handleDrop = (files: File[]) => {
    const additionalFiles: UIFile[] = files.map(file => ({
      file,
      errors: [],
      isUploading: true,
    }))
    setFiles(files => [...files, ...additionalFiles])
    additionalFiles.forEach(async uiFile => {
      try {
        const { key, previewUrl } = await presignAndUploadFile(
          presignUrl,
          uiFile.file,
        )
        handleFileUploaded(uiFile, key, previewUrl)
      } catch (e) {
        handleFileUploadError(uiFile)
      }
    })
  }

  const handleReject = (rejections: FileRejection[]) => {
    const additionalFiles: UIFile[] = rejections.map(rejection => ({
      file: rejection.file,
      errors: rejection.errors.map(e => e.message),
      isUploading: false,
    }))
    setFiles(files => [...files, ...additionalFiles])
  }

  const { getInputProps, getRootProps } = useDropzone({
    ...options,
    onDropAccepted: handleDrop,
    onDropRejected: handleReject,
  })

  return (
    <>
      <div>
        {files.map(file => (
          <Box
            key={file.key ?? file.file.name}
            className={classNames('p-3 my-2', {
              'bg-red-100': !!file.errors.length,
              'bg-gray-100': !file.errors.length,
            })}
          >
            <div className="flex justify-between">
              {file.previewUrl ? (
                <Anchor href={file.previewUrl} target="_blank">
                  {file.file.name}
                </Anchor>
              ) : (
                file.file.name
              )}
              {file.isUploading ? (
                <Loader size="sm" />
              ) : (
                <div className="flex">
                  <ActionIcon
                    onClick={() => handleRemove(file)}
                    variant="transparent"
                  >
                    <Cross1Icon />
                  </ActionIcon>
                </div>
              )}
            </div>
            {!!file.errors.length && (
              <Text size="sm" className="text-red-500">
                {file.errors.map(error => (
                  <p key={error}>{error}</p>
                ))}
              </Text>
            )}
          </Box>
        ))}
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} id={inputId} />
        <ActionIcon size={50} variant="light" color="gray">
          <PlusIcon />
        </ActionIcon>
      </div>
    </>
  )
}

const isUploadedFile = (file: Partial<UploadedFile>): file is UploadedFile => {
  return !!file.key && !!file.original_file_name
}
