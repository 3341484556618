import { useRef } from 'react'
import { FieldArrayWithId } from 'react-hook-form'
import { Button, Flex, Text, TextInput } from '@mantine/core'
import { useToggle } from '@mantine/hooks'

import { FormFieldValues } from './ShownExhibitsFieldArray'

import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline'

type Props = {
  field: FieldArrayWithId<FormFieldValues, string, 'id'>
  onChange: (value: string) => void
}

export const EditableInput = ({ field, onChange }: Props) => {
  const [value, toggle] = useToggle([false, true])
  const ref = useRef<HTMLInputElement>(null)

  return (
    <ActionTitle>
      <Flex align="center">
        {value ? (
          <>
            <TextInput defaultValue={field.name} ref={ref} />
            <Button onClick={() => toggle()} variant="subtle">
              Cancel
            </Button>
            <Button
              onClick={() => {
                onChange(ref.current?.value ?? '')
                toggle()
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Text>
              Exhibit {field.index + 1} – {field.name}
            </Text>
            <Button onClick={() => toggle()} variant="subtle">
              Edit
            </Button>
          </>
        )}
      </Flex>
    </ActionTitle>
  )
}
