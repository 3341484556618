import React, { useMemo, useState } from 'react'
import { Button, Group, Stack, Switch, Text } from '@mantine/core'
import capitalize from 'lodash/capitalize'

import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { ScriptStep, ScriptStepStatus } from '@/constants'
import { Event } from '@/types'

export type ContinueButtonProps = {
  label: string
  canContinue: boolean
  step: ScriptStep
  event: Event
  handleClick: () => void
  onCancelSwitchClick?: (cancellationFlowEnabled: boolean) => void
  canCancel?: boolean
  loading?: boolean
  isTenMinutesBeforeEventStarts?: boolean
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  canContinue,
  handleClick,
  onCancelSwitchClick,
  label,
  step,
  canCancel = true,
  event,
  loading = false,
}) => {
  const [cancelFlow, setCancelFlow] = useState(false)

  const { cancelProceeding, revertCanceledProceeding } = useStepActions(
    step,
    event,
  )

  const {
    state: { current_step, first_skipped_step, steps },
  } = useScriptState(event)

  const { showSwitch, switchLabel } = useMemo(() => {
    if (steps[current_step].status === ScriptStepStatus.COMPLETED) {
      return {
        showSwitch: false,
        switchLabel: '',
      }
    }

    switch (current_step) {
      case ScriptStep.ATTENDANCE:
      case ScriptStep.OPENING:
        return {
          showSwitch: true,
          switchLabel:
            'Activate if the proceeding needs to be rescheduled, canceled or CNA.',
        }
      case ScriptStep.QUESTIONING:
        return {
          showSwitch: true,
          switchLabel:
            'Activate if the proceeding needs to be restarted, rescheduled or canceled.',
        }
      default:
        return {
          showSwitch: false,
          switchLabel: '',
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_step])

  const getButtonLabel = () => {
    if (steps[current_step].status === ScriptStepStatus.COMPLETED) {
      return capitalize(`${current_step} completed`)
    }

    return label
  }

  return (
    <Stack align="flex-start" spacing="xl" p="xl">
      {showSwitch && (
        <Switch
          checked={cancelFlow}
          onChange={event => {
            onCancelSwitchClick?.(event.currentTarget.checked)
            setCancelFlow(event.currentTarget.checked)
          }}
          label={switchLabel}
          styles={theme => ({
            thumb: {
              cursor: 'pointer',
            },
            track: {
              cursor: 'pointer',
            },
            label: {
              color: theme.colors.indigo[7],
            },
          })}
        />
      )}
      {current_step === ScriptStep.CLOSING && (
        <Text color="indigo" fw={600} my="md" size="sm">
          * Keep in mind that clicking on “Continue to review” will
          automatically end the Zoom call.
        </Text>
      )}
      {!cancelFlow && (
        <Group>
          <Button
            size="md"
            disabled={!canContinue}
            onClick={handleClick}
            loading={loading}
          >
            {getButtonLabel()}
          </Button>
          {first_skipped_step && current_step === ScriptStep.CLOSING && (
            <Button
              size="md"
              onClick={() => revertCanceledProceeding()}
              variant="outline"
            >
              Revert cancelation
            </Button>
          )}
        </Group>
      )}
      {cancelFlow && (
        <Button size="md" disabled={!canCancel} onClick={cancelProceeding}>
          Skip to Closing
        </Button>
      )}
    </Stack>
  )
}
