import React, { useCallback, useEffect } from 'react'
import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { ReporterSettings } from '@/components/ReporterSettings'
import { useForm } from '@/hooks/useForm'
import {
  useCreateReporterMutation,
  useReporterQuery,
  useSetReporterRoleMutation,
  useSetReporterStripeAccountIdMutation,
  useUpdateReporterExperienceLevelMutation,
} from '@/queries/reporters'
import { ReporterBase } from '@/types'
import { EXPERIENCE_LEVELS, REPORTER_ROLES } from '@/utils/parrot'
import { isValidEmail } from '@/utils/validations'

const nameValidation = (value: string) => {
  if (value.length === 0) {
    return 'Please enter a name'
  }

  return null
}

const emailValidation = (value: string) => {
  if (!isValidEmail(value)) {
    return 'Please enter a valid email'
  }

  return null
}

export const InviteReporterModal: React.FC<{
  opened: boolean
  onClose: () => void
  onSave: () => void
}> = ({ opened, onClose, onSave }) => {
  const { mutateAsync } = useCreateReporterMutation(() => {
    onClose()
    onSave()
    showNotification({ message: 'Reporter was invited' })
  })

  const { reset, onSubmit, getInputProps, submitting } = useForm({
    initialValues: {
      name: '',
      email: '',
      role: 'digital_reporter',
    },
    validate: {
      name: nameValidation,
      email: emailValidation,
    },
  })

  useEffect(() => {
    if (opened) {
      reset()
    }
  }, [opened, reset])

  return (
    <Modal opened={opened} onClose={onClose} title="Invite reporter">
      <form onSubmit={onSubmit(mutateAsync)}>
        <Stack>
          <Text size="sm" color="gray">
            Once you invite a reporter, they will get an email with instructions
            on how to setup their account.
          </Text>

          <TextInput
            required
            label="Name"
            {...getInputProps('name')}
            data-autofocus
          />

          <TextInput required label="Email" {...getInputProps('email')} />

          <Select
            {...getInputProps('role')}
            data={REPORTER_ROLES}
            label="Role"
            withinPortal
          />

          <Group position="right">
            <Button type="submit" loading={submitting}>
              Invite reporter
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export const ChangeReporterExperienceLevelModal: React.FC<{
  opened: boolean
  reporter: ReporterBase
  onClose: () => void
  onSave: () => void
}> = ({ opened, reporter, onClose, onSave }) => {
  const { mutateAsync } = useUpdateReporterExperienceLevelMutation(
    reporter,
    () => {
      onClose()
      onSave()
      showNotification({
        message: 'Experience level was changed',
      })
    },
  )

  const { onSubmit, setFieldValue, getInputProps, submitting } = useForm({
    initialValues: { experience_level: String(reporter.experience_level) },
  })

  useEffect(() => {
    setFieldValue('experience_level', String(reporter.experience_level))
  }, [setFieldValue, reporter.experience_level])

  return (
    <Modal opened={opened} onClose={onClose} title="Set experience level">
      <form onSubmit={onSubmit(mutateAsync)}>
        <Stack>
          <Text size="sm" color="gray">
            Set experience level which determine which events can be booked by
            the reporter.
          </Text>

          <Select
            placeholder="Pick an experience level"
            {...getInputProps('experience_level')}
            data={EXPERIENCE_LEVELS}
            withinPortal
          />

          <Button type="submit" loading={submitting}>
            Set experience level
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}

export const SetReporterStripeAccountIdModal: React.FC<{
  opened: boolean
  reporter: ReporterBase
  onClose: () => void
  onSave: () => void
}> = ({ opened, reporter, onClose, onSave }) => {
  const { mutateAsync } = useSetReporterStripeAccountIdMutation(
    reporter,
    () => {
      onClose()
      onSave()
      showNotification({
        message: 'Stripe account ID was changed',
      })
    },
  )

  const { onSubmit, setFieldValue, getInputProps, submitting } = useForm({
    initialValues: { stripe_account_id: reporter.stripe_account_id ?? '' },
  })

  useEffect(() => {
    setFieldValue('stripe_account_id', reporter.stripe_account_id ?? '')
  }, [setFieldValue, reporter.stripe_account_id])

  return (
    <Modal opened={opened} onClose={onClose} title="Set stripe account">
      <form onSubmit={onSubmit(mutateAsync)}>
        <Stack>
          <TextInput
            required
            label="Stripe Account ID"
            {...getInputProps('stripe_account_id')}
          />

          <Button type="submit" loading={submitting}>
            Set stripe account
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}

export const ChangeReporterRoleModal: React.FC<{
  opened: boolean
  reporter: ReporterBase
  onClose: () => void
  onSave: () => void
}> = ({ opened, reporter, onClose, onSave }) => {
  const { mutateAsync } = useSetReporterRoleMutation(reporter, () => {
    onClose()
    onSave()
    showNotification({
      message: 'Role was changed',
    })
  })

  const { onSubmit, setFieldValue, getInputProps, submitting } = useForm({
    initialValues: { role: reporter.role },
  })

  useEffect(() => {
    setFieldValue('role', reporter.role)
  }, [setFieldValue, reporter.role])

  return (
    <Modal opened={opened} onClose={onClose} title="Set role">
      <form onSubmit={onSubmit(mutateAsync)}>
        <Stack>
          <Select
            {...getInputProps('role')}
            data={REPORTER_ROLES}
            withinPortal
          />

          <Button type="submit" loading={submitting}>
            Set role
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}

export const EditReporterModal: React.FC<{
  opened: boolean
  reporter: ReporterBase
  onClose: () => void
  onSave: () => void
}> = ({ opened, reporter, onClose, onSave }) => {
  // fetch reporter detail because it contains also urls to reporter files
  const {
    data: reporterDetail,
    isLoading,
    remove: removeFromCache,
  } = useReporterQuery(reporter.id, {
    enabled: opened,
  })

  const onUpdated = useCallback(() => {
    onClose()
    onSave()
    showNotification({
      message: 'Reporter was updated',
    })
    removeFromCache()
  }, [onClose, onSave, removeFromCache])

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Edit reporter"
      styles={{ inner: { overflowY: 'auto' }, root: { height: 'auto' } }}
    >
      <LoadingOverlay visible={isLoading} />
      {reporterDetail && (
        <>
          {!reporter.is_active && (
            <Alert color="red">Deactivated reporter</Alert>
          )}
          <ReporterSettings
            reporter={reporterDetail}
            onUpdated={onUpdated}
            wide={false}
          />
        </>
      )}
    </Modal>
  )
}
