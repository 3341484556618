import React from 'react'
import { Box, NavLink, Text } from '@mantine/core'

import { StepNavLink } from '@/components/Event/NewScript/components/common/Stepper/StepNavLink'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepsNavigation } from '@/components/Event/NewScript/hooks/useStepsNavigation'
import { ScriptStep } from '@/constants'
import { Event } from '@/types'

type NaveStepsProps = {
  event: Event
}

export const NavSteps: React.FC<NaveStepsProps> = ({ event }) => {
  const { setCurrentStep, isIncomplete, getNavColor, getStatusLabel } =
    useStepsNavigation(event)

  const {
    state: { current_step },
  } = useScriptState(event)

  const StepItem: React.FC<{ label: string; step: ScriptStep }> = ({
    label,
    step,
  }) => {
    return (
      <NavLink
        disabled={isIncomplete(step)}
        active={current_step === step}
        onClick={() => setCurrentStep(step)}
        label={
          <StepNavLink
            label={label}
            description={getStatusLabel(step)}
            color={getNavColor(step)}
          />
        }
      />
    )
  }

  return (
    <Box w={280}>
      <Text fw={700} fz="lg" ml="sm" mb="lg">
        Proceeding
      </Text>
      <StepItem label="1. Preparation" step={ScriptStep.PREPARATION} />
      <StepItem label="2. Attendance" step={ScriptStep.ATTENDANCE} />
      <StepItem label="3. Opening" step={ScriptStep.OPENING} />
      <StepItem label="4. Questioning" step={ScriptStep.QUESTIONING} />
      <StepItem label="5. Closing" step={ScriptStep.CLOSING} />
      <StepItem label="6. Review" step={ScriptStep.REVIEW} />
    </Box>
  )
}
