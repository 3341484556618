import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Box, Button } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'

import { ReporterEvent } from '@/types'

export const OverlappingRelatedEventDetail: React.FC<{
  event: ReporterEvent
}> = ({ event }) => {
  return (
    <Layout
      left={
        <>
          <Alert
            title="This event is not available for booking."
            color="orange"
          >
            You have already booked an event which overlaps with an event
            related to this one. As you can only book the whole group of related
            events, this event is not available for booking.
          </Alert>
          <Card>
            <Box m="md">
              <Button
                component={Link}
                to="/calendar"
                variant="light"
                color="gray"
              >
                Back to calendar view
              </Button>
            </Box>
          </Card>
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
