import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

export type UseBreakpoints = {
  md: boolean
  lg: boolean
  xl: boolean
  sm: boolean
  xs: boolean
}
export const useBreakpoints = (): UseBreakpoints => {
  const { breakpoints } = useMantineTheme()

  const md = useMediaQuery(`(min-width: ${breakpoints.md})`)
  const lg = useMediaQuery(`(min-width: ${breakpoints.lg})`)
  const xl = useMediaQuery(`(min-width: ${breakpoints.xl})`)
  const sm = useMediaQuery(`(min-width: ${breakpoints.sm})`)
  const xs = useMediaQuery(`(min-width: ${breakpoints.xs})`)

  return { md, lg, xl, sm, xs }
}
