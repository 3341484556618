import {
  ATTENDEE_NAME_IGNORED_WORDS,
  MAX_ATTENDEE_NAME_LENGTH,
} from '@/constants'
import { AttendeeFormValues } from '@/types'
import { isAttorney } from '@/utils/validations'

export const getAttendeesWarnings = (
  attendees: AttendeeFormValues[],
  userEmail?: string,
): {
  affectedFields: Set<keyof AttendeeFormValues>[]
  warnings: string[]
} => {
  const affectedFields = Array(attendees.length).fill(new Set())
  const warnings = []

  attendees.forEach((attendee, idx) => {
    // name shouldn't be too long
    if (attendee.name.length > MAX_ATTENDEE_NAME_LENGTH) {
      affectedFields[idx] = new Set([...affectedFields[idx], 'name'])
      warnings.push(`This attendee name looks quite long: “${attendee.name}”`)
    }

    // name should have at least 2 words
    if (attendee.name.split(' ').filter(word => word.length > 0).length === 1) {
      affectedFields[idx] = new Set([...affectedFields[idx], 'name'])
      warnings.push(
        `This name looks suspicious as it contains only one word: “${attendee.name}”`,
      )
    }

    // name shouldn't contain ignored word for speaker name shortening
    const ignoredWordsInName = attendee.name.split(' ').filter(word =>
      ATTENDEE_NAME_IGNORED_WORDS.includes(
        // remove punctuation and convert to lowercase
        word.replace(/[^\w\s']/, '').toLowerCase(),
      ),
    )
    if (ignoredWordsInName.length > 0) {
      affectedFields[idx] = new Set([...affectedFields[idx], 'name'])
      warnings.push(
        `This attendee name contains words: ${ignoredWordsInName
          .map(word => `“${word}”`)
          .join(', ')} which don’t look like a valid name: “${attendee.name}”`,
      )
    }
  })

  // there should be exactly one digital reporter, and it should be the current user
  const isReporter = (attendee: AttendeeFormValues) =>
    attendee.role === 'digital_reporter' || attendee.role === 'court_reporter'
  const reporters = attendees.filter(isReporter)
  if (reporters.length === 1) {
    if (reporters[0].email !== userEmail) {
      warnings.push(
        `The Reporter in the attendee list should be you: “${userEmail}”`,
      )
      attendees.map((attendee, idx) => {
        if (isReporter(attendee)) {
          affectedFields[idx] = new Set([...affectedFields[idx], 'email'])
        }
      })
    }
  } else {
    if (reporters.length === 0) {
      warnings.push('There is no Reporter attendee')
    } else {
      warnings.push(
        `There should always be only one Reporter attendee: ${reporters
          .map(({ name }) => `“${name}”`)
          .join(' vs. ')}`,
      )
    }
    attendees.map((attendee, idx) => {
      if (isReporter(attendee)) {
        affectedFields[idx] = new Set([...affectedFields[idx], 'role'])
      }
    })
  }

  // there should be exactly one witness
  const witnesses = attendees.filter(attendee => attendee.role === 'witness')
  if (witnesses.length !== 1) {
    if (witnesses.length === 0) {
      warnings.push('There is no Witness attendee')
    } else {
      warnings.push(
        `There should always be only one Witness attendee: ${witnesses
          .map(({ name }) => `“${name}”`)
          .join(' vs. ')}`,
      )
    }
    attendees.map((attendee, idx) => {
      if (attendee.role === 'witness') {
        affectedFields[idx] = new Set([...affectedFields[idx], 'role'])
      }
    })
  }

  // there shouldn't be duplicate names
  const attendeesNames = attendees.map(attendee => attendee.name)

  const duplicateNames = attendeesNames.filter(
    (name, idx) => attendeesNames.indexOf(name) !== idx,
  )

  if (duplicateNames.length > 0) {
    duplicateNames.forEach(name =>
      warnings.push(`This attendee is duplicated: “${name}”`),
    )
    attendees.map((attendee, idx) => {
      if (duplicateNames.includes(attendee.name)) {
        affectedFields[idx] = new Set([...affectedFields[idx], 'name'])
      }
    })
  }

  // all counsels should have email
  if (
    attendees.some(attendee => isAttorney(attendee.role) && !attendee.email)
  ) {
    warnings.push(
      'Provide the email address for all counsels present in the event',
    )
    attendees.map((attendee, idx) => {
      if (isAttorney(attendee.role) && !attendee.email) {
        affectedFields[idx] = new Set([...affectedFields[idx], 'email'])
      }
    })
  }

  return { affectedFields, warnings }
}
