import { useState } from 'react'
import { showNotification } from '@mantine/notifications'
import { AxiosError } from 'axios'

import { useCreateInitialScriptState } from '@/components/Event/NewScript/hooks/state/useCreateInitialScriptState'
import {
  ScriptActions,
  useScriptActions,
} from '@/components/Event/NewScript/hooks/state/useScriptActions'
import {
  useGetScriptDataQuery,
  useSaveScriptDataMutation,
  useSubmitScriptDataMutation,
} from '@/components/Event/NewScript/queries/scriptData'
import { useReporter } from '@/providers/UserProvider'
import { DRScriptData, Event } from '@/types'

type HookResult = {
  isLoading: boolean
  isError: boolean
  state: DRScriptData
  actions: ScriptActions
  submitScript: (data: DRScriptData) => any
  isSubmittingScript: boolean
  refetchState: () => any
}

export const useScriptState = (event: Event): HookResult => {
  const [stateError, setStateError] = useState<boolean>(false)
  const reporter = useReporter()
  const { initScriptData, isLoading: isInitializing } =
    useCreateInitialScriptState({
      event,
      reporter,
    })
  const {
    data: state,
    isLoading,
    refetch: refetchState,
  } = useGetScriptDataQuery(event, {
    enabled: !!event,
    staleTime: Infinity,
    onError: (error: AxiosError) => {
      if (error.response?.status === 404) {
        return initScriptData()
          .then(() => {
            refetchState()
          })
          .catch(() => {
            setStateError(true)
          })
      }
    },
  })

  const { mutate: saveScriptData } = useSaveScriptDataMutation(event!, {
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message:
          'Error while uploading to the server, please try to refresh the page and try again',
      })
    },
  })

  const { mutateAsync: submitScriptMutation, isLoading: isSubmittingScript } =
    useSubmitScriptDataMutation(event!, {
      onError: () => {
        showNotification({
          color: 'red',
          title: 'Error',
          message:
            'Could not submit the script, please refresh the page and try again',
        })
      },
    })

  const submitScript = (data: DRScriptData) => {
    return submitScriptMutation({ ...data, submitted: true })
  }

  const actions = useScriptActions({
    uploadState: saveScriptData,
    state: state!,
  })

  return {
    isLoading: isLoading || isInitializing,
    isError: stateError,
    state: state!, // TODO: figure out how to handle this
    actions,
    submitScript,
    isSubmittingScript,
    refetchState,
  }
}
