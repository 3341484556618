import React from 'react'
import { Text, TextProps } from '@mantine/core'

export const StepText: React.FC<Omit<TextProps, 'fz' | 'fw'>> = ({
  children,
  ...props
}: TextProps) => {
  return (
    <Text fz="sm" fw={600} {...props}>
      {children}
    </Text>
  )
}
