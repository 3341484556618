import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingOverlay } from '@mantine/core'

import { ScriptScreen } from '@/components/ScriptScreen'
import { Shell } from '@/components/Shell'
import { useAdminEventQuery } from '@/queries/adminEvents'
import { useReporterQuery } from '@/queries/reporters'

export const Script: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data: event, isLoading: isEventLoading } = useAdminEventQuery(id)
  const { data: reporter, isLoading: isReporterLoading } = useReporterQuery(
    event?.booking?.reporter_id,
  )

  if (
    isEventLoading ||
    isReporterLoading ||
    event === undefined ||
    reporter === undefined
  ) {
    return (
      <Shell>
        <LoadingOverlay visible />
      </Shell>
    )
  }

  return <ScriptScreen event={event} reporter={reporter} />
}
