import React from 'react'
import { Stack, Text } from '@mantine/core'

import { CancelEventBookingButton } from '@/components/Event/CancelEventBookingButton'
import { Card } from '@/components/Event/Detail/components/Card'
import { UncancellableBookingNotice } from '@/components/Event/UncancellableBookingNotice'
import { Event } from '@/types'

export const CancelBookingCard: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  return (
    <Card heading="Do you need to cancel booking?">
      {event.is_cancelable ? (
        <Stack>
          <Text size="sm">
            You can cancel a booking up to 2 business days before the event.
          </Text>

          <CancelEventBookingButton event={event} onCancel={onChange} />
        </Stack>
      ) : (
        <UncancellableBookingNotice />
      )}
    </Card>
  )
}
