import React from 'react'
import { Stack } from '@mantine/core'

import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { IdentityDocumentInput } from '@/components/Event/NewScript/components/common/IdentityDocumentCard/IdentityDocumentInput'
import {
  ActionContainer,
  ActionText,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { IdentityDocumentStatus } from '@/constants'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  witness: ScriptAttendee
  namePrefix: string
  attendeeChanged: (attendee: ScriptAttendee) => void
  event: Event
  isPresenceDisabled: boolean
}

export const WitnessInput: React.FC<Props> = ({
  witness,
  namePrefix,
  attendeeChanged,
  event,
  isPresenceDisabled,
}) => {
  const { identity_document: identityDocument } = witness

  return (
    <>
      <ActionContainer outer>
        <Stack>
          <ActionText mono>
            Would the witness please identify themselves and spell their last
            name for the record?
          </ActionText>
          <AttendeeCard
            isPresenceDisabled={isPresenceDisabled}
            event={event}
            attendee={witness}
            onSubmitClick={attendeeChanged}
            namePrefix={namePrefix}
          />
        </Stack>
      </ActionContainer>
      <ActionContainer outer>
        <Stack>
          <ActionText mono>
            Mr/Ms. {witness.name}, can you please hold a valid government photo
            identification up to the screen to verify your identity?
          </ActionText>
          <ActionContainer>
            <IdentityDocumentInput
              attendee={witness}
              namePrefix={`${namePrefix}.identity_document`}
              hideButton
              showWarnings
              onSubmitClick={attendeeChanged}
            />
          </ActionContainer>
        </Stack>
      </ActionContainer>
      {identityDocument?.status === IdentityDocumentStatus.EXPIRED && (
        <ActionContainer outer>
          <ActionText mono>
            For the record, the witness has presented an expired ID for
            verification. Accordingly, do both sides stipulate to my
            administering the oath to this witness.
          </ActionText>
        </ActionContainer>
      )}
      {[
        IdentityDocumentStatus.NOT_PRESENT,
        IdentityDocumentStatus.STIPULATE,
      ].includes(identityDocument?.status as IdentityDocumentStatus) && (
        <ActionContainer outer>
          <ActionText mono>
            For the record, the witness has not presented an ID for
            verification. Accordingly, do both sides stipulate to my
            administering the oath to this witness.
          </ActionText>
        </ActionContainer>
      )}
    </>
  )
}
