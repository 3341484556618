import React, { useEffect, useState } from 'react'
import { Button, Group, Title } from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { showNotification } from '@mantine/notifications'
import { CalendarIcon } from '@radix-ui/react-icons'
import Interval from 'date-fns/esm'

import { EventPopover } from '@/components/Calendar/EventPopover'
import { CalendarScreen } from '@/components/CalendarScreen'
import { useForm } from '@/hooks/useForm'
import {
  Availability as IAvailability,
  useAvailabilityQuery,
  useCreateAvailabilityMutation,
  useRemoveAvailabilityMutation,
  useUpdateAvailabilityMutation,
} from '@/queries/availabilities'
import { formatTimeRange } from '@/utils/time'

export const Availability = () => {
  const [interval, setInterval] = useState<Interval>()

  const { data: availabilities, refetch } = useAvailabilityQuery(interval)
  const { mutateAsync: createAvailability } = useCreateAvailabilityMutation()

  useEffect(() => {
    showNotification({
      title: 'Setting your availability',
      message:
        'Select the times when you are available to work so the administrator can assign you to events accordingly. Drag and drop in the calendar… It’s good to set you work schedule 2-3 weeks ahead',
      autoClose: 15 * 1000, // the text is pretty long
    })
  }, [])

  return (
    <CalendarScreen
      events={
        availabilities?.map(availability => ({
          start: availability.starts_at,
          end: availability.ends_at,
          extendedProps: availability,
        })) ?? []
      }
      initialDate={new Date()}
      initialView="week"
      selectable={true}
      select={e => {
        createAvailability({
          starts_at: e.startStr,
          ends_at: e.endStr,
        }).then(() => refetch())
      }}
      onStateChange={({ interval }) => setInterval(interval)}
      renderEvent={value => (
        <AvailabilityEvent
          availability={value as IAvailability}
          onUpdate={refetch}
        />
      )}
      header={{ middle: <Title order={6}>My availability</Title> }}
    />
  )
}

export type AvailabilityEventValues = {
  starts_at: string
  ends_at: string
}

const AvailabilityEvent: React.FC<{
  availability: IAvailability
  onUpdate: () => void
}> = ({ availability, onUpdate }) => {
  const { mutateAsync: updateAvailability } = useUpdateAvailabilityMutation()

  const form = useForm<AvailabilityEventValues>({
    initialValues: {
      starts_at: availability.starts_at,
      ends_at: availability.ends_at,
    },
  })

  return (
    <EventPopover
      handle={
        <div className="bg-blue-200 rounded h-full overflow-hidden">
          {availability.duration <= 60 && (
            <div className="px-2 py-0.5">
              <div className="text-blue-500 font-medium">
                {formatTimeRange(availability.starts_at, availability.duration)}
              </div>
            </div>
          )}
          {availability.duration > 60 && (
            <div className="p-2">
              <div className="text-blue-500 font-medium">My available time</div>
              <div className="text-blue-500">
                {formatTimeRange(availability.starts_at, availability.duration)}
              </div>
            </div>
          )}
        </div>
      }
      header={<div>My available time</div>}
    >
      <form
        onSubmit={form.onSubmit((values: AvailabilityEventValues) =>
          updateAvailability({
            ...availability,
            starts_at: values.starts_at,
            ends_at: values.ends_at,
          }).then(onUpdate),
        )}
      >
        <div className="mb-12">
          <div className="flex mb-3">
            <div className="w-6 pt-1 mr-2">
              <CalendarIcon />
            </div>
            <div className="flex-1 space-y-2">
              <TimeInput
                label="Since"
                value={form.values.starts_at}
                onChange={value =>
                  form.setFieldValue('starts_at', value.target.value)
                }
                description="Please enter the time of day in a 24-hour format"
              />
              <TimeInput
                label="Until"
                value={form.values.ends_at}
                onChange={value =>
                  form.setFieldValue('ends_at', value.target.value)
                }
                description="Please enter the time of day in a 24-hour format"
              />
            </div>
          </div>
        </div>

        <Group position="right">
          <DeleteButton availability={availability} onDelete={onUpdate} />

          <Button type="submit" loading={form.submitting}>
            Update
          </Button>
        </Group>
      </form>
    </EventPopover>
  )
}

const DeleteButton: React.FC<{
  availability: IAvailability
  onDelete: () => void
}> = ({ availability, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const { mutateAsync: removeAvailability } = useRemoveAvailabilityMutation()

  return (
    <Button
      onClick={() => {
        setIsDeleting(true)

        removeAvailability(availability)
          .then(() => {
            setIsDeleting(false)

            onDelete()
          })
          .catch(() => {
            setIsDeleting(false)
          })
      }}
      variant="light"
      color="gray"
      loading={isDeleting}
    >
      Remove
    </Button>
  )
}
