import * as yup from 'yup'

export const zoomParticipantSchema = yup.object().shape({
  id: yup.string().required(),
  user_id: yup.string().required(),
  participant_user_id: yup.string().nullable(),
  meeting_instance_id: yup.string().required(),
  user_name: yup.string().required(),
  join_time: yup.string().required(),
  email: yup.string().nullable(),
  phone_number: yup.string().nullable(),
  created: yup.string(),
})
