import React from 'react'
import { Flex, Grid, Group, Stack, Text } from '@mantine/core'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import {
  ATTENDEE_ROLE_LABEL,
  AttendeeRole,
  IDENTITY_DOCUMENT_STATUS_LABEL,
  IDENTITY_DOCUMENT_TYPE_LABEL,
  IdentityDocumentStatus,
  IdentityDocumentType,
} from '@/constants'
import { ScriptAttendee } from '@/types'

type Props = {
  attendee: ScriptAttendee
  onEditClick?: () => void
}

export const IdentityDocumentView: React.FC<Props> = ({
  attendee,
  onEditClick,
}) => {
  const { identity_document: identityDocument } = attendee

  const getDocumentTypeLabel = (documentType?: IdentityDocumentType) => {
    return documentType ? IDENTITY_DOCUMENT_TYPE_LABEL[documentType] : 'N/A'
  }

  const getAttendeeRoleLabel = (attendeeRole: AttendeeRole) => {
    return ATTENDEE_ROLE_LABEL[attendeeRole]
  }

  const getIdentityDocumentStatusLabel = (status?: IdentityDocumentStatus) => {
    return status ? IDENTITY_DOCUMENT_STATUS_LABEL[status] : 'N/A'
  }

  const isStatusNotPresentOrStipulate = [
    IdentityDocumentStatus.NOT_PRESENT,
    IdentityDocumentStatus.STIPULATE,
  ].includes(identityDocument?.status as IdentityDocumentStatus)

  return (
    <Grid grow>
      <Grid.Col span={5}>
        <Stack spacing={0}>
          <Text size="sm" fw={600}>
            {attendee.name}
          </Text>
          <Text size="sm">{getAttendeeRoleLabel(attendee.role)}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={5}>
        {identityDocument ? (
          <Stack spacing={0}>
            <Group>
              <Group spacing={5}>
                <Text size="sm">
                  {getDocumentTypeLabel(identityDocument.document_type)}
                </Text>
                {identityDocument.number ? (
                  <Text size="sm">({identityDocument.number})</Text>
                ) : !isStatusNotPresentOrStipulate ? (
                  <Text color="red" size="sm">
                    <Group spacing={5}>
                      <ExclamationTriangleIcon />
                      <span>Missing number</span>
                    </Group>
                  </Text>
                ) : null}
              </Group>
            </Group>
            <Text size="sm">
              {getIdentityDocumentStatusLabel(identityDocument.status)}
            </Text>
          </Stack>
        ) : (
          <Text color="red">Missing Identity Document</Text>
        )}
      </Grid.Col>
      <Grid.Col span={2}>
        <Flex align="center" justify="end" h={'100%'} w="100%">
          <SubtleButton p={0} onClick={onEditClick}>
            Edit
          </SubtleButton>
        </Flex>
      </Grid.Col>
    </Grid>
  )
}
