import React from 'react'
import { Anchor, Box, Button, Text, useMantineTheme } from '@mantine/core'
import { useClipboard, useMediaQuery } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'

import { useModals } from '@/hooks/useModals'
import { useJoinInfoQuery } from '@/queries/events'
import { Event, JoinInfo } from '@/types'

export const useConnectionInfoModal = (event: Event) => {
  const modals = useModals()
  const clipboard = useClipboard()
  const {
    refetch,
    isFetching,
    isFetched,
    data: joinInfoData,
  } = useJoinInfoQuery(event, { enabled: false })
  const { breakpoints } = useMantineTheme()
  const isMdScreen = useMediaQuery(`(min-width: ${breakpoints.md})`)

  const onCopyClicked = (
    joinInfo: JoinInfo,
    oneTapMeetingInformationSuffix: string,
  ) => {
    clipboard.copy(`
Join Zoom Meeting
${joinInfo.join_url}

Meeting ID: ${joinInfo.join_code}
${joinInfo.join_password ? `Passcode: ${joinInfo.join_password}` : ''}
    
One tap mobile
+12532158782,,${oneTapMeetingInformationSuffix} US (New York)
+19294362866,,${oneTapMeetingInformationSuffix} US (Tacoma)
    
Dial by your location
  +1 929 536 2866 US (New York)
  +1 253 215 8782 US (Tacoma)
  +1 301 715 8592 US (Washington DC)
  +1 312 626 6799 US (Chicago)
  +1 346 248 7799 US (Houston)
  +1 669 900 6833 US (San Jose)
      
Find your local number: https://parrothq.zoom.us/u/kM3dLUwwo
`)
    showNotification({
      message: 'Connection information copied to clipboard',
    })
  }

  const openModal = (joinInfo?: JoinInfo) => {
    modals.closeAll()

    if (!joinInfo) {
      return showNotification({
        color: 'red',
        message:
          'Could not retrieve connection information. Please try again later',
      })
    }

    const oneTapMeetingInformationSuffix = `${joinInfo.join_code}#${
      joinInfo.join_password ? `,,,,*${joinInfo.join_password}#` : ''
    }`

    modals.openModal({
      title: 'Connection Info',
      children: (
        <div>
          <Box mb="md">
            <Box mb="xs">
              <Text weight="bold">Dial by location</Text>
            </Box>
            <Text>
              +1 929 436 2866{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (New York)
              </Text>
            </Text>
            <Text>
              +1 253 215 8782{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (Tacoma)
              </Text>
            </Text>
            <Text>
              +1 301 715 8592{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (Washington DC)
              </Text>
            </Text>
            <Text>
              +1 312 626 6799{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (Chicago)
              </Text>
            </Text>
            <Text>
              +1 346 248 7799{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (Houston)
              </Text>
            </Text>
            <Text>
              +1 669 900 6833{' '}
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                (San Jose)
              </Text>
            </Text>
          </Box>

          <Box mb="md">
            <Box mb="xs">
              <Text weight="bold">Meeting ID</Text>
            </Box>
            {joinInfo.join_code}
          </Box>

          <Box mb="md">
            <Box mb="xs">
              <Text weight="bold">Passcode</Text>
            </Box>
            {joinInfo.join_password}
          </Box>

          <Box mb="md">
            <Box mb="xs">
              <Text weight="bold">One tap mobile</Text>
            </Box>
            <Text>
              <Anchor
                href={`tel:+19294362866,,${oneTapMeetingInformationSuffix} US`}
              >
                {`+19294362866,,${oneTapMeetingInformationSuffix} US`}
              </Anchor>
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                {' '}
                (New York)
              </Text>
            </Text>
            <Text>
              <Anchor
                href={`tel:+12532158782,,${oneTapMeetingInformationSuffix}`}
              >
                {`+12532158782,,${oneTapMeetingInformationSuffix} US`}
              </Anchor>
              <Text
                component="span"
                sx={theme => ({ color: theme.colors.gray[6] })}
              >
                {' '}
                (Tacoma)
              </Text>
            </Text>
          </Box>

          <Box mb="md">
            <Box mb="xs">
              <Text weight="bold">Link</Text>
              <Anchor href={joinInfo.join_url || '#'}>
                {joinInfo.join_url}
              </Anchor>
            </Box>
          </Box>

          <div className="flex gap-x-2 mt-8 justify-end">
            <Button
              variant="light"
              color="gray"
              onClick={() => modals.closeAll()}
            >
              Cancel
            </Button>
            <Button
              variant="light"
              color="gray"
              onClick={() =>
                onCopyClicked(joinInfo, oneTapMeetingInformationSuffix)
              }
            >
              Copy info
            </Button>
          </div>
        </div>
      ),
      size: isMdScreen ? 'lg' : 'sm',
      centered: true,
      onClose: () => modals.closeAll(),
    })
  }

  const fetchAndOpenModal = () => {
    if (isFetched && joinInfoData) {
      return openModal(joinInfoData)
    }

    refetch().then(r => openModal(r.data))
  }

  return { fetchAndOpenModal, isFetching }
}

export const ConnectionInformationButton: React.FC<{ event: Event }> = ({
  event,
}) => {
  const { isFetching, fetchAndOpenModal } = useConnectionInfoModal(event)

  return (
    <Button
      variant="light"
      color="gray"
      onClick={fetchAndOpenModal}
      loading={isFetching}
    >
      {isFetching ? 'Dial-i...' : 'Dial-in Info'}
    </Button>
  )
}
