import React from 'react'
import { Alert, Group, Stack, Text } from '@mantine/core'

import { Event } from '@/types'

export const EventRow: React.FC<{
  event: Event
  subtitle: React.ReactNode
  active?: boolean
  right?: React.ReactNode
}> = ({ event, subtitle, active = false, right }) => {
  return (
    <Alert color={active ? 'indigo' : 'gray'} className="w-full">
      <Group position="apart">
        <Stack spacing={0}>
          <Text>{event.name}</Text>
          <Text size="xs" color="gray">
            {subtitle}
          </Text>
        </Stack>
        {right}
      </Group>
    </Alert>
  )
}
