import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import axios from 'axios'

import * as urls from './urls'

import { ReporterEvent } from '@/types'

export const useWaitingReporterEventsQuery = (
  reporter_id: string | undefined,
) =>
  useQuery(
    'waiting_events',
    () =>
      axios
        .get<ReporterEvent[]>(urls.reporterWaitingEvents(reporter_id!))
        .then(r => r.data),
    { enabled: reporter_id !== undefined },
  )

export const useReporterEventQuery = (
  id: string,
  reporter_id: string | undefined,
  options?: Omit<
    UseQueryOptions<ReporterEvent, unknown, ReporterEvent>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ReporterEvent>(
    ['event', id],
    () => axios.get(urls.reporterEvent(reporter_id!, id!)).then(r => r.data),
    { ...options, enabled: reporter_id !== undefined },
  )

export const useAvailableReporterEventsQuery = (
  reporter_id: string | undefined,
) =>
  useQuery(
    'available_events',
    () =>
      axios
        .get<ReporterEvent[]>(urls.reporterAvailableEvents(reporter_id!))
        .then(r => r.data),
    { enabled: reporter_id !== undefined },
  )
