import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Anchor,
  Checkbox,
  CheckboxIcon,
  CheckboxProps,
  Popover,
  Stack,
  Text,
  TimelineItem,
  TimelineItemProps,
  Title,
  Tooltip,
} from '@mantine/core'

export type TimelineActionProps = {
  title: string
  actionKey: string
  onComplete: (key: string) => void
  completed: boolean
  collapsible?: boolean
} & Omit<TimelineItemProps, 'bullet'>

// We need to override the indeterminate value for the icon
// so we can show the icon when hovering over the checkbox
const MyCheckboxIcon: CheckboxProps['icon'] = ({
  indeterminate,
  className,
}: {
  indeterminate: boolean
  className: string
}) =>
  indeterminate ? (
    <CheckboxIcon indeterminate={false} className={className} />
  ) : (
    <CheckboxIcon indeterminate={false} className={className} />
  )

// the rest is there in order the ScriptTimeline work properly
// because wrapping TimelineItem is not supported out of the box
export const TimelineAction: React.FC<TimelineActionProps> = ({
  title,
  actionKey,
  onComplete,
  completed,
  children,
  collapsible = true,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(completed)
  const [showError, setShowError] = useState<boolean>(false)

  const notifyAboutError = () => {
    setShowError(true)
    setTimeout(() => setShowError(false), 2000)
  }

  const handleCheckedEvent = (event: ChangeEvent<HTMLInputElement>) => {
    onComplete(event.target.value)

    if (completed) {
      setCollapsed(true)
      return
    }

    notifyAboutError()
  }

  const toggleCollapsed = () => {
    setCollapsed(prev => !prev)
  }

  useEffect(() => {
    setCollapsed(completed)
  }, [completed])

  return (
    <TimelineItem
      bullet={
        <Popover
          position="bottom"
          withArrow
          shadow="md"
          disabled={completed}
          opened={showError}
          withinPortal={true}
          zIndex={10}
        >
          <Popover.Target>
            <Tooltip
              label="Acknowledge you completed this task."
              openDelay={700}
              disabled={completed}
            >
              <Checkbox
                value={actionKey}
                onChange={handleCheckedEvent}
                checked={completed}
                indeterminate={!completed}
                icon={MyCheckboxIcon}
                radius="xl"
                size="md"
                sx={theme => ({
                  input: {
                    borderColor: theme.colors.indigo[7],
                    borderWidth: '2px',
                    cursor: 'pointer',
                    ...(!completed && {
                      '&:hover': {
                        backgroundColor: theme.colors.indigo[2],
                      },
                    }),
                    inner: {
                      '&:checked': {
                        backgroundColor: theme.colors.indigo[7],
                      },
                    },
                  },
                })}
              />
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown bg="red.3">
            <Text size="md" color="gray.9">
              Please fix the highlighted errors before completing the step
            </Text>
          </Popover.Dropdown>
        </Popover>
      }
      {...rest}
    >
      <Stack>
        <Tooltip
          openDelay={200}
          position="top-start"
          disabled={!collapsible}
          label={
            collapsed
              ? 'Click to open the action content'
              : 'Click to collapse action content'
          }
        >
          <Anchor onClick={toggleCollapsed} underline={collapsible}>
            <Title order={5} weight={600} color="indigo.7">
              {title}
            </Title>
          </Anchor>
        </Tooltip>
        {!collapsed && children}
      </Stack>
    </TimelineItem>
  )
}
