import React from 'react'
import { Alert } from '@mantine/core'

export const ActionItem: React.FC = ({ children }) => {
  return (
    <Alert
      icon={<div>&#x2705;</div>}
      styles={{ root: { backgroundColor: 'transparent !important' } }}
    >
      {children}
    </Alert>
  )
}
