import React from 'react'
import { Tabs } from '@mantine/core'

import { Details } from '@/components/Event/NewScript/components/common/Layout/Details'
import { StepsNavigation } from '@/components/Event/NewScript/components/common/Layout/StepsNavigation'
import { Event } from '@/types'

type ScriptTabsLayout = {
  event: Event
  showNavigation?: boolean
}

export const ScriptTabsLayout: React.FC<ScriptTabsLayout> = ({
  event,
  children,
  showNavigation = true,
}) => {
  return (
    <Tabs defaultValue="script">
      <Tabs.List position="center">
        <Tabs.Tab value="script">Script</Tabs.Tab>
        <Tabs.Tab value="details">Details</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="script" p="xl">
        {showNavigation && <StepsNavigation event={event} collapsed />}
        {children}
      </Tabs.Panel>

      <Tabs.Panel value="details" p="xl">
        <Details event={event} />
      </Tabs.Panel>
    </Tabs>
  )
}
