import React from 'react'
import { Alert, LoadingOverlay, Stack, Text } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'

import { BookEventButton } from '@/components/Event/BookEventButton'
import {
  hasHearingDocket,
  HearingDocket,
} from '@/components/Event/Detail/components/HearingDocket'
import { RelatedEventsNotice } from '@/components/Event/RelatedEventsNotice'
import { useRelatedEvents } from '@/queries/relatedEvents'
import { Event } from '@/types'
import { formatDuration } from '@/utils/time'

export const AvailableEventDetail: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  const { data: relatedEvents, isLoading } = useRelatedEvents(event)

  if (isLoading || relatedEvents === undefined) {
    return <LoadingOverlay visible={true} />
  }

  return (
    <Layout
      left={
        <>
          <Alert title="Event is not booked yet" color="teal">
            This event is available for booking.
          </Alert>

          {hasHearingDocket(event) && <HearingDocket event={event} />}

          {event.has_related_events ? (
            <Card
              heading={`Book the ${event.related_events_type} events`}
              highlighted
            >
              <Stack align="flex-start">
                <Text size="sm">
                  Expected duration is {formatDuration(event.duration)} (event
                  can take longer).
                </Text>

                <RelatedEventsNotice
                  event={event}
                  action="book"
                  relatedEvents={relatedEvents}
                />

                <BookEventButton
                  event={event}
                  onBook={onChange}
                  text={`Book all ${event.related_events_type} events`}
                />
              </Stack>
            </Card>
          ) : (
            <Card heading="Book this event" highlighted>
              <Stack align="flex-start">
                <Text size="sm">
                  Expected duration is {formatDuration(event.duration)} (event
                  can take longer).
                </Text>

                <BookEventButton event={event} onBook={onChange} />
              </Stack>
            </Card>
          )}
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
