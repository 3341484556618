import React from 'react'

import { Header, HeaderProps } from '@/components/Header'
import { HEADER_HEIGHT } from '@/constants'

export const Shell: React.FC<{
  header?: HeaderProps
  className?: string
}> = ({ children, header, className }) => {
  return (
    <div className={className}>
      <Header {...header} />
      <div style={{ marginTop: HEADER_HEIGHT }}>{children}</div>
    </div>
  )
}
