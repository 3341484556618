import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { TIME_FORMAT } from '@/constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

type Timezone = string | null

type FormattedDateParts = {
  day: string
  date: string
  month: string
  year: string
  time: string
  timezone: string
  partOfTheDay: string
}

const getFormattedDateParts = (
  timezone: Timezone | null,
): FormattedDateParts => {
  const date = timezone ? dayjs().tz(timezone) : dayjs()

  return {
    day: date.format('dddd'),
    date: date.format('DD'),
    month: date.format('MMMM'),
    year: date.format('YYYY'),
    time: date.format(TIME_FORMAT),
    timezone: date.format('z'),
    partOfTheDay: date.hour() < 12 ? 'morning' : 'afternoon',
  }
}

export const useFormattedCurrentDateParts = (
  timezone: Timezone = null,
): FormattedDateParts => {
  const [startFormatted, setStartFormatted] = useState<FormattedDateParts>(() =>
    getFormattedDateParts(timezone),
  )
  const intervalRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setStartFormatted(getFormattedDateParts(timezone))
    }, 1000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [timezone])

  return startFormatted
}
