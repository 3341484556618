import React from 'react'
import { Flex } from '@mantine/core'

import { StepText } from '@/components/Event/NewScript/components/common/Stepper/StepText'

export const StepNavLink: React.FC<{
  label: string
  description: string
  color?: string
}> = ({ label, description, color, ...props }) => {
  return (
    <Flex justify="space-between" {...props}>
      <StepText>{label}</StepText>
      <StepText color={color}>{description}</StepText>
    </Flex>
  )
}
