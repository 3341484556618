import * as yup from 'yup'

export const preparationStepSchema = yup.object().shape({
  isEventOngoing: yup
    .boolean()
    .oneOf(
      [true],
      'First, you have to join the call. You can use the button above.',
    ),
})
