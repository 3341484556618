import { useMemo } from 'react'
import debounce from 'lodash/debounce'

import {
  ALL_ACTIONS,
  StateActions,
} from '@/components/Event/NewScript/hooks/state/stateActions'
import { DRScriptData } from '@/types'

export type ScriptActions = {
  [key in keyof StateActions]: (data?: any) => DRScriptData
}

export type HookProps = {
  state: DRScriptData
  uploadState: (data: DRScriptData) => any
}

export const useScriptActions = ({
  state,
  uploadState,
}: HookProps): ScriptActions => {
  const uploadDebounced = useMemo(
    () => debounce(uploadState, 500, { leading: true }),
    [uploadState],
  )
  // Wrap state mutation functions with uploadState to upload the state to the server
  // This is done to stay completable with the original implementation when we had little-state-machine
  return useMemo((): ScriptActions => {
    const result = {} as ScriptActions
    for (const [key, action] of Object.entries(ALL_ACTIONS)) {
      result[key as keyof ScriptActions] = (data: any) => {
        const newState = action(state, data)
        uploadDebounced(newState)
        return newState
      }
    }

    return result
  }, [state, uploadDebounced])
}
