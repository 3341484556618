import React from 'react'
import { Button, Center, Group, Paper, Stack, Text } from '@mantine/core'
import { FileTextIcon } from '@radix-ui/react-icons'

export const FilesModal: React.FC<{
  files: { key: string; url: string; name: string }[]
  empty: React.ReactNode
  onClose: () => void
}> = ({ files, empty, onClose }) => {
  return (
    <Stack>
      <Stack>
        {files.length === 0 && (
          <Paper p="xl">
            <Center>
              <Text size="sm">{empty}</Text>
            </Center>
          </Paper>
        )}
        {files.map(file => (
          <Button
            key={file.key}
            component="a"
            href={file.url}
            target="_blank"
            variant="light"
            color="gray"
            fullWidth
            leftIcon={<FileTextIcon />}
            // align button text to the left
            sx={{ '> div': { justifyContent: 'start' } }}
          >
            {file.name}
          </Button>
        ))}
      </Stack>

      <Group position="right">
        <Button variant="light" color="gray" onClick={onClose}>
          Close
        </Button>
      </Group>
    </Stack>
  )
}
