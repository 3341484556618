import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingOverlay } from '@mantine/core'

import { ScriptScreen } from '@/components/ScriptScreen'
import { Shell } from '@/components/Shell'
import { useReporter } from '@/providers/UserProvider'
import { useReporterEventQuery } from '@/queries/reporterEvents'

export const Script: React.FC = () => {
  const reporter = useReporter()
  const { id } = useParams<{ id: string }>()
  const { data: event, isLoading: isEventLoading } = useReporterEventQuery(
    id,
    reporter.id,
  )

  if (isEventLoading || event === undefined) {
    return (
      <Shell>
        <LoadingOverlay visible />
      </Shell>
    )
  }

  return <ScriptScreen event={event} reporter={reporter} />
}
