import React from 'react'
import { Text } from '@mantine/core'

import { Attendee } from '@/types'

export const AttorneyList: React.FC<{ attorneys: Attendee[] }> = ({
  attorneys,
}) => (
  <>
    {attorneys.length > 0
      ? attorneys.map((a, idx) => (
          <span key={a.id}>
            {idx !== 0 && ', '}
            <Text
              component="span"
              size="sm"
              weight={a.is_attorney_who_is_our_customer ? 'bold' : 'normal'}
            >
              {a.name}
            </Text>
          </span>
        ))
      : 'Not recognized'}
  </>
)
