import React, { useEffect, useState } from 'react'
import {
  Controller,
  FieldErrorsImpl,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  Box,
  Group,
  MultiSelect,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import get from 'lodash/get'

import { BooleanSelect } from '@/components/common/BooleanSelect'
import { SelectInput } from '@/components/common/SelectInput'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { FormWarnings } from '@/components/Event/NewScript/components/common/forms/FormWarnings'
import { useAttendeeWarnings } from '@/components/Event/NewScript/hooks/form/useAttendeeWarnings'
import { useInputWarnings } from '@/components/Event/NewScript/hooks/form/useInputWarningStyle'
import {
  getFieldName,
  transformToZoomParticipants,
} from '@/components/Event/NewScript/utils/attendees'
import { ATTENDEE_ROLE_LABEL, EventType } from '@/constants'
import { ScriptAttendee, ZoomParticipant } from '@/types'
import { getAttendeeRolesForEvent } from '@/utils/parrot'
import { formatTimeForTimezone } from '@/utils/time'

type Props = {
  zoomParticipants: ZoomParticipant[]
  namePrefix?: string
  timezone: string
  eventType: EventType
  isPresenceDisabled: boolean
}

export const AttendeeForm = ({
  zoomParticipants,
  namePrefix,
  timezone,
  eventType,
  isPresenceDisabled,
}: Props) => {
  const { register, control, trigger, getValues, setValue, formState } =
    useFormContext()
  const attendeeData = useWatch({ control })
  const isPresent = useWatch({
    control,
    name: getFieldName('present', namePrefix),
  })
  const email = useWatch({ control, name: getFieldName('email', namePrefix) })

  const { error } = control.getFieldState(
    getFieldName('', namePrefix),
    formState,
  ) as ReturnType<typeof control.getFieldState> & {
    error: FieldErrorsImpl<ScriptAttendee>
  }

  const { warnings, getWarningsMessages } = useAttendeeWarnings(
    namePrefix ? get(attendeeData, namePrefix ?? '') : attendeeData,
    attendeeData?.['attendees'],
  )

  const { getInputWarningProps } = useInputWarnings(warnings)
  const [selectedZoomParticipants, setSelectedZoomParticipants] = useState<
    string[]
  >(() => {
    // get current participants mapping
    const attendeeZoomParticipants = getValues(
      getFieldName('zoom_participants', namePrefix),
    )
    return attendeeZoomParticipants.map((zp: ZoomParticipant) => zp.id)
  })

  // automatically trim email value to avoid validation fail due leading or trailing spaces
  useEffect(() => {
    setValue(getFieldName('email', namePrefix), email ? email.trim() : email)
  }, [email, setValue, namePrefix])

  return (
    <Stack align="stretch">
      <Group position="center" grow>
        <TextInput
          label="Name"
          withAsterisk
          {...register(getFieldName('name', namePrefix))}
          error={!!error?.name?.message}
          {...getInputWarningProps('name')}
        />
        <TextInput
          label="Email"
          {...register(getFieldName('email', namePrefix))}
          error={!!error?.email?.message}
          {...getInputWarningProps('email')}
        />
      </Group>
      <Group position="center" grow>
        <SelectInput
          control={control}
          name={getFieldName('role', namePrefix)}
          label="Role"
          placeholder="Select option"
          error={!!error?.role?.message}
          data={getAttendeeRolesForEvent({
            type: eventType,
            is_court_reporter_event: false,
          }).map(e => ({
            value: e,
            label: ATTENDEE_ROLE_LABEL[e],
          }))}
          withAsterisk
          withinPortal
          {...getInputWarningProps('role')}
        />
        <Controller
          control={control}
          name={getFieldName('present', namePrefix)}
          render={({ field: { onChange, value } }) => (
            <Tooltip
              label="You can't change this because the attendance step has already been completed."
              multiline
              withArrow
              disabled={!isPresenceDisabled}
            >
              {/* added div because tooltip doesn't work with disabled input */}
              <div>
                <BooleanSelect
                  withAsterisk
                  disabled={isPresenceDisabled}
                  value={value}
                  onChange={value => {
                    onChange(value)
                    if (value === false) {
                      trigger(getFieldName('zoom_participants', namePrefix))
                    }
                  }}
                  placeholder="Select option"
                  label="Joined Zoom"
                  error={!!error?.present?.message}
                  {...getInputWarningProps('present')}
                />
              </div>
            </Tooltip>
          )}
        />
      </Group>
      {isPresent && !!zoomParticipants.length && (
        <>
          <Stack spacing={4}>
            <Group spacing={4}>
              <Text size="sm" fw={500}>
                Zoom account name before renaming
                <Text size="sm" component="span" color="red">
                  {' '}
                  *
                </Text>
              </Text>
              <Tooltip
                label="Zoom account name under which this attendee or multiple attendees joined the session before you renamed their Zoom account name."
                multiline
                width={400}
              >
                <QuestionMarkCircledIcon />
              </Tooltip>
            </Group>
            <Controller
              control={control}
              name={getFieldName(`zoom_participants`, namePrefix)}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  data={zoomParticipants.map(zp => ({
                    label: `${zp.user_name} (${formatTimeForTimezone(
                      zp.join_time,
                      timezone,
                    )})`,
                    value: zp.id,
                  }))}
                  placeholder="Select option"
                  searchable
                  value={selectedZoomParticipants}
                  onChange={(value: string[]) => {
                    const newZoomParticipants = transformToZoomParticipants(
                      value,
                      zoomParticipants,
                    )
                    onChange(newZoomParticipants)
                    setSelectedZoomParticipants(value)
                  }}
                  error={!!error?.zoom_participants?.message}
                />
              )}
            />
          </Stack>
          <Box
            py="sm"
            px="md"
            w="100%"
            sx={theme => ({
              // TODO: Add this color to theme
              backgroundColor: '#CCD6FF',
              borderRadius: theme.radius['sm'],
            })}
          >
            <Text fw={600} color="gray.9" size="sm">
              Confirm that you have renamed attendee in Zoom to reflect a clear
              identity (ie first and last name)
            </Text>
          </Box>
        </>
      )}
      <FormErrors<ScriptAttendee> errors={error} />
      <FormWarnings warnings={getWarningsMessages()} />
    </Stack>
  )
}
