import * as yup from 'yup'

import { attendeesFieldSchema } from './attendeeSchema'

export const openingStepSchema = yup.object().shape({
  attendees: attendeesFieldSchema,
  isRecordingStarted: yup
    .boolean()
    .oneOf(
      [true],
      'You must go on the record. Do that by clicking the ‘Go on record’ button.',
    )
    .required(
      'You must go on the record. Do that by clicking the ‘Go on record’ button.',
    ),
})
