import React from 'react'

import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import { Event } from '@/types'

type QuestioningVerbatimProps = {
  event: Event
}

export const QuestioningVerbatim: React.FC<QuestioningVerbatimProps> = ({
  event,
}) => {
  const { time, timezone } = useFormattedCurrentDateParts(event.team.timezone)

  return (
    <>
      We are now ON the record and the time is {time} {timezone}
    </>
  )
}
