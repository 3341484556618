import React, { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Radio, Stack } from '@mantine/core'

import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import {
  DEPOSITION_ENDED_OPTIONS_LABEL,
  DepositionResult,
  ScriptStep,
} from '@/constants'
import { Event } from '@/types'

type CanceledEventOptionsProps = {
  canceledFromStep: ScriptStep | null
  control: Control<any>
  event: Event
}

const RESCHEDULED = {
  label: 'Rescheduled',
  value: DepositionResult.RESCHEDULED,
  description:
    'The proceeding has not yet officially gone on the record, and the parties agreed to reschedule for a different time.',
}

const CANCELLED = {
  label: DEPOSITION_ENDED_OPTIONS_LABEL[DepositionResult.CANCELLED],
  value: DepositionResult.CANCELLED,
  description:
    'The proceeding has not yet gone on the record, and the parties agreed the proceeding is no longer needed.',
}

const CNA = {
  label: DEPOSITION_ENDED_OPTIONS_LABEL[DepositionResult.CNA],
  value: DepositionResult.CNA,
  description: 'A certificate of non-appearance needs to be issued',
}

const RESET = {
  label: DEPOSITION_ENDED_OPTIONS_LABEL[DepositionResult.RESET],
  value: DepositionResult.RESET,
  description:
    'The proceeding has officially gone on the record, but the parties agreed to reschedule for a different time. (Only to be selected if officially requested by the client.)',
}

export const EventResultOptions: React.FC<CanceledEventOptionsProps> = ({
  canceledFromStep,
  control,
  event,
}) => {
  const { canHaveCna } = useStatePolicies(event)

  const options = useMemo(() => {
    switch (canceledFromStep) {
      case ScriptStep.ATTENDANCE:
        return [RESCHEDULED, CANCELLED, ...(canHaveCna ? [CNA] : [])]
      case ScriptStep.OPENING:
        return [RESCHEDULED, CANCELLED, RESET, ...(canHaveCna ? [CNA] : [])]
      case ScriptStep.QUESTIONING:
        return [RESET, CANCELLED]
      default:
        return [RESET, CANCELLED, ...(canHaveCna ? [CNA] : [])]
    }
  }, [canceledFromStep, canHaveCna])

  return (
    <Controller
      name="result"
      control={control}
      render={({ field }) => (
        <Radio.Group
          {...field}
          onChange={(value: string) =>
            field.onChange(value as DepositionResult)
          }
        >
          <Stack spacing="sm">
            {options.map(option => (
              <Radio
                styles={{ radio: { cursor: 'pointer' } }}
                key={option.value}
                value={option.value}
                label={option.label}
                description={option.description}
              />
            ))}
          </Stack>
        </Radio.Group>
      )}
    />
  )
}
