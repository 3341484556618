import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Group, Stack, Text, Textarea, Tooltip } from '@mantine/core'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'

import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'

export const SpellingsForm: React.FC = () => {
  const methods = useFormContext()
  return (
    <Stack spacing={4}>
      <Group spacing={4}>
        <Text size="sm" fw={500}>
          Spellings
        </Text>
        <Tooltip
          label="Words that may be difficult to spell are used during questioning, such as names or technical terminology, it is your responsibility to note them down and when appropriate, during breaks or at the end of the deposition, ask for spelling clarification. Then note the clarified words here, separated by commas."
          multiline
          width={400}
        >
          <QuestionMarkCircledIcon />
        </Tooltip>
      </Group>
      <Textarea
        {...methods.register('spellings')}
        error={!!methods.formState.errors.spellings?.message}
        minRows={4}
      />
      <FormErrors errors={methods.formState.errors.spellings?.message} />
    </Stack>
  )
}
