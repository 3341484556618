import React from 'react'
import { Anchor, AnchorProps } from '@mantine/core'

export const EmailLink: React.FC<
  { email: string } & Omit<
    AnchorProps & React.ComponentPropsWithoutRef<'a'>,
    'component' | 'href'
  >
> = ({ email, ...props }) => {
  return (
    <Anchor {...props} component="a" href={`mailto:${email}`}>
      {email}
    </Anchor>
  )
}
