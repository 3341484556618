import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Box, Button } from '@mantine/core'

import { AttendeesCard } from './components/AttendeesCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'

import { ReporterEvent } from '@/types'

export const OverlappingEventDetail: React.FC<{
  event: ReporterEvent
}> = ({ event }) => {
  return (
    <Layout
      left={
        <>
          <Alert
            title="This event is not available for booking."
            color="orange"
          >
            You have already booked an event for this time.
          </Alert>
          <Card>
            <Box m="md">
              <Button
                component={Link}
                to="/calendar"
                variant="light"
                color="gray"
              >
                Back to calendar view
              </Button>
            </Box>
          </Card>
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
