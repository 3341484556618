import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import {
  Alert,
  Button,
  Center,
  JsonInput,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import * as Yup from 'yup'

import { EventTitle } from '@/components/common/EventTitle'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { Shell } from '@/components/Shell'
import { STALE_TIME_EVENT_QUERY } from '@/constants'
import { useAdminEventQuery } from '@/queries/adminEvents'
import { NotFound } from '@/routes/NotFound'
import { Event } from '@/types'

type StateEditorValues = {
  state: string
}

const schema = Yup.object().shape({
  state: Yup.string().required(),
})

const ScriptStateEditor: React.FC<{ event: Event }> = ({ event }) => {
  const { state, actions, isLoading } = useScriptState(event)
  const form = useForm<StateEditorValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      state: JSON.stringify(state, null, 2),
    },
  })

  useEffect(() => {
    form.reset({
      state: JSON.stringify(state, null, 2),
    })
  }, [form, state])

  const onSave = (values: StateEditorValues) => {
    try {
      const state = JSON.parse(values.state)
      actions.updateGlobalState(state)
      showNotification({
        message:
          'State has been successfully updated, please check the script.',
      })
    } catch (error) {
      showNotification({
        message:
          'Could not parse JSON or submit script, please check the form errors.',
        color: 'red',
      })
      form.setError('state', {
        type: 'manual',
        message: error?.toString() ?? 'Could not parse JSON or submit script',
      })
    }
  }

  if (!state || isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Stack>
      <Controller
        control={form.control}
        name="state"
        render={({ field: { onChange, value } }) => (
          <JsonInput
            withAsterisk
            label="Current state"
            placeholder="Textarea will autosize to fit the content"
            validationError="Invalid JSON"
            formatOnBlur
            minRows={40}
            error={form.formState.errors.state?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Button
        type="submit"
        loading={isLoading}
        onClick={form.handleSubmit(onSave)}
      >
        Submit
      </Button>
    </Stack>
  )
}

export const NewScriptStateEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    isLoading,
    error,
    data: event,
  } = useAdminEventQuery(id, { staleTime: STALE_TIME_EVENT_QUERY })

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  if (error || !event) {
    return <NotFound />
  }

  return (
    <Shell
      header={{
        middle: <EventTitle>{event.name} (Engineers only page)</EventTitle>,
      }}
    >
      <Center>
        <Stack>
          <Alert color="red">
            <Text align="center" size="lg" fw={600}>
              This section for engineers only. Please use it with caution.
            </Text>
            <Text size="md">
              Here you can update state for the new DR script using the form.
              The script state is saved in json format, please be careful when
              editing it and make sure you know what you are doing. Just in
              case, store the original state somewhere safe before making
              changes.
            </Text>
          </Alert>
          <ScriptStateEditor event={event} />
        </Stack>
      </Center>
    </Shell>
  )
}
