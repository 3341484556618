import React, { forwardRef } from 'react'
import {
  ActionIcon,
  Group,
  Input,
  MantineColor,
  Select,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'

import { useInputWarnings } from '@/components/Event/useInputWarnings'
import { ATTENDEE_ROLE_LABEL, AttendeeRole } from '@/constants'
import { ReporterFeedback } from '@/types'

export type AttendeeFormValues = {
  name: string
  role: AttendeeRole
  email: string | null
  present: boolean | null
}

export type AttendeeFormValidatedValues = {
  name: string
  role: AttendeeRole
  email: string | null
  present: boolean
}

export const AttendeeInput: React.FC<{
  roles: AttendeeRole[]
  onBlur?: () => void
  form: UseFormReturnType<ReporterFeedback>
  index: number
  attendeeWarnings: Set<keyof AttendeeFormValues>[]
}> = ({ roles, onBlur, form, index, attendeeWarnings }) => {
  const formWarnings = useInputWarnings(attendeeWarnings)

  return (
    <div className="flex space-x-4 items-start">
      <TextInput
        required
        disabled={form.values.attendees[index].present === false}
        label="Name"
        {...form.getInputProps(`attendees.${index}.name`)}
        className="flex-1"
        {...formWarnings.getInputWarningProps(index, 'name')}
      />
      <TextInput
        disabled={form.values.attendees[index].present === false}
        label="Email"
        type="email"
        {...form.getInputProps(`attendees.${index}.email`)}
        value={form.values.attendees[index].email ?? ''}
        onChange={event =>
          form.setFieldValue(
            `attendees.${index}.email`,
            event.target.value.length ? event.target.value : null,
          )
        }
        className="flex-1"
        {...formWarnings.getInputWarningProps(index, `email`)}
      />
      <Select
        required
        styles={{ item: { wordBreak: 'normal' } }}
        disabled={form.values.attendees[index].present === false}
        label="Role"
        {...form.getInputProps(`attendees.${index}.role`)}
        data={roles.map(role => ({
          value: role,
          label: ATTENDEE_ROLE_LABEL[role],
        }))}
        // className="w-44"
        {...formWarnings.getInputWarningProps(index, `role`)}
      />
      <Input.Wrapper label="Present" required>
        <Group mt={10}>
          <Tooltip label="Absent" withArrow position="top">
            <PresenceButton
              color="red"
              error={Boolean(form.errors[`attendees.${index}.present`])}
              active={form.values.attendees[index].present === false}
              onClick={() => {
                // if name is empty then this button serves as remove button
                if (!form.values.attendees[index].name) {
                  form.removeListItem('attendees', index)
                } else {
                  form.setFieldValue(`attendees.${index}.present`, false)
                }
              }}
              onBlur={onBlur}
            >
              <Cross2Icon />
            </PresenceButton>
          </Tooltip>
          <Tooltip label="Present" withArrow position="top">
            <PresenceButton
              color="green"
              error={Boolean(form.errors[`attendees.${index}.present`])}
              active={form.values.attendees[index].present === true}
              onClick={() =>
                form.setFieldValue(`attendees.${index}.present`, true)
              }
              onBlur={onBlur}
            >
              <CheckIcon />
            </PresenceButton>
          </Tooltip>
        </Group>
        {form.errors[`attendees.${index}.present`] && (
          <Text color="red" size="sm" className="mt-2 w-16">
            {form.errors[`attendees.${index}.present`]}
          </Text>
        )}
      </Input.Wrapper>
    </div>
  )
}

type PresenceButtonProps = {
  color: MantineColor
  onClick: () => void
  onBlur?: () => void
  error: boolean
  active: boolean
  children: React.ReactNode
}

export const PresenceButton = forwardRef<
  HTMLButtonElement,
  PresenceButtonProps
>(({ error, active, color, onClick, onBlur, children }, ref) => {
  const getColor = () => {
    if (error) {
      return 'red'
    }

    return active ? color : 'gray'
  }

  return (
    <ActionIcon
      color={getColor()}
      variant={error ? 'outline' : 'light'}
      onClick={onClick}
      onBlur={onBlur}
      ref={ref}
    >
      {children}
    </ActionIcon>
  )
})
PresenceButton.displayName = 'PresenceButton'
