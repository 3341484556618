import React, { useEffect, useMemo } from 'react'
import {
  Anchor,
  Box,
  Group,
  Input,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { useModals } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'

import { Card } from '@/components/Event/Detail/components/Card'
import { TurnaroundHoursSelect } from '@/components/Event/Feedback/TurnaroundHoursSelect'
import { ATTENDEE_ROLE_LABEL, AttendeeRole, TurnaroundHours } from '@/constants'
import { AttendeeFormValues, ReporterFeedback, TranscriptOrders } from '@/types'
import { isAttorney } from '@/utils/validations'

type Attorney = {
  name: string
  role: AttendeeRole
  email: string
  present: boolean
}

type Props = {
  form: UseFormReturnType<ReporterFeedback>
  withOcOrdering: boolean
  withRoughDraftOption: boolean
  attendees: AttendeeFormValues[]
  defaultTurnaroundHours: TurnaroundHours | null
  clearable: boolean
}

type OCTranscriptOrderInputProps = {
  value: TranscriptOrders
  attorneys: Attorney[]
  onChange: (value: ReporterFeedback['transcript_orders']) => void
  error: React.ReactNode
  defaultTurnaroundHours: TurnaroundHours | null
  withRoughDraftOption: boolean
  clearable: boolean
}

const AttorneyOrderInput: React.FC<{
  attorney: AttendeeFormValues
  onChange: (value: TurnaroundHours | null) => void
  value: TurnaroundHours | null
  withRoughDraftOption: boolean
  clearable: boolean
}> = ({ attorney, onChange, value, withRoughDraftOption, clearable }) => {
  const { openConfirmModal } = useModals()

  const handleSelect = (value: TurnaroundHours | null) => {
    if (value === TurnaroundHours.ONE_DAY) {
      openConfirmModal({
        title: (
          <Text size="xl" fw={600}>
            Confirm Surge 1 day turnaround
          </Text>
        ),
        onConfirm: () => onChange(value),
        children: (
          <div>
            Surge turnaround is a non-standard more expensive option.{' '}
            <Text component="span" fw={600}>
              Make sure the attendee really requested it.
            </Text>
          </div>
        ),
        labels: {
          cancel: 'Cancel',
          confirm: 'Confirm order',
        },
      })
    } else {
      onChange(value)
    }
  }

  const scrollToAttendeesForm = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Box>
      <Group position="apart">
        <Title order={4}>{attorney.name}</Title>
        {attorney.email ? (
          <Text size="sm">{attorney.email}</Text>
        ) : (
          <Text size="sm" color="red">
            Missing email
          </Text>
        )}
      </Group>
      <Text size="sm" mb="xs">
        {ATTENDEE_ROLE_LABEL[attorney.role as AttendeeRole]}
      </Text>
      {!attorney.email ? (
        <Text size="md" mt="xs" color="red">
          In order to request a certified transcript for this attendee, you must
          first provide his email address in the{' '}
          <Anchor onClick={scrollToAttendeesForm} td="underline" color="red">
            attendee presence section above.
          </Anchor>
        </Text>
      ) : (
        <TurnaroundHoursSelect
          value={value}
          onChange={handleSelect}
          clearable={clearable}
          disabled={!attorney.email}
          withRoughDraftOption={withRoughDraftOption}
        />
      )}
    </Box>
  )
}

const OCTranscriptOrderInput: React.FC<OCTranscriptOrderInputProps> = ({
  value,
  onChange,
  attorneys,
  error,
  withRoughDraftOption,
  clearable,
}) => {
  const theme = useMantineTheme()

  if (!attorneys.length) {
    return (
      <Text weight={600}>
        No attorneys eligible for a transcript order found
      </Text>
    )
  }

  return (
    <Input.Wrapper
      error={error}
      p="xs"
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: error ? theme.colors.red[3] : 'transparent',
        borderRadius: theme.radius.md,
      }}
    >
      <Stack spacing="md">
        {attorneys.map(a => (
          <Card key={a.email}>
            <AttorneyOrderInput
              attorney={a}
              onChange={turnaround =>
                onChange({
                  ...value,
                  [a.email]: { turnaround_hours: turnaround },
                })
              }
              value={value[a.email]?.turnaround_hours}
              withRoughDraftOption={withRoughDraftOption}
              clearable={clearable}
            />
          </Card>
        ))}
      </Stack>
    </Input.Wrapper>
  )
}

const getAttorneysThatCanOrder = (attendees: AttendeeFormValues[]) => {
  return uniqBy(
    attendees.filter(a => a.present && isAttorney(a.role)),
    a => a.email ?? a,
  ) as Attorney[]
}

export const TranscriptOrderInput: React.FC<Props> = ({
  withOcOrdering,
  withRoughDraftOption,
  form,
  attendees,
  defaultTurnaroundHours = null,
  clearable,
}) => {
  const attorneys = useMemo(
    () => getAttorneysThatCanOrder(attendees),
    [attendees],
  )

  useEffect(() => {
    if (!withOcOrdering) {
      return
    }

    const attorneyEmails = attorneys.map(a => a.email).filter(e => Boolean(e))
    const currentOrders = form.values.transcript_orders ?? {}
    const attorneysWithOrders = Object.keys(currentOrders)

    if (isEqual(attorneysWithOrders, attorneyEmails)) {
      return
    }

    const defaultOrder = { turnaround_hours: defaultTurnaroundHours }
    const defaultOrders = {
      ...Object.fromEntries(attorneyEmails.map(email => [email, defaultOrder])),
      ...currentOrders,
    }

    const ordersNotMatchingAttorneys = attorneysWithOrders.filter(
      email => !attorneyEmails.includes(email),
    )

    // skip a full rerender of the form
    if (ordersNotMatchingAttorneys.length > 0) {
      showNotification({
        title: <Text weight={600}>Order cancelled</Text>,
        message:
          'Some transcript orders were cancelled due to attendee changes',
      })
    }

    form.setFieldValue(
      'transcript_orders',
      omit(defaultOrders, ordersNotMatchingAttorneys),
    )
  }, [withOcOrdering, form, defaultTurnaroundHours, attorneys])

  if (!withOcOrdering) {
    return (
      <TurnaroundHoursSelect
        required
        onChange={value => {
          form.setFieldValue('turnaround_hours', value)
        }}
        error={form.errors.turnaround_hours}
        value={form.values.turnaround_hours}
        withRoughDraftOption={withRoughDraftOption}
        clearable={clearable}
      />
    )
  }

  return (
    <OCTranscriptOrderInput
      value={form.values.transcript_orders ?? {}}
      error={form.errors.transcript_orders}
      attorneys={attorneys}
      onChange={transcriptOrders =>
        form.setFieldValue('transcript_orders', transcriptOrders)
      }
      defaultTurnaroundHours={defaultTurnaroundHours}
      withRoughDraftOption={withRoughDraftOption}
      clearable={clearable}
    />
  )
}
