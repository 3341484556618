import { useCallback, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { Location } from 'history'

import { PageTrackingData, TrackingUserAction } from './types'

import { AnalyticsContext } from '@/providers/AnalyticsProvider'

const UUID_REGEX = '[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}'

const PAGE_TRACKING_DATA_MAP: { pattern: RegExp; category_3: string }[] = [
  {
    pattern: /\/calendar\/.*/,
    category_3: 'Calendar',
  },
  {
    pattern: /\/admin\/reporters/,
    category_3: 'Reporters management',
  },
  {
    pattern: /\/waiting/,
    category_3: 'Waiting events',
  },
  {
    pattern: /\/settings/,
    category_3: 'Settings',
  },
  {
    pattern: new RegExp(`/event/${UUID_REGEX}$`),
    category_3: 'Event detail',
  },
  {
    pattern: new RegExp(`event/${UUID_REGEX}/script$`),
    category_3: 'Event script',
  },
]

const getPageTrackingData = (
  pathname: Location['pathname'],
): PageTrackingData | null => {
  const pageTrackingData = PAGE_TRACKING_DATA_MAP.find(({ pattern }) =>
    pattern.test(location.pathname),
  )

  if (!pageTrackingData) {
    return null
  }

  return {
    pageCategories: {
      category_1: 'Reporters',
      category_2: pathname.indexOf('admin') > -1 ? 'Admin' : 'Reporter',
      category_3: pageTrackingData.category_3,
    },
  }
}

export const useTrackUserAction = <A extends keyof TrackingUserAction>(): ((
  action: A,
  payload: TrackingUserAction[A],
) => Promise<void>) => {
  const location = useLocation()
  const analytics = useContext(AnalyticsContext)

  return useCallback(
    async (action, payload) => {
      if (!analytics) {
        return
      }

      const pageTrackingData = getPageTrackingData(location?.pathname)
      await analytics.track(action, {
        ...payload,
        page: pageTrackingData,
      })
    },
    [location?.pathname, analytics],
  )
}

export const usePageViewTracking = (analytics?: AnalyticsBrowser): void => {
  const { pathname } = useLocation()
  const lastVisitedPath = useRef('')

  useEffect(() => {
    if (analytics && lastVisitedPath.current !== pathname) {
      const pageTrackingData = getPageTrackingData(pathname)

      if (pageTrackingData) {
        analytics.page(undefined, undefined, pageTrackingData)
      }

      lastVisitedPath.current = pathname
    }
  }, [pathname, analytics])
}
