import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { Role, useAuth } from '@/hooks/useAuth'

const SentryRoute = Sentry.withSentryRouting(Route)

export const ProtectedRoute: React.FC<
  React.ComponentProps<typeof Route> & { role: Role }
> = ({ children, role, ...rest }) => {
  const { user } = useAuth()

  return (
    <SentryRoute
      {...rest}
      render={() => {
        if (user?.role !== role) {
          if (user?.role === 'admin') {
            return <Redirect to="/admin" />
          }

          return <Redirect to="/" />
        }

        return children
      }}
    />
  )
}
