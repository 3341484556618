import React, { forwardRef } from 'react'
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'

type ActionButtonProps = {
  children: React.ReactNode
} & ButtonProps

const _ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ children, ...others }, ref) => (
    <Button
      component="button"
      variant="outline"
      ref={ref}
      sx={theme => ({
        width: 200,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
        },
        '&:disabled': {
          color: theme.colors.indigo[3],
          backgroundColor: theme.colors.indigo[2],
          borderColor: theme.colors.indigo[2],
          pointerEvents: 'unset',
        },
      })}
      {...others}
    >
      {children}
    </Button>
  ),
)

_ActionButton.displayName = 'ActionButton'

export const ActionButton = createPolymorphicComponent<
  'button',
  ActionButtonProps
>(_ActionButton)
