import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Center, Menu, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
  ChevronDownIcon,
  ExitIcon,
  GearIcon,
  HomeIcon,
  PersonIcon,
  QuestionMarkIcon,
} from '@radix-ui/react-icons'

import { FAQ_URL } from '@/constants'
import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { useAuth } from '@/hooks/useAuth'
import { useReporter } from '@/providers/UserProvider'

export const UserMenu: React.FC = () => {
  const { user, logout } = useAuth()
  const reporter = useReporter()
  const { breakpoints } = useMantineTheme()
  const trackUserAction = useTrackUserAction()
  const isSmOrHigherResolution = useMediaQuery(`(min-width: ${breakpoints.sm})`)

  if (!user) {
    return <></>
  }

  const onOpenFAQ = () => {
    trackUserAction('FAQ clicked', {})
    return true
  }

  const initials = user.name
    ?.split(' ')
    .map(s => s.charAt(0))
    .join('')
    .toUpperCase()

  return (
    <Center>
      <Menu width={200} position="bottom-end">
        <Menu.Target>
          <Button
            variant="subtle"
            color="gray"
            rightIcon={
              <ChevronDownIcon color="black" className="-ml-2.5 sm:-ml-0" />
            }
            className="px-0 pl-1 sm:px-2"
            size={isSmOrHigherResolution ? 'sm' : 'xs'}
          >
            <Text size="sm" weight={500} color="dark">
              {isSmOrHigherResolution ? user.name : initials}
            </Text>
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {user.role === 'reporter' && (
            <Menu.Item component={Link} to="/" icon={<HomeIcon />}>
              Home
            </Menu.Item>
          )}
          {/* {user.role === 'reporter' && (
            <Menu.Item
              component={Link}
              to="/availability"
              icon={<CalendarIcon />}
            >
              My availability
            </Menu.Item>
          )} */}
          {user.role === 'reporter' && (
            <Menu.Item component={Link} to="/settings" icon={<GearIcon />}>
              Settings
            </Menu.Item>
          )}

          {user.role === 'admin' && (
            <Menu.Item component={Link} to="/" icon={<HomeIcon />}>
              Home
            </Menu.Item>
          )}
          {user.role === 'admin' && (
            <Menu.Item
              component={Link}
              to="/admin/reporters"
              icon={<PersonIcon />}
            >
              Reporters
            </Menu.Item>
          )}
          {reporter?.role === 'digital_reporter' && (
            <Menu.Item
              component="a"
              href={FAQ_URL}
              target="_blank"
              icon={<QuestionMarkIcon />}
              onClick={onOpenFAQ}
            >
              FAQ
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item
            onClick={() => logout({ returnTo: window.location.origin })}
            icon={<ExitIcon />}
          >
            Log out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Center>
  )
}
