import * as yup from 'yup'

import { attendeesFieldSchema } from '@/components/Event/NewScript/schemas/attendeeSchema'
import { lowConfidenceWordSchema } from '@/components/Event/NewScript/schemas/lowConfidenceWordsSchema'
import { shownExhibitSchema } from '@/components/Event/NewScript/schemas/shownExhibitSchema'

export const defaultReviewStepSchema = yup.object({
  state: yup.string().required('State is required'),
  county: yup.string().required('County is required'),
  lowConfidenceWords: yup
    .array()
    .of(lowConfidenceWordSchema)
    .nullable()
    .default(null),
  timestamps: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  spellings: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  notes: yup
    .string()
    .max(5000, 'Must be less than 5000 characters')
    .nullable()
    .default(null),
  attendees: attendeesFieldSchema,
  shownExhibits: yup.array().of(shownExhibitSchema).nullable().default(null),
})
