import React from 'react'
import {
  ActionIcon,
  Group,
  MediaQuery,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons'

type Props = {
  onTodayClick: () => void
  onPrevClick: () => void
  onNextClick: () => void
  title: string | undefined
}

export const Controls: React.FC<Props> = ({
  onTodayClick,
  onPrevClick,
  onNextClick,
  title,
}) => {
  const { breakpoints } = useMantineTheme()
  const isSmOrHigherResolution = useMediaQuery(`(min-width: ${breakpoints.sm})`)

  return (
    <>
      <MediaQuery largerThan="md" styles={{ display: 'none' }}>
        <ActionIcon variant="light" onClick={onTodayClick}>
          <CalendarIcon color="black" />
        </ActionIcon>
      </MediaQuery>
      <Group className="gap-1 sm:gap-0" noWrap>
        <ActionIcon
          variant={isSmOrHigherResolution ? 'hover' : 'light'}
          onClick={onPrevClick}
        >
          <ChevronLeftIcon color="black" />
        </ActionIcon>
        <ActionIcon
          variant={isSmOrHigherResolution ? 'hover' : 'light'}
          onClick={onNextClick}
        >
          <ChevronRightIcon color="black" />
        </ActionIcon>
      </Group>
      <Text size="sm" weight={500} className="mx-1" align="center">
        {title}
      </Text>
    </>
  )
}
