import React from 'react'
import { Alert, List, Stack, Text } from '@mantine/core'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'

import { AttendeesCard } from './components/AttendeesCard'
import { CancelBookingCard } from './components/CancelBookingCard'
import { Card } from './components/Card'
import { DetailsCard } from './components/DetailsCard'
import { Layout } from './components/Layout'
import {
  JoinCallButton,
  LinksCard,
  OpenLiveTranscriptButton,
  OpenScriptButton,
} from './components/LinksCard'
import { OngoingBookedEventDetail } from './OngoingBookedEventDetail'

import {
  hasHearingDocket,
  HearingDocket,
} from '@/components/Event/Detail/components/HearingDocket'
import { canUseScriptForEvent } from '@/components/Event/Script/utils/event'
import { Event } from '@/types'

export const BookedEventDetail: React.FC<{
  event: Event
  onChange: () => void
}> = ({ event, onChange }) => {
  if (event.is_court_reporter_event && event.status === 'upcoming') {
    const eventDate = parseISO(event.datetime)
    const minutesToEvent = differenceInMinutes(eventDate, new Date())

    if (minutesToEvent < 30) {
      return <OngoingBookedEventDetail event={event} onChange={onChange} />
    }
  }

  if (event.is_ongoing) {
    return <OngoingBookedEventDetail event={event} onChange={onChange} />
  }

  const startsIn = formatDistanceToNow(parseISO(event.datetime))

  return (
    <Layout
      left={
        <>
          <Alert title={`Event is starting in ${startsIn}`}>
            Event is starting soon, prepare for your event.
          </Alert>

          {hasHearingDocket(event) && <HearingDocket event={event} />}

          <Card heading="Get ready for the call" highlighted>
            <Stack align="flex-start">
              <List type="ordered" withPadding style={{ listStyle: 'ordered' }}>
                <List.Item>
                  Join 10 minutes prior to make sure you're all set up.
                </List.Item>
                <List.Item>
                  Make sure to join using a computer/laptop. Tablets and phones
                  have limited host features such as spotlighting.
                </List.Item>
                <List.Item>Be in a quiet and well-lit environment</List.Item>
                <List.Item>
                  Make sure you have a working microphone and speakers
                </List.Item>
                <List.Item>Check is your camera is working</List.Item>
              </List>

              <JoinCallButton
                event={event}
                asHost={!event.uses_external_zoom_meeting}
              />
            </Stack>
          </Card>

          <Card heading="Realtime transcript">
            <Stack align="flex-start">
              <Text size="sm">
                Use realtime transcript when clients ask for readback.
              </Text>

              <OpenLiveTranscriptButton event={event} />
            </Stack>
          </Card>

          {canUseScriptForEvent(event) && (
            <Card heading="Event script">
              <Stack align="flex-start">
                <Text size="sm">
                  Open and follow the script. It will guide you through the
                  event.
                </Text>

                <OpenScriptButton event={event} />
              </Stack>
            </Card>
          )}

          <CancelBookingCard event={event} onChange={onChange} />
        </>
      }
      right={
        <>
          <DetailsCard event={event} />
          <LinksCard
            event={event}
            options={{
              join_button: true,
              open_script: canUseScriptForEvent(event),
              open_live_transcript: true,
            }}
          />
          <AttendeesCard event={event} />
        </>
      }
    />
  )
}
