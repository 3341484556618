import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Radio, Stack, Textarea } from '@mantine/core'

import { EventResultOptions } from '@/components/Event/NewScript/components/common/EventResultOptions'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { canceledEventSchema } from '@/components/Event/NewScript/schemas/canceledEventSchema'
import {
  DepositionResult,
  REASON_FOR_CNA_LABEL,
  ReasonForCna,
  ScriptStep,
} from '@/constants'
import { Event } from '@/types'

type Props = {
  onSubmitClick: () => void
  event: Event
}

export const EventCancelledCardForm: React.FC<Props> = ({
  onSubmitClick,
  event,
}) => {
  const { state, actions } = useScriptState(event)
  const [isCNA, setIsCNA] = useState<boolean>(
    () => state.result && state.result === DepositionResult.CNA,
  )

  const methods = useForm({
    resolver: yupResolver(canceledEventSchema),
    mode: 'all',
    defaultValues: {
      cna_declared_at: state.cna_declared_at,
      result: state.result,
      notes: state.notes,
      reason_for_cna: state.reason_for_cna,
      other_reason: state.other_reason,
    },
  })

  const { control, register, setValue } = methods

  const handleEventResultChange = () => {
    const values = methods.getValues()
    if (values.result === DepositionResult.CNA) {
      actions.unskipStepAction({
        result: values.result,
        reasonForCNA: values.reason_for_cna,
        otherReason: values.other_reason,
        notes: values.notes,
        stepAndActionKeys: [
          {
            step: ScriptStep.CLOSING,
            key: 'state_the_cna',
          },
          {
            step: ScriptStep.CLOSING,
            key: 'select_reason_for_cna',
          },
        ],
      })
    } else {
      actions.skipStepAction({
        result: values.result,
        reasonForCNA: values.reason_for_cna,
        otherReason: values.other_reason,
        notes: values.notes,
        stepAndActionKeys: [
          {
            step: ScriptStep.CLOSING,
            key: 'state_the_cna',
          },
          {
            step: ScriptStep.CLOSING,
            key: 'select_reason_for_cna',
          },
        ],
      })
      setValue('reason_for_cna', undefined)
      setValue('other_reason', undefined)
    }
  }

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'result') {
        setIsCNA(value.result === DepositionResult.CNA)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const onSubmitClickHandler = () => {
    methods.trigger().then(isValid => {
      if (isValid) {
        handleEventResultChange()
        onSubmitClick()
      }
    })
  }

  return (
    <ActionContainer outer>
      <ActionContainer>
        <Stack align="flex-start">
          <Stack spacing={40}>
            <EventResultOptions
              event={event}
              control={control}
              canceledFromStep={state.first_skipped_step}
            />
            {state.first_skipped_step !== ScriptStep.ATTENDANCE && (
              <Textarea
                {...register('notes')}
                label="Notes"
                placeholder="How was the event? (Anything worth mentioning?)"
                minRows={3}
              />
            )}
            {isCNA && (
              <>
                <Controller
                  name="reason_for_cna"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      {...field}
                      onChange={value => {
                        field.onChange(value)
                        methods.trigger('other_reason')
                      }}
                    >
                      <Stack>
                        {Object.values(ReasonForCna).map(value => (
                          <Radio
                            styles={{ radio: { cursor: 'pointer' } }}
                            key={value}
                            value={value}
                            label={REASON_FOR_CNA_LABEL[value]}
                          />
                        ))}
                      </Stack>
                    </Radio.Group>
                  )}
                />
                {methods.formState.errors.reason_for_cna !== undefined && (
                  <FormErrors
                    errors={methods.formState.errors.reason_for_cna.message}
                  />
                )}
                <Textarea
                  {...register('other_reason')}
                  label="Specify the other reason (will be visible on the certificate)"
                  placeholder="How was the event? (Anything worth mentioning?)"
                  minRows={3}
                />
                {methods.formState.errors.other_reason !== undefined && (
                  <FormErrors
                    errors={methods.formState.errors.other_reason.message}
                  />
                )}
              </>
            )}
          </Stack>
          <SubtleButton p={0} onClick={onSubmitClickHandler}>
            Save changes
          </SubtleButton>
        </Stack>
      </ActionContainer>
    </ActionContainer>
  )
}
