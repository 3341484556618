import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingOverlay } from '@mantine/core'

import { NewScriptScreen } from '@/components/NewScriptScreen'
import { STALE_TIME_EVENT_QUERY } from '@/constants'
import { useAdminEventQuery } from '@/queries/adminEvents'
import { NotFound } from '@/routes/NotFound'

export const NewScript: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    isLoading,
    error,
    data: event,
    refetch,
  } = useAdminEventQuery(id, { staleTime: STALE_TIME_EVENT_QUERY })

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  if (error || !event) {
    return <NotFound />
  }

  return <NewScriptScreen event={event} refetchEvent={refetch} />
}
