import * as yup from 'yup'

import { LowConfidenceWordStatus } from '@/constants'

export const lowConfidenceWordSchema = yup
  .object({
    word: yup.string().max(256).required("Word can't be empty"),
    start_time: yup.number().required(),
    end_time: yup.number().required(),
    speaker_id: yup.string().nullable().default(null),
    status: yup
      .mixed<LowConfidenceWordStatus>()
      .oneOf(Object.values(LowConfidenceWordStatus))
      .required()
      .test('is_resolved', (value, context) => {
        if (value === LowConfidenceWordStatus.UNRESOLVED) {
          return context.createError({
            message: 'Word must be either marked as correct or unclear',
          })
        }

        return true
      }),
    correct_text: yup
      .string()
      .max(256, 'Word cannot be longer than 256 characters')
      .required(),
  })
  .required()
