import React, { useEffect, useState } from 'react'
import { useIsMutating } from 'react-query'
import { Group, Loader, MediaQuery, Text } from '@mantine/core'
import { useForceUpdate, useInterval } from '@mantine/hooks'
import { CheckIcon } from '@radix-ui/react-icons'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import { SAVE_REPORTER_FEEDBACK_MUTATION_KEY } from '@/constants'

const EVERY_MINUTE = 60000

export const ReporterFeedbackSaveStatus: React.FC = () => {
  const isSavingReporterFeedback = useIsMutating({
    mutationKey: SAVE_REPORTER_FEEDBACK_MUTATION_KEY,
  })

  const [savedAt, setSavedAt] = useState<Date>()

  const forceUpdate = useForceUpdate()
  const interval = useInterval(() => {
    forceUpdate()
  }, EVERY_MINUTE)

  useEffect(() => {
    interval.start()

    return interval.stop
  }, [interval])

  useEffect(() => {
    if (isSavingReporterFeedback) {
      setSavedAt(new Date())
    }
  }, [setSavedAt, isSavingReporterFeedback])

  if (isSavingReporterFeedback) {
    return (
      <Group spacing="xs">
        <Loader size="sm" color="gray" />
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Text size="sm" color="gray">
            Saving
          </Text>
        </MediaQuery>
      </Group>
    )
  }

  if (!savedAt) {
    return <></>
  }

  return (
    <>
      <MediaQuery largerThan="md" styles={{ display: 'none' }}>
        <CheckIcon color="gray" />
      </MediaQuery>
      <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
        <Text size="sm" color="gray">
          Changes saved {formatDistanceToNow(savedAt)} ago
        </Text>
      </MediaQuery>
    </>
  )
}
