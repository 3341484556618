import { Anchor, Stack, Text } from '@mantine/core'

import { EmailLink } from '@/components/common/EmailLink'
import { useReporter } from '@/providers/UserProvider'

export const UncancellableBookingNotice = () => {
  const reporter = useReporter()
  const supportEmail =
    reporter?.role === 'court_reporter'
      ? import.meta.env.VITE_APP_CR_SUPPORT_EMAIL
      : import.meta.env.VITE_APP_SUPPORT_EMAIL

  return (
    <Stack>
      <Text size="sm">
        You cannot cancel a booking less than 2 business days before the event
        through the app.
      </Text>
      <Text size="sm">
        If you need to cancel the event, email us at{' '}
        <EmailLink size="sm" email={supportEmail} /> or reach out directly at{' '}
        <Anchor href="tel:(213) 516-9218" size="sm">
          (213) 516-9218
        </Anchor>
        .
      </Text>
    </Stack>
  )
}
