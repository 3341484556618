import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Stack, Text } from '@mantine/core'

import { Card } from './Card'

import { MediaType } from '@/constants'
import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { useAuth } from '@/hooks/useAuth'
import { useJoinZoomCall } from '@/hooks/useJoinZoomCall'
import { Event, MediaResource } from '@/types'

export const LinksCard: React.FC<{
  event: Event
  options: {
    join_button: boolean
    open_script: boolean
    open_live_transcript: boolean
  }
}> = ({ event, options }) => {
  return (
    <Card heading="Event links">
      <Stack align="flex-start">
        {options.join_button && (
          <>
            <Text size="sm">Before the event will start, open this link.</Text>
            <JoinCallButton
              event={event}
              asHost={!event.uses_external_zoom_meeting}
            />
          </>
        )}
        {options.open_script && <OpenScriptButton event={event} />}
        {options.open_live_transcript && (
          <OpenLiveTranscriptButton event={event} />
        )}
      </Stack>
    </Card>
  )
}

export const OpenLiveTranscriptButton: React.FC<{ event: Event }> = ({
  event,
}) => {
  const trackUserAction = useTrackUserAction()

  const onButtonClick = () => {
    trackUserAction('Live transcript opened', { event_id: event.id })
    return true
  }

  return (
    <Button
      component="a"
      href={event.livestream_url}
      target="_blank"
      variant="outline"
      onClick={onButtonClick}
    >
      Open Realtime transcript
    </Button>
  )
}

export const OpenScriptButton: React.FC<{ event: Event }> = ({ event }) => {
  const { user } = useAuth()

  return (
    <Button
      component={Link}
      to={
        user?.role === 'admin'
          ? `/admin/event/${event.id}/script`
          : `/event/${event.id}/script`
      }
      target="_blank"
      variant="outline"
    >
      Open event script
    </Button>
  )
}

export const JoinCallButton: React.FC<{ event: Event; asHost: boolean }> = ({
  event,
  asHost,
}) => {
  const { isFetching, joinZoomCall } = useJoinZoomCall(event, asHost)

  return (
    <Button onClick={joinZoomCall} loading={isFetching}>
      {asHost ? 'Join the call as a host' : 'Join the call'}
    </Button>
  )
}

export const DownloadVideoRecordingButton: React.FC<{
  media: MediaResource[]
}> = ({ media }) => {
  const videoUrl = media.find(m => m.media_type === MediaType.VIDEO)?.url

  return videoUrl ? (
    <Button
      component="a"
      href={videoUrl}
      target="_blank"
      color="gray"
      variant="light"
    >
      Download video recording
    </Button>
  ) : (
    <Button color="gray" variant="light" disabled={true}>
      Video recording will be available soon
    </Button>
  )
}

export const DownloadAudioRecordingButton: React.FC<{
  media: MediaResource[]
}> = ({ media }) => {
  const audioUrl = media.find(m => m.media_type === MediaType.AUDIO)?.url

  return audioUrl ? (
    <Button
      component="a"
      href={audioUrl}
      target="_blank"
      color="gray"
      variant="light"
    >
      Download audio recording
    </Button>
  ) : (
    <Button color="gray" variant="light" disabled={true}>
      Audio recording will be available soon
    </Button>
  )
}

export const OpenTranscriptButton: React.FC<{
  event: Event
  media: MediaResource[]
}> = ({ event, media }) => {
  if (event.result === 'canceled_cna') {
    return <></>
  }

  if (
    !['rough_draft', 'certified_transcript'].includes(
      event.recording.client_status,
    )
  ) {
    return (
      <Button color="gray" variant="light" disabled={true}>
        Transcript PDF will be available soon
      </Button>
    )
  }

  const roughDraftUrl = media.find(
    m => m.media_type === MediaType.DOCUMENT_ROUGH_DRAFT,
  )?.url
  const certifiedUrl = media.find(
    m => m.media_type === MediaType.DOCUMENT_CERTIFIED,
  )?.url

  return (
    <Button
      component="a"
      href={certifiedUrl || roughDraftUrl}
      target="_blank"
      color="gray"
      variant="light"
    >
      Download{' '}
      {event.recording.client_status === 'rough_draft'
        ? 'rough draft'
        : 'certified transcript'}
    </Button>
  )
}
