import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import formatDurationDateFns from 'date-fns/formatDuration'
import intervalToDuration from 'date-fns/intervalToDuration'
import isSameDay from 'date-fns/isSameDay'
import parseISO from 'date-fns/parseISO'
import dayjs, { Dayjs } from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { TIME_FORMAT } from '@/constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const formatDate = (
  datetime: string,
  options: { long: boolean; separator: string } = {
    long: false,
    separator: ' ',
  },
): string => {
  if (options.long) {
    return format(parseISO(datetime), `EEEE${options.separator}M/d/Y`)
  }

  return format(parseISO(datetime), `EEEE${options.separator}M/d`)
}

export const formatTime = (datetime: string): string =>
  format(parseISO(datetime), 'h:mm aaa')

export const formatDatetime = (datetime: string): string =>
  `${formatDate(datetime)} ${formatTime(datetime)}`

const generateTimeRangeFormatString = (
  date: Date,
  showFullDate: boolean,
): string =>
  `${showFullDate ? 'EEEE M/d ' : ''}h${date.getMinutes() ? ':mm' : ''} aaa`

export const formatTimeRange = (
  datetime: string,
  duration: number,
  options: { long: boolean } = { long: false },
): string => {
  const startsAt = parseISO(datetime)
  const endsAt = addMinutes(parseISO(datetime), duration)

  const startTime = format(
    startsAt,
    generateTimeRangeFormatString(
      startsAt,
      !isSameDay(startsAt, endsAt) || options.long,
    ),
  )
  const endTime = format(
    endsAt,
    generateTimeRangeFormatString(endsAt, !isSameDay(startsAt, endsAt)),
  )

  return `${startTime} – ${endTime}`
}

export const formatDuration = (durationInMinutes: number): string =>
  formatDurationDateFns(
    intervalToDuration({ start: 0, end: durationInMinutes * 60 * 1000 }),
    { format: ['hours', 'minutes'] },
  )

export const formatTimezone = (timezone: string): string =>
  dayjs().tz(timezone).format('z (Z)')

export const getCurrentTimeInUtc = (): Dayjs => dayjs.utc()

export const formatTimeForTimezone = (utcTime: string, tz: string): string => {
  return dayjs.utc(utcTime).tz(tz).format(TIME_FORMAT)
}

export const secToMsec = (time: number): number => Math.trunc(time * 1000)
