import React, { useState } from 'react'

import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { AttendeeFieldInputWrapper } from '@/components/Event/NewScript/components/Review/AttendeeFieldCard/AttendeeFieldInputWrapper'
import { AttendeeFieldView } from '@/components/Event/NewScript/components/Review/AttendeeFieldCard/AttendeeFieldView'
import { ScriptAttendee } from '@/types'

type Props = {
  attendee: ScriptAttendee
  fieldValue: string
  fieldInput: React.ReactElement
  onSubmitClick: () => void
  onCancelClick: () => void
}

export const AttendeeFieldCard: React.FC<Props> = ({
  attendee,
  fieldValue,
  onSubmitClick,
  onCancelClick,
  fieldInput,
}) => {
  const [edit, setEdit] = useState(false)

  return (
    <ActionContainer>
      {edit ? (
        <AttendeeFieldInputWrapper
          attendee={attendee}
          onSave={() => {
            onSubmitClick()
            setEdit(false)
          }}
          onCancel={() => {
            onCancelClick()
            setEdit(false)
          }}
        >
          {fieldInput}
        </AttendeeFieldInputWrapper>
      ) : (
        <AttendeeFieldView
          onEditClick={() => setEdit(true)}
          fieldValue={fieldValue}
          attendee={attendee}
        />
      )}
    </ActionContainer>
  )
}
