import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import get from 'lodash/get'

import { IdentityDocumentInput } from './IdentityDocumentInput'
import { IdentityDocumentView } from './IdentityDocumentView'

import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  attendee: ScriptAttendee
  namePrefix: string
  onSubmitClick?: (identityDocument: ScriptAttendee) => void
  event: Event
}

export const IdentityDocumentCard: React.FC<Props> = ({
  onSubmitClick,
  namePrefix,
  attendee,
  event,
}) => {
  const [edit, setEdit] = useState(false)
  const { formState } = useFormContext()

  useEffect(() => {
    const documentErrors = get(formState.errors, namePrefix, null)
    if (documentErrors) {
      setEdit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors])

  const {
    actions: { updateAttendee },
  } = useScriptState(event)

  const onSubmitClickHandler = (attendee: ScriptAttendee) => {
    if (onSubmitClick) {
      onSubmitClick(attendee)
    } else {
      updateAttendee(attendee)
    }
    setEdit(false)
  }

  const onEditClickHandler = () => {
    setEdit(true)
  }

  const onCancelClick = () => {
    setEdit(false)
  }

  return (
    <ActionContainer>
      {edit ? (
        <IdentityDocumentInput
          attendee={attendee}
          onSubmitClick={onSubmitClickHandler}
          onCancelClick={() => onCancelClick()}
          namePrefix={namePrefix}
          requiredDocumentNumber
        />
      ) : (
        <IdentityDocumentView
          attendee={attendee}
          onEditClick={onEditClickHandler}
        />
      )}
    </ActionContainer>
  )
}
