import React from 'react'
import { TimeInput } from '@mantine/dates'

type Props = Omit<React.ComponentProps<typeof TimeInput>, 'onChange'> & {
  value: string | undefined
  onChange: (value: string | null) => void
}

// Mantine in version 6 uses default HTML time input
// So, in order to preserve original functionality we needed to crate our own component
// that take the time string and converts it into Date object

export const TimeField: React.FC<Props> = React.forwardRef(
  // TODO: fix type
  // eslint-disable-next-line react/prop-types
  ({ value, onChange, ...props }, ref) => {
    const timeStringToDate = (timeString: string): string | null => {
      if (!timeString) {
        return null
      }

      const [hours, minutes] = timeString.split(':').map(Number)
      const currentDateTime = new Date()
      currentDateTime.setHours(hours, minutes)

      return currentDateTime.toISOString()
    }

    const dateToTimeString = (dateValue: string | undefined) => {
      if (!dateValue) {
        return ''
      }

      const date = new Date(dateValue)
      if (isNaN(date.getTime())) {
        return dateValue
      }

      return `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(timeStringToDate(event.target.value))
    }

    return (
      <TimeInput
        ref={ref}
        value={dateToTimeString(value)}
        onChange={handleChange}
        {...props}
      />
    )
  },
)

TimeField.displayName = 'TimeField'
