import { Dayjs } from 'dayjs'
import * as yup from 'yup'

import { DepositionResult } from '@/constants'

export const canceledEventSchema = yup.object().shape({
  result: yup
    .mixed<DepositionResult>()
    .oneOf(Object.values(DepositionResult))
    .required('Please select how deposition ended'),
  notes: yup.string().nullable(),
  reason_for_cna: yup.string().when('result', {
    is: (value: string) => value === DepositionResult.CNA,
    then: schema => schema.required('Please select reason for CNA'),
    otherwise: schema => schema.nullable(),
  }),
  cna_declared_at: yup.mixed<Dayjs>().when('result', {
    is: (value: string) => value === DepositionResult.CNA,
    then: schema =>
      schema.required(
        'You must go on the record to declare CNA. Do that by clicking the ‘Go on record’ button.',
      ),
    otherwise: schema => schema.nullable(),
  }),
  other_reason: yup.string().when('reason_for_cna', {
    is: (value: string) => value === 'other',
    then: schema => schema.required('Please state other reason for CNA'),
    otherwise: schema => schema.nullable(),
  }),
})
