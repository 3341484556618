import { useEffect, useState } from 'react'
import { Text } from '@mantine/core'
import { modals } from '@mantine/modals'

import { AddAttendeeForm } from '@/components/Event/NewScript/components/common/ParticipantsMapping/AddAttendeeForm'
import { ParticipantAttendeesMultiselect } from '@/components/Event/NewScript/components/common/ParticipantsMapping/ParticipantAttendeesMultiselect'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useEventZoomParticipants } from '@/components/Event/NewScript/queries/scriptData'
import { Event, ScriptAttendee, ZoomParticipant } from '@/types'
import { formatTimeForTimezone } from '@/utils/time'

type ParticipantsMappingModalType = {
  event: Event
  enabled?: boolean
}

export const useParticipantsMapping = ({
  event,
  enabled = false,
}: ParticipantsMappingModalType) => {
  const [open, setOpen] = useState(false)
  const { state, actions } = useScriptState(event)
  const isEnabled = enabled && !open
  const { data: eventParticipants } = useEventZoomParticipants(event, {
    enabled: isEnabled,
    refetchInterval: 10_000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    if (!eventParticipants?.length) {
      return
    }

    const mappedParticipants = [
      ...new Set([...state.attendees.flatMap(a => a.zoom_participants)]),
    ]
    const newParticipants = eventParticipants.filter(
      p => !mappedParticipants.some(mp => mp?.id === p.id),
    )

    if (!newParticipants.length) {
      return
    }

    openMappingModal(newParticipants[0], state.attendees, eventParticipants)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventParticipants, enabled, open, state.attendees, isEnabled])

  const onAddNewAttendee = (newAttendee: ScriptAttendee) => {
    actions.updateGlobalState({
      attendees: [...state.attendees, newAttendee],
    })
  }

  const onMappedAttendees = (updatedAttendees: ScriptAttendee[]) => {
    actions.updateGlobalState({
      attendees: updatedAttendees,
    })
  }

  const openAddNewAttendeeModal = (participant: ZoomParticipant) => {
    if (!eventParticipants) {
      return
    }

    modals.open({
      modalId: 'add-attendee',
      title: (
        <Text size="lg" fw={700}>
          Zoom account called "{participant.user_name}" has joined the
          proceeding.
        </Text>
      ),
      size: 740,
      withCloseButton: false,
      centered: true,
      closeOnClickOutside: false,
      children: (
        <AddAttendeeForm
          timezone={event.team.timezone}
          eventType={event.type}
          onSelectClick={() => {
            openMappingModal(participant, state.attendees, eventParticipants)
            modals.close('add-attendee')
          }}
          onSave={newAttendee => {
            onAddNewAttendee(newAttendee)
            openMappingModal(
              participant,
              [...state.attendees, newAttendee],
              eventParticipants,
            )
            modals.close('add-attendee')
          }}
          participant={participant}
        />
      ),
    })
  }

  const openMappingModal = (
    participant: ZoomParticipant,
    attendees: ScriptAttendee[],
    allZoomParticipants: ZoomParticipant[],
  ) => {
    if (!eventParticipants) {
      return
    }

    modals.open({
      onClose: () => {
        // updating state like this as otherwise it would prevent closing the modal
        setTimeout(() => setOpen(false))
      },
      modalId: 'mapping',
      title: (
        <Text size="lg" fw={700}>
          New Zoom user "{participant.user_name}" has just joined at{' '}
          {formatTimeForTimezone(participant.join_time, event.team.timezone)}.
        </Text>
      ),
      size: 740,
      withCloseButton: false,
      centered: true,
      closeOnClickOutside: false,
      children: (
        <ParticipantAttendeesMultiselect
          onAddAttendeeClick={() => {
            openAddNewAttendeeModal(participant)
          }}
          zoomParticipants={allZoomParticipants}
          attendees={attendees}
          onSave={(updatedAttendees: ScriptAttendee[]) => {
            onMappedAttendees(updatedAttendees)
            modals.close('mapping')
          }}
          participant={participant}
        />
      ),
    })
    setOpen(true)
  }
}
