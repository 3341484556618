import React from 'react'
import { Checkbox, Select, TextInput } from '@mantine/core'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import includes from 'lodash/includes'
import union from 'lodash/union'
import without from 'lodash/without'

import { ReporterSelect } from '@/components/ReporterSelect'
import { StateSelect } from '@/components/StateSelect'
import { Event, ExperienceLevel, ReporterBase, ReporterEvent } from '@/types'
import { EXPERIENCE_LEVELS } from '@/utils/parrot'

export const ReporterStatusFilter: React.FC<{
  values: ReporterEvent['reporter_status'][]
  onChange: (values: ReporterEvent['reporter_status'][]) => void
}> = ({ values, onChange }) => {
  const statuses: {
    values: ReporterEvent['reporter_status'][]
    label: string
    color: string
  }[] = [
    { values: ['available'], label: 'Available', color: 'green' },
    { values: ['booked'], label: 'My Bookings', color: 'indigo' },
    { values: ['waiting'], label: 'Waiting for action', color: 'red' },
    { values: ['past'], label: 'Past', color: 'gray' },
    { values: ['cancelled'], label: 'My canceled', color: 'gray' },
    {
      values: ['overlapping', 'overlapping_related'],
      label: 'Overlapping',
      color: 'yellow',
    },
  ]

  return (
    <>
      {statuses.map(status => (
        <Checkbox
          key={status.values[0]}
          color={status.color}
          label={status.label}
          checked={includes(values, status.values[0])}
          onChange={e =>
            onChange(
              e.currentTarget.checked
                ? union(values, status.values)
                : without(values, ...status.values),
            )
          }
        />
      ))}
    </>
  )
}

export const GeneralStatusFilter: React.FC<{
  values: Event['general_status'][]
  onChange: (values: Event['general_status'][]) => void
}> = ({ values, onChange }) => {
  const statuses: {
    value: Event['general_status']
    label: string
    color: string
  }[] = [
    { value: 'available', label: 'Available', color: 'green' },
    { value: 'booked', label: 'Booked', color: 'indigo' },
    { value: 'waiting', label: 'Waiting for action', color: 'red' },
    { value: 'past', label: 'Past', color: 'gray' },
    { value: 'cancelled', label: 'Canceled', color: 'gray' },
  ]

  return (
    <>
      {statuses.map(status => (
        <Checkbox
          key={status.value}
          color={status.color}
          label={status.label}
          checked={includes(values, status.value)}
          onChange={e =>
            onChange(
              e.currentTarget.checked
                ? union(values, [status.value])
                : without(values, status.value),
            )
          }
        />
      ))}
    </>
  )
}

export const ReporterFilter: React.FC<{
  reporters: ReporterBase[]
  value?: ReporterBase['id']
  onChange: (value?: ReporterBase['id']) => void
}> = ({ reporters, value, onChange }) => (
  <ReporterSelect
    value={value}
    reporters={reporters}
    onChange={onChange}
    placeholder="All reporters"
    clearable
    searchable
  />
)

export const StateFilter: React.FC<{
  value?: string | null
  states: Array<string>
  onChange: (value: string | null) => void
}> = ({ value, onChange, states }) => (
  <StateSelect
    value={value}
    states={states}
    onChange={onChange}
    placeholder="All states"
    clearable
  />
)

export const SearchFilter: React.FC<{
  value?: string
  placeholder?: string
  onChange: (value: string) => void
}> = ({ value, placeholder = 'Search', onChange }) => (
  <TextInput
    rightSection={<MagnifyingGlassIcon color="gray" />}
    placeholder={placeholder}
    value={value ?? ''}
    onKeyDown={e => {
      // we stop here so the keystrokes are not caught
      // by `useHotKeys` which sits above in the tree
      e.stopPropagation()
    }}
    onChange={e => onChange(e.target.value)}
  />
)

export const RequiredExperienceLevelFilter: React.FC<{
  value?: ExperienceLevel
  maxLevel?: ExperienceLevel
  onChange: (value?: ExperienceLevel) => void
}> = ({ value, maxLevel, onChange }) => (
  <Select
    data={EXPERIENCE_LEVELS.map(level => ({
      ...level,
      disabled: maxLevel !== undefined && parseInt(level.value) > maxLevel,
    }))}
    placeholder="All event types"
    clearable
    searchable
    value={value?.toString() ?? null}
    onChange={value =>
      onChange(
        value !== null ? (parseInt(value) as ExperienceLevel) : undefined,
      )
    }
  />
)
