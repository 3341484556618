import React from 'react'
import { MantineProvider } from '@mantine/core'

import { CustomFonts } from '@/CustomFonts'

export const ThemeProvider: React.FC = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        primaryColor: 'indigo',
        fontFamilyMonospace: 'Aeonik Mono, monospace',
        components: {
          Button: {
            styles: (theme, params) => ({
              light: {
                color: theme.colors[params.color || theme.primaryColor]?.[8],
              },
            }),
          },
          Select: {
            styles: { item: { wordBreak: 'normal' } },
          },
        },
      }}
    >
      <CustomFonts />
      {children}
    </MantineProvider>
  )
}
