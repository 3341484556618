import { Dayjs } from 'dayjs'

import {
  DepositionResult,
  ReasonForCna,
  ScriptStep,
  ScriptStepStatus,
  TIMESTAMP_MESSAGES,
  TimestampType,
} from '@/constants'
import {
  DRScriptData,
  LowConfidenceWord,
  ScriptAttendee,
  ScriptIdentityDocument,
  ShownExhibit,
} from '@/types'
import { formatTimeForTimezone, getCurrentTimeInUtc } from '@/utils/time'

type StepAndActionKey = {
  step: string
  key: string
}

// TODO: Fix any type
export type Action = (state: DRScriptData, data?: any) => DRScriptData
export type StateActions = {
  completeStepAction: Action
  undoStepAction: Action
  updateAttendees: Action
  updateCurrentStep: Action
  startStep: Action
  completeStep: Action
  skipStep: Action
  updateNotes: Action
  updateSpellings: Action
  updateTimestamps: Action
  updateAttendee: Action
  updateIsOnRecord: Action
  updateLastTimestamp: Action
  skipStepAction: Action
  addTimestamp: Action
  updateConductingData: Action
  unskipStepAction: Action
  updateOrderTranscript: Action
  updateTranscriptOrders: Action
  updateWitnessRequestedTranscript: Action
  setFirstSkippedStep: Action
  updateEventResult: Action
  updateReasonForCna: Action
  updateOtherReason: Action
  updateCounty: Action
  updateState: Action
  updateIsRecordingStarted: Action
  updateIsRecordingEnded: Action
  updateLowConfidenceWords: Action
  updateShownExhibits: Action
  completeStepAndContinue: Action
  cancelProceeding: Action
  updateGlobalState: Action
  incompleteStep: Action
  revertCanceledProceeding: Action
}

// Timestamps
export const addTimestamp = (
  state: DRScriptData,
  payload: {
    timestampType: TimestampType
    timezone: string
    isOnRecord?: boolean
    isCnaDeclaration?: boolean
  },
) => {
  const timestampTime = getCurrentTimeInUtc()
  const timestampText = `${formatTimeForTimezone(
    timestampTime.toISOString(),
    payload.timezone,
  )} ${TIMESTAMP_MESSAGES[payload.timestampType]}\n`

  return {
    ...state,
    last_timestamp: timestampTime,
    timestamps: (state.timestamps ?? '') + timestampText,
    ...(payload.isOnRecord !== undefined && {
      is_on_record: payload.isOnRecord,
    }),
    ...(payload.isCnaDeclaration !== undefined && {
      cna_declared_at: getCurrentTimeInUtc(),
    }),
  }
}

export const updateLastTimestamp = (
  state: DRScriptData,
  { lastTimestamp, timestamps }: { lastTimestamp: Dayjs; timestamps: string },
) => {
  return {
    ...state,
    last_timestamp: lastTimestamp,
    timestamps,
  }
}

export const updateIsOnRecord = (
  state: DRScriptData,
  {
    isOnRecord,
    timestamp,
    timestamps,
  }: { isOnRecord: boolean; timestamp: Dayjs; timestamps: string },
) => {
  return {
    ...state,
    is_on_record: isOnRecord,
    last_timestamp: timestamp,
    timestamps,
  }
}

export const updateTimestamps = (state: DRScriptData, timestamps: string) => {
  return {
    ...state,
    timestamps,
  }
}

// Attendee
export const updateTranscriptOrders = (
  state: DRScriptData,
  transcriptOrders: {
    email: string
    turnaround_hours: number
  }[],
) => {
  return {
    ...state,
    transcript_orders: transcriptOrders,
  }
}

export const updateAttendee = (
  state: DRScriptData,
  attendee: ScriptAttendee,
) => {
  const updatedAttendees = state.attendees.map(a =>
    a.attendee_id === attendee.attendee_id ? { ...a, ...attendee } : a,
  )

  return {
    ...state,
    attendees: [...updatedAttendees],
  }
}

export const updateAttendees = (
  state: DRScriptData,
  attendees: ScriptAttendee[],
) => {
  const updatedAttendees: ScriptAttendee[] = attendees.map(a => {
    const existingAttendee = state.attendees.find(
      attendee => attendee.attendee_id === a.attendee_id,
    )

    return {
      ...existingAttendee,
      ...a,
    }
  })

  return {
    ...state,
    attendees: [...updatedAttendees],
  }
}

// Identity document
export const updateIdentityDocument = (
  state: DRScriptData,
  attendeeId: string,
  identityDocument: ScriptIdentityDocument[],
) => {
  const updatedAttendees = state.attendees.map(a =>
    a.attendee_id === attendeeId ? { ...a, identityDocument } : a,
  )

  return {
    ...state,
    attendees: [...updatedAttendees],
  }
}

// Opening
export const updateIsRecordingStarted = (
  state: DRScriptData,
  isRecordingStarted: boolean | undefined,
) => {
  return {
    ...state,
    ...(isRecordingStarted && { isRecordingStarted }),
  }
}

export const updateIsRecordingEnded = (
  state: DRScriptData,
  isRecordingEnded: boolean | undefined,
) => {
  return {
    ...state,
    ...(isRecordingEnded && { isRecordingEnded }),
  }
}

// Questioning
export const updateSpellings = (state: DRScriptData, spellings: string) => {
  return {
    ...state,
    spellings,
  }
}

export const updateConductingData = (
  state: DRScriptData,
  {
    notes,
    spellings,
    timestamps,
  }: {
    notes: string
    spellings: string
    timestamps: string
  },
) => {
  return {
    ...state,
    notes,
    spellings,
    timestamps,
  }
}

// Closing
export const updateOrderTranscript = (
  state: DRScriptData,
  orderTranscript: boolean,
) => {
  return {
    ...state,
    order_transcript: orderTranscript,
    ...(!orderTranscript && {
      attendees: state.attendees?.map(a => ({ ...a, transcript_order: null })),
    }),
  }
}

export const updateWitnessRequestedTranscript = (
  state: DRScriptData,
  witnessRequestedTranscript: boolean,
) => {
  return {
    ...state,
    witness_requested_transcript: witnessRequestedTranscript,
  }
}

export const setFirstSkippedStep = (state: DRScriptData, step: ScriptStep) => {
  return {
    ...state,
    first_skipped_step: step,
  }
}

export const updateEventResult = (
  state: DRScriptData,
  eventResult: DepositionResult,
) => {
  return {
    ...state,
    result: eventResult,
  }
}

export const updateNotes = (state: DRScriptData, notes: string) => {
  return {
    ...state,
    notes: notes,
  }
}

export const updateReasonForCna = (
  state: DRScriptData,
  reasonForCna: string,
) => {
  return {
    ...state,
    reason_for_cna: reasonForCna,
  }
}

export const updateOtherReason = (state: DRScriptData, otherReason: string) => {
  return {
    ...state,
    other_reason: otherReason,
  }
}

// Review
export const updateState = (newState: DRScriptData, state: string) => {
  return {
    ...newState,
    state,
  }
}

export const updateCounty = (state: DRScriptData, county: string) => {
  return {
    ...state,
    county,
  }
}

export const updateShownExhibits = (
  state: DRScriptData,
  shownExhibits: ShownExhibit[],
) => {
  return { ...state, shown_exhibits: shownExhibits }
}

export const updateLowConfidenceWords = (
  state: DRScriptData,
  words: LowConfidenceWord[],
) => {
  return { ...state, low_confidence_words: words }
}

// Script timeline
export const completeStepAction = (
  state: DRScriptData,
  {
    result,
    step,
    key,
  }: { result?: DepositionResult; step: string; key: string },
) => {
  return {
    ...state,
    ...(result && { result }),
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        actions: {
          ...state.steps[step].actions,
          [key]: {
            ...state.steps[step].actions[key],
            completed: true,
          },
        },
      },
    },
  }
}

export const skipStepAction = (
  state: DRScriptData,
  {
    stepAndActionKeys,
    result,
    reasonForCNA,
    otherReason,
    notes,
  }: {
    stepAndActionKeys: StepAndActionKey[]
    result?: DepositionResult
    reasonForCNA?: ReasonForCna
    otherReason?: string
    notes?: string
  },
) => {
  const steps = state.steps
  stepAndActionKeys.forEach(value => {
    steps[value.step].actions[value.key].skip = true
  })

  return {
    ...state,
    ...(result && { result }),
    ...(reasonForCNA && { reason_for_cna: reasonForCNA }),
    ...(otherReason && { other_reason: otherReason }),
    ...(notes && { notes }),
    steps: {
      ...state.steps,
      ...steps,
    },
  }
}

export const unskipStepAction = (
  state: DRScriptData,
  {
    stepAndActionKeys,
    result,
    reasonForCNA,
    otherReason,
    notes,
  }: {
    stepAndActionKeys: StepAndActionKey[]
    result?: DepositionResult
    reasonForCNA?: ReasonForCna
    otherReason?: string
    notes?: string
  },
) => {
  const steps = state.steps
  stepAndActionKeys.forEach(value => {
    steps[value.step].actions[value.key].skip = false
  })

  return {
    ...state,
    ...(result && { result }),
    ...(reasonForCNA && { reason_for_cna: reasonForCNA }),
    ...(otherReason && { other_reason: otherReason }),
    ...(notes && { notes }),
    steps: {
      ...state.steps,
      ...steps,
    },
  }
}

export const undoStepAction = (
  state: DRScriptData,
  { step, key }: { step: string; key: string },
) => {
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        actions: {
          ...state.steps[step].actions,
          [key]: {
            ...state.steps[step].actions[key],
            completed: false,
          },
        },
      },
    },
  }
}

export const updateCurrentStep = (
  state: DRScriptData,
  current_step: ScriptStep,
) => {
  return {
    ...state,
    current_step,
  }
}

export const completeStep = (state: DRScriptData, step: ScriptStep) => {
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        status: ScriptStepStatus.COMPLETED,
      },
    },
  }
}

export const completeStepAndContinue = (
  state: DRScriptData,
  { step, nextStep }: { step: ScriptStep; nextStep: ScriptStep },
) => {
  const result = startStep(completeStep(state, step), nextStep)
  return updateCurrentStep(result, nextStep)
}

export const skipStep = (state: DRScriptData, step: ScriptStep) => {
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        status: ScriptStepStatus.SKIPPED,
      },
    },
  }
}

export const incompleteStep = (state: DRScriptData, step: ScriptStep) => {
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        status: ScriptStepStatus.INCOMPLETE,
      },
    },
  }
}

export const startStep = (state: DRScriptData, step: ScriptStep) => {
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        status: ScriptStepStatus.IN_PROGRESS,
      },
    },
  }
}

export const revertCanceledProceeding = (
  state: DRScriptData,
  isEUO: boolean,
) => {
  const closingSteps = [ScriptStep.CLOSING, ScriptStep.REVIEW]
  let newState = { ...state }

  // Unskip all steps
  Object.values(state.steps).forEach(step => {
    if (
      step.status === ScriptStepStatus.SKIPPED ||
      closingSteps.includes(step.key)
    ) {
      newState = incompleteStep(newState, step.key)
    }
  })

  // Skip all steps from canceled closing step
  newState = skipStepAction(newState, {
    stepAndActionKeys: [
      {
        step: ScriptStep.CLOSING,
        key: 'state_the_cna',
      },
      {
        step: ScriptStep.CLOSING,
        key: 'select_reason_for_cna',
      },
      {
        step: ScriptStep.CLOSING,
        key: 'declare_how_deposition_ended',
      },
    ],
  })

  newState = unskipStepAction(newState, {
    stepAndActionKeys: [
      {
        step: ScriptStep.CLOSING,
        key: 'clarify_exhibit_and_spelling',
      },
      {
        step: ScriptStep.CLOSING,
        key: 'go_off_the_record',
      },
      ...(!isEUO
        ? [
            {
              step: ScriptStep.CLOSING,
              key: 'collect_information',
            },
          ]
        : []),
    ],
  })

  // Uncheck all steps from closing step
  Object.values(state.steps[ScriptStep.CLOSING].actions).forEach(action => {
    newState = undoStepAction(newState, {
      step: ScriptStep.CLOSING,
      key: action.key,
    })
  })

  // Uncheck all steps from review step
  Object.values(state.steps[ScriptStep.REVIEW].actions).forEach(action => {
    newState = undoStepAction(newState, {
      step: ScriptStep.REVIEW,
      key: action.key,
    })
  })

  // Reset values for closing step
  newState = updateEventResult(newState, null as never)
  newState = updateReasonForCna(newState, '')
  newState = updateOtherReason(newState, '')
  newState = updateNotes(newState, '')

  // Clearing timestamps in case they went on record during CNA,
  // because it prevented reporter from going on record during Opening step
  if (
    state.first_skipped_step === ScriptStep.ATTENDANCE ||
    state.first_skipped_step === ScriptStep.OPENING
  ) {
    newState = updateTimestamps(newState, '')
  }

  // Reset values for navigation
  newState = setFirstSkippedStep(newState, null as never)
  newState = updateCurrentStep(newState, state.first_skipped_step!)
  newState = startStep(newState, state.first_skipped_step!)

  return newState
}

// cancel proceeding
export const cancelProceeding = (
  state: DRScriptData,
  {
    skipSteps,
    skipStepActions,
    unskipStepActions,
    first_skipped_step,
  }: {
    skipSteps: ScriptStep[]
    skipStepActions: StepAndActionKey[]
    unskipStepActions: StepAndActionKey[]
    first_skipped_step: ScriptStep | null
  },
) => {
  let newState = { ...state }

  for (const step of skipSteps) {
    newState = skipStep(newState, step)
  }

  newState = skipStepAction(newState, { stepAndActionKeys: skipStepActions })
  newState = unskipStepAction(newState, {
    stepAndActionKeys: unskipStepActions,
  })
  newState = updateCurrentStep(newState, ScriptStep.CLOSING)
  newState = startStep(newState, ScriptStep.CLOSING)

  if (first_skipped_step !== null) {
    newState = setFirstSkippedStep(newState, first_skipped_step)
  }

  return newState
}

export const updateGlobalState = (
  state: DRScriptData,
  data: Partial<DRScriptData>,
) => {
  return {
    ...state,
    ...data,
  }
}

export const ALL_ACTIONS: StateActions = {
  completeStepAction,
  undoStepAction,
  updateAttendees,
  updateCurrentStep,
  startStep,
  completeStep,
  skipStep,
  updateNotes,
  updateSpellings,
  updateTimestamps,
  updateAttendee,
  updateIsOnRecord,
  updateLastTimestamp,
  skipStepAction,
  addTimestamp,
  updateConductingData,
  unskipStepAction,
  updateOrderTranscript,
  updateTranscriptOrders,
  updateWitnessRequestedTranscript,
  setFirstSkippedStep,
  updateEventResult,
  updateReasonForCna,
  updateOtherReason,
  updateCounty,
  updateState,
  updateIsRecordingStarted,
  updateIsRecordingEnded,
  updateLowConfidenceWords,
  completeStepAndContinue,
  cancelProceeding,
  updateGlobalState,
  incompleteStep,
  revertCanceledProceeding,
  updateShownExhibits,
}
