import React, { useEffect, useState } from 'react'
import { Alert, Button, List } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { ExclamationTriangleIcon, InfoCircledIcon } from '@radix-ui/react-icons'

import { AttendeeFormValues, AttendeeInput } from './AttendeeInput'

import { getAttendeesWarnings } from '@/components/Event/attendeesWarnings'
import { AttendeeRole } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { ReporterFeedback } from '@/types'

export const AttendeesInput: React.FC<{
  form: UseFormReturnType<ReporterFeedback>
  roles: AttendeeRole[]
}> = ({ form, roles }) => {
  const [warnings, setWarnings] = useState<string[]>([])
  const [affectedFieldsWarnings, setAffectedFieldsWarnings] = useState<
    Set<keyof AttendeeFormValues>[]
  >([])
  const { user } = useAuth()

  useEffect(() => {
    const { affectedFields, warnings } = getAttendeesWarnings(
      form.values.attendees,
      user?.email,
    )
    setWarnings(warnings)
    setAffectedFieldsWarnings(affectedFields)
  }, [form.values.attendees, user?.email])

  return (
    <div className="flex">
      <div className="flex-1 space-y-4">
        {form.values.attendees.map((_: AttendeeFormValues, index: number) => (
          <AttendeeInput
            key={index}
            roles={roles}
            form={form}
            index={index}
            attendeeWarnings={affectedFieldsWarnings}
          />
        ))}
        {warnings.length === 0 && (
          <Alert
            icon={<InfoCircledIcon />}
            title="The pre-filled values may contain mistakes"
          >
            <p>Only submit valid person names and add all missing attendees.</p>
            <p>
              Remove words that aren’t actual names (esq, office etc.) from the
              above fields.
            </p>
          </Alert>
        )}
        {warnings.length > 0 && (
          <Alert icon={<ExclamationTriangleIcon />} color="orange">
            Please, double check the following attendee names and roles. They
            look a bit suspicious to us but we may be wrong:
            <List
              size="sm"
              mt="md"
              type="unordered"
              withPadding
              style={{ listStyle: 'disc' }}
            >
              {warnings.map((warning, idx) => (
                <List.Item key={idx}>{warning}</List.Item>
              ))}
            </List>
          </Alert>
        )}
        <Button
          color="gray"
          variant="light"
          onClick={() => {
            const newAttendee = {
              name: '',
              email: null,
              // use a default role as the field is not nullable & we use autosave
              role: roles[roles.length - 1],
              present: true,
            }
            form.insertListItem('attendees', newAttendee)
          }}
        >
          Add attendee
        </Button>
      </div>
    </div>
  )
}
