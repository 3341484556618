import { ReactNode } from 'react'
import { createStyles } from '@mantine/core'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

const useWarningStyle = createStyles(theme => ({
  input: {
    color: theme.black,
    borderColor: theme.colors.orange[6],
  },
  icon: {
    color: theme.colors.orange[6],
  },
}))

type HookResult = {
  getInputWarningProps: (
    index: number,
    field: string,
  ) => {
    classNames: Record<string, string | undefined>
    rightSection?: ReactNode
  }
}

export const useInputWarnings = (warnings: Set<string>[]): HookResult => {
  const { classes: warningStyleClasses } = useWarningStyle()
  return {
    getInputWarningProps: (index: number, field: string) => ({
      classNames: {
        input:
          warnings[index] && warnings[index].has(field)
            ? warningStyleClasses.input
            : undefined,
      },
      rightSection:
        warnings[index] && warnings[index].has(field) ? (
          <ExclamationTriangleIcon className={warningStyleClasses.icon} />
        ) : undefined,
    }),
  }
}
