import React from 'react'

import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import { Event } from '@/types'

export type ClosingVerbatimProps = {
  event: Event
}

export const ClosingVerbatim: React.FC<ClosingVerbatimProps> = ({ event }) => {
  const { time, timezone } = useFormattedCurrentDateParts(event.team.timezone)

  return (
    <>
      If there is nothing further, it is {time} {timezone} and we are now off
      the record.
    </>
  )
}
