import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'

import { AnalyticsProvider } from './providers/AnalyticsProvider'
import { App } from './App'

import './main.css'

import './polyfills'
import { AuthProvider } from '@/providers/AuthProvider'
import { AxiosProvider } from '@/providers/AxiosProvider'
import { RouterProvider } from '@/providers/RouterProvider'
import { SentryProvider } from '@/providers/SentryProvider'
import { ThemeProvider } from '@/providers/ThemeProvider'
import { UserProvider } from '@/providers/UserProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <SentryProvider>
      <RouterProvider>
        <ThemeProvider>
          <AuthProvider>
            <AxiosProvider>
              <QueryClientProvider client={queryClient}>
                <AnalyticsProvider>
                  <Notifications />
                  <UserProvider>
                    <ModalsProvider>
                      <App />
                    </ModalsProvider>
                  </UserProvider>
                </AnalyticsProvider>
              </QueryClientProvider>
            </AxiosProvider>
          </AuthProvider>
        </ThemeProvider>
      </RouterProvider>
    </SentryProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
