import React from 'react'
import { Box, Button, Group, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { RelatedEventsNotice } from './RelatedEventsNotice'
import { UncancellableBookingNotice } from './UncancellableBookingNotice'

import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { useAuth } from '@/hooks/useAuth'
import { useModals } from '@/hooks/useModals'
import { useUnbookMutation } from '@/queries/events'
import { useRelatedEvents } from '@/queries/relatedEvents'
import { Event, ReporterEvent } from '@/types'
import { isReporterEvent } from '@/utils/parrot'
import { formatDuration, formatTimeRange } from '@/utils/time'

export const CancelEventBookingButton: React.FC<{
  event: Event
  onCancel: () => void
}> = ({ event, onCancel }) => {
  const { cancel, isLoading } = useCancel(event, onCancel)

  return (
    <Button onClick={cancel} color="red" variant="light" loading={isLoading}>
      Cancel booking
    </Button>
  )
}

export const ConfirmCancelModal: React.FC<{
  event: ReporterEvent
  relatedEvents: Event[]
  onCancel: () => void
}> = ({ event, relatedEvents, onCancel }) => {
  const modals = useModals()
  const trackUserAction = useTrackUserAction()

  const onSuccess = () => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()
    showNotification({
      message: <Text weight={600}>Event booking cancelled</Text>,
    })
    trackUserAction('Event booking cancelled', { event_id: event.id })
    onCancel()
  }

  const onErrorMessage = (message: string) => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()
    showNotification({ color: 'red', message })
  }

  const { mutateAsync, isLoading } = useUnbookMutation(event, {
    onSuccess,
    onErrorMessage,
  })

  return (
    <>
      <Box mb={20}>
        {relatedEvents.length > 0 ? (
          <RelatedEventsNotice
            event={event}
            action="cancel"
            relatedEvents={relatedEvents}
          />
        ) : (
          <>
            Do you want to cancel the {event.name} on{' '}
            {formatTimeRange(event.datetime, event.duration, { long: true })} (
            {formatDuration(event.duration)})?
          </>
        )}
      </Box>
      <Group position="right">
        <Button variant="light" color="gray" onClick={() => modals.closeAll()}>
          Close
        </Button>
        <Button
          color="red"
          variant="filled"
          loading={isLoading}
          onClick={() => mutateAsync()}
        >
          {relatedEvents.length > 0 ? 'Cancel all bookings' : 'Cancel booking'}
        </Button>
      </Group>
    </>
  )
}

const UncancellableBookingModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <>
      <UncancellableBookingNotice />

      <Group position="right">
        <Button className="mt-4" variant="light" color="gray" onClick={onClose}>
          Ok, understood
        </Button>
      </Group>
    </>
  )
}

const useCancel = (event: Event, onCancel: () => void) => {
  const modals = useModals()
  const { user } = useAuth()
  const { isLoading, refetch } = useRelatedEvents(event, 'booked', {
    enabled: false,
  })

  if (
    user?.role === 'reporter' &&
    isReporterEvent(event) &&
    !event.is_cancelable
  ) {
    return {
      isLoading: false,
      cancel: () => {
        modals.openModal({
          title: 'Booking cannot be canceled in app',
          children: <UncancellableBookingModal onClose={modals.closeAll} />,
          centered: true,
        })
      },
    }
  }

  const showConfirmModal = (relatedEvents: Event[]) => {
    if (user?.role === 'reporter' && isReporterEvent(event)) {
      modals.openModal({
        title:
          relatedEvents?.length > 0
            ? `Cancel ${event.related_events_type} events bookings`
            : 'Do you want to cancel booking?',
        children: (
          <ConfirmCancelModal
            event={event}
            relatedEvents={relatedEvents}
            onCancel={onCancel}
          />
        ),
        centered: true,
      })
    } else {
      modals.openModal({
        title:
          relatedEvents?.length > 0
            ? `Cancel ${event.related_events_type} events bookings`
            : 'Do you want to cancel booking?',
        children: (
          <ConfirmUnassignModal
            event={event}
            relatedEvents={relatedEvents}
            onCancel={onCancel}
          />
        ),
        centered: true,
      })
    }
  }

  return {
    isLoading,
    cancel: () => refetch().then(r => showConfirmModal(r.data ?? [])),
  }
}

export const ConfirmUnassignModal: React.FC<{
  event: Event
  relatedEvents: Event[]
  onCancel: () => void
}> = ({ event, relatedEvents, onCancel }) => {
  const modals = useModals()

  const onSuccess = () => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()
    showNotification({
      message: <Text weight={600}>Event booking cancelled</Text>,
    })
    onCancel()
  }

  const onErrorMessage = (message: string) => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()
    showNotification({ color: 'red', message })
  }

  const { mutateAsync: unbook, isLoading: isUnbookLoading } = useUnbookMutation(
    event,
    { onSuccess, onErrorMessage },
  )
  const { mutateAsync: unbookAll, isLoading: isUnbookAllLoading } =
    useUnbookMutation(
      event,
      { onSuccess, onErrorMessage },
      { unbook_related_events: true },
    )

  return (
    <>
      <Box mb={20}>
        {event.has_related_events ? (
          <RelatedEventsNotice
            event={event}
            relatedEvents={relatedEvents}
            action="cancel"
          />
        ) : (
          <>
            Do you want to cancel the {event.name} on{' '}
            {formatTimeRange(event.datetime, event.duration, { long: true })} (
            {formatDuration(event.duration)})?
          </>
        )}
      </Box>
      <Group position="right">
        <Button variant="light" color="gray" onClick={() => modals.closeAll()}>
          Close
        </Button>
        {event.has_related_events ? (
          <>
            <Button
              variant="light"
              color="gray"
              disabled={isUnbookAllLoading}
              loading={isUnbookLoading}
              onClick={() => unbook()}
            >
              Only selected
            </Button>
            <Button
              color="red"
              variant="filled"
              disabled={isUnbookLoading}
              loading={isUnbookAllLoading}
              onClick={() => unbookAll()}
            >
              Cancel all
            </Button>
          </>
        ) : (
          <Button
            color="red"
            variant="filled"
            loading={isUnbookLoading}
            onClick={() => unbook()}
          >
            Cancel booking
          </Button>
        )}
      </Group>
    </>
  )
}
