import React from 'react'

import { ScriptColumnLayout } from './ScriptColumnLayout'
import { ScriptTabsLayout } from './ScriptTabsLayout'

import { useBreakpoints } from '@/hooks/useBreakpoints'
import { Event } from '@/types'

type ScriptLayoutProps = {
  event: Event
  showNavigation?: boolean
}

export const ScriptLayout: React.FC<ScriptLayoutProps> = ({
  event,
  showNavigation = true,
  children,
}) => {
  const { lg, md, sm } = useBreakpoints()
  const isMidScreen = !lg && (md || sm)

  if (lg || isMidScreen) {
    return (
      <ScriptColumnLayout
        showNavigation={showNavigation}
        event={event}
        compact={isMidScreen}
      >
        {children}
      </ScriptColumnLayout>
    )
  }
  return (
    <ScriptTabsLayout showNavigation={showNavigation} event={event}>
      {children}
    </ScriptTabsLayout>
  )
}
