import { useCallback } from 'react'
import { showNotification } from '@mantine/notifications'
import debounce from 'lodash/debounce'

import { useConfirmSubmitModal } from './useConfirmSubmitModal.tsx'
import { useFeedbackFormTracking } from './useFeedbackFormTracking'

import { AttendeeFormValidatedValues } from '@/components/Event/AttendeeInput'
import {
  cnaDeclaredAtValidation,
  cnaTypeOtherTextValidation,
  cnaTypeValidation,
  nameValidation,
  presentValidation,
  reporterCountyValidation,
  reporterStateValidation,
  roleValidation,
  transcriptOrderedValidation,
  transcriptOrdersValidation,
  turnaroundHoursValidation,
  uniqueEmailValidation,
} from '@/components/Event/Feedback/validation'
import { AttendeeRole, TurnaroundHours } from '@/constants'
import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { useForm } from '@/hooks/useForm'
import {
  useEndEventMutation,
  useSaveReporterFeedbackMutation,
  useSubmitReporterFeedbackMutation,
} from '@/queries/events'
import { Event, Reporter, ReporterFeedback } from '@/types'

type ReporterFeedbackFormValidatedValues = {
  reporter_state: string
  reporter_county: string
  attendees: AttendeeFormValidatedValues[]
} & Omit<ReporterFeedback, 'reporter_state' | 'reporter_county'>

export const useFeedbackForm = ({
  event,
  reporter,
  feedback,
  onSubmit,
}: {
  event: Event
  reporter: Reporter
  feedback: ReporterFeedback | undefined
  onSubmit: () => void
}) => {
  const trackUserAction = useTrackUserAction()
  const { trackOnSave } = useFeedbackFormTracking(event.id)
  const confirmAsync = useConfirmSubmitModal(event)
  const { mutateAsync: endAsync } = useEndEventMutation(event, {
    onErrorMessage: message => {
      showNotification({ message, color: 'red' })
    },
  })
  const { mutateAsync: submitAsync } = useSubmitReporterFeedbackMutation(
    event,
    {
      onErrorMessage: message => {
        showNotification({ message, color: 'red' })
      },
    },
  )
  const { mutateAsync: saveAsync, isLoading: isSaving } =
    useSaveReporterFeedbackMutation(event, {
      onErrorMessage: message => {
        showNotification({ message, color: 'red' })
      },
    })

  const debouncedSaveAsync = useCallback(
    payload =>
      debounce(values => {
        saveAsync(values)
        trackOnSave(values)
      }, 2000)(payload),
    [saveAsync, trackOnSave],
  )

  const form = useForm<ReporterFeedback>({
    initialValues: feedback
      ? feedback
      : {
          reporter_state: reporter.state ?? null,
          reporter_county: reporter.county ?? null,
          event_status: 'completed',
          witness_requested_transcript: false,
          transcript_ordered: event.is_court_reporter_event,
          turnaround_hours: event.is_court_reporter_event
            ? TurnaroundHours.SEVEN_DAYS
            : TurnaroundHours.ROUGH_DRAFT,
          cna_type: null,
          cna_type_other_text: null,
          cna_declared_at: null,
          id_type: null,
          id_number: null,
          id_screenshots: [],
          timestamp_log: null,
          additional_notes: null,
          attendees: [
            // add reporter as attendee if he is not listed in attendees list
            ...(event.attendees
              .map(({ email }) => email)
              .includes(reporter.email)
              ? []
              : [
                  {
                    name: reporter.name,
                    role:
                      reporter.role === 'court_reporter'
                        ? AttendeeRole.COURT_REPORTER
                        : AttendeeRole.DIGITAL_REPORTER,
                    email: reporter.email,
                    present: null,
                  },
                ]),
            ...event.attendees.map(({ name, role, email }) => ({
              name,
              role,
              email,
              present: null,
            })),
          ],
          transcript_orders: null,
        },

    validate: {
      reporter_state: reporterStateValidation,
      reporter_county: reporterCountyValidation,
      transcript_ordered: (transcriptOrdered, values) =>
        transcriptOrderedValidation(transcriptOrdered, values, {
          isCourtReporterEvent: event.is_court_reporter_event,
        }),
      turnaround_hours: (turnaroundHours, values) =>
        turnaroundHoursValidation(
          turnaroundHours,
          values,
          !!event.can_order_for_oc,
        ),
      transcript_orders: (turnaroundOrders, values) =>
        transcriptOrdersValidation(turnaroundOrders, values, {
          canOrderForOc: !!event.can_order_for_oc,
          isCourtReporterEvent: event.is_court_reporter_event,
        }),

      attendees: {
        name: nameValidation,
        role: roleValidation,
        email: uniqueEmailValidation,
        present: presentValidation,
      },
      cna_declared_at: cnaDeclaredAtValidation,
      cna_type: cnaTypeValidation,
      cna_type_other_text: cnaTypeOtherTextValidation,
    },
    onChange: debouncedSaveAsync,
    validateInputOnChange: true,
    validateInputOnBlur: true,
  })

  const onSubmitWithErrors = (validationErrors: Record<string, string>) => {
    if (Object.values(validationErrors).length !== 0) {
      showNotification({
        message:
          'Form could not be submitted. Please fix the highlighted errors.',
        color: 'red',
      })

      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return {
    form,
    save: async () => {
      await saveAsync(form.values as ReporterFeedbackFormValidatedValues)

      trackUserAction('Event form saved', { event_id: event.id })
      showNotification({ message: 'Form was saved' })
    },
    saving: isSaving,
    submit: async () => {
      await confirmAsync()

      if (event.is_ongoing) {
        await endAsync()
      }

      await submitAsync(form.values as ReporterFeedbackFormValidatedValues)

      trackUserAction('Event form submitted', { event_id: event.id })
      onSubmit()
    },
    onSubmitWithErrors,
  }
}
