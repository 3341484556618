import React from 'react'
import { Flex, Grid, Stack, Text } from '@mantine/core'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ATTENDEE_ROLE_LABEL } from '@/constants'
import { ScriptAttendee } from '@/types'

export const AttendeeCardView = ({
  attendee,
  onEditClick,
  emailEmptySlot,
}: {
  attendee: ScriptAttendee
  onEditClick: () => void
  emailEmptySlot?: React.ReactNode
}) => {
  return (
    <Grid grow>
      <Grid.Col span={5}>
        <Stack spacing={0}>
          <Text size="sm" fw={600}>
            {attendee.name}
          </Text>
          <Text size="sm">
            {
              ATTENDEE_ROLE_LABEL[
                attendee.role as keyof typeof ATTENDEE_ROLE_LABEL
              ]
            }
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={5}>
        <Stack spacing={0}>
          <Text size="sm">
            {attendee.email ? (
              attendee.email
            ) : emailEmptySlot ? (
              emailEmptySlot
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Text size="sm">{attendee.present ? 'Present' : 'Absent'}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={2}>
        <Flex align="center" justify="end" h={'100%'} w="100%">
          <SubtleButton p={0} onClick={onEditClick}>
            Edit
          </SubtleButton>
        </Flex>
      </Grid.Col>
    </Grid>
  )
}
