import React from 'react'

import { DefaultClosingForm } from './DefaultClosingForm'
import { EventCancelledClosingForm } from './EventCancelledClosingForm'

import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { ScriptStepProps } from '@/types'

export const ClosingStep: React.FC<ScriptStepProps> = ({ event }) => {
  const {
    state: { first_skipped_step },
  } = useScriptState(event)

  return first_skipped_step ? (
    <EventCancelledClosingForm event={event} />
  ) : (
    <DefaultClosingForm event={event} />
  )
}
