import React from 'react'
import { FieldErrorsImpl, useFormContext, useWatch } from 'react-hook-form'
import { Button, Group, Stack, TextInput } from '@mantine/core'
import get from 'lodash/get'

import { SelectInput } from '@/components/common/SelectInput'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { FormWarnings } from '@/components/Event/NewScript/components/common/forms/FormWarnings'
import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { useIdentityDocumentWarnings } from '@/components/Event/NewScript/hooks/form/useIdentityDocumentWarnings'
import { useInputWarnings } from '@/components/Event/NewScript/hooks/form/useInputWarningStyle'
import { getFieldName } from '@/components/Event/NewScript/utils/attendees'
import {
  IDENTITY_DOCUMENT_STATUS_OPTIONS,
  IDENTITY_DOCUMENT_TYPE_OPTIONS,
  IdentityDocumentStatus,
} from '@/constants'
import { ScriptAttendee, ScriptIdentityDocument } from '@/types'

type Props = {
  attendee: ScriptAttendee
  onSubmitClick?: (attendee: ScriptAttendee) => void
  onCancelClick?: () => void
  hideButton?: boolean
  onValidationStateChange?: (isValid: boolean) => void
  requiredDocumentNumber?: boolean
  namePrefix: string
  showWarnings?: boolean
}

export const IdentityDocumentInput: React.FC<Props> = ({
  attendee,
  onSubmitClick,
  onCancelClick,
  hideButton,
  requiredDocumentNumber = false,
  namePrefix,
}) => {
  const { register, control, getValues, trigger, formState } = useFormContext()
  const identityDocument = useWatch({
    control: control,
    name: namePrefix,
  })
  const { warnings, getWarningsMessages } =
    useIdentityDocumentWarnings(identityDocument)
  const { getInputWarningProps } = useInputWarnings(warnings)

  const isStatusNotPresentOrStipulate = [
    IdentityDocumentStatus.NOT_PRESENT,
    IdentityDocumentStatus.STIPULATE,
  ].includes(identityDocument?.status)

  const errors = get(
    formState.errors,
    namePrefix,
    {},
  ) as FieldErrorsImpl<ScriptIdentityDocument>

  const onSaveChangesClick = () => {
    trigger(namePrefix).then(isValid => {
      if (isValid) {
        onSubmitClick?.({
          ...attendee,
          identity_document: {
            ...getValues(namePrefix),
          },
        })
      }
    })
  }

  return (
    <Stack align="flex-start">
      <Stack>
        <Group grow>
          <SelectInput
            control={control}
            name={getFieldName('status', namePrefix)}
            placeholder="Select option"
            label="ID status"
            error={!!errors?.status?.message}
            data={IDENTITY_DOCUMENT_STATUS_OPTIONS}
            withAsterisk
            {...(!requiredDocumentNumber ? getInputWarningProps('status') : {})}
          />
          <SelectInput
            control={control}
            name={getFieldName('document_type', namePrefix)}
            disabled={isStatusNotPresentOrStipulate}
            placeholder="Select option"
            label="ID type"
            error={!!errors?.document_type?.message}
            data={IDENTITY_DOCUMENT_TYPE_OPTIONS}
            withAsterisk={!isStatusNotPresentOrStipulate}
            {...(!isStatusNotPresentOrStipulate
              ? getInputWarningProps('document_type')
              : {})}
          />
          <TextInput
            {...register(getFieldName('number', namePrefix))}
            label="ID number"
            disabled={isStatusNotPresentOrStipulate}
            placeholder="Enter ID number"
            error={!!errors?.number?.message}
            withAsterisk={requiredDocumentNumber}
            {...(!requiredDocumentNumber && !isStatusNotPresentOrStipulate
              ? getInputWarningProps('number')
              : {})}
          />
        </Group>
        <FormErrors<ScriptIdentityDocument> errors={errors} />
        {!requiredDocumentNumber && !isStatusNotPresentOrStipulate && (
          <FormWarnings warnings={getWarningsMessages()} />
        )}
      </Stack>
      {!hideButton && (
        <Group>
          <Button
            variant="filled"
            color="primary.2"
            onClick={onSaveChangesClick}
          >
            Save changes
          </Button>
          <SubtleButton onClick={onCancelClick}>Cancel</SubtleButton>
        </Group>
      )}
    </Stack>
  )
}
