import * as yup from 'yup'

import { ExhibitDesignation } from '@/constants'

export const shownExhibitSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required('Please enter exhibit name'),
    filename: yup.string().required(),
    index: yup.number().required(),
    download_url: yup.string().required(),
    designation: yup
      .mixed<ExhibitDesignation>()
      .oneOf(Object.values(ExhibitDesignation))
      .required('Please select a designation'),
  })
  .required()
