import React from 'react'

import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import {
  getEventTypeLongLabel,
  getEventTypeShortLabel,
  isExaminationUnderOath,
} from '@/components/Event/NewScript/utils/event'
import { AttendeeRole, ParrotContact } from '@/constants'
import { Event, ReporterBase } from '@/types'

type OpeningVerbatimProps = {
  event: Event
  reporter: ReporterBase
  isNY: boolean
  isPA: boolean
}

export const OpeningVerbatim: React.FC<OpeningVerbatimProps> = ({
  event,
  reporter,
  isPA,
  isNY,
}) => {
  const isEUO = isExaminationUnderOath(event)
  const dateTime = useFormattedCurrentDateParts(event.team.timezone)
  const caseNumber = event.case.number ?? '[ ]'
  const reporterCounty = reporter.county ?? '[ ]'
  const reporterState = reporter.state ?? '[ ]'
  const name = reporter.name ?? '[  ]'
  const { state } = useScriptState(event)
  const witness = state.attendees.find(
    attendee => attendee.role === AttendeeRole.WITNESS,
  )

  if (isNY || isPA) {
    return (
      <>
        Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
        {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
        the time is {dateTime.time} {dateTime.timezone}. We are present to
        record a remote {getEventTypeLongLabel(event)} in the case of{' '}
        {event.case.name}, court case number {caseNumber} on behalf of{' '}
        {event.team.organization.name}. My name is {name}, I will be the Parrot
        Digital Reporter for today’s {getEventTypeShortLabel(event)}. I am
        located in {reporterState}, {reporterCounty} and I work with Parrot
        which is located at {ParrotContact.ADDRESS}.
      </>
    )
  }

  if (isEUO) {
    return (
      <>
        Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
        {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
        the time is {dateTime.time} {dateTime.timezone}. We are present to
        record the Examination Under Oath of {witness?.name ?? '<WITNESS>'}. My
        name is {name}, I will be the Parrot Digital Reporter for today’s
        proceedings.
      </>
    )
  }

  return (
    <>
      Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
      {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
      the time is {dateTime.time} {dateTime.timezone}. We are present to record{' '}
      {isExaminationUnderOath(event) ? 'an' : 'a'}{' '}
      {getEventTypeLongLabel(event)} in the case of {event.case.name}, court
      case number {caseNumber}. My name is {name}, I will be the Parrot Digital
      Reporter for today’s {getEventTypeShortLabel(event)}.
    </>
  )
}
