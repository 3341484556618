import { useEffect, useState } from 'react'

import { ScriptAttendee } from '@/types'

// TODO: Experimental warnings, has to be improved
export const useGlobalAttendeesWarnings = (
  attendees: ScriptAttendee[],
): {
  warnings: string[]
} => {
  const [warnings, setWarnings] = useState<string[]>([])

  const addWarning = (warning: string) => {
    setWarnings(prev => {
      if (!prev.includes(warning)) {
        return [warning, ...prev]
      }

      return prev
    })
  }

  const clearWarnings = () => {
    setWarnings([])
  }

  useEffect(() => {
    clearWarnings()
    // there should be exactly one digital reporter, and it should be the current user
    const digitalReporters = attendees.filter(
      attendee => attendee.role === 'digital_reporter',
    )

    if (digitalReporters.length === 0) {
      addWarning('There is no Digital Reporter attendee')
    }

    // DR has multiple participants mapped
    if (digitalReporters[0]?.zoom_participants?.length! > 1) {
      addWarning(
        `The digital reporter has multiple participants assigned – ${digitalReporters[0]?.zoom_participants
          ?.map(e => e.user_name)
          .join(', ')}`,
      )
    }

    // All attendees share one participant
    const participantsMapped: Record<string, { count: number; name: string }> =
      {}
    for (const attendee of attendees) {
      for (const participant of attendee.zoom_participants!) {
        if (participantsMapped[participant.id]) {
          participantsMapped[participant.id].count += 1
        } else {
          participantsMapped[participant.id] = {
            count: 1,
            name: participant.user_name,
          }
        }
      }
    }
    for (const { count, name } of Object.values(participantsMapped)) {
      if (count === attendees.length) {
        addWarning(
          `The participant with name ${name} has been assigned to represent all attendees, which seems highly improbable.`,
        )
        break
      }
    }
  }, [attendees])

  return { warnings }
}
