import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { useModals } from '@/hooks/useModals'
import { useResendWelcomeEmailMutation } from '@/queries/reporters'
import { ReporterBase } from '@/types'

export const useResendWelcomeEmail = (reporter: ReporterBase) => {
  const modals = useModals()

  const { mutateAsync } = useResendWelcomeEmailMutation(reporter, {
    onSuccess: () => {
      showNotification({
        title: <Text weight={600}>Mail send</Text>,
        message: 'Welcome email has been re-send',
      })
    },
    onError: () => {
      showNotification({
        color: 'red',
        message: 'Re-send failed, please try again.',
      })
    },
  })

  return () => {
    modals.openConfirmModal({
      title: 'Re-send welcome email?',
      children: (
        <Text size="sm">This will force user to reset their's password.</Text>
      ),
      labels: { confirm: 'Re-send', cancel: 'Cancel' },
      onConfirm: mutateAsync,
    })
  }
}
