import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { RocketIcon } from '@radix-ui/react-icons'
import max from 'date-fns/max'
import min from 'date-fns/min'
import parseISO from 'date-fns/parseISO'

import { toCalendarEvent } from '@/components/Calendar/Calendar'
import { CalendarEvent } from '@/components/Calendar/CalendarEvent'
import { CalendarScreen } from '@/components/CalendarScreen'
import { useReporter } from '@/providers/UserProvider'
import { useWaitingReporterEventsQuery } from '@/queries/reporterEvents'
import { ReporterEvent } from '@/types'

export const Waiting = () => {
  const reporter = useReporter()
  const { data: waitingEvents, isLoading } = useWaitingReporterEventsQuery(
    reporter.id,
  )

  const visibleInterval = React.useMemo(() => {
    if (waitingEvents === undefined) {
      return undefined
    }

    const dates = waitingEvents.map(e => parseISO(e.datetime))
    const [minDate, maxDate] = [min(dates), max(dates)]
    return {
      start: minDate,
      end: maxDate,
    }
  }, [waitingEvents])

  return (
    <CalendarScreen
      events={waitingEvents?.map(toCalendarEvent) ?? []}
      initialDate={new Date()}
      initialView="listCustom"
      visibleInterval={visibleInterval}
      isLoading={isLoading}
      renderEvent={(event, { view }) => (
        <CalendarEvent event={event as ReporterEvent} view={view} />
      )}
      noEventsContent={<NoWaitingEvents />}
      header={{
        middle: (
          <Title
            className="text-center text-ellipsis whitespace-nowrap overflow-hidden"
            order={6}
          >
            Events waiting for action
          </Title>
        ),
      }}
    />
  )
}

export const NoWaitingEvents = () => {
  const theme = useMantineTheme()

  return (
    <div className="max-w-sm">
      <Stack spacing="xl" align="center">
        <RocketIcon
          className="w-20 h-20"
          style={{ color: theme.colors.gray[3] }}
        />
        <Text align="center">
          No events are waiting for your action, well done, keep doing great
          work!
        </Text>
        <Button component={Link} to="/calendar" variant="light" color="gray">
          Back to calendar
        </Button>
      </Stack>
    </div>
  )
}
