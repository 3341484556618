import React from 'react'
import { Text } from '@mantine/core'

import { useAuth } from '@/hooks/useAuth'
import { Event } from '@/types'
import { isAdminEvent } from '@/utils/parrot'
import { formatDate, formatDuration, formatTimeRange } from '@/utils/time'

export const RelatedEventsNotice: React.FC<{
  event: Event
  action: 'book' | 'cancel'
  relatedEvents: Event[]
}> = ({ event, action, relatedEvents }) => {
  const { user } = useAuth()
  const isAdmin = user?.role === 'admin'

  return (
    <Text size="sm">
      <div className="mb-4">
        {event.related_events_type === 'back-to-back' && (
          <BackToBackEventsInfo
            event={event}
            backToBackEvents={relatedEvents}
          />
        )}
        {event.related_events_type === 'hearing' && (
          <HearingEventsInfo hearingEvents={relatedEvents} />
        )}{' '}
        {action === 'book' ? (
          <>
            To continue booking this event, you{' '}
            {isAdmin ? 'should assign' : 'must be available to work'} the
            complete block. See details below.
          </>
        ) : (
          <>
            To prevent booking overlaps, you {isAdmin ? 'should' : 'need to'}{' '}
            cancel all following events.
          </>
        )}
      </div>

      <ul className="list-disc ml-8">
        <li key={event.id}>
          <span className="font-bold">(Selected)</span>{' '}
          <EventShortInfo event={event} />
        </li>

        {relatedEvents.map(relatedEvent => (
          <li key={relatedEvent.id}>
            <EventShortInfo event={relatedEvent} />
          </li>
        ))}
      </ul>
    </Text>
  )
}

export const BackToBackEventsInfo: React.FC<{
  event: Event
  backToBackEvents: Event[]
}> = ({ event, backToBackEvents }) => {
  return (
    <>
      This client has {backToBackEvents.length + 1} events on{' '}
      <Text component="span" size="sm" weight={600}>
        {formatDate(event.datetime, { long: false, separator: ', ' })}.
      </Text>
    </>
  )
}

export const HearingEventsInfo: React.FC<{
  hearingEvents: Event[]
}> = ({ hearingEvents }) => {
  return (
    <>
      This event is part of hearing which consists of {hearingEvents.length + 1}{' '}
      events.
    </>
  )
}

const EventShortInfo: React.FC<{ event: Event }> = ({ event }) => {
  return (
    <>
      {event.name} at {formatTimeRange(event.datetime, event.duration)} (
      {formatDuration(event.duration)})
      {isAdminEvent(event) &&
        event.reporter !== null &&
        ` assigned to ${event.reporter.name}`}
    </>
  )
}
