import { ComponentProps, forwardRef, ReactNode, useState } from 'react'
import {
  createStyles,
  getStylesRef,
  Group,
  Text,
  TextInput,
} from '@mantine/core'
import { CheckIcon, Cross1Icon, QuestionMarkIcon } from '@radix-ui/react-icons'

import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { LowConfidenceWordStatus } from '@/constants'
import { AsrWord, Event, LowConfidenceWord } from '@/types'
import { secToMsec } from '@/utils/time'

const getLowConfidenceWordReviewUrl = ({
  event: { livestream_url },
  word: { start_time, end_time },
}: {
  event: Event
  word: AsrWord
}) => {
  if (livestream_url === undefined) {
    return undefined
  }

  const url = new URL(livestream_url)
  url.searchParams.set(
    'active',
    `time-${secToMsec(start_time)}-${secToMsec(end_time)}`,
  )
  return url.toString()
}

const useLowConfidenceWordStyles = createStyles(() => {
  return {
    rightSection: {
      ref: getStylesRef('rightSection'),
      display: 'none',
    },
    input: {
      ref: getStylesRef('input'),
    },
    wrapper: {
      [`input:focus + .${getStylesRef('rightSection')}`]: {
        display: 'block',
      },
      [`&:hover .${getStylesRef('rightSection')}`]: {
        display: 'block',
      },
    },
  }
})

export const LowConfidenceWordInput = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<typeof TextInput>, 'value' | 'onChange'> & {
    event: Event
    value: LowConfidenceWord
    onChange: (lowConfidenceWord: LowConfidenceWord) => void
  }
>(({ event, value: word, onChange, ...rest }, ref) => {
  const [correctText, setCorrectText] = useState(word.correct_text)
  const { classes } = useLowConfidenceWordStyles()

  const isResolved = word.status !== LowConfidenceWordStatus.UNRESOLVED
  const reviewUrl = getLowConfidenceWordReviewUrl({ event, word })

  const icon: Record<LowConfidenceWord['status'], ReactNode> = {
    unresolved: <Cross1Icon color="red" />,
    corrected: <CheckIcon color="green" />,
    unclear: <QuestionMarkIcon color="orange" />,
  }

  const setStatus = (status: LowConfidenceWordStatus) =>
    onChange({ ...word, status, correct_text: correctText })

  const setUnresolved = () => setStatus(LowConfidenceWordStatus.UNRESOLVED)
  const setCorrected = () => setStatus(LowConfidenceWordStatus.CORRECTED)
  const setUnclear = () => setStatus(LowConfidenceWordStatus.UNCLEAR)

  const rightSection = isResolved ? (
    <Group position="right" px="xs" spacing="xs">
      <SubtleButton fz="xs" px={0} onClick={setUnresolved}>
        Edit
      </SubtleButton>
    </Group>
  ) : (
    <Group position="right" px="xs" spacing="xs">
      <SubtleButton
        fz="xs"
        px={0}
        component="a"
        href={reviewUrl}
        target="child"
      >
        Review
      </SubtleButton>
      <Text color="gray.4">|</Text>
      <SubtleButton fz="xs" px={0} onClick={setUnclear}>
        Unclear
      </SubtleButton>
      <SubtleButton fz="xs" px={0} onClick={setCorrected}>
        Correct
      </SubtleButton>
    </Group>
  )

  return (
    <TextInput
      ref={ref}
      classNames={classes}
      icon={icon[word.status]}
      value={correctText}
      onChange={e => setCorrectText(e.currentTarget.value)}
      placeholder="No words"
      readOnly={isResolved}
      rightSectionWidth="auto"
      rightSection={rightSection}
      {...rest}
    />
  )
})

LowConfidenceWordInput.displayName = 'LowConfidenceWordInput'
