import React, { createContext, useEffect, useMemo } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'

import { usePageViewTracking } from '@/hooks/analytics/useAnalytics'
import { useAuth } from '@/hooks/useAuth'

const createAnalytics = (): AnalyticsBrowser | undefined => {
  const writeKey = import.meta.env.VITE_SEGMENT_KEY as string | undefined
  const cdnURL = import.meta.env.VITE_SEGMENT_CDN as string | undefined

  if (!writeKey) {
    return undefined
  }
  return AnalyticsBrowser.load({ writeKey, cdnURL: cdnURL || undefined })
}

export const AnalyticsContext = createContext<AnalyticsBrowser | undefined>(
  undefined,
)

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { user } = useAuth()
  const analytics = useMemo(createAnalytics, [])

  useEffect(() => {
    if (!user || !analytics) {
      return
    }

    analytics.identify(user.id, { email: user.email, role: user.role })
  }, [analytics, user])

  usePageViewTracking(analytics)

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
