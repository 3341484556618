import React from 'react'
import { Box, Center, Group } from '@mantine/core'

import { CalendarRef } from '@/components/Calendar/Calendar'
import { ViewControl } from '@/components/Calendar/ViewControl'
import { CalendarProps } from '@/components/CalendarScreen'
import { Controls } from '@/components/Header/CalendarHeader/components/Controls'
import { Left } from '@/components/Header/CalendarHeader/components/Left'
import { Right } from '@/components/Header/CalendarHeader/components/Right'
import { useReporter } from '@/providers/UserProvider'

type Props = {
  onBurgerMenuClick: () => void
  title: string | undefined
  calendarProps: CalendarProps
  calendarRef: React.RefObject<CalendarRef>
  isBurgerMenuOpen: boolean
  waitingEventsCount?: number
}

export const DefaultHeader: React.FC<Props> = ({
  calendarProps,
  calendarRef,
  onBurgerMenuClick,
  title,
  isBurgerMenuOpen,
  waitingEventsCount,
}) => {
  const reporter = useReporter()

  return (
    <Box className="h-full px-4 flex justify-between items-center gap-4">
      <Group className="w-full gap-2" noWrap>
        <Left
          isBurgerMenuOpen={isBurgerMenuOpen}
          onBurgerMenuClick={onBurgerMenuClick}
          onTodayClick={() => calendarRef.current?.today()}
        />
        <Controls
          onPrevClick={() => calendarRef.current?.prev()}
          onNextClick={() => calendarRef.current?.next()}
          onTodayClick={() => calendarRef.current?.today()}
          title={title}
        />
      </Group>
      <Center className="w-fit">
        <ViewControl
          value={calendarProps.initialView}
          onChange={view => calendarRef.current?.changeView(view)}
        />
      </Center>
      <Right
        waitingEventsCount={waitingEventsCount}
        showHelpCenter={reporter?.role === 'digital_reporter'}
      />
    </Box>
  )
}
