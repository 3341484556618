import React from 'react'
import { Box, Flex } from '@mantine/core'

import { Details } from '@/components/Event/NewScript/components/common/Layout/Details'
import { StepsNavigation } from '@/components/Event/NewScript/components/common/Layout/StepsNavigation'
import { Event } from '@/types'

export type ScriptColumnLayoutProps = {
  compact?: boolean
  showNavigation?: boolean
  event: Event
}

export const ScriptColumnLayout: React.FC<ScriptColumnLayoutProps> = ({
  event,
  compact = false,
  showNavigation = false,
  children,
}) => {
  return (
    <Flex
      gap="md"
      justify="space-between"
      align="flex-start"
      direction="row"
      wrap="nowrap"
      p="xl"
    >
      {!compact && (
        <Box>{showNavigation && <StepsNavigation event={event} />}</Box>
      )}
      <Box w={740}>
        {compact && showNavigation && (
          <StepsNavigation event={event} collapsed />
        )}
        {children}
      </Box>
      <Box w={280}>
        <Details event={event} />
      </Box>
    </Flex>
  )
}
