import * as yup from 'yup'

import { IdentityDocumentStatus, IdentityDocumentType } from '@/constants'

export const identityDocumentSchema = yup.object({
  status: yup
    .mixed<IdentityDocumentStatus>()
    .oneOf(Object.values(IdentityDocumentStatus))
    .required('Status is required'),
  document_type: yup
    .mixed<IdentityDocumentType>()
    .oneOf(Object.values(IdentityDocumentType))
    .when('status', {
      is: (value: string) =>
        ![
          IdentityDocumentStatus.NOT_PRESENT,
          IdentityDocumentStatus.STIPULATE,
        ].includes(value as IdentityDocumentStatus),
      then: schema => schema.required('Document type is required'),
      otherwise: schema => schema.optional().nullable(),
    }),
  number: yup
    .string()
    .trim()
    .default(null)
    .when(
      ['status', '$isDocumentNumberRequired'],
      ([status, isDocumentNumberRequired], schema) => {
        if (
          ![
            IdentityDocumentStatus.NOT_PRESENT,
            IdentityDocumentStatus.STIPULATE,
          ].includes(status as IdentityDocumentStatus) &&
          isDocumentNumberRequired
        ) {
          return schema.required('Document number is required')
        }

        return schema.optional().nullable()
      },
    ),
})
