import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Group, Stack, Text, Textarea, Tooltip } from '@mantine/core'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'

import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'

export const NotesForm: React.FC = () => {
  const methods = useFormContext()

  return (
    <Stack>
      <Stack spacing={4}>
        <Group spacing={4}>
          <Text size="sm" fw={500}>
            Notes
          </Text>
          <Tooltip
            label="Note down any unusual events that occur during the progression that are good for the Parrots Event Operators to be aware of."
            multiline
            width={400}
          >
            <QuestionMarkCircledIcon />
          </Tooltip>
        </Group>
        <Textarea
          {...methods.register('notes')}
          error={!!methods.formState.errors.notes?.message}
          minRows={4}
        />
      </Stack>
      <FormErrors errors={methods.formState.errors.notes?.message} />
    </Stack>
  )
}
