import React, { useState } from 'react'

import { BooleanSelect } from '@/components/common/BooleanSelect'
import { AttendeeFieldCard } from '@/components/Event/NewScript/components/Review/AttendeeFieldCard/AttendeeFieldCard'
import { ScriptAttendee } from '@/types'

type WitnessFieldCardProps = {
  witness: ScriptAttendee
  read: boolean
  onSubmitClick: (value: boolean) => void
}

export const WitnessFieldCard: React.FC<WitnessFieldCardProps> = ({
  witness,
  read,
  onSubmitClick,
}) => {
  const [selectedValue, setSelectedValue] = useState<boolean>(read)

  return (
    <AttendeeFieldCard
      attendee={witness}
      fieldValue={read ? 'Read' : 'Waived'}
      onSubmitClick={() => onSubmitClick(selectedValue)}
      onCancelClick={() => setSelectedValue(read)}
      fieldInput={
        <BooleanSelect
          value={selectedValue}
          onChange={(value: boolean) => setSelectedValue(value)}
          falseLabel="Waive"
          trueLabel="Read"
          placeholder="Select option"
          label="Read or waive"
        />
      }
    />
  )
}
