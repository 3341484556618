import React from 'react'
import { Select } from '@mantine/core'

import { STATES } from '@/utils/states'

export const StateSelect: React.FC<
  {
    value?: string | null
    states: string[]
    onChange: (value: string | null) => void
  } & Omit<React.ComponentProps<typeof Select>, 'value' | 'data' | 'onChange'>
> = ({ value, onChange, states, ...props }) => {
  const statesOptions = states.map(state => ({
    value: state,
    // show the value as label in case it is invalid state code
    label: state in STATES ? STATES[state] : state,
  }))

  return (
    <Select
      {...props}
      data={statesOptions}
      value={value}
      onChange={onChange}
      searchable
    />
  )
}
