import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, Text } from '@mantine/core'
import { v4 as uuid } from 'uuid'

import { AttendeeForm } from '@/components/Event/NewScript/components/common/forms/AttendeeForm'
import { SubtleButton } from '@/components/Event/NewScript/components/common/Layout/SubtleButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { attendeeSchema } from '@/components/Event/NewScript/schemas'
import { EventType } from '@/constants'
import { ScriptAttendee, ZoomParticipant } from '@/types'

type AddAttendeeFormProps = {
  onSelectClick: () => void
  participant: ZoomParticipant
  onSave: (data: ScriptAttendee) => void
  timezone: string
  eventType: EventType
}

export const AddAttendeeForm: React.FC<AddAttendeeFormProps> = ({
  onSelectClick,
  participant,
  onSave,
  timezone,
  eventType,
}) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(attendeeSchema),
    context: {
      isZoomParticipantsMappingRequired: false,
    },
    defaultValues: {
      attendee_id: uuid(),
      name: '',
      email: '',
      role: undefined,
      present: true,
      transcript_order: null,
      identity_document: null,
      zoom_participants: [],
    },
  })

  return (
    <FormProvider {...methods}>
      <ActionContainer>
        <Stack>
          <Text fw={600} mb="md" size="sm">
            Add new attendee for "{participant.user_name}" Zoom account:
          </Text>
          <Stack>
            <AttendeeForm
              timezone={timezone}
              zoomParticipants={[]}
              eventType={eventType}
              isPresenceDisabled={false}
            />
          </Stack>
        </Stack>
      </ActionContainer>
      <SubtleButton p={0} onClick={onSelectClick}>
        Select existing attendee
      </SubtleButton>
      <Stack align="flex-end">
        <Button
          type="submit"
          onClick={methods.handleSubmit(onSave)}
          disabled={Object.keys(methods.formState.errors).length > 0}
        >
          Confirm
        </Button>
      </Stack>
    </FormProvider>
  )
}
