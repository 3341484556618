import React from 'react'
import * as Sentry from '@sentry/react'

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_RELEASE_VERSION ?? '',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({ maskAllText: false, blockAllMedia: false }),
    ],
    ignoreErrors: [
      // NetworkErrors are quite ambiguous and the sentry report
      // doesn't tell us anything useful. We simply filter them out.
      'Network Error',
      'Request Aborted',
      // Known issue with ResizeObserver
      // https://github.com/WICG/resize-observer/issues/38
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      // Safari <= 14 emits this error when assets fetch is cancelled by the user
      // in most cases this means that the user has navigated away from the site
      // and no actual stack trace or screen recording is available
      'TypeError: cancelled',
      // Ignore errors from the browser extensions
      /.*@webkit-masked-url.*/,
    ],
    denyUrls: [/fast\.chameleon\.io/i, /helpscout\.net/i],
    tracesSampleRate: 1.0,

    // sample all sessions without errors
    replaysSessionSampleRate: 1.0,

    // sample all sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

export const SentryProvider: React.FC = ({ children }) => {
  return <>{children}</>
}
