import { useMemo, useState } from 'react'

import { Filters } from './FilterControls'

import { toCalendarEvent } from '@/components/Calendar/Calendar'
import { useCalendarEvents } from '@/queries/calendar'
import * as urls from '@/queries/urls'
import { CalendarParams } from '@/queries/useCalendarParams'
import { ReporterEvent } from '@/types'

export type UseFilteredCalendarEventsProps = {
  reporterId: string
  calendarParams: CalendarParams
  initialFilters: Filters
}

export const useFilteredCalendarEvents = ({
  reporterId,
  calendarParams,
  initialFilters,
}: UseFilteredCalendarEventsProps) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)

  const {
    events: allEvents,
    isFetching,
    refetch,
  } = useCalendarEvents<ReporterEvent[]>({
    url: urls.reporterEvents(reporterId),
    calendarParams,
  })

  const filterByReporterStatus = (event: ReporterEvent): boolean => {
    return filters.reporter_status_in.includes(event.reporter_status)
  }

  const filterByName = (event: ReporterEvent): boolean => {
    const normalize = (s: string) => s.toLowerCase().trim()

    return normalize(event.name).includes(normalize(filters.search ?? ''))
  }

  const events = useMemo(() => {
    return allEvents
      .filter(filterByReporterStatus)
      .filter(filterByName)
      .map(toCalendarEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents, filters])

  return {
    events,

    filters,
    setFilters,

    isFetching,
    refetch,
  }
}
