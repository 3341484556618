import React from 'react'
import { useHistory } from 'react-router-dom'
import { ActionIcon, Box, Center, Header as MantineHeader } from '@mantine/core'
import { ArrowLeftIcon } from '@radix-ui/react-icons'

import { UserMenu } from '@/components/Header/UserMenu'
import { HEADER_HEIGHT } from '@/constants'

export type HeaderProps = {
  middle?: React.ReactNode
  right?: React.ReactNode
  hasHistory?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  middle,
  right,
  hasHistory = true,
}) => {
  const history = useHistory()

  const handleBackIconClick = () =>
    history.length > 1 ? history.goBack() : history.push('/')

  return (
    <MantineHeader height={HEADER_HEIGHT} fixed zIndex={100}>
      <Box className="h-full px-4 flex justify-between items-center gap-4">
        <Box className="xs:w-1/3">
          {hasHistory && (
            <ActionIcon variant="light" onClick={handleBackIconClick}>
              <ArrowLeftIcon color="black" />
            </ActionIcon>
          )}
        </Box>
        <Center className="xs:w-1/3 px-2 overflow-hidden xs:overflow-visible">
          {middle}
        </Center>
        <Box className=" flex xs:w-1/3 justify-end items-center gap-4">
          {right}
          <UserMenu />
        </Box>
      </Box>
    </MantineHeader>
  )
}
