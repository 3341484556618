import React from 'react'
import { useHistory } from 'react-router-dom'

import { FilterControls } from './FilterControls'
import { useFilteredCalendarEvents } from './useFilteredCalendarEvents'

import { CalendarEvent } from '@/components/Calendar/CalendarEvent'
import { CalendarScreen } from '@/components/CalendarScreen'
import {
  CalendarParams,
  toCalendarParamsPath,
  useCalendarParams,
} from '@/queries/useCalendarParams'
import { NotFound } from '@/routes/NotFound'
import { Event } from '@/types'

export const Dashboard = () => {
  const calendarParams = useCalendarParams()

  if (!calendarParams) {
    return <NotFound />
  }

  return <DashboardScreen calendarParams={calendarParams} />
}

const DashboardScreen: React.FC<{
  calendarParams: CalendarParams
}> = ({ calendarParams }) => {
  const history = useHistory()

  const { events, filters, setFilters, isFetching, refetch, states } =
    useFilteredCalendarEvents({
      calendarParams,
      initialFilters: {
        general_status_in: [
          'available',
          'booked',
          'past',
          'waiting',
          'unavailable',
        ],
        reporter_status_in: ['available', 'booked', 'past', 'waiting'],
      },
    })

  return (
    <CalendarScreen
      events={events ?? []}
      initialView={calendarParams.view}
      initialDate={calendarParams.date}
      renderEvent={(event, { view }) => (
        <CalendarEvent event={event as Event} view={view} onChange={refetch} />
      )}
      onStateChange={({ date, view }) => {
        if (view === 'listCustom') {
          return
        }

        history.push(`/admin/calendar/${toCalendarParamsPath(view, date)}`)
      }}
      isLoading={isFetching}
      hotkeysEnabled={true}
      sidebar={
        <FilterControls
          states={states}
          values={filters}
          onChange={setFilters}
        />
      }
    />
  )
}
