import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Anchor,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { GearIcon } from '@radix-ui/react-icons'

import { RelatedEventsNotice } from './RelatedEventsNotice'

import { ReporterSelect } from '@/components/ReporterSelect'
import { useTrackUserAction } from '@/hooks/analytics/useAnalytics'
import { useAuth } from '@/hooks/useAuth'
import { useModals } from '@/hooks/useModals'
import { useAvailableReportersQuery } from '@/queries/adminEvents'
import { useBookMutation } from '@/queries/events'
import { useRelatedEvents } from '@/queries/relatedEvents'
import { Event, Reporter, ReporterEvent } from '@/types'
import { isReporterEvent } from '@/utils/parrot'
import { formatDuration, formatTimeRange } from '@/utils/time'

type ButtonPropsButton = ButtonProps & React.ComponentPropsWithoutRef<'button'>

export const BookEventButton: React.FC<{
  event: Event
  onBook: () => void
  onCancel?: () => void
  text?: string
  buttonProps?: Omit<ButtonPropsButton, 'onClick' | 'loading'>
}> = ({ event, onBook, onCancel, text, buttonProps = {} }) => {
  const { user } = useAuth()
  const label =
    text ?? (user?.role === 'admin' ? 'Assign to reporter' : 'Book event')

  const { book, isLoading } = useBook(event, onBook, onCancel)

  return (
    <Button onClick={book} loading={isLoading} {...buttonProps}>
      {label}
    </Button>
  )
}

export const ConfirmBookingModal: React.FC<{
  event: ReporterEvent
  relatedEvents: Event[]
  onBook: () => void
  onCancel?: () => void
}> = ({ event, relatedEvents, onBook, onCancel }) => {
  const { user } = useAuth()
  const modals = useModals()
  const trackUserAction = useTrackUserAction()

  const { mutateAsync, isLoading } = useBookMutation(event, {
    onSuccess: () => {
      // we don't use confirm modal here so we have to close the modal manually
      trackUserAction('Event booked', { event_id: event.id })
      modals.closeAll()
      showNotification({
        title: <Text weight={600}>Event booked</Text>,
        message: 'All details have been sent to your email',
      })
      onBook()
    },
    onReporterCommissionExpired: () => {
      modals.closeAll()

      modals.openModal({
        title: 'Your commission has expired',
        children: (
          <div className="flex flex-col space-y-4">
            <Alert color="red">
              <div className="flex text-red-500 justify-center p-1">
                <GearIcon className="w-12 h-12" />
              </div>
            </Alert>
            <Text size="sm">
              Your commission is not valid at the time of the event. Please go
              to your settings and update "commission expiration date" before
              you can book this event.
            </Text>
            <div className="flex gap-x-2 mt-8 justify-end">
              <Button
                variant="light"
                color="gray"
                onClick={() => modals.closeAll()}
              >
                Cancel
              </Button>
              <Button
                component={Link}
                to="/settings"
                onClick={() => modals.closeAll()}
              >
                Take me to settings
              </Button>
            </div>
          </div>
        ),
        centered: true,
        onClose: onCancel,
      })
    },
    onErrorMessage: message => {
      // we don't use confirm modal here so we have to close the modal manually
      modals.closeAll()

      showNotification({ color: 'red', message })

      onCancel?.()
    },
  })

  return (
    <>
      <Box mb="md">
        {relatedEvents.length > 0 ? (
          <Box mb="xl">
            <RelatedEventsNotice
              event={event}
              action="book"
              relatedEvents={relatedEvents}
            />
          </Box>
        ) : (
          <Text size="sm">
            <div className="mb-4">
              Do you want to book {event.name} at{' '}
              {formatTimeRange(event.datetime, event.duration)} (
              {formatDuration(event.duration)})?
            </div>
            <div>
              The above duration is only estimated, and events often run longer.
            </div>
          </Text>
        )}
      </Box>
      <Group position="right">
        <Button variant="light" color="gray" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={isLoading}
          onClick={() => mutateAsync({ reporter_id: user!.id })}
        >
          {relatedEvents.length > 0 ? 'Book all events' : 'Book event'}
        </Button>
      </Group>
    </>
  )
}

const useBook = (
  event: Event,
  onBook: () => void,
  onCancel: (() => void) | undefined,
) => {
  const modals = useModals()
  const { user } = useAuth()
  const { isLoading, refetch } = useRelatedEvents(event, 'available', {
    enabled: false,
  })

  const showConfirmModal = (relatedEvents: Event[]) => {
    if (user?.role === 'reporter' && isReporterEvent(event)) {
      modals.openModal({
        title:
          relatedEvents?.length > 0
            ? 'You need to book multiple events'
            : 'Do you want to book this event?',
        children: (
          <ConfirmBookingModal
            event={event}
            relatedEvents={relatedEvents}
            onBook={onBook}
            onCancel={onCancel}
          />
        ),
        onClose: onCancel,
        centered: true,
      })
    } else {
      modals.openModal({
        title:
          relatedEvents?.length > 0
            ? 'Assign multiple events?'
            : 'Assign event to reporter',
        children: (
          <ConfirmAssignModal
            event={event}
            relatedEvents={relatedEvents}
            onBook={onBook}
            onCancel={onCancel}
          />
        ),
        onClose: onCancel,
        centered: true,
      })
    }
  }

  return {
    isLoading,
    book: () => refetch().then(r => showConfirmModal(r.data ?? [])),
  }
}

export const ConfirmAssignModal: React.FC<{
  event: Event
  relatedEvents: Event[]
  onBook: () => void
  onCancel?: () => void
}> = ({ event, relatedEvents, onBook, onCancel }) => {
  const modals = useModals()
  const [selectedReporter, setSelectedReporter] = useState<{
    reporter: Reporter
    available: boolean
  }>()
  const [showUnavailable, setShowUnavailable] = useState(false)
  const {
    data: reportersWithAvailability = [],
    isLoading: areReportersLoading,
    isFetched: areReportersFetched,
  } = useAvailableReportersQuery(event)

  const onSuccess = () => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()
    showNotification({
      title: <Text weight={600}>Event booked</Text>,
      message: "All details have been sent to reporter's email",
    })
    onBook()
  }

  const onErrorMessage = (message: string) => {
    // we don't use confirm modal here so we have to close the modal manually
    modals.closeAll()

    showNotification({ color: 'red', message })

    onCancel?.()
  }

  const { mutateAsync: book, isLoading: isBookLoading } = useBookMutation(
    event,
    {
      onSuccess,
      onErrorMessage,
    },
  )

  const { mutateAsync: bookAll, isLoading: isBookAllLoading } = useBookMutation(
    event,
    {
      onSuccess,
      onErrorMessage,
    },
    { book_related_events: true },
  )

  const onReporterSelectChange = (reporterId?: string) => {
    setSelectedReporter(
      reportersWithAvailability.find(
        ({ reporter }) => reporter.id === reporterId,
      ),
    )
  }

  return (
    <>
      <Box mb={20}>
        <Stack>
          {selectedReporter?.available === false && (
            <Alert title="Verify reporter selection" color="orange">
              With safe booking off, we do not check if the reporter is able to
              book the event. Please verify this yourself before proceeding.
            </Alert>
          )}
          {event.has_related_events ? (
            <RelatedEventsNotice
              event={event}
              action="book"
              relatedEvents={relatedEvents}
            />
          ) : (
            <>
              Listed are reporters available and with sufficient experience
              level.
            </>
          )}
          <div>
            <ReporterSelect
              label="Assign event to"
              value={selectedReporter?.reporter.id}
              reporters={reportersWithAvailability
                .filter(r => showUnavailable || r.available)
                .map(r => r.reporter)}
              onChange={onReporterSelectChange}
              showState={showUnavailable}
              placeholder={
                areReportersLoading ? 'Loading...' : 'Select reporter'
              }
              error={
                areReportersFetched &&
                reportersWithAvailability.length === 0 &&
                'There are no reporters available'
              }
              withinPortal
            />
            <Box mt="md">
              <Tooltip
                label="Shows every reporter on the platform"
                withArrow
                withinPortal
              >
                <Checkbox
                  label={
                    <Anchor>
                      <Text size="sm">Turn off safe booking</Text>
                    </Anchor>
                  }
                  checked={showUnavailable}
                  onChange={event =>
                    setShowUnavailable(event.currentTarget.checked)
                  }
                />
              </Tooltip>
            </Box>
          </div>
        </Stack>
      </Box>
      <Group position="right">
        <Button variant="light" color="gray" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        {event.has_related_events ? (
          <>
            <Button
              variant="light"
              color="gray"
              disabled={isBookAllLoading || selectedReporter === undefined}
              loading={isBookLoading}
              onClick={() =>
                book({ reporter_id: selectedReporter!.reporter.id })
              }
            >
              Only selected
            </Button>
            <Button
              variant="filled"
              disabled={isBookLoading || selectedReporter === undefined}
              loading={isBookAllLoading}
              onClick={() =>
                bookAll({ reporter_id: selectedReporter!.reporter.id! })
              }
            >
              All unassigned
            </Button>
          </>
        ) : (
          <Button
            variant="filled"
            disabled={selectedReporter === undefined}
            loading={isBookLoading}
            onClick={() => book({ reporter_id: selectedReporter!.reporter.id })}
          >
            Assign to reporter
          </Button>
        )}
      </Group>
    </>
  )
}
