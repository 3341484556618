import React from 'react'

export const OptionalPart: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  return (
    <div className="border border-dashed border-black p-8 my-4">
      <span className="font-bold">{title}</span>
      {children}
    </div>
  )
}
